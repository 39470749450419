import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Good, {isSpecialTile} from '../goods/Good'
import {specialTiles} from '../goods/SpecialTile'

export default class Host extends OccupationRules {
  immediateEffect() {
    const specialTiles = countPlayerSpecialTiles(this.player)
    if (specialTiles < 3) return []
    const good = specialTiles === 3 ? Good.Silk : specialTiles === 4 ? Good.Spices : Good.Jewelry
    return [receiveGoodsMove(this.player.color, good)]
  }
}

export function countPlayerSpecialTiles(player: Player | PlayerView) {
  return countSpecialTilesInStock(player) + countSpecialTilesOnBoards(player)
}

function countSpecialTilesInStock(player: Player | PlayerView) {
  return specialTiles.reduce((sum, good) => sum + (player.goods[good] ?? 0), 0)
}

function countSpecialTilesOnBoards(player: Player | PlayerView) {
  return player.placedGoodsAreas.reduce((sum, area) =>
      sum + area.placedGoods.reduce((sum, placedGood) =>
          isSpecialTile(placedGood.good) ? sum + 1 : sum
        , 0)
    , 0)
}
