import OccupationRules from './OccupationRules'
import {countSheep} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Shepherd extends OccupationRules {
  immediateEffect() {
    const sheep = countSheep(this.player)
    return sheep >= 2 ? [receiveGoodsMove(this.player.color, {[Good.Wool]: sheep >= 4 ? 2 : 1, [Good.Milk]: 1})] : []
  }
}
