/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import {PlayMoveButton} from '../PlayMoveButton'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function MeatCurerChoice({player}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="meat.curer.effect" components={[
          <PlayMoveButton move={spendWeaponsMove(player.color, Weapon.Snare)} disabled={!player.weapons[Weapon.Snare]}/>,
          <GoodItem css={inlineGood} good={Good.SaltMeat} height={1.5}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
