enum Building {
  Shed = 5, StoneHouse, LongHouse
}

export default Building

export function isHouse(building: Building) {
  return building === Building.StoneHouse || building === Building.LongHouse
}

export const buildingValue: Record<Building, number> = {
  [Building.Shed]: 8,
  [Building.StoneHouse]: 10,
  [Building.LongHouse]: 17
}
