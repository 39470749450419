import Game from '../Game'
import GameView, {isGameView} from '../GameView'
import Weapon, {weapons} from '../material/Weapon'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'
import Move from './Move'
import {shuffleWeaponsDeckMove} from './ShuffleWeaponsDeck'
import {receiveWeaponsMove} from './ReceiveWeapons'

type DrawWeapon = {
  type: MoveType.DrawWeapon
  player: PlayerColor
}

export default DrawWeapon

export type DrawWeaponView = DrawWeapon & {
  weapon: Weapon
}

export function drawWeaponMove(player: PlayerColor): DrawWeapon {
  return {type: MoveType.DrawWeapon, player}
}

export function drawWeapon(game: Game, move: DrawWeapon) {
  const player = game.players.find(player => player.color === move.player)!
  addWeapon(player, game.weaponsDeck.shift()!)
}

export function drawWeaponInView(game: GameView, move: DrawWeaponView) {
  const player = game.players.find(player => player.color === move.player)!
  addWeapon(player, move.weapon)
  game.weaponsDeck--
}

export function addWeapon(player: Player | PlayerView, weapon: Weapon) {
  const previousQuantity = player.weapons[weapon] ?? 0
  player.weapons[weapon] = previousQuantity + 1
}

export function getDrawWeaponMoves(game: Game | GameView, player: PlayerColor, quantity = 1): Move[] {
  const moves: Move[] = []
  let expectedWeaponsDeckSize = isGameView(game) ? game.weaponsDeck : game.weaponsDeck.length
  let expectedWeaponsDiscardSize = game.weaponsDiscard.length
  for (let i = 0; i < quantity; i++) {
    if (expectedWeaponsDeckSize === 0 && expectedWeaponsDiscardSize > 0) {
      moves.push(shuffleWeaponsDeckMove)
      expectedWeaponsDeckSize += expectedWeaponsDiscardSize
      expectedWeaponsDiscardSize = 0
    }
    if (expectedWeaponsDeckSize > 0) {
      moves.push(drawWeaponMove(player))
      expectedWeaponsDeckSize--
    } else {
      moves.push(receiveWeaponsMove(player, [weapons[Math.floor(Math.random() * 4)]]))
    }
  }
  return moves
}
