import {countCattle, countSheep} from '../../AFeastForOdin'
import Good from '../../material/goods/Good'
import ProduceRules from './ProduceRules'
import Action from '../Action'

export default class GetSpiceMilkWoolRules extends ProduceRules {
  action = Action.GetSpiceMilkWool

  get goods() {
    const goods = [Good.Spices, Good.Silver]
    if (countCattle(this.player) > 0) {
      goods.push(Good.Milk, Good.Milk)
    }
    if (countSheep(this.player) > 0) {
      goods.push(Good.Wool)
    }
    return goods
  }
}
