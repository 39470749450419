import Action from '../Action'
import Good from '../../material/goods/Good'
import ProduceRules from '../market/ProduceRules'

export default class CraftClothingRules extends ProduceRules {
  action = Action.CraftClothing

  get cost() {
    return [Good.Fur, Good.Linen]
  }

  goods = {[Good.Silver]: 2, [Good.Clothing]: 1}
}