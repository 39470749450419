import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {drawWeaponMove} from '../../moves/DrawWeapon'
import LocationType from '../LocationType'
import Occupation from '../Occupation'

export default class WeaponsWarden extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.MoveViking && move.player === this.player.color
      && move.location.type === LocationType.Action && move.location.action % 5 === 2) {
      const action = move.location.action
      if (this.player.vikings.reduce((sum, location) => location.type === LocationType.Action && location.action === action ? sum + 1 : sum, 0) === 2) {
        if (this.player.exhaustedOccupations.includes(Occupation.WeaponsWarden)) {
          return [drawWeaponMove(this.player.color)]
        } else {
          // We need to keep track of the event, because with Inspector occupation we can take back 2 vikings from one action space...
          this.player.exhaustedOccupations.push(Occupation.WeaponsWarden)
        }
      }
    }
    if (move.type === MoveType.StartNewRound) {
      this.player.exhaustedOccupations = this.player.exhaustedOccupations.filter(occupation => occupation !== Occupation.WeaponsWarden)
    }
    return []
  }
}
