import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Good from '../goods/Good'
import Occupation from '../Occupation'
import {passMove} from '../../moves/Pass'
import Weapon from '../Weapon'
import {spendWeaponsMove} from '../../moves/SpendWeapons'
import MoveView from '../../moves/MoveView'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import LayingSnareSuccessOccupationRules from './LayingSnareSuccessOccupationRules'

export default class MeatCurer extends LayingSnareSuccessOccupationRules {
  onLayingSnareSuccess(): Move[] {
    this.player.effects.unshift({occupation: Occupation.MeatCurer})
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = [passMove(this.player.color)]
    if (this.player.weapons[Weapon.Snare]) {
      moves.push(spendWeaponsMove(this.player.color, Weapon.Snare))
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.SpendWeapons:
        if (move.player === this.player.color && move.weapons === Weapon.Snare) {
          this.player.effects.shift()
          return [receiveGoodsMove(this.player.color, Good.SaltMeat)]
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return []
  }
}
