import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import {passMove} from '../moves/Pass'
import MoveType from '../moves/MoveType'
import Good, {isBoat, isBuilding} from '../material/goods/Good'
import {receiveGoodsMove} from '../moves/ReceiveGoods'
import {takeBuildingMove} from '../moves/TakeBuilding'
import {takeBoatMove} from '../moves/TakeBoat'
import MoveRandomized from '../moves/MoveRandomized'
import Player from '../Player'
import PlayerView from '../PlayerView'
import GoodsAreaType from '../material/goods/GoodsAreaType'
import TerritoryPlacementArea from '../material/goods/TerritoryPlacementArea'
import {isBuildingPlacedGoodsArea} from '../material/goods/PlacedGoodsArea'
import BuildingPlacementArea from '../material/goods/BuildingPlacementArea'

export default class BonusPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  isTurnToPlay(playerId: PlayerColor): boolean {
    return !this.game.players.find(p => p.color === playerId)!.passed
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.every(player => player.passed && !player.effects.length)) {
      return [startNextPhaseMove]
    }
    return []
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    const player = this.game.players.find(p => p.color === playerId)!
    return !player.passed && !player.effects.length ? [passMove(playerId)] : []
  }

  play(move: MoveRandomized | MoveView) {
    switch (move.type) {
      case MoveType.Pass:
        const player = this.game.players.find(player => player.color === move.player)!
        if (!player.effects.length) {
          player.passed = true
          return getBonusMoves(this.game, player)
        }
        break
      case MoveType.StartNextPhase:
        for (const player of this.game.players) {
          delete player.passed
        }
        break
    }
    return []
  }
}

export function getBonusGoods(game: Game | GameView, player: Player | PlayerView): Good[] {
  return player.placedGoodsAreas.flatMap(placedGoodsArea => {
    if (placedGoodsArea.goodsArea.type === GoodsAreaType.Home || placedGoodsArea.goodsArea.type === GoodsAreaType.Territory) {
      return new TerritoryPlacementArea(placedGoodsArea).getBonusGoods(game)
    } else if (isBuildingPlacedGoodsArea(placedGoodsArea)) {
      return new BuildingPlacementArea(placedGoodsArea).getBonusGoods(game)
    } else {
      return []
    }
  })
}

export function getBonusMoves(game: Game | GameView, player: Player | PlayerView, bonus = getBonusGoods(game, player)): Move[] {
  const moves: Move[] = []
  const goods = bonus.filter(good => !isBuilding(good) && !isBoat(good))
  if (goods.length) {
    moves.push(receiveGoodsMove(player.color, goods))
  }
  const buildings = bonus.filter(isBuilding)
  for (const building of buildings) {
    moves.push(takeBuildingMove(player.color, building))
  }
  const boats = bonus.filter(isBoat)
  for (const boat of boats) {
    moves.push(takeBoatMove(player.color, boat))
  }
  return moves
}
