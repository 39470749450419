import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import GoodsAreaType from '../goods/GoodsAreaType'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Building from '../Building'
import {isPlacedGoodsLocal} from '../../moves/PlacedGoodsLocal'

export default class Judge extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Building
      && (move.goodsArea.building === Building.StoneHouse || move.goodsArea.building === Building.LongHouse)
      && move.placedGoods.some(placedGood => placedGood.good === Good.RuneStone && !isPlacedGoodsLocal(placedGood))) {
      return [receiveGoodsMove(this.player.color, [Good.Mead, Good.Silver])]
    }
    return []
  }
}
