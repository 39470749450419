import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import equal from 'fast-deep-equal'
import LayingSnareRules from '../../actions/hunting/LayingSnareRules'
import {getActionRules} from '../../AFeastForOdin'

export default abstract class LayingSnareSuccessOccupationRules extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const rules = getActionRules(this.game)
      if (rules?.player.color === this.player.color && rules.action === Action.LayingSnare && equal(move.goods, (rules as LayingSnareRules).successGoods)) {
        return this.onLayingSnareSuccess()
      }
    }
    return []
  }

  abstract onLayingSnareSuccess(): Move[]
}
