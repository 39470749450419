import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Good from './Good'
import GoodsAreaType from './GoodsAreaType'
import {_1, __, i0, i1, i2, i3, i4, i5, i6, i7, i9, iC, iE, PlacementSpace, XX} from './PlacementSpace'
import TerritoryPlacementArea from './TerritoryPlacementArea'

type HomeGoodsArea = {
  type: typeof GoodsAreaType.Home
}

export default HomeGoodsArea

export const homeGoodsArea: HomeGoodsArea = {type: GoodsAreaType.Home}

const me: PlacementSpace = {bonus: [Good.Mead]}
const wo: PlacementSpace = {bonus: [Good.Wood]}
const st: PlacementSpace = {bonus: [Good.Stone]}
const rs: PlacementSpace = {bonus: [Good.RuneStone]}
const or: PlacementSpace = {bonus: [Good.Ore]}

export const homePlacementArea: PlacementSpace[][] = [
  [_1, _1, _1, _1, _1, _1, _1, _1, _1, _1, _1, iE, __],
  [_1, _1, _1, _1, _1, _1, _1, _1, _1, _1, iC, _1, __],
  [_1, _1, _1, _1, _1, _1, _1, _1, _1, i9, _1, _1, __],
  [_1, _1, _1, _1, _1, _1, _1, _1, i7, _1, _1, _1, XX],
  [_1, _1, _1, _1, _1, _1, _1, i6, _1, _1, _1, _1, XX],
  [or, __, __, __, __, __, i5, _1, _1, _1, _1, _1, XX],
  [__, __, __, __, __, i4, __, _1, _1, _1, _1, _1, XX],
  [__, __, wo, __, i3, __, rs, _1, _1, _1, _1, _1, XX],
  [__, __, __, i2, __, __, __, _1, _1, _1, _1, _1, XX],
  [__, me, i2, __, __, __, __, _1, _1, _1, _1, _1, XX],
  [__, i1, __, __, __, st, __, _1, _1, _1, _1, _1, XX],
  [i0, __, __, __, __, __, __, _1, XX, XX, XX, XX, XX]
]

export function getHomePlacementArea(player: Player | PlayerView, home = getHomeGoodsArea(player)) {
  return new TerritoryPlacementArea(home)
}

export function getHomeGoodsArea(player: Player | PlayerView) {
  return player.placedGoodsAreas.find(area => area.goodsArea.type === GoodsAreaType.Home)!
}
