import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import OccupationRules from './OccupationRules'
import MoveView from '../../moves/MoveView'
import {playOccupationMove} from '../../moves/PlayOccupation'
import {isPlayerView} from '../../PlayerView'
import {drawOccupationMove} from '../../moves/DrawOccupation'
import {countOccupationDeck} from '../../actions/occupation/DrawOccupationRules'
import {passMove} from '../../moves/Pass'

export default class Preceptor extends OccupationRules {
  getPlayerMoves(): Move[] {
    const moves: Move[] = [countOccupationDeck(this.game) > 0 ? drawOccupationMove(this.player.color) : passMove(this.player.color)]
    if (!isPlayerView(this.player)) {
      for (const occupation of this.player.hand) {
        moves.push(playOccupationMove(this.player.color, occupation))
      }
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if ((move.type === MoveType.DrawOccupation || move.type === MoveType.PlayOccupation || move.type === MoveType.Pass) && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
