/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {css} from '@emotion/react'
import {PayButton} from '../PayButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function ShipArchitectChoice({player}: Props) {
  return (
    <div>
      <p>
        <strong><Trans defaults="choose.pay.effect" components={[<GoodItem good={Good.Oil} height={1.5} css={inlineGood}/>]}/></strong>
        <PayButton css={buttonCss} player={player} goods={{[Good.Wood]: 3, [Good.Wool]: 1}}>
          <GoodItem good={Good.Wool} height={4}/>
        </PayButton>
        <PayButton css={buttonCss} player={player} goods={{[Good.Wood]: 3, [Good.Linen]: 1}}>
          <GoodItem good={Good.Linen} height={2}/>
        </PayButton>
      </p>
    </div>
  )
}

const buttonCss = css`
  margin-left: 1em;
  padding-top: 0.2em;
  min-width: 4em;
`
