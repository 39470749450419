/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans, useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import PlayerLocal from '../../PlayerLocal'
import {css} from '@emotion/react'
import {PayButton} from '../../PayButton'
import {PassButton} from '../../PassButton'

type Props = {
  player: PlayerLocal
}

export default function PlayOneOccupationChoice({player}: Props) {
  const {t} = useTranslation()

  return (
    <div>
      <p>
        <strong>{t('choose.pay')}</strong>
        <PayButton css={buttonCss} player={player} goods={Good.Stone}>
          <GoodItem good={Good.Stone} height={2} alt="Stone"/>
        </PayButton>
        <PayButton css={buttonCss} player={player} goods={Good.Ore}>
          <GoodItem good={Good.Ore} height={2} alt="Ore"/>
        </PayButton>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}

const buttonCss = css`
  margin-left: 1em;
  padding-top: 0.2em;
  min-width: 4em;
`
