import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import ActionRules from '../ActionRules'
import MoveView from '../../moves/MoveView'
import Move from '../../moves/Move'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveType from '../../moves/MoveType'
import {Goods} from '../../material/goods/Good'
import equal from 'fast-deep-equal'

export default abstract class ProduceRules extends ActionRules {
  abstract get goods(): Goods

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.complete) {
      moves.push(receiveGoodsMove(this.player.color, this.goods))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && equal(move.goods, this.goods)) {
      this.complete()
    }
    return super.play(move)
  }
}
