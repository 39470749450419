import Action from '../Action'
import Good from '../../material/goods/Good'
import ProduceRules from '../market/ProduceRules'

export default class CraftLinenRules extends ProduceRules {
  action = Action.CraftLinen

  get cost() {
    return Good.Flax
  }

  goods = Good.Linen
}