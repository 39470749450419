import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {chooseActionMove} from '../../moves/ChooseAction'
import Action from '../../actions/Action'
import LocationType from '../LocationType'
import ActionsRules from '../../actions/ActionsRules'
import PendingAction from '../../effects/PendingAction'

export type FollowerEffect = {
  occupation: Occupation.Follower
  pendingAction?: PendingAction
}

export default class Follower extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Follower})
    return []
  }

  delegate() {
    if (!this.effect.pendingAction) return
    return new ActionsRules[this.effect.pendingAction.action](this.game)
  }

  get effect(): FollowerEffect {
    return this.player.effects[0] as FollowerEffect
  }

  getPlayerMoves() {
    if (this.effect.pendingAction) return []
    const actions: Action[] = []
    for (const player of this.game.players) {
      for (const location of player.vikings) {
        if (location.type === LocationType.Action && location.action % 5 === 2 % 5 && !actions.includes(location.action)
          && new ActionsRules[location.action](this.game).canUseEffect()) {
          actions.push(location.action)
        }
      }
    }
    return actions.map<Move>(action => chooseActionMove(this.player.color, action)).concat(passMove(this.player.color))
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.ChooseAction:
        if (move.player === this.player.color) {
          this.effect.pendingAction = new ActionsRules[move.action](this.game).createPendingAction()
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }

  eachTimeEffect() {
    const effect = this.player.effects[0]
    if (effect?.occupation === Occupation.Follower && effect.pendingAction?.complete) {
      this.player.effects.shift()
    }
    return []
  }
}
