import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {startEffectMove} from '../../moves/StartEffect'
import Occupation from '../Occupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import MoveView from '../../moves/MoveView'
import {getPendingAction} from '../../AFeastForOdin'

export default class DisheartenedWarrior extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.DeclareFailure && move.player === this.player.color) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.action === Action.Raiding && pendingAction.dice && pendingAction.dice.rollNumber < 3) {
        return [startEffectMove(this.player.color, {occupation: Occupation.DisheartenedWarrior})]
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    return [
      receiveGoodsMove(this.player.color, Good.Stockfish),
      receiveGoodsMove(this.player.color, Good.Oil)
    ]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && (move.goods === Good.Stockfish || move.goods === Good.Oil)) {
      this.player.effects.shift()
    }
    return []
  }
}
