import Move from '../../moves/Move'
import WhalingSuccessOccupationRules from './WhalingSuccessOccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class BlubberCook extends WhalingSuccessOccupationRules {
  onWhalingSuccess(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Oil)]
  }
}
