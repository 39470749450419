import Action from '../Action'
import Good from '../../material/goods/Good'
import ProduceRules from '../market/ProduceRules'

export default class CraftRuneStoneRules extends ProduceRules {
  action = Action.CraftRuneStone

  get cost() {
    return Good.Stone
  }

  goods = [Good.Silver, Good.RuneStone]
}