import Good from '../goods/Good'
import {_1, __, i0, i1, i2, i3, i4, PlacementSpace, XX} from '../goods/PlacementSpace'

const sf: PlacementSpace = {bonus: [Good.Stockfish]}
const wm: PlacementSpace = {bonus: [Good.WhaleMeat]}

export const greenland: PlacementSpace[][] = [
  [_1, _1, _1, __, __, i2, _1, _1],
  [__, __, __, XX, i1, _1, _1, _1],
  [__, wm, __, i0, _1, _1, _1, i4],
  [__, __, __, _1, _1, _1, i3, XX],
  [__, _1, __, _1, _1, i2, __, __],
  [XX, _1, _1, _1, i1, XX, __, sf],
  [XX, XX, __, i0, __, __, __, XX],
  [XX, XX, XX, XX, __, __, __, XX]
]