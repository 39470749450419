import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Miller extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (this.game.phase === Phase.Feast && move.type === MoveType.Pass && move.player === this.player.color && this.player.passed
      && this.player.goods[Good.Grain]) {
      return [receiveGoodsMove(move.player, {[Good.Silver]: Math.min(2, this.player.goods[Good.Grain]!)})]
    }
    return []
  }
}
