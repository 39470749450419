/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import {playOccupationMove} from '@gamepark/a-feast-for-odin/moves/PlayOccupation'
import {useDrop} from 'react-dnd'
import {useTranslation} from 'react-i18next'
import {OCCUPATION} from '../../occupations/OccupationCard'
import {cardHeight, margin, playerBoardWidth} from '../../styles'
import {usePlayerId} from '@gamepark/react-client'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'

export default function OccupationsDropArea() {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const [{dragging, over}, ref] = useDrop({
    accept: OCCUPATION,
    collect: monitor => ({
      dragging: monitor.getItemType() === OCCUPATION,
      over: monitor.isOver()
    }),
    drop: (item: {occupation: Occupation}) => playOccupationMove(playerId, item.occupation)
  })
  return (
    <div ref={ref} css={[style, !dragging && hidden, over && highlight]}>
      <span>{t('drop.occupation.here')}</span>
    </div>
  )
}

const style = css`
  position: absolute;
  width: ${playerBoardWidth}em;
  height: ${cardHeight * 2 + margin * 3}em;
  border-radius: 2em;
  background-color: rgba(0, 128, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  
  span {
    font-size: 5em;
  }
`

const hidden = css`
  display: none;
`

const highlight = css`
  background-color: rgba(0, 128, 0, 0.7);
`
