/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import {Picture, PictureAttributes} from '@gamepark/react-components'
import Images from '../images/Images'
import {cardHeight, cardWidth} from '../styles'

type Props = {
  weapon: Weapon
} & PictureAttributes

export default function WeaponCard({weapon, ...props}: Props) {
  return <Picture src={weaponsImage[weapon]} css={style} {...props}/>
}

const weaponsImage: Record<Weapon, string> = {
  [Weapon.Bow]: Images.bow,
  [Weapon.Snare]: Images.snare,
  [Weapon.Spear]: Images.spear,
  [Weapon.Sword]: Images.sword
}

const style = css`
  width: ${cardWidth}em;
  height: ${cardHeight}em;
  border-radius: 1.5em;
  box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
`
