import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Messenger extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && isWeeklyMarket(move.action)) {
      return [receiveGoodsMove(this.player.color, [Good.Stone, Good.Mead])]
    }
    return []
  }
}

export function isWeeklyMarket(action: Action) {
  switch (action) {
    case Action.GetBeans:
    case Action.GetFlaxAndFish:
    case Action.GetFruitsOilMeat:
    case Action.GetSpiceMilkWool:
      return true
  }
  return false
}
