/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import PendingAction from '@gamepark/a-feast-for-odin/effects/PendingAction'
import {hasBonusForPlacingFourVikings} from '@gamepark/a-feast-for-odin/actions/BonusForPlacingFourVikingsRules'
import FourVikingsBonusChoice from '../actions/FourVikingsBonusChoice'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {PlayMoveButton} from '../PlayMoveButton'
import {displayPlayerGoodsMove} from '../player/goods/DisplayPlayerGoods'
import {PassButton} from '../PassButton'
import MountainsAndTradeChoice from '../actions/MountainsAndTradeChoice'
import {isMountainsAndTradeRules} from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen, {getPlayerScreen} from '../navigation/Screen'
import ExchangeBoatAndEmigrateChoice from '../actions/sailing/ExchangeBoatAndEmigrateChoice'
import ExchangeBoatAndEmigrateRules from '@gamepark/a-feast-for-odin/actions/emigration/ExchangeBoatAndEmigrateRules'
import {isPlayOccupations} from '@gamepark/a-feast-for-odin/actions/occupation/PlayOccupationsRules'
import PlayOccupationsChoice from '../actions/occupations/PlayOccupationsChoice'
import {actionRulesDialog, setRulesDialog} from '../RulesDialog'
import {getActionRules} from '@gamepark/a-feast-for-odin/AFeastForOdin'

type Props = {
  game: GameLocalView
  pendingAction: PendingAction
  currentPlayer?: PlayerColor
}

export default function PendingActionHeaderText({game, pendingAction, currentPlayer = game.currentPlayer}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()

  if (hasBonusForPlacingFourVikings(pendingAction)) {
    if (playerId === currentPlayer) {
      return <FourVikingsBonusChoice afterAction={pendingAction?.complete}/>
    } else {
      return <>{t('player.play.occupation', {player: getPlayerName(currentPlayer, t)})}</>
    }
  }
  const action = pendingAction.action
  if (currentPlayer === playerId) {
    const actionRules = getActionRules(game)!
    if (action === Action.TradeOverseas1 || action === Action.TradeOverseas2) {
      return <Trans defaults="can.trade.overseas"
                    components={[<PlayMoveButton move={displayPlayerGoodsMove(playerId, true)} local/>, <PassButton/>]}/>
    } else if (isMountainsAndTradeRules(actionRules)) {
      return <MountainsAndTradeChoice rules={actionRules}/>
    } else if (action === Action.Emigrate1 || action === Action.Emigrate2) {
      return <Trans defaults="emigrate.choice"
                    components={[<PlayMoveButton move={displayScreenMove(getPlayerScreen(playerId))} local/>]}/>
    } else if (action === Action.ExchangeBoatAndEmigrate) {
      return <ExchangeBoatAndEmigrateChoice rules={new ExchangeBoatAndEmigrateRules(game)}/>
    } else if (isPlayOccupations(action) && (action !== Action.PlayOneOccupation || pendingAction.costPaid)) {
      return <PlayOccupationsChoice pendingAction={pendingAction} playerId={playerId}/>
    } else if (action === Action.Imitation1 || action === Action.Imitation2 || action === Action.Imitation3 || action === Action.Imitation4) {
      return <Trans defaults="imitate.choice" components={[<PlayMoveButton move={displayScreenMove(Screen.ActionBoard)} local/>]}/>
    } else {
      return <Trans defaults="action.must.complete"
                    components={[<PlayMoveButton move={setRulesDialog(actionRulesDialog(action))} local/>]}/>
    }
  } else {
    return <Trans defaults="player.complete.action" values={{player: getPlayerName(currentPlayer, t)}}
                  components={[<PlayMoveButton move={setRulesDialog(actionRulesDialog(action))} local/>]}/>
  }
}
