import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {passMove} from '../../moves/Pass'
import MoveView from '../../moves/MoveView'
import MoveRandomized from '../../moves/MoveRandomized'
import TradeRules from '../../actions/mountainsAndTrade/TradeRules'
import Trade from '../../actions/mountainsAndTrade/Trade'
import equal from 'fast-deep-equal'

export default abstract class TradeOccupationRules extends OccupationRules {
  abstract get trades(): Trade[]

  delegate() {
    return new TradeRules(this.game, this.player, this.trades)
  }

  getPlayerMoves(): Move[] {
    if (this.anyTimeEffect) return []
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color && this.trades.some(trade => equal(move.goods, trade.receive))) {
          this.player.effects.shift()
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return super.play(move)
  }
}
