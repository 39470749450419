import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import BoatType from '../boats/BoatType'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'
import Good from '../goods/Good'

export default class SpiceTrader extends TradeOccupationRules {
  trades = [{spend: {[Good.Silver]: 3}, receive: Good.Spices}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.BuildBoat && move.player === this.player.color && move.boat === BoatType.Knarr) {
      this.player.effects.unshift({occupation: Occupation.SpiceTrader})
    }
    return []
  }
}
