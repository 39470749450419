/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import OccupationCard from '../../occupations/OccupationCard'
import PlayerLocal from '../../PlayerLocal'
import {cardHeight, cardWidth, margin, playerBoardWidth} from '../../styles'
import {usePlay} from '@gamepark/react-client'
import {occupationRulesDialog, setRulesDialog} from '../../RulesDialog'

type Props = {
  player: PlayerLocal
}

export default function PlayerOccupations({player}: Props) {
  const play = usePlay()
  return <>{player.occupations.map((occupation, index) =>
    <OccupationCard key={occupation} occupation={occupation} onClick={() => play(setRulesDialog(occupationRulesDialog(occupation)), {local: true})}
                    css={[style, position(index, player.occupations.length)]}/>
  )}</>
}

const style = css`
  position: absolute;
  cursor: pointer;
  top: ${margin * 2 + cardHeight}em;
  left: ${playerBoardWidth - cardWidth - margin}em;
  transition: transform 0.2s ease-in-out;
`

const position = (index: number, totalCards: number) => css`
  transform: translateX(${-index * Math.min(cardWidth + margin, (playerBoardWidth - margin * 2 - cardWidth) / (totalCards - 1))}em);
`
