import {countSheep} from '../../AFeastForOdin'
import Good from '../../material/goods/Good'
import ProduceRules from './ProduceRules'
import Action from '../Action'

export default class ShearingRules extends ProduceRules {
  action = Action.Shearing

  get goods() {
    return {[Good.Wool]: Math.min(3, countSheep(this.player))}
  }

  canUseEffect() {
    return countSheep(this.player) > 0
  }
}
