import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Game from '../../Game'
import GameView from '../../GameView'
import PlayerColor from '../../PlayerColor'
import {Rules} from '@gamepark/rules-api'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import {playerHasGoods} from '../../AFeastForOdin'
import Trade from './Trade'
import equal from 'fast-deep-equal'

export default class TradeRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  player: Player | PlayerView
  trades: Trade[]

  constructor(game: Game | GameView, player: Player | PlayerView, trades: Trade[]) {
    super(game)
    this.player = player
    this.trades = trades
  }

  getActivePlayer(): PlayerColor | undefined {
    return this.player.color
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    if (playerId !== this.player.color) return []
    const moves: Move[] = []
    for (const trade of this.trades) {
      if (playerHasGoods(this.player, trade.spend)) {
        moves.push(spendGoodsMove(this.player.color, trade.spend))
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      const trade = this.trades.find(trade => equal(trade.spend, move.goods))
      if (trade) {
        return [receiveGoodsMove(this.player.color, trade.receive)]
      }
    }
    return []
  }
}
