/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import WeaponCard from '../weapons/WeaponCard'

type Props = {
  weapons: Partial<Record<Weapon, number>>
}

export default function PlayerWeaponsOverview({weapons}: Props) {
  return (
    <div css={style}>
      <WeaponCard css={weaponIcon} weapon={Weapon.Bow}/>
      <span css={weaponsCount}>{weapons[Weapon.Bow] ?? 0}</span>
      <WeaponCard css={weaponIcon} weapon={Weapon.Snare}/>
      <span css={weaponsCount}>{weapons[Weapon.Snare] ?? 0}</span>
      <WeaponCard css={weaponIcon} weapon={Weapon.Spear}/>
      <span css={weaponsCount}>{weapons[Weapon.Spear] ?? 0}</span>
      <WeaponCard css={weaponIcon} weapon={Weapon.Sword}/>
      <span css={weaponsCount}>{weapons[Weapon.Sword] ?? 0}</span>
    </div>
  )
}

const style = css`
  position: absolute;
  left: 1em;
  top: 6.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const weaponIcon = css`
  font-size: 0.2em;
`

const weaponsCount = css`
  color: white;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
  font-size: 3em;

  &:not(:last-of-type) {
    margin-right: 0.2em;
  }
`