import Move from '../../moves/Move'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import MoveType from '../../moves/MoveType'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import Occupation from '../Occupation'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import {getActionRules, getPendingAction} from '../../AFeastForOdin'

export default class FurMerchant extends TradeOccupationRules {
  trades = [{spend: Good.GameMeat, receive: Good.Fur}, {spend: Good.Silk, receive: Good.Fur}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      const actionRules = getActionRules(this.game)
      if (actionRules && isMountainsAndTradeRules(actionRules) && actionRules.trades.length === 1 && actionRules.trades[0] === 1) {
        // TODO: in norwegian expansion it also applies to take 3 resources and 2 weapons, instead of 2 weapons...
        this.player.effects.unshift({occupation: Occupation.FurMerchant})
      }
    }
    return []
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color && move.goods === Good.Fur) {
          const pendingAction = getPendingAction(this.game)
          if (pendingAction) {
            delete pendingAction.goodsTraded
          }
        }
        break
    }
    return super.play(move)
  }
}
