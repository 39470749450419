import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Pirate extends OccupationRules {
  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, {[Good.Wood]: 1, [Good.Silver]: 6})
  }

  anyTimeEffect(): Move[] {
    return [
      spendGoodsMove(this.player.color, {[Good.Wood]: 1, [Good.Silver]: 6}),
      receiveGoodsMove(this.player.color, Good.TreasureChest)
    ]
  }
}
