import OccupationRules from './OccupationRules'
import PlayerColor from '../../PlayerColor'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import {isLongship} from '../boats/Longship'
import MoveType from '../../moves/MoveType'

export default class Modifier extends OccupationRules {
  isLegalMove(playerId: PlayerColor, move: Move | MoveView): boolean {
    if (move.type === MoveType.DisarmBoat) {
      if (move.player !== playerId) return false
      if (move.whalingBoat) {
        const boat = this.player.landingStages.whalingBoats[move.landingStage]
        return boat !== null && !!boat.extraOre
      } else {
        const boat = this.player.landingStages.longBoats[move.landingStage]
        return isLongship(boat) && boat.ore > 0
      }
    }
    return false
  }
}
