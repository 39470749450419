/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'

type Props = {
  player: PlayerLocal
  good: Good
}

export default function MeatBuyerChoice({player, good}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="meat.buyer.effect" components={[
          <PayButton player={player} goods={good}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
