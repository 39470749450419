import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import BoatType from '../boats/BoatType'
import Good from '../goods/Good'

export default class Captain extends OccupationRules {
  immediateEffect() {
    const whalingBoatsOre = this.player.landingStages.whalingBoats.reduce((sum, boat) => boat?.extraOre ? sum + 1 : sum, 0)
    const longshipsOre = this.player.landingStages.longBoats.reduce((sum, boat) => boat?.type === BoatType.Longship ? sum + boat.ore : sum, 0)
    if (whalingBoatsOre + longshipsOre < 4) return []
    return [receiveGoodsMove(this.player.color, {[Good.Silver]: Math.min(5, whalingBoatsOre + longshipsOre - 2)})]
  }
}
