import Move from '../../moves/Move'
import Occupation from '../Occupation'
import OccupationRules from './OccupationRules'
import {countLongships} from '../boats/LandingStages'
import {getIncome} from '../../phases/Income'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class BeachRaider extends OccupationRules {
  eachTimeEffect(): Move[] {
    const longships = countLongships(this.player)
    if (longships >= 3 && !this.player.exhaustedOccupations.includes(Occupation.BeachRaider)) {
      this.player.exhaustedOccupations.push(Occupation.BeachRaider)
      const income = getIncome(this.player)
      if (income <= 30) {
        return [receiveGoodsMove(this.player.color, {[Good.Silverware]: income <= 5 ? 4 : income <= 11 ? 3 : 2})]
      }
    }
    return []
  }
}
