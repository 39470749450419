/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import GoodsArea from '@gamepark/a-feast-for-odin/material/goods/GoodsArea'
import GoodsAreaType from '@gamepark/a-feast-for-odin/material/goods/GoodsAreaType'
import {usePlay} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import Images from '../../images/Images'
import PlayerLocal from '../../PlayerLocal'
import {buttonReset, greyBackground} from '../../styles'
import {displayBoardMove} from '../DisplayBoard'

type Props = {
  player: PlayerLocal
}

export default function BoardsNavigation({player}: Props) {
  const play = usePlay()
  return (
    <div css={style}>
      {player.placedGoodsAreas.map(({goodsArea}, index) => {
        if (goodsArea.type === GoodsAreaType.Home || goodsArea.type === GoodsAreaType.Banquet) return null
        return (
          <button key={index} css={[buttonReset, buttonCss, player.board === index && selected]}
                  onClick={() => play(displayBoardMove(player.color, index), {local: true})}>
            <Picture src={getIcon(goodsArea)} css={pictureCss}/>
          </button>
        )
      })}
    </div>
  )
}

const style = css`
  position: absolute;
  top: 0.3em;
  left: 2.3em;
  height: 6em;
  border-radius: 1em;
  box-shadow: 0 0 0.5em black, 0 0 0.5em black;
`

const buttonCss = css`
  width: 6em;
  height: 6em;
  overflow: hidden;
  background: ${greyBackground};
  border: 0.1em solid black;

  &:hover {
    border-color: darkgoldenrod;
  }

  &:first-of-type {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  &:last-of-type {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
`

const selected = css`
  border-color: yellow !important;
  cursor: auto;
`

const pictureCss = css`
  width: 100%;
  height: 100%;
`

function getIcon(goodsArea: GoodsArea) {
  if (goodsArea.type === GoodsAreaType.Building) {
    switch (goodsArea.building) {
      case Building.Shed:
        return Images.shedIcon
      case Building.StoneHouse:
        return Images.stoneHouseIcon
      case Building.LongHouse:
        return Images.longHouseIcon
      default:
        throw new Error('Missing icon for a board!')
    }
  } else {
    return Images.islandIcon
  }
}
