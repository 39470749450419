import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNewRoundMove} from '../moves/NewRound'
import LocationType from '../material/LocationType'
import {moveViking} from '../moves/MoveViking'

export default class ReturnPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    const moves: Move[] = []
    for (const player of this.game.players) {
      for (let viking = 0; viking < player.vikings.length; viking++) {
        const vikingLocation = player.vikings[viking]
        if (vikingLocation.type === LocationType.Action) {
          moves.push(moveViking(player.color, viking, {type: LocationType.ThingSquare, player: player.color}))
        }
      }
    }
    moves.push(startNewRoundMove)
    return moves
  }
}
