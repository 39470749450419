import MoveType from './MoveType'

type ThrowDice = {
  type: MoveType.ThrowDice
  dice: number
}

export default ThrowDice

export function throwDiceMove(dice: number): ThrowDice {
  return {type: MoveType.ThrowDice, dice}
}

export type ThrowDiceRandomized = ThrowDice & {
  side: number
}
