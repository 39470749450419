/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import {PassButton} from '../PassButton'
import PendingActionHeaderText from './PendingActionHeaderText'
import {getPendingAction} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {PlayMoveButton} from '../PlayMoveButton'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen from '../navigation/Screen'

type Props = {
  game: GameLocalView
}

export default function ActionsHeaderText({game}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()

  const pendingAction = getPendingAction(game)
  if (!pendingAction) {
    if (game.currentPlayer === playerId) {
      return <Trans defaults="action.you" components={[<PlayMoveButton move={displayScreenMove(Screen.ActionBoard)} local/>, <PassButton/>]}/>
    } else {
      return <>{t('action.player', {player: getPlayerName(game.currentPlayer, t)})}</>
    }
  } else {
    return <PendingActionHeaderText game={game} pendingAction={pendingAction}/>
  }
}
