import Building from '../Building'
import {longHouse} from '../buildings/LongHouse'
import {shed, shedExtraSpaces} from '../buildings/Shed'
import {stoneHouse, stoneHouseExtraSpaces} from '../buildings/StoneHouse'
import Orientation from '../Orientation'
import BuildingGoodsArea from './BuildingGoodsArea'
import Good, {isFood, isTile} from './Good'
import IncomePlacementArea, {IncomeDiagonal} from './IncomePlacementArea'
import PlacedGood from './PlacedGood'
import PlacedGoodsArea from './PlacedGoodsArea'
import PlacementArea from './PlacementArea'
import {PlacementSpace} from './PlacementSpace'
import {orientMatrix} from './Polyomino'

export default class BuildingPlacementArea extends IncomePlacementArea {
  extraSpaces: PlacementSpace[]
  masterJoiner?: boolean

  constructor(area: PlacedGoodsArea<BuildingGoodsArea>, masterJoiner?: boolean) {
    const building = area.goodsArea.building
    super(orientMatrix(getBuildingPlacementArea(building), Orientation.Right), getBuildingIncomeDiagonals(building))
    if (building === Building.StoneHouse || building === Building.LongHouse) {
      this.masterJoiner = masterJoiner
    }
    this.extraSpaces = JSON.parse(JSON.stringify(getBuildingExtraSpaces(building)))
    this.placeGoods(area.placedGoods)
    if (area.extraGoods) {
      for (const good of area.extraGoods) {
        this.placeGood(good)
      }
    }
  }

  placeGood(good: Good) {
    this.extraSpaces.find(space => space && space.requiredGood === good && !space.good)!.good = good
  }

  canPlaceGood(good: Good | PlacedGood): boolean {
    if (typeof good === 'number') {
      return this.extraSpaces.some(space => space && space.requiredGood === good && !space.good)
    } else {
      return super.canPlaceGood(good)
    }
  }

  isValidGood(good: Good): boolean {
    if (this.masterJoiner && good === Good.Wood) return true
    return good === Good.Silver || isTile(good)
  }

  protected isAdjacencyForbidden(good: Good): boolean {
    return isFood(good)
  }
}

function getBuildingPlacementArea(building: Building): PlacementSpace[][] {
  switch (building) {
    case Building.Shed:
      return shed
    case Building.StoneHouse:
      return stoneHouse
    case Building.LongHouse:
      return longHouse
  }
}

function getBuildingExtraSpaces(building: Building): PlacementSpace[] {
  switch (building) {
    case Building.Shed:
      return shedExtraSpaces
    case Building.StoneHouse:
      return stoneHouseExtraSpaces
    default:
      return []
  }
}

export function isBuildingPlacementArea(area: PlacementArea): area is BuildingPlacementArea {
  return (area as BuildingPlacementArea).extraSpaces !== undefined
}

export function getBuildingIncomeDiagonals(_building: Building): IncomeDiagonal[] {
  // TODO: expansion artisan sheds
  return []
}
