/** @jsxImportSource @emotion/react */
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import ActionTile from './board/ActionTile'
import GameLocalView from '../GameLocalView'
import {Trans, useTranslation} from 'react-i18next'
import {css} from '@emotion/react'
import {useEffect, useMemo, useRef} from 'react'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import AFeastForOdin, {getActionRules, getPendingAction, isEffectWithPendingAction} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import {titleButtonCss} from '../styles'
import {chooseActionMove} from '@gamepark/a-feast-for-odin/moves/ChooseAction'
import FourVikingsBonusChoice from './FourVikingsBonusChoice'
import {TFunction} from 'i18next'
import BuildHouseAndBoatChoice from './building/BuildHouseAndBoatChoice'
import CraftChestActionChoice from './crafting/CraftChestActionChoice'
import HuntingChoice from './hunting/HuntingChoice'
import HuntingRules from '@gamepark/a-feast-for-odin/actions/hunting/HuntingRules'
import SheepOrCattleChoice from './market/SheepOrCattleChoice'
import ReceiveGoodDialogContent from '../goods/ReceiveGoodDialogContent'
import CraftRobeAndJewelryChoice from './crafting/CraftRobeAndJewelryChoice'
import MountainsAndTradeChoice from './MountainsAndTradeChoice'
import MountainsAndTradeRules from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'
import {PassButton} from '../PassButton'
import {PlayMoveButton} from '../PlayMoveButton'
import {displayPlayerGoodsMove} from '../player/goods/DisplayPlayerGoods'
import SpecialSaleChoice from './special-sale/SpecialSaleChoice'
import SpecialSaleRules from '@gamepark/a-feast-for-odin/actions/sailing/SpecialSaleRules'
import RaidingChoice from './raiding/RaidingChoice'
import RaidingRules from '@gamepark/a-feast-for-odin/actions/sailing/RaidingRules'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen, {getPlayerScreen} from '../navigation/Screen'
import {isEmigration} from '@gamepark/a-feast-for-odin/actions/emigration/EmigrateRules'
import GoodItem from '../goods/GoodItem'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {BanquetPlacementArea} from '@gamepark/a-feast-for-odin/phases/Feast'
import ExchangeBoatAndEmigrateChoice from './sailing/ExchangeBoatAndEmigrateChoice'
import ExchangeBoatAndEmigrateRules from '@gamepark/a-feast-for-odin/actions/emigration/ExchangeBoatAndEmigrateRules'
import PlayOneOccupationChoice from './occupations/PlayOneOccupationChoice'
import PlayOccupationsChoice from './occupations/PlayOccupationsChoice'
import {hasBonusForPlacingFourVikings} from '@gamepark/a-feast-for-odin/actions/BonusForPlacingFourVikingsRules'
import BuySheepOrCattleRules from '@gamepark/a-feast-for-odin/actions/market/BuySheepOrCattleRules'
import SmithyRules from '@gamepark/a-feast-for-odin/actions/crafting/SmithyRules'

type Props = {
  game: GameLocalView
  action: Action
  close: () => void
}

export default function ActionRulesDialogContent({game, action, close}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(player => player.color === playerId)
  const canChoose = useMemo(() =>
      playerId !== undefined && new AFeastForOdin(game).getLegalMoves(playerId).some(move => move.type === MoveType.ChooseAction && move.action === action)
    , [game, playerId])
  const play = usePlay()

  const actionRules = getActionRules(game)
  const pendingAction = actionRules?.pendingAction
  const isPendingAction = actionRules?.action === action
  const wasPendingAction = useRef(false)
  useEffect(() => {
    if (isPendingAction) wasPendingAction.current = true
    else if (wasPendingAction.current) close()
  }, [isPendingAction])

  const isActionToSolve = useMemo(() => {
    if (!isPendingAction || actionRules?.player.color !== playerId) return false
    const playerEffect = game.players.find(player => player.color === playerId)!.effects[0]
    return !playerEffect || isEffectWithPendingAction(playerEffect) && playerEffect.pendingAction?.action === action
  }, [game, playerId])

  return <>
    <h2>{t('Action rules')}</h2>
    <div css={flexContainer}>
      <ActionTile action={action} css={actionTileCss}/>
      <div css={rightColumn}>
        {isEmigration(action) &&
          <p css={rulesCss}>
            <Trans defaults="emigration.cost" values={{cost: game.round}} components={[
              <GoodItem css={css`display: inline;`} good={Good.Silver} height={1}/>
            ]}/>
          </p>
        }
        <p css={rulesCss}>{actionsRulesTexts[action](t)}</p>
        {action % 5 === 3 && <p><em>{t('bonus.3.vikings')}</em></p>}
        {action % 5 === 4 && <p><em>{t('bonus.4.vikings')}</em></p>}
      </div>
    </div>
    <div css={actionContainer}>
      {isEmigration(action) && player && !new BanquetPlacementArea(player).canEmigrate() && <p><strong>{t('emigration.limit')}</strong></p>}
      {canChoose && playerId &&
        <p>
          <button css={titleButtonCss} onClick={() => play(chooseActionMove(playerId, action))}>{t('Choose this action')}</button>
          <em css={longPressInfo}>{t('action.choice.longPressInfo')}</em>
        </p>
      }
      {isActionToSolve &&
        (hasBonusForPlacingFourVikings(pendingAction!) ?
            <p><FourVikingsBonusChoice afterAction={pendingAction?.complete}/></p>
            : <ActionChoice game={game} action={action}/>
        )
      }
    </div>
  </>
}

function ActionChoice({game, action}: { game: GameLocalView, action: Action }) {
  const player = game.players.find(player => player.color === game.currentPlayer)!
  const pendingAction = getPendingAction(game)!
  switch (action) {
    case Action.BuildHouseAndBoat:
      return <BuildHouseAndBoatChoice game={game} player={player}/>
    case Action.HuntingGameWithOneViking:
    case Action.HuntingGameWithTwoVikings:
    case Action.LayingSnare:
    case Action.Whaling:
    case Action.WhalingWithOneBoat:
      return <HuntingChoice hunting={getActionRules(game) as HuntingRules}/>
    case Action.BuySheepOrCattle:
      return <SheepOrCattleChoice rules={getActionRules(game) as BuySheepOrCattleRules}/>
    case Action.Smithy:
      return <ReceiveGoodDialogContent goods={new SmithyRules(game).forgeOptions()}/>
    case Action.CraftChest:
      return <CraftChestActionChoice player={player}/>
    case Action.CraftRobeAndJewelry:
      return <CraftRobeAndJewelryChoice player={player}/>
    case Action.Take2Resources:
    case Action.Take5Resources:
    case Action.Take1ResourceAndTrade:
    case Action.Take3ResourcesAndTrade:
    case Action.Trade3AndWeapons:
    case Action.Take4ResourcesAndTrade2:
    case Action.Trade2:
    case Action.Trade3:
    case Action.Trade4:
    case Action.Take8ResourcesOrTrade3:
      return <p><MountainsAndTradeChoice rules={getActionRules(game) as MountainsAndTradeRules}/></p>
    case Action.TradeOverseas1:
    case Action.TradeOverseas2:
      return <p>
        <Trans defaults="can.trade.overseas"
               components={[<PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>, <PassButton/>]}/>
      </p>
    case Action.SpecialSale:
      return <SpecialSaleChoice specialSale={getActionRules(game) as SpecialSaleRules}/>
    case Action.Raiding:
    case Action.Pillaging1:
    case Action.Pillaging2:
      return <RaidingChoice raiding={getActionRules(game) as RaidingRules}/>
    case Action.ExploreI:
    case Action.ExploreII:
    case Action.ExploreIII:
      return <p>
        <Trans defaults="explore.choice"
               components={[<PlayMoveButton move={displayScreenMove(Screen.BoardsSupply)} local/>]}/>
      </p>
    case Action.Emigrate1:
    case Action.Emigrate2:
      return <p>
        <Trans defaults="emigrate.choice"
               components={[<PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local/>]}/>
      </p>
    case Action.ExchangeBoatAndEmigrate:
      return <p><ExchangeBoatAndEmigrateChoice rules={new ExchangeBoatAndEmigrateRules(game)}/></p>
    case Action.PlayOneOccupation:
      if (!pendingAction.costPaid) {
        return <PlayOneOccupationChoice player={player}/>
      } else {
        return <p><PlayOccupationsChoice pendingAction={pendingAction} playerId={player.color}/></p>
      }
    case Action.PlayTwoOccupations:
    case Action.PlayFourOccupations:
      return <p><PlayOccupationsChoice pendingAction={pendingAction} playerId={player.color}/></p>
    default:
      return null
  }
}

const flexContainer = css`
  display: flex;
  margin: 2em;
  align-items: center;
`

const rightColumn = css`
  flex-grow: 1;
  margin-left: 3em;
  text-align: left;
`

const actionContainer = css`
  margin: 2em;
  text-align: start;
`

const rulesCss = css`
  white-space: break-spaces;
  text-align: start;
  margin: 0;
`

const actionsRulesTexts: Record<Action, (t: TFunction) => string> = {
  [Action.BuildShed]: t => t('action.buildShed'),
  [Action.BuildStoneHouse]: t => t('action.buildStoneHouse'),
  [Action.BuildLongHouse]: t => t('action.buildLongHouse'),
  [Action.BuildWhalingBoat]: t => t('action.buildWhalingBoat'),
  [Action.BuildKnarr]: t => t('action.buildKnarr'),
  [Action.BuildLongship]: t => t('action.buildLongship'),
  [Action.BuildHouseAndBoat]: t => t('action.buildHouseAndBoat'),
  [Action.HuntingGameWithOneViking]: t => t('action.huntingGame'),
  [Action.HuntingGameWithTwoVikings]: t => t('action.huntingGame'),
  [Action.Fishing]: t => t('action.fishing'),
  [Action.LayingSnare]: t => t('action.layingSnare'),
  [Action.Whaling]: t => t('action.whaling'),
  [Action.WhalingWithOneBoat]: t => t('action.whalingMinor'),
  [Action.BuyFish]: t => t('action.buyFish'),
  [Action.BuySheep]: t => t('action.buySheep'),
  [Action.BuyMeat]: t => t('action.buyMeat'),
  [Action.BuyCattleAndMilk]: t => t('action.buyCattleAndMilk'),
  [Action.BuySheepOrCattle]: t => t('action.buySheepOrCattle'),
  [Action.BuySheepAndCattle]: t => t('action.buySheepAndCattle'),
  [Action.GetBeans]: t => t('action.getBeans'),
  [Action.GetFlaxAndFish]: t => t('action.getFlaxAndFish'),
  [Action.GetFruitsOilMeat]: t => t('action.getFruitsOilMeat'),
  [Action.Milking]: t => t('action.milking'),
  [Action.GetMead]: t => t('action.getMead'),
  [Action.Shearing]: t => t('action.shearing'),
  [Action.GetSpiceMilkWool]: t => t('action.getSpiceMilkWool'),
  [Action.CraftLinen]: t => t('action.craftLinen'),
  [Action.CraftClothing]: t => t('action.craftClothing'),
  [Action.Smithy]: t => t('action.smithy'),
  [Action.CraftRuneStone]: t => t('action.craftRuneStone'),
  [Action.CraftChest]: t => t('action.craftChest'),
  [Action.CraftChestsAndRuneStones]: t => t('action.craftChestsAndRuneStones'),
  [Action.CraftRobeAndJewelry]: t => t('action.craftRobeAndJewelry'),
  [Action.Take2Resources]: t => t('action.take2Resources'),
  [Action.TakeWoodAndOre]: t => t('action.takeWoodAndOre'),
  [Action.Take5Resources]: t => t('action.take5Resources'),
  [Action.Take1ResourceAndTrade]: t => t('action.take1ResourceAndTrade'),
  [Action.Take3ResourcesAndTrade]: t => t('action.take3ResourcesAndTrade'),
  [Action.Trade3AndWeapons]: t => t('action.trade3AndWeapons'),
  [Action.Take4ResourcesAndTrade2]: t => t('action.take4ResourcesAndTrade2'),
  [Action.Trade2]: t => t('action.trade2'),
  [Action.Trade3]: t => t('action.trade3'),
  [Action.Trade4]: t => t('action.trade4'),
  [Action.TradeOverseas1]: t => t('action.tradeOversee'),
  [Action.TradeOverseas2]: t => t('action.tradeOversee'),
  [Action.SpecialSale]: t => t('action.specialSale'),
  [Action.Take8ResourcesOrTrade3]: t => t('action.take8ResourcesOrTrade3'),
  [Action.Raiding]: t => t('action.raiding'),
  [Action.Pillaging1]: t => t('action.pillaging'),
  [Action.Pillaging2]: t => t('action.pillaging'),
  [Action.ExploreI]: t => t('action.exploreI'),
  [Action.ExploreII]: t => t('action.exploreII'),
  [Action.ExploreIII]: t => t('action.exploreIII'),
  [Action.Plundering]: t => t('action.plundering'),
  [Action.DrawOccupation]: t => t('action.drawOccupation'),
  [Action.Emigrate1]: t => t('action.emigrate'),
  [Action.Emigrate2]: t => t('action.emigrate'),
  [Action.PlayOneOccupation]: t => t('action.playOccupation'),
  [Action.PlayTwoOccupations]: t => t('action.play2Occupations'),
  [Action.PlayFourOccupations]: t => t('action.play4Occupations'),
  [Action.ExchangeBoatAndEmigrate]: t => t('action.exchangeBoatAndEmigrate'),
  [Action.Imitation1]: t => t('action.imitation'),
  [Action.Imitation2]: t => t('action.imitation'),
  [Action.Imitation3]: t => t('action.imitation'),
  [Action.Imitation4]: t => t('action.imitation')
}

const actionTileCss = css`
  font-size: 1.5em;
  flex-grow: 0;
  flex-shrink: 0;
`

const longPressInfo = css`
  margin-left: 1em;
`
