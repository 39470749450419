import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countKnarrs} from '../boats/LandingStages'
import Good from '../goods/Good'

export default class MeatTrader extends OccupationRules {
  immediateEffect() {
    const knarrs = countKnarrs(this.player)
    return knarrs > 0 ? [receiveGoodsMove(this.player.color, {[Good.SaltMeat]: knarrs})] : []
  }
}
