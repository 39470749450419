import Move from '../../moves/Move'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import Good, {isGood, upgradableGoods} from '../goods/Good'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import Effect from '../../effects/Effect'

export type WholesalerEffect = {
  occupation: Occupation.Wholesaler
  traded: Good[]
}

export default class Wholesaler extends UpgradeGoodOccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Wholesaler, traded: []})
    return []
  }

  get effect(): WholesalerEffect {
    return this.player.effects[0] as WholesalerEffect
  }

  get upgradableGoods() {
    if (this.effect && this.effect.traded.length > 0) {
      const good = this.effect.traded[0]
      switch (good) {
        case Good.Sheep:
        case Good.PregnantSheep:
          return [Good.Sheep, Good.PregnantSheep]
        case Good.Cattle:
        case Good.PregnantCattle:
          return [Good.Cattle, Good.PregnantCattle]
        default:
          return [good]
      }
    } else {
      return upgradableGoods
    }
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && isGood(move.goods)) {
      this.effect.traded.push(move.goods)
    }
    return super.play(move)
  }

  onReceiveGoods() {
    if (this.effect.traded.length === 4) {
      this.player.effects.shift()
    }
  }
}

export function isWholesalerEffect(effect: Effect): effect is WholesalerEffect {
  return effect.occupation === Occupation.Wholesaler
}
