import Occupation from '../Occupation'
import OccupationRules from './OccupationRules'
import Game from '../../Game'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import GameView from '../../GameView'
import Peddler from './Peddler'
import Patron from './Patron'
import Furrier from './Furrier'
import Chief from './Chief'
import StoneCarver from './StoneCarver'
import Miller from './Miller'
import CraftLeader from './CraftLeader'
import OreBoatman from './OreBoatman'
import Milker from './Milker'
import TridentHunter from './TridentHunter'
import Helmsman from './Helmsman'
import Archer from './Archer'
import ShipOwner from './ShipOwner'
import Cottager from './Cottager'
import Captain from './Captain'
import OrientShipper from './OrientShipper'
import Fighter from './Fighter'
import Host from './Host'
import Builder from './Builder'
import Collector from './Collector'
import Miner from './Miner'
import Custodian from './Custodian'
import WhalingEquipper from './WhalingEquipper'
import MeatTrader from './MeatTrader'
import MeatInspector from './MeatInspector'
import Fisherman from './Fisherman'
import Locksmith from './Locksmith'
import MeatPreserver from './MeatPreserver'
import SilkMerchant from './SilkMerchant'
import Storeman from './Storeman'
import WeaponsSupplier from './WeaponsSupplier'
import SheepShearer from './SheepShearer'
import Shepherd from './Shepherd'
import CattleBreeder from './CattleBreeder'
import ShipsCook from './ShipsCook'
import ForeignTrader from './ForeignTrader'
import Peacemaker from './Peacemaker'
import Hunter from './Hunter'
import Slowpoke from './Slowpoke'
import Modifier from './Modifier'
import MasterJoiner from './MasterJoiner'
import Steward from './Steward'
import FruitPicker from './FruitPicker'
import MasterTailor from './MasterTailor'
import Pirate from './Pirate'
import ShipArchitect from './ShipArchitect'
import Tanner from './Tanner'
import Farmer from './Farmer'
import Plower from './Plower'
import RuneEngraver from './RuneEngraver'
import MasterMason from './MasterMason'
import Tutor from './Tutor'
import FarmShopOwner from './FarmShopOwner'
import Tradesman from './Tradesman'
import Treasurer from './Treasurer'
import ArmsDealer from './ArmsDealer'
import BeanFarmer from './BeanFarmer'
import LinseedOilPresser from './LinseedOilPresser'
import LinenWeaver from './LinenWeaver'
import Inspector from './Inspector'
import WharfOwner from './WharfOwner'
import FineBlacksmith from './FineBlacksmith'
import Hornturner from './Hornturner'
import ShipBuilder from './ShipBuilder'
import Dragonslayer from './Dragonslayer'
import FieldFarmer from './FieldFarmer'
import PrivateChef from './PrivateChef'
import WeaponSupplier from './WeaponSupplier'
import EquipmentSupplier from './EquipmentSupplier'
import Courier from './Courier'
import Innkeeper from './Innkeeper'
import Earl from './Earl'
import Metalsmith from './Metalsmith'
import Cowherd from './Cowherd'
import BoneCollector from './BoneCollector'
import Homecomer from './Homecomer'
import Follower from './Follower'
import TravelingMerchant from './TravelingMerchant'
import Breeder from './Breeder'
import ShepherdBoy from './ShepherdBoy'
import Quartermaster from './Quartermaster'
import LongshipBuilder from './LongshipBuilder'
import Wholesaler from './Wholesaler'
import HarborGuard from './HarborGuard'
import Hornblower from './Hornblower'
import HideBuyer from './HideBuyer'
import Preacher from './Preacher'
import Clerk from './Clerk'
import Catapulter from './Catapulter'
import SwordFighter from './SwordFighter'
import Latecomer from './Latecomer'
import SympatheticSoul from './SympatheticSoul'
import HelperInTimeOfNeed from './HelperInTimeOfNeed'
import SpiceMerchant from './SpiceMerchant'
import Wanderer from './Wanderer'
import MeatMerchant from './MeatMerchant'
import PeaCounter from './PeaCounter'
import Nobleman from './Nobleman'
import Preceptor from './Preceptor'
import Undertaker from './Undertaker'
import Judge from './Judge'
import FurMerchant from './FurMerchant'
import ForestBlacksmith from './ForestBlacksmith'
import DisheartenedWarrior from './DisheartenedWarrior'
import KnarrBuilder from './KnarrBuilder'
import Warmonger from './Warmonger'
import Adventurer from './Adventurer'
import Ironsmith from './Ironsmith'
import MasterCount from './MasterCount'
import BirkaSettler from './BirkaSettler'
import Artist from './Artist'
import Barkeep from './Barkeep'
import ApprenticeCraftsman from './ApprenticeCraftsman'
import SilkStitcher from './SilkStitcher'
import Princess from './Princess'
import MasterBricklayer from './MasterBricklayer'
import Shipowner from './Shipowner2'
import Messenger from './Messenger'
import Milkman from './Milkman'
import Middleman from './Middleman'
import Carpenter from './Carpenter'
import MountainGuard from './MountainGuard'
import OilSeller from './OilSeller'
import Laborer from './Laborer'
import Scribe from './Scribe'
import Trapper from './Trapper'
import Angler from './Angler'
import Digger from './Digger'
import MeatCurer from './MeatCurer'
import SnareSpecialist from './SnareSpecialist'
import BaitLayer from './BaitLayer'
import Berserker from './Berserker'
import LanceBearer from './LanceBearer'
import Drunkard from './Drunkard'
import LoneWolf from './LoneWolf'
import Raider from './Raider'
import MeleeFighter from './MeleeFighter'
import Robber from './Robber'
import LootHunter from './LootHunter'
import BlubberCook from './BlubberCook'
import IvorySculptor from './IvorySculptor'
import WhaleCatcher from './WhaleCatcher'
import AntlersSeller from './AntlersSeller'
import Deerstalker from './Deerstalker'
import Forester from './Forester'
import Sledger from './Sledger'
import Steersman from './Steersman'
import SpiceTrader from './SpiceTrader'
import Priest from './Priest'
import MeatBuyer from './MeatBuyer'
import ThingSpokesman from './ThingSpokesman'
import Merchant from './Merchant'
import ProficientHunter from './ProficientHunter'
import Cooper from './Cooper'
import Woodcutter from './Woodcutter'
import StoneCrusher from './StoneCrusher'
import Skinner from './Skinner'
import Barbarian from './Barbarian'
import FestiveHunter from './FestiveHunter'
import SoberMan from './SoberMan'
import WhalingAssistant from './WhalingAssistant'
import FlaxFarmer from './FlaxFarmer'
import Farmhand from './Farmhand'
import ArmedFighter from './ArmedFighter'
import VillageLeader from './VillageLeader'
import WeaponsWarden from './WeaponsWarden'
import Taster from './Taster'
import EarlOfLade from './EarlOfLade'
import Sower from './Sower'
import RefugeeHelper from './RefugeeHelper'
import FlaxBaker from './FlaxBaker'
import FishCook from './FishCook'
import Punchcutter from './Punchcutter'
import BosporusTraveller from './BosporusTraveller'
import CodliverOilPresser from './CodliverOilPresser'
import EtiquetteTeacher from './EtiquetteTeacher'
import Maid from './Maid'
import BoatBuilder from './BoatBuilder'
import CutterOperator from './CutterOperator'
import BeachRaider from './BeachRaider'
import SailPatcher from './SailPatcher'
import HerbGardener from './HerbGardener'
import GrainDeliveryman from './GrainDeliveryman'
import Sponsor from './Sponsor'
import DorestadTraveller from './DorestadTraveller'
import PeaFlourBaker from './PeaFlourBaker'
import Chronicler from './Chronicler'
import Mineworker from './Mineworker'
import BosporusMerchant from './BosporusMerchant'
import Seafarer from './Seafarer'

const OccupationsRules: Record<Occupation, new(game: Game | GameView, player: Player | PlayerView) => OccupationRules> = {
  [Occupation.Peddler]: Peddler,
  [Occupation.Patron]: Patron,
  [Occupation.Furrier]: Furrier,
  [Occupation.Hunter]: Hunter,
  [Occupation.Chief]: Chief,
  [Occupation.StoneCarver]: StoneCarver,
  [Occupation.Miller]: Miller,
  [Occupation.CraftLeader]: CraftLeader,
  [Occupation.OreBoatman]: OreBoatman,
  [Occupation.Milker]: Milker,
  [Occupation.TridentHunter]: TridentHunter,
  [Occupation.Helmsman]: Helmsman,
  [Occupation.Archer]: Archer,
  [Occupation.ShipOwner]: ShipOwner,
  [Occupation.Cottager]: Cottager,
  [Occupation.Captain]: Captain,
  [Occupation.OrientShipper]: OrientShipper,
  [Occupation.Fighter]: Fighter,
  [Occupation.Host]: Host,
  [Occupation.Builder]: Builder,
  [Occupation.Collector]: Collector,
  [Occupation.Miner]: Miner,
  [Occupation.Custodian]: Custodian,
  [Occupation.WhalingEquipper]: WhalingEquipper,
  [Occupation.MeatTrader]: MeatTrader,
  [Occupation.MeatInspector]: MeatInspector,
  [Occupation.Fisherman]: Fisherman,
  [Occupation.Locksmith]: Locksmith,
  [Occupation.MeatPreserver]: MeatPreserver,
  [Occupation.SilkMerchant]: SilkMerchant,
  [Occupation.Storeman]: Storeman,
  [Occupation.WeaponsSupplier]: WeaponsSupplier,
  [Occupation.SheepShearer]: SheepShearer,
  [Occupation.Shepherd]: Shepherd,
  [Occupation.CattleBreeder]: CattleBreeder,
  [Occupation.ShipsCook]: ShipsCook,
  [Occupation.ForeignTrader]: ForeignTrader,
  [Occupation.Peacemaker]: Peacemaker,
  [Occupation.Modifier]: Modifier,
  [Occupation.MasterJoiner]: MasterJoiner,
  [Occupation.Slowpoke]: Slowpoke,
  [Occupation.Steward]: Steward,
  [Occupation.FruitPicker]: FruitPicker,
  [Occupation.MasterTailor]: MasterTailor,
  [Occupation.Pirate]: Pirate,
  [Occupation.ShipArchitect]: ShipArchitect,
  [Occupation.Tanner]: Tanner,
  [Occupation.Farmer]: Farmer,
  [Occupation.Plower]: Plower,
  [Occupation.RuneEngraver]: RuneEngraver,
  [Occupation.MasterMason]: MasterMason,
  [Occupation.Tutor]: Tutor,
  [Occupation.FarmShopOwner]: FarmShopOwner,
  [Occupation.Tradesman]: Tradesman,
  [Occupation.Treasurer]: Treasurer,
  [Occupation.ArmsDealer]: ArmsDealer,
  [Occupation.BeanFarmer]: BeanFarmer,
  [Occupation.LinseedOilPresser]: LinseedOilPresser,
  [Occupation.LinenWeaver]: LinenWeaver,
  [Occupation.Inspector]: Inspector,
  [Occupation.WharfOwner]: WharfOwner,
  [Occupation.FineBlacksmith]: FineBlacksmith,
  [Occupation.Hornturner]: Hornturner,
  [Occupation.ShipBuilder]: ShipBuilder,
  [Occupation.Dragonslayer]: Dragonslayer,
  [Occupation.FieldFarmer]: FieldFarmer,
  [Occupation.PrivateChef]: PrivateChef,
  [Occupation.WeaponSupplier]: WeaponSupplier,
  [Occupation.EquipmentSupplier]: EquipmentSupplier,
  [Occupation.Courier]: Courier,
  [Occupation.Innkeeper]: Innkeeper,
  [Occupation.Earl]: Earl,
  [Occupation.Metalsmith]: Metalsmith,
  [Occupation.Cowherd]: Cowherd,
  [Occupation.BoneCollector]: BoneCollector,
  [Occupation.Homecomer]: Homecomer,
  [Occupation.Follower]: Follower,
  [Occupation.TravelingMerchant]: TravelingMerchant,
  [Occupation.Breeder]: Breeder,
  [Occupation.ShepherdBoy]: ShepherdBoy,
  [Occupation.Quartermaster]: Quartermaster,
  [Occupation.LongshipBuilder]: LongshipBuilder,
  [Occupation.Wholesaler]: Wholesaler,
  [Occupation.HarborGuard]: HarborGuard,
  [Occupation.Hornblower]: Hornblower,
  [Occupation.HideBuyer]: HideBuyer,
  [Occupation.Preacher]: Preacher,
  [Occupation.Clerk]: Clerk,
  [Occupation.Catapulter]: Catapulter,
  [Occupation.SwordFighter]: SwordFighter,
  [Occupation.Latecomer]: Latecomer,
  [Occupation.SympatheticSoul]: SympatheticSoul,
  [Occupation.HelperInTimeOfNeed]: HelperInTimeOfNeed,
  [Occupation.SpiceMerchant]: SpiceMerchant,
  [Occupation.Wanderer]: Wanderer,
  [Occupation.MeatMerchant]: MeatMerchant,
  [Occupation.PeaCounter]: PeaCounter,
  [Occupation.Nobleman]: Nobleman,
  [Occupation.Preceptor]: Preceptor,
  [Occupation.Undertaker]: Undertaker,
  [Occupation.Judge]: Judge,
  [Occupation.FurMerchant]: FurMerchant,
  [Occupation.ForestBlacksmith]: ForestBlacksmith,
  [Occupation.DisheartenedWarrior]: DisheartenedWarrior,
  [Occupation.KnarrBuilder]: KnarrBuilder,
  [Occupation.Warmonger]: Warmonger,
  [Occupation.Adventurer]: Adventurer,
  [Occupation.Ironsmith]: Ironsmith,
  [Occupation.MasterCount]: MasterCount,
  [Occupation.BirkaSettler]: BirkaSettler,
  [Occupation.Artist]: Artist,
  [Occupation.Barkeep]: Barkeep,
  [Occupation.ApprenticeCraftsman]: ApprenticeCraftsman,
  [Occupation.SilkStitcher]: SilkStitcher,
  [Occupation.Princess]: Princess,
  [Occupation.MasterBricklayer]: MasterBricklayer,
  [Occupation.Shipowner]: Shipowner,
  [Occupation.Messenger]: Messenger,
  [Occupation.Milkman]: Milkman,
  [Occupation.Middleman]: Middleman,
  [Occupation.Carpenter]: Carpenter,
  [Occupation.MountainGuard]: MountainGuard,
  [Occupation.OilSeller]: OilSeller,
  [Occupation.Laborer]: Laborer,
  [Occupation.Scribe]: Scribe,
  [Occupation.Trapper]: Trapper,
  [Occupation.Angler]: Angler,
  [Occupation.Digger]: Digger,
  [Occupation.MeatCurer]: MeatCurer,
  [Occupation.SnareSpecialist]: SnareSpecialist,
  [Occupation.BaitLayer]: BaitLayer,
  [Occupation.Berserker]: Berserker,
  [Occupation.LanceBearer]: LanceBearer,
  [Occupation.Drunkard]: Drunkard,
  [Occupation.LoneWolf]: LoneWolf,
  [Occupation.Raider]: Raider,
  [Occupation.MeleeFighter]: MeleeFighter,
  [Occupation.Robber]: Robber,
  [Occupation.LootHunter]: LootHunter,
  [Occupation.BlubberCook]: BlubberCook,
  [Occupation.IvorySculptor]: IvorySculptor,
  [Occupation.WhaleCatcher]: WhaleCatcher,
  [Occupation.AntlersSeller]: AntlersSeller,
  [Occupation.Deerstalker]: Deerstalker,
  [Occupation.Forester]: Forester,
  [Occupation.Sledger]: Sledger,
  [Occupation.Steersman]: Steersman,
  [Occupation.SpiceTrader]: SpiceTrader,
  [Occupation.Priest]: Priest,
  [Occupation.MeatBuyer]: MeatBuyer,
  [Occupation.ThingSpokesman]: ThingSpokesman,
  [Occupation.Merchant]: Merchant,
  [Occupation.ProficientHunter]: ProficientHunter,
  [Occupation.Woodcutter]: Woodcutter,
  [Occupation.Cooper]: Cooper,
  [Occupation.StoneCrusher]: StoneCrusher,
  [Occupation.Skinner]: Skinner,
  [Occupation.Barbarian]: Barbarian,
  [Occupation.FestiveHunter]: FestiveHunter,
  [Occupation.SoberMan]: SoberMan,
  [Occupation.WhalingAssistant]: WhalingAssistant,
  [Occupation.FlaxFarmer]: FlaxFarmer,
  [Occupation.Farmhand]: Farmhand,
  [Occupation.ArmedFighter]: ArmedFighter,
  [Occupation.VillageLeader]: VillageLeader,
  [Occupation.WeaponsWarden]: WeaponsWarden,
  [Occupation.Taster]: Taster,
  [Occupation.EarlOfLade]: EarlOfLade,
  [Occupation.Sower]: Sower,
  [Occupation.RefugeeHelper]: RefugeeHelper,
  [Occupation.FlaxBaker]: FlaxBaker,
  [Occupation.FishCook]: FishCook,
  [Occupation.Punchcutter]: Punchcutter,
  [Occupation.BosporusTraveller]: BosporusTraveller,
  [Occupation.CodliverOilPresser]: CodliverOilPresser,
  [Occupation.EtiquetteTeacher]: EtiquetteTeacher,
  [Occupation.Maid]: Maid,
  [Occupation.BoatBuilder]: BoatBuilder,
  [Occupation.CutterOperator]: CutterOperator,
  [Occupation.BeachRaider]: BeachRaider,
  [Occupation.SailPatcher]: SailPatcher,
  [Occupation.HerbGardener]: HerbGardener,
  [Occupation.GrainDeliveryman]: GrainDeliveryman,
  [Occupation.Sponsor]: Sponsor,
  [Occupation.DorestadTraveller]: DorestadTraveller,
  [Occupation.PeaFlourBaker]: PeaFlourBaker,
  [Occupation.Chronicler]: Chronicler,
  [Occupation.Mineworker]: Mineworker,
  [Occupation.Seafarer]: Seafarer,
  [Occupation.BosporusMerchant]: BosporusMerchant
}

export default OccupationsRules
