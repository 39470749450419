import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import equal from 'fast-deep-equal'
import {getActionRules} from '../../AFeastForOdin'
import {isWhalingRules} from '../../actions/hunting/WhalingRules'

export default abstract class WhalingSuccessOccupationRules extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const rules = getActionRules(this.game)
      if (rules?.player.color === this.player.color && isWhalingRules(rules) && equal(move.goods, rules.successGoods)) {
        return this.onWhalingSuccess()
      }
    }
    return []
  }

  abstract onWhalingSuccess(): Move[]
}
