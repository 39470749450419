import OccupationRules from './OccupationRules'
import {countCattle, countSheep, playerHasGoods} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class MeatInspector extends OccupationRules {
  immediateEffect() {
    const quantity = [Good.Mead, Good.Stockfish, Good.Milk, Good.SaltMeat, Good.GameMeat]
        .reduce((sum, good) => playerHasGoods(this.player, good) ? sum + 1 : sum, 0)
      + Math.min(1, countSheep(this.player)) + Math.min(1, countCattle(this.player))
    return quantity > 0 ? [receiveGoodsMove(this.player.color, {[Good.Silver]: quantity})] : []
  }
}
