import OccupationRules from './OccupationRules'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Breeder extends OccupationRules {
  immediateEffect() {
    if (playerHasGoods(this.player, Good.Cattle)) {
      return [spendGoodsMove(this.player.color, Good.Cattle), receiveGoodsMove(this.player.color, Good.PregnantCattle)]
    }
    return []
  }
}
