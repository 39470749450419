import Move from '../../moves/Move'
import Good from '../goods/Good'
import {getPendingAction, playerHasGoods} from '../../AFeastForOdin'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'
import Trade from '../../actions/mountainsAndTrade/Trade'

export default class Chronicler extends TradeOccupationRules {
  canUseAnyTimeEffect(): boolean {
    return this.canTakeBelt() || this.canTakeChest()
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Chronicler})
    return []
  }

  canTakeBelt() {
    return this.game.specialTilesSupply.includes(Good.Belt) && playerHasGoods(this.player, {[Good.Flax]: 2}) && !getPendingAction(this.game)
  }

  canTakeChest() {
    return playerHasGoods(this.player, {[Good.Flax]: 3})
  }

  get trades(): Trade[] {
    const trades: Trade[] = [{spend: {[Good.Flax]: 3}, receive: Good.TreasureChest}]
    if (this.canTakeBelt()) {
      trades.push({spend: {[Good.Flax]: 2}, receive: Good.Belt})
    }
    return trades
  }
}
