/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function SpiceMerchantChoice({player}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="spice.merchant.effect" components={[
          <PayButton player={player} goods={{[Good.Oil]: 2}}/>,
          <GoodItem css={inlineGood} good={Good.Spices} height={3}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
