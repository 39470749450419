import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {hasStageForLargeShip, hasWhalingBoat} from '../boats/LandingStages'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {discardBoatMove} from '../../moves/DiscardBoat'
import {takeBoatMove} from '../../moves/TakeBoat'
import BoatType from '../boats/BoatType'
import {spendGoodsMove} from '../../moves/SpendGoods'

export default class ShipBuilder extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.ShipBuilder})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (hasStageForLargeShip(this.player) && hasWhalingBoat(this.player) && playerHasGoods(this.player, {[Good.Silver]: 2})) {
      for (let landingStage = 0; landingStage < this.player.landingStages.whalingBoats.length; landingStage++) {
        if (this.player.landingStages.whalingBoats[landingStage]) {
          moves.push(discardBoatMove(this.player.color, landingStage))
        }
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.Pass:
      case MoveType.TakeBoat:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
      case MoveType.DiscardBoat:
        if (move.player === this.player.color) {
          consequences.push(spendGoodsMove(this.player.color, {[Good.Silver]: 2}))
          consequences.push(takeBoatMove(this.player.color, BoatType.Longship))
        }
        break
    }
    return consequences
  }
}
