import BoatType from '../material/boats/BoatType'
import Good, {isFood} from '../material/goods/Good'
import GoodsAreaType from '../material/goods/GoodsAreaType'
import {isPlacedHorizontally} from '../material/goods/OrientedGood'
import PlacedGood from '../material/goods/PlacedGood'
import PlacementArea from '../material/goods/PlacementArea'
import Player from '../Player'
import PlayerView from '../PlayerView'
import Occupation from '../material/Occupation'
import PlacedGoodsArea from '../material/goods/PlacedGoodsArea'
import Banquet from '../material/goods/Banquet'

const maxFoodSize = 4

export function getBanquet(player: Player | PlayerView) {
  return player.placedGoodsAreas.find(area => area.goodsArea.type === GoodsAreaType.Banquet)!
}

export class BanquetPlacementArea extends PlacementArea {
  private placedGoods: PlacedGood[] = []
  private readonly isSober: boolean
  private readonly peaFlourBaker: boolean

  constructor(player: Player | PlayerView, banquet: PlacedGoodsArea<Banquet> = getBanquet(player)) {
    super([...Array(player.vikings.length)].map(() => [...Array(maxFoodSize)].map(() => ({}))))
    this.placeGoods(banquet.placedGoods)
    this.isSober = player.exhaustedOccupations.includes(Occupation.SoberMan)
    this.peaFlourBaker = player.occupations.includes(Occupation.PeaFlourBaker)
    let emigratedVikings = getEmigratedVikings(player)
    for (let x = 0; x < emigratedVikings; x++) {
      for (let y = 0; y < maxFoodSize; y++) {
        this.area[x][y] = null
      }
    }
  }

  override placeGoods(placedGoods: PlacedGood[]) {
    super.placeGoods(placedGoods)
    this.placedGoods.push(...placedGoods)
  }

  isValidGood(good: Good): boolean {
    if (this.isSober && good === Good.Mead) return false
    return good === Good.Silver || isFood(good)
  }

  protected isAdjacencyForbidden(good: Good): boolean {
    return isFood(good)
  }

  override canPlaceGood(placedGood: PlacedGood) {
    return placedGood.y === 0 && !this.isSecondGoodPlacedHorizontally(placedGood) && super.canPlaceGood(placedGood)
  }

  override canPlaceGoods(placedGoods: PlacedGood[]): boolean {
    for (let i = 0; i < placedGoods.length - 1; i++) {
      const placedGood1 = placedGoods[i]
      for (let j = i + 1; j < placedGoods.length; j++) {
        const placedGood2 = placedGoods[j]
        if (placedGood1.good === placedGood2.good && isPlacedHorizontally(placedGood1) && isPlacedHorizontally(placedGood2)) {
          return false
        }
      }
    }
    return super.canPlaceGoods(placedGoods)
  }

  private isSecondGoodPlacedHorizontally(placedGood: PlacedGood) {
    if (this.peaFlourBaker && placedGood.good === Good.Peas) {
      return isPlacedHorizontally(placedGood)
        && this.placedGoods.reduce((sum, alreadyPlaced) => alreadyPlaced.good === placedGood.good && isPlacedHorizontally(alreadyPlaced) ? sum + 1 : sum, 0) > 1
    }
    return isPlacedHorizontally(placedGood)
      && this.placedGoods.some(alreadyPlaced => alreadyPlaced.good === placedGood.good && isPlacedHorizontally(alreadyPlaced))
  }

  getThingPenalties() {
    return this.area.reduce((penalties, [space]) => !space || space.good ? penalties : penalties + 1, 0)
  }

  canEmigrate(boatSize = 2) {
    for (let x = 0; x < this.area.length; x++) {
      const space = this.area[x][0]
      if (space && !space.good && --boatSize === 0) {
        return true
      }
    }
    return false
  }
}

function getEmigratedVikings(player: Player | PlayerView) {
  return player.emigration.reduce((sum, boatType) => boatType === BoatType.WhalingBoat ? sum + 1 : sum + 2, 0)
}
