import Good from '../../material/goods/Good'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import DiceActionRules from '../DiceActionRules'

export default abstract class HuntingRules extends DiceActionRules {
  abstract successGoods: Good[]

  getPlayerMoves(): Move[] {
    if (this.getRequiredPayment() <= 0) {
      return [receiveGoodsMove(this.player.color, this.successGoods)]
    } else {
      return super.getPlayerMoves()
    }
  }

  isBestRoll(roll: number): boolean {
    return roll === 1
  }

  getRequiredPayment() {
    const pendingDice = this.pendingDice
    return pendingDice.side + this.getDiceModifier() - pendingDice.valueSpent
  }

  getMaxPayment(): number {
    return this.getRequiredPayment()
  }

  canDeclareFailure(): boolean {
    return super.canDeclareFailure() && this.getRequiredPayment() > 0
  }
}
