import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView, {isGameView} from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import MoveType from '../moves/MoveType'
import Occupation from '../material/Occupation'
import {drawWeaponMove} from '../moves/DrawWeapon'
import MoveRandomized from '../moves/MoveRandomized'
import {shuffleWeaponsDeckMove} from '../moves/ShuffleWeaponsDeck'
import {receiveWeaponsMove} from '../moves/ReceiveWeapons'
import {weapons} from '../material/Weapon'

export default class WeaponPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    return [startNextPhaseMove]
  }

  play(move: MoveRandomized | MoveView) {
    const moves: Move[] = []
    if (move.type === MoveType.StartNextPhase) {
      let expectedWeaponsDeckSize = isGameView(this.game) ? this.game.weaponsDeck : this.game.weaponsDeck.length
      let expectedWeaponsDiscardSize = this.game.weaponsDiscard.length
      for (const player of this.game.players) {
        if (player.occupations.includes(Occupation.Peacemaker)) {
          player.effects.unshift({occupation: Occupation.Peacemaker})
        } else {
          if (expectedWeaponsDeckSize === 0 && expectedWeaponsDiscardSize > 0) {
            moves.push(shuffleWeaponsDeckMove)
            expectedWeaponsDeckSize += expectedWeaponsDiscardSize
            expectedWeaponsDiscardSize = 0
          }
          if (expectedWeaponsDeckSize > 0) {
            moves.push(drawWeaponMove(player.color))
            expectedWeaponsDeckSize--
          } else {
            moves.push(receiveWeaponsMove(player.color, [weapons[Math.floor(Math.random() * 4)]]))
          }
        }
      }
    }
    return moves
  }
}