import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {passMove} from '../../moves/Pass'
import TakeMountainsResourcesRules from '../../actions/mountainsAndTrade/TakeMountainsResourcesRules'
import {endEffectMove} from '../../moves/EndEffect'

export default class Preacher extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Preacher})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (this.game.specialTilesSupply.includes(Good.Crucifix)) {
      moves.push(receiveGoodsMove(this.player.color, Good.Crucifix))
    }

    return moves
  }

  delegate() {
    return new TakeMountainsResourcesRules(this.game, this.player, 4)
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.ReceiveGoods:
      case MoveType.TakeGoodsFromMountainStrip:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          consequences.push(endEffectMove(this.player.color)) // delay effect removal for Mountain Guard occupation
        }
    }
    return consequences
  }
}
