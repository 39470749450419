import Good from '../goods/Good'
import {_1, __, i1, i2, i3, i4, i5, i6, i7, PlacementSpace, XX} from '../goods/PlacementSpace'

const ws: PlacementSpace = {bonus: [Good.Wood, Good.Stone]}
const oi: PlacementSpace = {bonus: [Good.Oil]}
const sf: PlacementSpace = {bonus: [Good.Stockfish]}

export const iceland: PlacementSpace[][] = [
  [__, _1, XX, _1, XX, _1, _1, i7],
  [_1, __, XX, __, _1, _1, i6, _1],
  [XX, __, sf, __, _1, i5, _1, _1],
  [__, __, __, __, i4, _1, _1, _1],
  [oi, __, __, i3, _1, _1, _1, _1],
  [__, __, i2, _1, __, __, __, _1],
  [__, i1, _1, _1, __, ws, __, XX],
  [XX, XX, _1, _1, __, XX, XX, XX],
]