import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {playerHasGoods} from '../../AFeastForOdin'
import Good, {goodsToMap} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {passMove} from '../../moves/Pass'

export default class HideBuyer extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.HideBuyer})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, {[Good.Silver]: 2})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 2}))
    }
    if (playerHasGoods(this.player, {[Good.Silver]: 4})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 4}))
    }
    if (playerHasGoods(this.player, {[Good.Silver]: 6})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 6}))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          const silver = goodsToMap(move.goods)[Good.Silver] ?? 0
          consequences.push(receiveGoodsMove(this.player.color, {[Good.Hide]: silver / 2}))
        }
        break
      case MoveType.Pass:
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }
}
