/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {useMemo} from 'react'
import DraggableGood from '../../goods/DraggableGood'

type Props = {
  goods: Partial<Record<Good, number>>
  canDrag?: boolean
}

export default function Stables({goods, canDrag}: Props) {
  const sheep = useMemo(() =>
      Array(goods[Good.Sheep] ?? 0).fill(Good.Sheep).concat(Array(goods[Good.PregnantSheep] ?? 0).fill(Good.PregnantSheep)),
    [goods[Good.Sheep], goods[Good.PregnantSheep]]
  )
  const cattle = useMemo(() =>
      Array(goods[Good.Cattle] ?? 0).fill(Good.Cattle).concat(Array(goods[Good.PregnantCattle] ?? 0).fill(Good.PregnantCattle)),
    [goods[Good.Cattle], goods[Good.PregnantCattle]]
  )
  return (
    <>
      {sheep.map((sheep, index) => <DraggableGood key={index} good={sheep} css={sheepPosition(index)} canDrag={canDrag}/>)}
      {cattle.map((cattle, index) => <DraggableGood key={index} good={cattle} css={cattlePosition(index)} canDrag={canDrag}/>)}
    </>
  )
}

const sheepPosition = (index: number) => css`
  top: ${72.8 + index}em;
  left: ${26 + index}em;
`

const cattlePosition = (index: number) => css`
  top: ${70.4 + index}em;
  left: ${54 + index}em;
`
