import Player from '../../Player'
import PlayerView from '../../PlayerView'
import BoatType from './BoatType'
import Knarr, {isKnarr} from './Knarr'
import Longship, {isLongship} from './Longship'
import WhalingBoat, {isWhalingBoat} from './WhalingBoat'

type LandingStages = {
  whalingBoats: [(WhalingBoat | null), (WhalingBoat | null), (WhalingBoat | null)]
  longBoats: [(Knarr | Longship | null), (Knarr | Longship | null), (Knarr | Longship | null), (Knarr | Longship | null)]
}

export default LandingStages

export function hasWhalingBoat(player: Player | PlayerView) {
  return player.landingStages.whalingBoats.some(isWhalingBoat)
}

export function hasLongBoat(player: Player | PlayerView) {
  return player.landingStages.longBoats.some(boat => boat !== null)
}

export function hasKnarr(player: Player | PlayerView) {
  return player.landingStages.longBoats.some(isKnarr)
}

export function hasLongship(player: Player | PlayerView) {
  return player.landingStages.longBoats.some(isLongship)
}

export function hasBoat(player: Player | PlayerView) {
  return hasWhalingBoat(player) || hasLongBoat(player)
}

export function hasStageForBoat(player: Player | PlayerView, boat: BoatType) {
  return boat === BoatType.WhalingBoat ? hasStageForSmallBoat(player) : hasStageForLargeShip(player)
}

export function hasStageForSmallBoat(player: Player | PlayerView) {
  return player.landingStages.whalingBoats.some(space => space === null)
}

export function hasStageForLargeShip(player: Player | PlayerView) {
  return player.landingStages.longBoats.some(space => space === null)
}

export function countWhalingBoats(player: Player | PlayerView) {
  return player.landingStages.whalingBoats.reduce((sum, boat) => boat !== null ? sum + 1 : sum, 0)
}

export function countKnarrs(player: Player | PlayerView) {
  return player.landingStages.longBoats.reduce((sum, boat) => boat?.type === BoatType.Knarr ? sum + 1 : sum, 0)
}

export function countLongships(player: Player | PlayerView) {
  return player.landingStages.longBoats.reduce((sum, boat) => boat?.type === BoatType.Longship ? sum + 1 : sum, 0)
}

export function countLongBoats(player: Player | PlayerView) {
  return player.landingStages.longBoats.reduce((sum, boat) => boat !== null ? sum + 1 : sum, 0)
}
