import OccupationRules from './OccupationRules'
import {getDrawWeaponMoves} from '../../moves/DrawWeapon'
import {countLongships} from '../boats/LandingStages'

export default class WeaponsSupplier extends OccupationRules {
  immediateEffect() {
    const longships = countLongships(this.player)
    if (longships > 0) {
      return getDrawWeaponMoves(this.game, this.player.color, longships === 1 ? 2 : longships === 2 ? 5 : 10)
    }
    return []
  }
}
