import Action from '../actions/Action'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type ReturnVikings = {
  type: MoveType.ReturnVikings
  player: PlayerColor
  actions: Action[]
}

export default ReturnVikings

export function returnVikingsMove(player: PlayerColor, actions: Action[]): ReturnVikings {
  return {type: MoveType.ReturnVikings, player, actions}
}
