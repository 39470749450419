import Good from '../goods/Good'
import {_1, __, i1, i3, i4, PlacementSpace, XX} from '../goods/PlacementSpace'

const ro: PlacementSpace = {bonus: [Good.RuneStone, Good.Ore]}
const sf: PlacementSpace = {bonus: [Good.Stockfish]}
const gm: PlacementSpace = {bonus: [Good.GameMeat]}

export const bearIsland: PlacementSpace[][] = [
  [XX, XX, XX, _1, _1, _1, _1, XX],
  [XX, _1, _1, _1, XX, _1, _1, _1],
  [_1, _1, _1, i4, __, _1, __, _1],
  [__, __, i3, __, gm, __, _1, __],
  [XX, i1, XX, __, __, _1, __, sf],
  [XX, __, __, __, __, __, _1, _1],
  [XX, __, __, __, __, __, ro, XX],
  [XX, XX, XX, __, _1, __, __, XX],
  [XX, XX, XX, XX, _1, _1, XX, XX]
]