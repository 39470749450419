import OccupationRules from './OccupationRules'
import Action from '../../actions/Action'

export default class Peddler extends OccupationRules {
  actionSilverDiscount(action: Action): number {
    return weeklyMarketAction.includes(action) ? 1 : 0
  }
}

const weeklyMarketAction = [
  Action.BuyFish, Action.BuySheep, Action.BuyMeat, Action.BuyCattleAndMilk, Action.BuySheepOrCattle, Action.BuySheepAndCattle
]
