/** @jsxImportSource @emotion/react */
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {shineEffect, titleButtonCss} from '../styles'
import {Trans} from 'react-i18next'
import {passMove} from '@gamepark/a-feast-for-odin/moves/Pass'
import {displayPlayerCardsMove} from '../player/cards/DisplayPlayerCards'

type Props = {
  afterAction?: boolean
}

export default function FourVikingsBonusChoice({afterAction}: Props) {
  const play = usePlay()
  const playerId = usePlayerId()
  return <Trans
    defaults={afterAction ? 'bonus.4.choice.after' : 'bonus.4.choice.before'}
    components={[
      <button css={[titleButtonCss, shineEffect]} onClick={() => {
        play(displayPlayerCardsMove(playerId, true), {local: true})
      }}/>,
      <button css={[titleButtonCss, shineEffect]} onClick={() => play(passMove(playerId))}/>
    ]}/>
}
