/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {PassButton} from '../PassButton'
import PlayerLocal from '../PlayerLocal'

type Props = {
  player: PlayerLocal
  round: number
}

export default function EarlChoice({player, round}: Props) {
  return <>
    <p>
      <Trans defaults="earl.effect" values={{cost: round}} components={[<PayButton player={player} goods={{[Good.Silver]: round}}/>]}/>
    </p>
    <p><Trans defaults="effect.renounce" components={[<PassButton/>]}/></p>
  </>
}
