/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import {PlayMoveButton} from '../PlayMoveButton'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen from '../navigation/Screen'

type Props = {
  player: PlayerLocal
  crucifixAvailable: boolean
}

export default function PreacherChoice({player, crucifixAvailable}: Props) {
  return <>
    <p>
      <Trans defaults="preacher.effect" components={[
        <PlayMoveButton move={receiveGoodsMove(player.color, Good.Crucifix)} disabled={!crucifixAvailable}/>,
        <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
      ]}/>
    </p>
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
