import Good from '../../material/goods/Good'
import ProduceRules from './ProduceRules'

export default abstract class BuyRules extends ProduceRules {
  get cost() {
    return {[Good.Silver]: this.silverCost - this.getSilverDiscount(this.silverCost)}
  }

  abstract get silverCost(): number
}
