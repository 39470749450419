import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Good from '../goods/Good'
import {playerHasGoods} from '../../AFeastForOdin'
import {emigrateBoatMove} from '../../moves/EmigrateBoat'
import {spendGoodsMove} from '../../moves/SpendGoods'
import Emigrate1Rules from '../../actions/emigration/Emigrate1Rules'

export default abstract class EmigrationOccupation extends OccupationRules {
  getEmigrationCost() {
    return new Emigrate1Rules(this.game, this.player).getEmigrationCost()
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, {[Good.Silver]: this.getEmigrationCost()})) {
      for (let landingStage = 0; landingStage < this.player.landingStages.longBoats.length; landingStage++) {
        const longBoat = this.player.landingStages.longBoats[landingStage]
        if (longBoat !== null) {
          moves.push(emigrateBoatMove(this.player.color, landingStage))
        }
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
      case MoveType.EmigrateBoat:
        if (move.player === this.player.color) {
          consequences.push(spendGoodsMove(this.player.color, this.getEmigrationCost()))
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }
}
