import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'

type EndEffect = {
  type: MoveType.EndEffect
  player: PlayerColor
}

export default EndEffect

export function endEffectMove(player: PlayerColor): EndEffect {
  return {type: MoveType.EndEffect, player}
}
