import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'

export default class Tradesman extends OccupationRules {
  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, Good.Silverware)
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Tradesman})
    return [spendGoodsMove(this.player.color, Good.Silverware)]
  }

  getPlayerMoves(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Silk), receiveGoodsMove(this.player.color, Good.Chest)]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
