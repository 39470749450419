import Move from '../../moves/Move'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import {countKnarrs} from '../boats/LandingStages'
import Good, {isAnimalProduct, isCraftProduct, isFarmProduct, isGood, isLuxuryGood, luxuryGoods, upgradableGoods} from '../goods/Good'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export type TravelingMerchantEffect = {
  occupation: Occupation.TravelingMerchant
  upgradesLeft: number
}

const upgradesPerKnarr = [0, 2, 4, 6, 7]

export default class TravelingMerchant extends UpgradeGoodOccupationRules {
  get upgradableGoods() {
    return [...upgradableGoods, ...luxuryGoods]
  }

  get upgrade() {
    return (good: Good) => {
      if (isFarmProduct(good) || isCraftProduct(good)) return 1
      else if (isAnimalProduct(good) || isLuxuryGood(good)) return -1
      else return 0
    }
  }

  immediateEffect(): Move[] {
    const knarrs = countKnarrs(this.player)
    const upgradesLeft = upgradesPerKnarr[knarrs]
    this.player.effects.unshift({occupation: Occupation.TravelingMerchant, upgradesLeft})
    return []
  }

  get effect(): TravelingMerchantEffect {
    return this.player.effects[0] as TravelingMerchantEffect
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && isGood(move.goods)) {
      switch (move.goods) {
        case Good.Sheep:
          return [receiveGoodsMove(this.player.color, Good.PregnantSheep)]
        case Good.PregnantSheep:
          return [receiveGoodsMove(this.player.color, Good.Sheep)]
        case Good.Cattle:
          return [receiveGoodsMove(this.player.color, Good.PregnantCattle)]
        case Good.PregnantCattle:
          return [receiveGoodsMove(this.player.color, Good.Cattle)]
      }
    }
    return super.play(move)
  }

  onReceiveGoods() {
    this.effect.upgradesLeft--
    if (!this.effect.upgradesLeft) {
      this.player.effects.shift()
    }
  }
}
