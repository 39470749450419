import Good from '../goods/Good'
import {_1, __, i0, i1, i2, PlacementSpace, XX} from '../goods/PlacementSpace'

const be: PlacementSpace = {bonus: [Good.Beans]}
const gm: PlacementSpace = {bonus: [Good.GameMeat]}
const ca: PlacementSpace = {bonus: [Good.Cabbage]}
const oi: PlacementSpace = {bonus: [Good.Oil]}
const si: PlacementSpace = {bonus: [Good.Silverware]}

export const shetland: PlacementSpace[][] = [
  [XX, XX, XX, XX, __, __, __, XX, XX],
  [_1, _1, _1, _1, __, si, __, XX, XX],
  [_1, gm, _1, _1, __, __, _1, _1, __],
  [_1, _1, _1, _1, __, __, __, oi, __],
  [XX, XX, XX, XX, _1, _1, __, __, __],
  [__, __, i2, XX, _1, _1, __, ca, __],
  [__, i1, __, XX, __, _1, _1, __, __],
  [i0, __, be, XX, XX, XX, _1, _1, _1],
  [XX, __, __, XX, XX, XX, _1, _1, __]
]