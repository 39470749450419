import OccupationRules from './OccupationRules'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Orientation from '../Orientation'

export default class Innkeeper extends OccupationRules {
  immediateEffect() {
    for (const player of this.game.players) {
      for (const placedGoodsArea of player.placedGoodsAreas) {
        const drinkingHornIndex = placedGoodsArea.placedGoods.findIndex(placedGood => placedGood.good === Good.DrinkingHorn)
        if (drinkingHornIndex !== -1) {
          const drinkingHorn = placedGoodsArea.placedGoods[drinkingHornIndex]
          placedGoodsArea.placedGoods.splice(drinkingHornIndex, 1)
          placedGoodsArea.placedGoods.push({
            good: Good.RuneStone,
            x: [2, 4].includes(drinkingHorn.orientation) ? drinkingHorn.x + 2 : [3, 7].includes(drinkingHorn.orientation) ? drinkingHorn.x + 1 : drinkingHorn.x,
            y: [1, 7].includes(drinkingHorn.orientation) ? drinkingHorn.y + 2 : [2, 6].includes(drinkingHorn.orientation) ? drinkingHorn.y + 1 : drinkingHorn.y,
            orientation: (drinkingHorn.orientation + 1) % 2
          }, {
            good: Good.Chest,
            x: [0, 1, 5, 6].includes(drinkingHorn.orientation) ? drinkingHorn.x + 1 : drinkingHorn.x,
            y: [0, 3, 4, 5].includes(drinkingHorn.orientation) ? drinkingHorn.y + 1 : drinkingHorn.y,
            orientation: Orientation.Default
          })
          return [receiveGoodsMove(this.player.color, Good.DrinkingHorn)]
        }
      }
    }
    return []
  }
}
