import Occupation from '../Occupation'
import {isBuildingPlacedGoodsArea} from '../goods/PlacedGoodsArea'
import {isHouse} from '../Building'
import Move from '../../moves/Move'
import {getActionRules, playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'

export default class HerbGardener extends TradeOccupationRules {
  trades = [{spend: {[Good.Wood]: 2}, receive: Good.Spices}, {spend: {[Good.Wood]: 5}, receive: {[Good.Spices]: 2}}]

  canUseAnyTimeEffect(): boolean {
    return !this.player.effects.length && !this.player.exhaustedOccupations.includes(Occupation.HerbGardener) && this.countHouses() >= 2
      && getActionRules(this.game)?.player.color !== this.player.color && playerHasGoods(this.player, {[Good.Wood]: 2})
  }

  countHouses() {
    return this.player.placedGoodsAreas.reduce((sum, area) => isBuildingPlacedGoodsArea(area) && isHouse(area.goodsArea.building) ? sum + 1 : sum, 0)
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.HerbGardener})
    return []
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      this.player.exhaustedOccupations.push(Occupation.HerbGardener)
    }
    return super.play(move)
  }
}
