import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countLongships} from '../boats/LandingStages'
import Good from '../goods/Good'

export default class Miner extends OccupationRules {
  immediateEffect() {
    const longships = countLongships(this.player)
    return longships > 0 ? [receiveGoodsMove(this.player.color, {[Good.Ore]: longships, [Good.Stone]: longships, [Good.Silver]: longships})] : []
  }
}
