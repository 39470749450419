/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {HTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../../images/Images'
import {materialText} from '../../styles'

type Props = {
  action: Action
} & HTMLAttributes<HTMLDivElement>

export default function ActionTile({action, ...props}: Props) {
  const {t} = useTranslation()
  return (
    <>
      <div css={actionCss(action)} {...props}>
        {action === Action.BuildHouseAndBoat && <span css={materialText(45.5, 52, 34)}>{t('action.or')}</span>}
        {action === Action.BuySheepOrCattle && <span css={materialText(42, 32.1, 42.2)}>{t('action.or')}</span>}
        {action === Action.Take8ResourcesOrTrade3 && <span css={materialText(40, 33.1, 31.6)}>{t('action.or')}</span>}
        {(action === Action.HuntingGameWithOneViking || action === Action.HuntingGameWithTwoVikings) &&
          <span css={materialText(6, 39, 39)}>{t('Hunting Game')}</span>
        }
        {action === Action.LayingSnare && <span css={materialText(7, 30, 52)}>{t('Laying a Snare')}</span>}
        {(action === Action.Whaling || action === Action.WhalingWithOneBoat) && <>
          <span css={materialText(7, 28, action === Action.Whaling ? 41 : 40)}>{t('Whaling')}</span>
          <span css={materialText(7, action === Action.Whaling ? 74 : 73, 18)}>{t('action.with')}</span>
        </>}
        {action === Action.Milking && <span css={materialText(38.5, 36, 19)}>{t('action.from')}</span>}
        {action === Action.Shearing && <span css={materialText(38.5, 43, 19)}>{t('action.from')}</span>}
        {action === Action.GetSpiceMilkWool && <>
          <span css={materialText(42.5, 27, 34)}>{t('action.from')}</span>
          <span css={materialText(75.5, 28, 34)}>{t('action.from')}</span>
        </>}
        {action === Action.TakeWoodAndOre && <span css={[materialText(27.5, 35), css`font-size: 2em !important;`]}>{t('action.per.player')}</span>}
        {(action === Action.TradeOverseas1 || action === Action.TradeOverseas2) && <>
          <span css={[materialText(8.5, 26, 50)]}>{t('action.any.number')}</span>
          <span css={materialText(8.5, 79, 18)}>{t('action.with')}</span>
        </>}
        {action === Action.SpecialSale && <span css={materialText(10, 79, 18)}>{t('action.with')}</span>}
        {action === Action.Raiding && <>
          <span css={[materialText(8.5, 26, 50)]}>{t('Raiding')}</span>
          <span css={materialText(7, 76.9, 18)}>{t('action.with')}</span>
        </>}
        {(action === Action.Pillaging1 || action === Action.Pillaging2) && <>
          <span css={[materialText(8.5, 26, 50)]}>{t('Pillaging')}</span>
          <span css={materialText(7.5, 79.5, 18)}>{t('action.with')}</span>
        </>}
        {action === Action.Plundering && <>
          <span css={[materialText(6, 12, 43)]}>{t('Plundering')}</span>
          <span css={materialText(6, 74, 18)}>{t('action.with')}</span>
        </>}
        {action === Action.Imitation1 && <>
          <span css={[materialText(26, 13, 76)]}>{t('imitate.action')}</span>
        </>}
        {(action === Action.Imitation2 || action === Action.Imitation3) && <>
          <span css={[materialText(30, 13, 76)]}>{t('imitate.action')}</span>
        </>}
        {action === Action.Imitation4 && <>
          <span css={[materialText(34, 18, 66)]}>{t('imitate.action')}</span>
        </>}
      </div>
    </>
  )
}

const actionCss = (action: Action) => css`
  width: ${actionsSize[action][0]}em;
  height: ${actionsSize[action][1]}em;
  background-image: url(${actionsImages[action]});
  background-size: contain;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 0.2em black);
  text-align: center;
  line-height: 1;
  color: black;
  font-weight: bold;

  & > span {
    font-size: 1.7em;
  }
`

const actionsImages: Record<Action, string> = {
  [Action.BuildShed]: Images.buildShed,
  [Action.BuildStoneHouse]: Images.buildStoneHouse,
  [Action.BuildLongHouse]: Images.buildLongHouse,
  [Action.BuildWhalingBoat]: Images.buildWhalingBoat,
  [Action.BuildKnarr]: Images.buildKnarr,
  [Action.BuildLongship]: Images.buildLongship,
  [Action.BuildHouseAndBoat]: Images.buildHouseAndBoat,
  [Action.HuntingGameWithOneViking]: Images.huntingGame1,
  [Action.HuntingGameWithTwoVikings]: Images.huntingGame2,
  [Action.Fishing]: Images.fishing,
  [Action.LayingSnare]: Images.layingSnare,
  [Action.Whaling]: Images.whaling,
  [Action.WhalingWithOneBoat]: Images.whalingMinor,
  [Action.BuyFish]: Images.buyFish,
  [Action.BuySheep]: Images.buySheep,
  [Action.BuyMeat]: Images.buyMeat,
  [Action.BuyCattleAndMilk]: Images.buyCattleAndMilk,
  [Action.BuySheepOrCattle]: Images.buySheepOrCattle,
  [Action.BuySheepAndCattle]: Images.buySheepAndCattle,
  [Action.GetBeans]: Images.getBeans,
  [Action.GetFlaxAndFish]: Images.getFlaxAndFish,
  [Action.GetFruitsOilMeat]: Images.getFruitsOilMeat,
  [Action.Milking]: Images.milking,
  [Action.GetMead]: Images.getMead,
  [Action.Shearing]: Images.shearing,
  [Action.GetSpiceMilkWool]: Images.getSpiceMilkWool,
  [Action.CraftLinen]: Images.craftLinen,
  [Action.CraftClothing]: Images.craftClothing,
  [Action.Smithy]: Images.smithy,
  [Action.CraftRuneStone]: Images.craftRuneStone,
  [Action.CraftChest]: Images.craftChest,
  [Action.CraftChestsAndRuneStones]: Images.craftChestsAndRuneStones,
  [Action.CraftRobeAndJewelry]: Images.craftRobeAndJewelry,
  [Action.Take2Resources]: Images.take2Resources,
  [Action.TakeWoodAndOre]: Images.takeWoodAndOre,
  [Action.Take5Resources]: Images.take5Resources,
  [Action.Take1ResourceAndTrade]: Images.take1ResourceAndTrade,
  [Action.Take3ResourcesAndTrade]: Images.take3ResourcesAndTrade,
  [Action.Trade3AndWeapons]: Images.trade3AndWeapons,
  [Action.Take4ResourcesAndTrade2]: Images.take4ResourcesAndTrade2,
  [Action.Trade2]: Images.trade2,
  [Action.Trade3]: Images.trade3,
  [Action.Trade4]: Images.trade4,
  [Action.TradeOverseas1]: Images.tradeOversee1,
  [Action.TradeOverseas2]: Images.tradeOversee2,
  [Action.SpecialSale]: Images.specialSale,
  [Action.Take8ResourcesOrTrade3]: Images.take8ResourcesOrTrade3,
  [Action.Raiding]: Images.raiding,
  [Action.Pillaging1]: Images.pillaging1,
  [Action.Pillaging2]: Images.pillaging2,
  [Action.ExploreI]: Images.exploreI,
  [Action.ExploreII]: Images.exploreII,
  [Action.ExploreIII]: Images.exploreIII,
  [Action.Plundering]: Images.plundering,
  [Action.DrawOccupation]: Images.drawOccupation,
  [Action.Emigrate1]: Images.emigrate1,
  [Action.Emigrate2]: Images.emigrate2,
  [Action.PlayOneOccupation]: Images.playOccupation,
  [Action.PlayTwoOccupations]: Images.play2Occupations,
  [Action.PlayFourOccupations]: Images.play4Occupations,
  [Action.ExchangeBoatAndEmigrate]: Images.exchangeBoatAndEmigrate,
  [Action.Imitation1]: Images.imitation1,
  [Action.Imitation2]: Images.imitation2,
  [Action.Imitation3]: Images.imitation3,
  [Action.Imitation4]: Images.imitation4
}

const actionsSize: Record<Action, [number, number]> = {
  [Action.BuildShed]: [23.2, 10.6],
  [Action.BuildStoneHouse]: [22.4, 11.1],
  [Action.BuildLongHouse]: [22.8, 10.5],
  [Action.BuildWhalingBoat]: [19.8, 10.2],
  [Action.BuildKnarr]: [20, 9.6],
  [Action.BuildLongship]: [21.1, 10.2],
  [Action.BuildHouseAndBoat]: [24.5, 23],
  [Action.HuntingGameWithOneViking]: [23.3, 14],
  [Action.HuntingGameWithTwoVikings]: [23, 14],
  [Action.Fishing]: [17.3, 12.5],
  [Action.LayingSnare]: [24.9, 16.4],
  [Action.Whaling]: [22.5, 28],
  [Action.WhalingWithOneBoat]: [24.6, 27.2],
  [Action.BuyFish]: [20.7, 11.5],
  [Action.BuySheep]: [22.7, 11.1],
  [Action.BuyMeat]: [22.4, 10.9],
  [Action.BuyCattleAndMilk]: [24.4, 11.5],
  [Action.BuySheepOrCattle]: [23.5, 24],
  [Action.BuySheepAndCattle]: [24.8, 23.2],
  [Action.GetBeans]: [19, 10],
  [Action.GetFlaxAndFish]: [22.5, 10.9],
  [Action.GetFruitsOilMeat]: [24.1, 11.6],
  [Action.Milking]: [23.9, 12.3],
  [Action.GetMead]: [19, 11.1],
  [Action.Shearing]: [25.2, 10.8],
  [Action.GetSpiceMilkWool]: [25.7, 23.3],
  [Action.CraftLinen]: [23.9, 9.5],
  [Action.CraftClothing]: [24.1, 11.4],
  [Action.Smithy]: [21.8, 10.3],
  [Action.CraftRuneStone]: [21.7, 9.8],
  [Action.CraftChest]: [22.6, 11],
  [Action.CraftChestsAndRuneStones]: [22, 10.6],
  [Action.CraftRobeAndJewelry]: [24.9, 22.1],
  [Action.Take2Resources]: [23.6, 11.6],
  [Action.TakeWoodAndOre]: [21.8, 11.4],
  [Action.Take5Resources]: [23.9, 11.3],
  [Action.Take1ResourceAndTrade]: [23.5, 11.3],
  [Action.Take3ResourcesAndTrade]: [23.3, 10.9],
  [Action.Trade3AndWeapons]: [22.6, 10.3],
  [Action.Take4ResourcesAndTrade2]: [24, 22.6],
  [Action.Trade2]: [16.8, 10.5],
  [Action.Trade3]: [20.8, 10.1],
  [Action.Trade4]: [22, 10.7],
  [Action.TradeOverseas1]: [24, 12],
  [Action.TradeOverseas2]: [23.6, 12.4],
  [Action.SpecialSale]: [23.3, 11.9],
  [Action.Take8ResourcesOrTrade3]: [25.3, 23.1],
  [Action.Raiding]: [23.5, 14.9],
  [Action.Pillaging1]: [23.5, 15.5],
  [Action.Pillaging2]: [23.7, 15.3],
  [Action.ExploreI]: [24.7, 10.9],
  [Action.ExploreII]: [22.8, 10.9],
  [Action.ExploreIII]: [23, 11.3],
  [Action.Plundering]: [24.1, 24.6],
  [Action.DrawOccupation]: [22.3, 11.3],
  [Action.Emigrate1]: [23.7, 11.1],
  [Action.Emigrate2]: [23.8, 11.2],
  [Action.PlayOneOccupation]: [24.9, 12.9],
  [Action.PlayTwoOccupations]: [20.7, 10.7],
  [Action.PlayFourOccupations]: [22.9, 11.2],
  [Action.ExchangeBoatAndEmigrate]: [24.9, 21.3],
  [Action.Imitation1]: [20.3, 9.6],
  [Action.Imitation2]: [20.4, 9.7],
  [Action.Imitation3]: [21.5, 9.9],
  [Action.Imitation4]: [26.3, 12.2]
}
