import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import PendingAction from '../../effects/PendingAction'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import {getActionRules} from '../../AFeastForOdin'

export default class Laborer extends TradeOccupationRules {
  trades = [{spend: {[Good.Silver]: 2}, receive: {[Good.Grain]: 1, [Good.Wood]: 2}}]

  eachTimeEffect(move: Move): Move[] {
    const actionRules = getActionRules(this.game)
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && actionRules && (actionRules.action === Action.GetMead || actionRules.action === Action.CraftClothing)) {
      this.addLaborerAfterEffect(actionRules.pendingAction)
    } else if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color && !this.player.effects.length
      && actionRules && isMountainsAndTradeRules(actionRules)) {
      for (const goods of move.goods) {
        if (goods.length === 2 && goods[0] === Good.Silver && goods[1] === Good.Silver) {
          this.addLaborerAfterEffect(actionRules.pendingAction)
        }
      }
    }
    if (actionRules?.player.color === this.player.color
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && actionRules.pendingAction.afterEffects?.includes(Occupation.Laborer)) {
      const isSingle = actionRules.pendingAction.afterEffects.reduce((sum, effect) => effect === Occupation.Laborer ? sum + 1 : sum, 0) === 1
      if (isSingle) {
        this.player.effects.push({occupation: Occupation.Laborer})
        actionRules.pendingAction.afterEffects = actionRules.pendingAction.afterEffects.filter(effect => effect !== Occupation.Laborer)
      }
    }
    return []
  }

  addLaborerAfterEffect(pendingAction: PendingAction) {
    if (!pendingAction.afterEffects) pendingAction.afterEffects = []
    pendingAction.afterEffects.push(Occupation.Laborer)
  }
}
