import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {spendGoodsMove} from '../../moves/SpendGoods'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {hasStageForLargeShip} from '../boats/LandingStages'
import BoatType from '../boats/BoatType'
import {buildBoatMove} from '../../moves/BuildBoat'

export default class ShipArchitect extends OccupationRules {
  canUseAnyTimeEffect() {
    return hasStageForLargeShip(this.player) && playerHasGoods(this.player, {[Good.Wood]: 3}) &&
      (playerHasGoods(this.player, Good.Wool) || playerHasGoods(this.player, Good.Linen))
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.ShipArchitect})
    return []
  }

  getPlayerMoves(): Move[] {
    return [
      spendGoodsMove(this.player.color, {[Good.Wood]: 3, [Good.Wool]: 1}),
      spendGoodsMove(this.player.color, {[Good.Wood]: 3, [Good.Linen]: 1})
    ]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      this.player.effects.shift()
      return [buildBoatMove(this.player.color, BoatType.Longship)]
    }
    return []
  }
}
