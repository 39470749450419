/** @jsxImportSource @emotion/react */
import {usePlay} from '@gamepark/react-client'
import {shineEffect, titleButtonCss} from './styles'
import MoveLocal from './MoveLocal'
import {ButtonHTMLAttributes} from 'react'

type PlayMoveButtonProps = {
  move: MoveLocal
  local?: boolean
  delayed?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export function PlayMoveButton({move, local, delayed, ...props}: PlayMoveButtonProps) {
  const play = usePlay()
  return <button css={[titleButtonCss, !props.disabled && shineEffect]} onClick={() => play(move, {local, delayed})} {...props}/>
}
