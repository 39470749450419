import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Occupation from '../Occupation'
import {passMove} from '../../moves/Pass'
import MoveView from '../../moves/MoveView'
import Phase from '../../phases/Phase'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {chooseActionMove} from '../../moves/ChooseAction'
import Action, {baseGameActions} from '../../actions/Action'
import LocationType from '../LocationType'
import PendingAction from '../../effects/PendingAction'
import ActionsRules from '../../actions/ActionsRules'
import {spendGoodsMove} from '../../moves/SpendGoods'

export type LatecomerEffect = {
  occupation: Occupation.Latecomer
  paid?: boolean
  pendingAction?: PendingAction
}

export default class Latecomer extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    const effect = this.player.effects[0]
    if (effect?.occupation === Occupation.Latecomer && effect.pendingAction?.complete) {
      this.player.effects.shift()
    } else if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Actions + 1) {
      this.player.effects.unshift({occupation: Occupation.Latecomer})
    }
    return []
  }

  get effect(): LatecomerEffect {
    return this.player.effects[0] as LatecomerEffect
  }

  delegate() {
    if (!this.effect.pendingAction || !this.effect.paid) return
    return new ActionsRules[this.effect.pendingAction.action](this.game, this.player)
  }

  get firstColumnActions() {
    const actions = baseGameActions.filter(action => action % 10 === 1)
    if (this.game.imitations?.includes(Action.Imitation1)) actions.push(Action.Imitation1)
    return actions
  }

  isActionOccupied(action: Action) {
    return this.game.players.some(player => player.vikings.some(location => location.type === LocationType.Action && location.action === action))
  }

  isActionOccupiedByMe(action: Action) {
    return this.player.vikings.some(location => location.type === LocationType.Action && location.action === action)
  }

  getPlayerMoves(): Move[] {
    if (this.effect.pendingAction) return []
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, Good.Silver)) {
      const actions = this.firstColumnActions
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i]
        if (!this.isActionOccupied(action)) {
          if ((i > 0 && this.isActionOccupiedByMe(actions[i - 1])) || i < actions.length - 1 && this.isActionOccupiedByMe(actions[i + 1])) {
            const actionRules = new ActionsRules[action](this.game, this.player)
            if (actionRules.canUseEffect()) {
              moves.push(chooseActionMove(this.player.color, action))
            }
          }
        }
      }
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.ChooseAction:
        if (move.player === this.player.color) {
          consequences.push(spendGoodsMove(this.player.color, Good.Silver))
          this.effect.pendingAction = new ActionsRules[move.action](this.game, this.player).createPendingAction()
        }
        break
      case MoveType.SpendGoods:
        if (move.player === this.player.color && move.goods === Good.Silver) {
          this.effect.paid = true
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }
}
