import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'

enum Screen {
  BluePlayer = PlayerColor.Blue,
  RedPlayer = PlayerColor.Red,
  BlackPlayer = PlayerColor.Black,
  YellowPlayer = PlayerColor.Yellow,
  RoundOverview = 5,
  ActionBoard = 6,
  Mountains = 7,
  BoardsSupply = 8
}

export function getPlayerScreen(color: PlayerColor): Screen {
  switch (color) {
    case PlayerColor.Blue:
      return Screen.BluePlayer
    case PlayerColor.Red:
      return Screen.RedPlayer
    case PlayerColor.Black:
      return Screen.BlackPlayer
    case PlayerColor.Yellow:
      return Screen.YellowPlayer
  }
}

export default Screen
