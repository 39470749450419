/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {useMemo} from 'react'
import GameLocalView from '../GameLocalView'
import PlayerLocal from '../PlayerLocal'
import {longBoatLeft, longBoatTop, shineEffect, whalingBoatLeft, whalingBoatTop} from '../styles'
import ArmBoat from './ArmBoat'
import PlayerBoat from './PlayerBoat'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import {boatRulesDialog, setRulesDialog} from '../RulesDialog'

type Props = {
  game: GameLocalView
  player: PlayerLocal
}

export default function PlayerBoats({game, player}: Props) {
  const playerId = usePlayerId()
  const play = usePlay()
  const legalMoves = useMemo(() => playerId && playerId === player.color ? new AFeastForOdin(game).getLegalMoves(playerId) : [], [game, playerId])
  const canDiscardWhalingBoat = useMemo(() => legalMoves.some(move => move.type === MoveType.DiscardBoat && !move.largeBoat), [legalMoves])
  const canEmigrate = useMemo(() => legalMoves.some(move => move.type === MoveType.EmigrateBoat), [legalMoves])

  return <>
    {player.landingStages.whalingBoats.map((boat, index) => boat ?
      playerId === player.color ?
        <ArmBoat key={index} boat={boat} landingStage={index} playerId={playerId} css={[whalingBoat(index), canDiscardWhalingBoat && shineEffect]}
                 canArm={!player.effects.length || player.effects[0].occupation !== Occupation.OreBoatman}
                 onClick={() => play(setRulesDialog(boatRulesDialog(boat.type, player.color, index)), {local: true})}/> :
        <PlayerBoat key={index} boat={boat} css={whalingBoat(index)}
                    onClick={() => play(setRulesDialog(boatRulesDialog(boat.type, player.color, index)), {local: true})}/>
      : null)}
    {player.landingStages.longBoats.map((boat, index) => boat ?
      playerId === player.color && boat.type === BoatType.Longship ?
        <ArmBoat key={index} boat={boat} css={[longBoat(index), canEmigrate && shineEffect]} landingStage={index} playerId={playerId}
                 canArm={!player.effects.length || player.effects[0].occupation !== Occupation.OreBoatman}
                 onClick={() => play(setRulesDialog(boatRulesDialog(boat.type, player.color, index)), {local: true})}/> :
        <PlayerBoat key={index} boat={boat}
                    onClick={() => play(setRulesDialog(boatRulesDialog(boat.type, player.color, index)), {local: true})}
                    css={[longBoat(index), canEmigrate && shineEffect]}/>
      : null)}
  </>
}

const whalingBoat = (position: number) => css`
  position: absolute;
  top: ${whalingBoatTop}em;
  left: ${whalingBoatLeft(position)}em;
  cursor: pointer;
`

const longBoat = (position: number) => css`
  position: absolute;
  top: ${longBoatTop}em;
  left: ${longBoatLeft(position)}em;
  cursor: pointer;
`
