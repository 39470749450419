/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import {HTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../images/Images'
import {cardHeight, cardWidth} from '../styles'
import OccupationCardsDetails, {OccupationCardText} from './OccupationCardsDetails'

type Props = {
  occupation?: Occupation
  hidden?: boolean
  startingOccupation?: boolean
} & HTMLAttributes<HTMLDivElement>

export const OCCUPATION = 'Occupation'

export default function OccupationCard({occupation, hidden = !occupation, startingOccupation = false, ...props}: Props) {
  return (
    <div css={[style, hidden && backface(startingOccupation)]} {...props}>
      {occupation && <OccupationCardFrontFace occupation={occupation}/>}
    </div>
  )
}

function OccupationCardFrontFace({occupation}: { occupation: Occupation }) {
  const {t} = useTranslation()
  const details = OccupationCardsDetails[occupation - 1]
  return (
    <div css={frontFace(details.image)}>
      <div css={nameBox}>
        <h3>{details.title(t)}</h3>
      </div>
      {details.texts.map((text, index) =>
        <div key={index} css={textBoxCss(text)}>
          <span>{text.text(t)}</span>
        </div>
      )}
    </div>
  )
}

const style = css`
  width: ${cardWidth}em;
  height: ${cardHeight}em;
`

const backface = (startingOccupation: boolean) => css`
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${(startingOccupation ? Images.startingOccupationBack : Images.occupationBack)});
    background-size: cover;
    border-radius: 1.5em;
    box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
  }
`

const frontFace = (image: string) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(${image});
  background-size: cover;
  border-radius: 1.5em;
  box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
  font-family: "Jorvik Informal", sans-serif;
`

const nameBox = css`
  position: absolute;
  top: 5%;
  left: 10%;
  right: 32%;
  bottom: 78%;
  display: flex;
  justify-content: center;
  align-items: center;

  > h3 {
    color: black;
    margin: 0;
    font-size: 1.6em;
    text-align: center;
  }
`

const textBoxCss = ({
                      top = 23,
                      bottom = 8,
                      left = 12,
                      right = 14,
                      size = 1.3,
                      lineHeight = 1.15,
                      justify = 'center',
                      align = 'center'
                    }: OccupationCardText) => css`
  position: absolute;
  top: ${top}%;
  bottom: ${bottom}%;
  left: ${left}%;
  right: ${right}%;
  display: flex;
  justify-content: ${justify};
  align-items: ${align};

  > span {
    color: black;
    font-size: ${size}em;
    line-height: ${lineHeight}em;
    text-align: ${justify};
  }
`
