import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import GameLocalView from '../GameLocalView'
import Screen, {getPlayerScreen} from './Screen'

export const DISPLAY_SCREEN = 'DisplayScreen'

export default interface DisplayScreen {
  type: typeof DISPLAY_SCREEN
  screen: Screen
}

export const displayScreenMove = (screen: Screen): DisplayScreen => ({
  type: DISPLAY_SCREEN, screen
})

export function displayScreen(game: GameLocalView, move: DisplayScreen) {
  delete game.rulesDialog
  game.screen = move.screen
}

export function getScreen(game: GameLocalView, playerId?: PlayerColor): Screen {
  return game.screen ?? (playerId ? getPlayerScreen(playerId) : Screen.ActionBoard)
}
