/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import GoodsAreaType from '@gamepark/a-feast-for-odin/material/goods/GoodsAreaType'
import {isBuildingPlacedGoodsArea} from '@gamepark/a-feast-for-odin/material/goods/PlacedGoodsArea'
import {usePlay} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import BuildingBoardWithGoods from '../../buildings/BuildingBoardWithGoods'
import ExplorationBoardWithGoods from '../../exploration/ExplorationBoardWithGoods'
import Images from '../../images/Images'
import PlayerLocal from '../../PlayerLocal'
import {buttonReset} from '../../styles'
import {displayBoardMove} from '../DisplayBoard'
import BoardsNavigation from './BoardsNavigation'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'

type Props = {
  player: PlayerLocal
}

export default function ExtraBoards({player}: Props) {
  const play = usePlay()
  const board = player.board ? player.placedGoodsAreas[player.board] : undefined
  return (
    <>
      {board &&
        <div css={extraBoardBackgroundCss}>
          <button css={buttonReset} onClick={() => play(displayBoardMove(player.color, 0), {local: true})}>
            <Picture src={Images.close} css={closeIcon} alt="Close"/>
          </button>
        </div>
      }
      {board && isBuildingPlacedGoodsArea(board) &&
        <BuildingBoardWithGoods area={board} css={boardPosition} masterJoiner={player.occupations.includes(Occupation.MasterJoiner)}/>
      }
      {board?.goodsArea.type === GoodsAreaType.Territory && <ExplorationBoardWithGoods area={board} css={boardPosition}/>}
      <BoardsNavigation player={player}/>
    </>
  )
}

const extraBoardBackgroundCss = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 70.3em;
  width: 71em;
  background: rgba(0, 0, 0, 0.8);
`

const closeIcon = css`
  position: absolute;
  right: 1em;
  top: 1em;
  width: 6em;
  height: 6em;
  filter: drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white);
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white);
  }
`

const boardPosition = css`
  position: absolute;
  left: 2.3em;
  top: 7.2em;
`
