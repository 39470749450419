/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import GoodItem from '../goods/GoodItem'

type Props = {
  goods: Partial<Record<Good, number>>
}

export default function PlayerResourcesOverview({goods}: Props) {
  return (
    <div css={style}>
      <GoodItem css={good} good={Good.Silver}/>
      <span css={count}>{goods[Good.Silver] ?? 0}</span>
      <GoodItem css={good} good={Good.Wood}/>
      <span css={count}>{goods[Good.Wood] ?? 0}</span>
      <GoodItem css={good} good={Good.Stone}/>
      <span css={count}>{goods[Good.Stone] ?? 0}</span>
      <GoodItem css={good} good={Good.Ore}/>
      <span css={count}>{goods[Good.Ore] ?? 0}</span>
    </div>
  )
}

const style = css`
  position: absolute;
  left: 6em;
  top: 11.7em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const good = css`
  font-size: 0.5em;
`

const count = css`
  color: white;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
  font-size: 3em;

  &:not(:last-of-type) {
    margin-right: 0.2em;
  }
`