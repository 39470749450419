import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import BoatType from '../boats/BoatType'
import Good from '../goods/Good'

export default class Fighter extends OccupationRules {
  immediateEffect() {
    const longships = this.player.landingStages.longBoats.reduce((sum, boat) => boat?.type === BoatType.Longship && boat.ore >= 2 ? sum + 1 : sum, 0)
    if (longships <= 0) return []
    const good = longships === 1 ? Good.Silk : longships === 2 ? Good.Jewelry : Good.SilverHoard
    return [receiveGoodsMove(this.player.color, good)]
  }
}
