import Game from '../Game'
import GameView from '../GameView'
import Longship from '../material/boats/Longship'
import Good from '../material/goods/Good'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'
import {addGoodsToPlayer} from './ReceiveGoods'
import Occupation from '../material/Occupation'

type DisarmBoat = {
  type: MoveType.DisarmBoat
  player: PlayerColor
  landingStage: number
  whalingBoat?: boolean
}

export default DisarmBoat

export function disarmBoatMove(player: PlayerColor, landingStage: number, whalingBoat = false): DisarmBoat {
  if (whalingBoat) {
    return {type: MoveType.DisarmBoat, player, landingStage, whalingBoat}
  } else {
    return {type: MoveType.DisarmBoat, player, landingStage}
  }
}

export function disarmBoat(game: Game | GameView, move: DisarmBoat) {
  const player = game.players.find(player => player.color === move.player)!
  if (move.whalingBoat) {
    delete player.landingStages.whalingBoats[move.landingStage]!.extraOre
  } else {
    (player.landingStages.longBoats[move.landingStage] as Longship).ore--
  }
  if (player.effects[0]?.occupation !== Occupation.SwordFighter || move.whalingBoat) {
    addGoodsToPlayer(game, player, Good.Ore)
  }
}
