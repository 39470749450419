import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {playerHasGoods} from '../../AFeastForOdin'
import {spendGoodsMove} from '../../moves/SpendGoods'
import BoatType, {boatsValue} from '../boats/BoatType'
import Weapon from '../Weapon'
import {takeBoatMove} from '../../moves/TakeBoat'
import {hasStageForLargeShip} from '../boats/LandingStages'
import Player from '../../Player'
import PlayerView from '../../PlayerView'

export default class Quartermaster extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Quartermaster})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (hasStageForLargeShip(this.player)) {
      const cost = this.getBoatCost()
      if (playerHasGoods(this.player, {[Good.Silver]: cost})) {
        moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: cost}))
      }
    }
    return moves
  }

  getBoatCost(): number {
    return getQuartermasterCost(this.player)
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.Pass:
      case MoveType.TakeBoat:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          consequences.push(takeBoatMove(this.player.color, BoatType.Longship))
        }
        break
    }
    return consequences
  }
}

export function getQuartermasterCost(player: Player | PlayerView) {
  const swords = player.weapons[Weapon.Sword] ?? 0
  const bows = player.weapons[Weapon.Bow] ?? 0
  const spears = player.weapons[Weapon.Spear] ?? 0
  const discount = swords + bows / 2 + spears / 2
  return Math.max(0, Math.floor(boatsValue[BoatType.Longship] - discount))
}
