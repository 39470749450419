/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good, {isPregnantAnimal, resourceGoods} from '@gamepark/a-feast-for-odin/material/goods/Good'
import {getPolyomino} from '@gamepark/a-feast-for-odin/material/goods/Polyomino'
import {spendGoodsMove} from '@gamepark/a-feast-for-odin/moves/SpendGoods'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import DraggableGood from '../../goods/DraggableGood'
import GoodItem from '../../goods/GoodItem'
import Images from '../../images/Images'
import {buttonReset, lightBackground, lightRedBackground, squareSize} from '../../styles'

type Props = {
  good: Good
  quantity?: number
  draggable?: boolean
  draggingGood?: Good
  upgrade?: number
}

export default function GoodWithQuantity({good, quantity = 0, draggable = false, draggingGood, upgrade = 0}: Props) {
  const playerId = usePlayerId<PlayerColor>()
  const play = usePlay()
  return (
    <>
      {draggable && quantity > 0 ?
        <DraggableGood good={good} css={[goodPosition(good), draggingGood && draggingGood !== good && hidden]}/>
        :
        <GoodItem good={good} css={[goodPosition(good), quantity === 0 && emptyGood, draggingGood && hidden]}/>
      }
      {quantity > 0 && <span key={`${good}-count`} css={[goodCount(good), draggingGood && hidden]}>x{quantity}</span>}
      {upgrade !== 0 &&
        <button css={[buttonReset, upgradeButtonPosition(good, upgrade), upgrade === -1 && redBg]} onClick={() => play(spendGoodsMove(playerId!, good))}>
          {[...Array(upgrade > 0 ? upgrade % 10 : 1)].map((_, index) =>
            <Picture key={index} src={Images.upgradeArrow}
                     css={[
                       upgradeWontApply(good, index, upgrade) && wontApply,
                       upgrade < 0 && (upgrade === -1 ? downgrade : leftUpgrade), upgrade > 10 && rightUpgrade
                     ]}/>
          )}
        </button>
      }
    </>
  )
}

const goodPosition = (good: Good) => css`
  position: absolute;
  top: ${goodTop(good)}em;
  left: ${goodLeft(good)}em;
`

function goodTop(good: Good) {
  switch (good) {
    case Good.Silver:
    case Good.Ore:
      return 76
    case Good.Wood:
    case Good.Stone:
      return 74
  }
  if (isPregnantAnimal(good)) {
    return 3 * 17 + (4 - getPolyomino(good).length) * squareSize / 2 + 1
  }
  return (4 - good % 10) * 17 + (4 - getPolyomino(good).length) * squareSize / 2 + 1
}

const goodsMargin = 1.4

function goodLeft(good: Good) {
  switch (good) {
    case Good.Silver:
      return 30
    case Good.Wood:
      return 45
    case Good.Stone:
      return 60
    case Good.Ore:
      return 75
  }
  const column = Math.floor(good / 10)
  switch (column) {
    case 1:
      return column * goodsMargin
    case 2:
      return squareSize * 2 + column * goodsMargin
    case 3:
      return squareSize * 5 + column * goodsMargin
    case 4:
      return squareSize * 7 + column * goodsMargin
    case 5:
      return squareSize * 11 + column * goodsMargin
    case 6:
      return squareSize * 14 + column * goodsMargin
    case 7:
      return squareSize * 18 + column * goodsMargin
    case 8:
    default:
      return squareSize * 21 + column * goodsMargin
  }
}

const emptyGood = css`
  filter: brightness(50%);
`

const goodCountFontSize = 4

const goodCount = (good: Good) => css`
  position: absolute;
  top: ${goodCountTop(good) / goodCountFontSize}em;
  left: ${goodCountLeft(good) / goodCountFontSize}em;
  font-size: ${goodCountFontSize}em;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
  pointer-events: none;
`

function goodCountTop(good: Good) {
  if (resourceGoods.includes(good)) {
    return 80
  }
  return goodTop(good) + (getPolyomino(good).length - 1) * squareSize + 2
}

function goodCountLeft(good: Good) {
  return goodLeft(good) + (getPolyomino(good)[0].length - 1) * squareSize
}

const hidden = css`
  display: none;
`

const upgradeButtonPosition = (good: Good, upgrade: number) => css`
  position: absolute;
  top: ${goodTop(good) + (getPolyomino(good).length - 1) * squareSize / 2 - 8}em;
  left: ${goodLeft(good) + (getPolyomino(good)[0].length - 1) * (upgrade < 0 ? 0 : upgrade > 10 ? squareSize : squareSize / 2) - 0.7}em;

  border: 0.2em solid white;
  border-radius: 1em;
  padding: 0.5em;
  background-color: ${lightBackground};

  width: 7em;
  height: 7em;

  z-index: 1;

  img {
    height: 100%;
  }
`

const wontApply = css`
  opacity: 0.5;
`

const downgrade = css`
  transform: rotate(-180deg);
`

const leftUpgrade = css`
  transform: rotate(-45deg);
`

const rightUpgrade = css`
  transform: rotate(45deg);
`

function upgradeWontApply(good: Good, index: number, upgrade: number): boolean {
  if (upgrade === -1) return false
  if (isPregnantAnimal(good)) return upgradeWontApply(good - 5, index, upgrade)
  return good % 10 + index > 3 || upgrade > 10 && good + (index + 1) * 10 > Good.SilverHoard
}

const redBg = css`
  background-color: ${lightRedBackground};
`