import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import {passMove} from '../moves/Pass'
import MoveType from '../moves/MoveType'
import {BanquetPlacementArea} from './Feast'
import MoveRandomized from '../moves/MoveRandomized'
import {clearBanquetMove} from '../moves/ClearBanquet'

export default class FeastPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  isTurnToPlay(playerId: PlayerColor): boolean {
    return !this.game.players.find(p => p.color === playerId)!.passed
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.every(player => player.passed && !player.effects.length)) {
      return [startNextPhaseMove]
    }
    return []
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    const player = this.game.players.find(p => p.color === playerId)!
    return !player.passed && !player.effects.length ? [passMove(playerId)] : []
  }

  play(move: MoveRandomized | MoveView) {
    switch (move.type) {
      case MoveType.Pass:
        const player = this.game.players.find(player => player.color === move.player)!
        if (!player.effects.length) {
          player.passed = true
          player.thingPenalties += new BanquetPlacementArea(player).getThingPenalties()
          return [clearBanquetMove(player.color)]
        }
        return []
      case MoveType.StartNextPhase:
        for (const player of this.game.players) {
          delete player.passed
        }
        break
    }
    return []
  }
}
