import BuildingGoodsArea from './BuildingGoodsArea'
import Good from './Good'
import GoodsArea from './GoodsArea'
import PlacedGood from './PlacedGood'
import TerritoryGoodsArea from './TerritoryGoodsArea'
import GoodsAreaType from './GoodsAreaType'

type PlacedGoodsArea<G extends GoodsArea = any> = {
  goodsArea: G
  placedGoods: PlacedGood[]
} & (G extends BuildingGoodsArea ? {
  extraGoods: Good[] // Buildings with special spaces for goods outside the grid
} : {})

export default PlacedGoodsArea

export function isBuildingPlacedGoodsArea(area: PlacedGoodsArea): area is PlacedGoodsArea<BuildingGoodsArea> {
  return area.goodsArea.type === GoodsAreaType.Building
}

export function isTerritoryPlacedGoodsArea(area: PlacedGoodsArea): area is PlacedGoodsArea<TerritoryGoodsArea> {
  return area.goodsArea.type === GoodsAreaType.Territory
}
