enum BoatType {
  WhalingBoat = 8, Knarr, Longship
}

export default BoatType

export const boatsValue: Record<BoatType, number> = {
  [BoatType.WhalingBoat]: 3,
  [BoatType.Knarr]: 5,
  [BoatType.Longship]: 8
}
