import Action from '../Action'
import WhalingRules from './WhalingRules'

export default class WhalingWithOneBoatRules extends WhalingRules {
  action = Action.WhalingWithOneBoat

  getWhalingBoatsModifier(): number {
    return Math.max(...this.player.landingStages.whalingBoats.map(this.getWhalingBoatOre))
  }
}
