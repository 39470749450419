/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import GameLocalView from '../GameLocalView'
import Images from '../images/Images'
import {canSelectMenuCss, headerHeight, margin, menuCss, selectedMenuCss} from '../styles'
import {displayScreenMove, getScreen} from './DisplayScreen'
import Screen from './Screen'
import Phase from '@gamepark/a-feast-for-odin/phases/Phase'

type Props = {
  game: GameLocalView
}

export default function RoundMenu({game}: Props) {
  const playerId = usePlayerId()
  const selected = getScreen(game, playerId) === Screen.RoundOverview
  const play = usePlay()
  const phase = game.phase === Phase.EndOfGame ? Phase.Feast : game.phase
  return (
    <button css={[menuCss, style, backgroundY(phase), selected ? selectedMenuCss : canSelectMenuCss]}
            onClick={() => play(displayScreenMove(Screen.RoundOverview), {local: true})}/>
  )
}

const style = css`
  top: ${headerHeight + margin}em;
  right: ${margin}em;
  background-position-x: 9%;
  background-size: 35em;
  background-image: url(${Images.roundOverview});
  transition: background-position 1s ease-in-out;
`

const backgroundY = (phase = 1) => css`
  background-position-y: ${(phase - 1) * 8.89 + 1.2}%;
`
