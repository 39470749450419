import OccupationRules from './OccupationRules'
import {countCattle, countSheep} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Milker extends OccupationRules {
  immediateEffect() {
    const quantity = Math.min(1, countSheep(this.player)) + Math.min(1, countCattle(this.player))
    return quantity > 0 ? [receiveGoodsMove(this.player.color, {[Good.Milk]: quantity, [Good.Silver]: quantity})] : []
  }
}
