import Game from '../Game'
import GameView from '../GameView'
import MoveType from './MoveType'

type ChangeCurrentPlayer = {
  type: MoveType.ChangeCurrentPlayer
}

export default ChangeCurrentPlayer

export const changeCurrentPlayerMove: ChangeCurrentPlayer = {type: MoveType.ChangeCurrentPlayer}

export function changeCurrentPlayer(game: Game | GameView) {
  delete game.pendingAction
  const playerIndex = game.players.findIndex(player => player.color === game.currentPlayer)
  for (let i = playerIndex + 1; i < playerIndex + game.players.length; i++) {
    const nextPlayer = game.players[i % game.players.length]
    if (!nextPlayer.passed) {
      game.currentPlayer = nextPlayer.color
    }
  }
}
