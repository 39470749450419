import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countKnarrs, countLongships, countWhalingBoats} from '../boats/LandingStages'
import Good from '../goods/Good'

export default class ShipOwner extends OccupationRules {
  immediateEffect() {
    const whalingBoats = countWhalingBoats(this.player)
    const knarrs = countKnarrs(this.player)
    const longships = countLongships(this.player)
    const whaleMeat = Math.min(2, whalingBoats, knarrs, longships)
    return whaleMeat > 0 ? [receiveGoodsMove(this.player.color, {[Good.WhaleMeat]: whaleMeat})] : []
  }
}
