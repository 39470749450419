/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import MountainStrip from '@gamepark/a-feast-for-odin/material/MountainStrip'
import {chooseMountainStripMove} from '@gamepark/a-feast-for-odin/moves/ChooseMountainStrip'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Fragment, HTMLAttributes} from 'react'
import GoodItem from '../goods/GoodItem'
import Images from '../images/Images'
import {glowingHighlight, longerStripWidth, stripHeight, stripWidth} from '../styles'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'

type Props = {
  strip?: MountainStrip
  long?: boolean
  canExtract?: number
} & HTMLAttributes<HTMLDivElement>

export default function MountainStripTile({strip, long = strip?.id === 8, canExtract = 0, ...props}: Props) {
  const playerId = usePlayerId<PlayerColor>()
  const play = usePlay()
  const onClick = () => {
    if (playerId && canExtract > 0 && strip) {
      play(chooseMountainStripMove(playerId, strip.id))
    }
  }
  return (
    <div css={[style(long), strip && frontImage(strip.id), canExtract && glowingHighlight]} onClick={onClick} {...props}>
      {strip && strip.goods.map((goods, index) =>
        <Fragment key={index}>
          <GoodItem good={goods[0]}
                    css={[goodCss(goods[0], index), canExtract >= strip.goods.length - index && canGatherGoodStyle, goods[0] === Good.Silver && rotateSilver]}/>
          {goods.length === 2 && <GoodItem good={goods[1]}
                                           css={[silver2Css(index), rotateSilver, canExtract >= strip.goods.length - index && canGatherGoodStyle]}/>}
        </Fragment>
      )}
    </div>
  )
}


const style = (long: boolean) => css`
  position: absolute;
  width: ${long ? longerStripWidth : stripWidth}em;
  height: ${stripHeight}em;
  transform-style: preserve-3d;
  border-radius: 2em;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 2em;
    backface-visibility: hidden;
    box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
  }

  &:after {
    background-image: url(${Images.mountainBack});
    transform: rotateY(180deg);
  }
`

const frontImage = (id: number) => css`
  &:before {
    background-image: url(${getMountainImage(id)});
  }
`

function getMountainImage(id: number) {
  switch (id) {
    case 0:
      return Images.mountain0
    case 1:
      return Images.mountain1
    case 2:
      return Images.mountain2
    case 3:
      return Images.mountain3
    case 4:
      return Images.mountain4
    case 5:
      return Images.mountain5
    case 6:
      return Images.mountain6
    case 7:
      return Images.mountain7
    //case 8:
    //return Images.mountain8
    default:
      throw new Error(`Unknown mountain strip id: ${id}`)
  }
}

const goodCss = (good: Good, index: number) => css`
  position: absolute;
  top: ${good === Good.Silver ? 6.3 : good === Good.Ore ? 6 : 2.8}em;
  right: ${(good === Good.Silver ? 6.8 : good === Good.Ore ? 4 : 3) + index * 11.4}em;
`

const silver2Css = (index: number) => css`
  position: absolute;
  top: 3.6em;
  right: ${2.1 + index * 11.4}em;
`

const rotateSilver = css`
  transform: rotate(-32deg);
`

const canGatherGoodStyle = css`
  filter: drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white);

  div:hover > & {
    filter: drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white) drop-shadow(0 0 0.2em white);
  }
`
