/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import {PassButton} from '../PassButton'
import {getIncome} from '@gamepark/a-feast-for-odin/phases/Income'

type Props = {
  game: GameLocalView
}

export default function IncomeHeaderText({game}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(p => p.color === playerId)

  if (player && !player.passed) {
    return <Trans defaults="income.you" values={{number: getIncome(player)}} components={[<PassButton/>]}/>
  }
  const players = game.players.filter(player => !player.passed)
  if (players.length === 1) {
    return <>{t('income.player', {player: getPlayerName(players[0].color, t)})}</>
  }
  return <>{t('income.players')}</>
}
