import Boat from './Boat'
import BoatType from './BoatType'

type WhalingBoat = {
  type: BoatType.WhalingBoat
  extraOre?: boolean
}

export function isWhalingBoat(boat: Boat | null): boat is WhalingBoat {
  return boat?.type === BoatType.WhalingBoat
}

export default WhalingBoat