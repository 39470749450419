/** @jsxImportSource @emotion/react */
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {Dialog} from '@gamepark/react-components'
import GameLocalView from './GameLocalView'
import {dialogCloseIcon, largeDialogCss} from './styles'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import ActionRulesDialogContent from './actions/ActionRulesDialogContent'
import {useCallback, useEffect} from 'react'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import Scrollbars from 'react-custom-scrollbars-2'
import {css} from '@emotion/react'
import Screen, {getPlayerScreen} from './navigation/Screen'
import {displayScreenMove} from './navigation/DisplayScreen'
import {displayPlayerGoodsMove} from './player/goods/DisplayPlayerGoods'
import {isExploration} from '@gamepark/a-feast-for-odin/actions/sailing/ExploreRules'
import {isEmigration} from '@gamepark/a-feast-for-odin/actions/emigration/EmigrateRules'
import {isPlayOccupations} from '@gamepark/a-feast-for-odin/actions/occupation/PlayOccupationsRules'
import {displayPlayerCardsMove} from './player/cards/DisplayPlayerCards'
import OccupationRulesDialogContent from './occupations/OccupationRulesDialogContent'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import {hasBonusForPlacingFourVikings} from '@gamepark/a-feast-for-odin/actions/BonusForPlacingFourVikingsRules'
import BoatRulesDialogContent from './boats/BoatRulesDialogContent'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {isMountainsAndTradeRules} from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'
import {getActionRules, getPendingAction} from '@gamepark/a-feast-for-odin/AFeastForOdin'

type Props = {
  game: GameLocalView
}

export default function RulesDialog({game}: Props) {
  const play = usePlay()
  const playerId = usePlayerId<PlayerColor>()
  const close = useCallback(() => play(setRulesDialog(undefined), {local: true}), [play])
  const player = game.players.find(player => player.color === playerId)
  const effect = player?.effects?.length ? player.effects[0] : undefined
  const pendingAction = getPendingAction(game)

  useEffect(() => {
    if (effect) {
      switch (effect.occupation) {
        case Occupation.Chief:
        case Occupation.Builder:
        case Occupation.Collector:
        case Occupation.WharfOwner:
        case Occupation.ShipBuilder:
        case Occupation.HarborGuard:
        case Occupation.SwordFighter:
        case Occupation.BirkaSettler:
        case Occupation.Berserker:
          play(displayPlayerCardsMove(playerId!, false), {local: true})
          play(displayScreenMove(getPlayerScreen(playerId!)), {local: true})
          return
        case Occupation.OreBoatman:
        case Occupation.OrientShipper:
        case Occupation.ShipsCook:
        case Occupation.ForeignTrader:
        case Occupation.Steward:
        case Occupation.FarmShopOwner:
        case Occupation.FieldFarmer:
        case Occupation.TravelingMerchant:
        case Occupation.Wholesaler:
        case Occupation.Clerk:
        case Occupation.Shipowner:
        case Occupation.Middleman:
        case Occupation.Merchant:
        case Occupation.FishCook:
        case Occupation.DorestadTraveller:
          play(displayPlayerGoodsMove(playerId!, true), {local: true})
          return
        case Occupation.Locksmith:
        case Occupation.Storeman:
        case Occupation.Peacemaker:
        case Occupation.Slowpoke:
        case Occupation.FineBlacksmith:
        case Occupation.Hornturner:
        case Occupation.Dragonslayer:
        case Occupation.PrivateChef:
        case Occupation.Earl:
        case Occupation.Metalsmith:
        case Occupation.Cowherd:
        case Occupation.BoneCollector:
        case Occupation.ShepherdBoy:
        case Occupation.Quartermaster:
        case Occupation.LongshipBuilder:
        case Occupation.HideBuyer:
        case Occupation.Preacher:
        case Occupation.SpiceMerchant:
        case Occupation.MeatMerchant:
        case Occupation.Nobleman:
        case Occupation.FurMerchant:
        case Occupation.DisheartenedWarrior:
        case Occupation.KnarrBuilder:
        case Occupation.Ironsmith:
        case Occupation.SilkStitcher:
        case Occupation.OilSeller:
        case Occupation.Laborer:
        case Occupation.MeatCurer:
        case Occupation.SnareSpecialist:
        case Occupation.BaitLayer:
        case Occupation.LanceBearer:
        case Occupation.Raider:
        case Occupation.Deerstalker:
        case Occupation.SpiceTrader:
        case Occupation.MeatBuyer:
        case Occupation.Cooper:
        case Occupation.Sower:
        case Occupation.FlaxBaker:
        case Occupation.BosporusTraveller:
        case Occupation.CodliverOilPresser:
        case Occupation.Maid:
        case Occupation.HerbGardener:
        case Occupation.Sponsor:
        case Occupation.Chronicler:
          play(setRulesDialog(occupationRulesDialog(effect.occupation)), {local: true})
          return
        case Occupation.Tutor:
        case Occupation.Preceptor:
          play(displayPlayerCardsMove(playerId!, true), {local: true})
          return
        case Occupation.Inspector:
          if (effect.costPaid === undefined) {
            play(setRulesDialog(occupationRulesDialog(effect.occupation)), {local: true})
          } else {
            play(displayScreenMove(Screen.ActionBoard), {local: true})
          }
          return
        case Occupation.FestiveHunter:
        case Occupation.Follower:
        case Occupation.Latecomer:
          if (effect.pendingAction) {
            break // go to pendingAction screen
          } else {
            play(displayScreenMove(Screen.ActionBoard), {local: true})
            return
          }
        case Occupation.Hornblower:
          if (effect.pendingAction) {
            break // go to pendingAction screen
          } else {
            play(setRulesDialog(occupationRulesDialog(effect.occupation)), {local: true})
            return
          }
        case Occupation.Wanderer:
        case Occupation.ForestBlacksmith:
        case Occupation.ApprenticeCraftsman:
        case Occupation.Carpenter:
        case Occupation.Digger:
        case Occupation.Forester:
        case Occupation.ArmedFighter:
          play(displayScreenMove(Screen.Mountains), {local: true})
          return
        default:
          return
      }
    }
    if (pendingAction) {
      const action = pendingAction.action
      const rules = getActionRules(game)!
      if (rules.player.color === playerId) {
        if (hasBonusForPlacingFourVikings(pendingAction)) {
          play(setRulesDialog(actionRulesDialog(action)), {local: true})
        } else if (isMountainsAndTradeRules(rules) && !pendingAction.goodsTraded) {
          play(displayScreenMove(Screen.Mountains), {local: true})
        } else if ((isMountainsAndTradeRules(rules) && !pendingAction.mountainsExtracted)
          || action === Action.TradeOverseas1 || action === Action.TradeOverseas2) {
          play(displayPlayerGoodsMove(game.currentPlayer, true), {local: true})
        } else if (isExploration(action)) {
          play(displayScreenMove(Screen.BoardsSupply), {local: true})
        } else if (isEmigration(action)) {
          play(displayScreenMove(getPlayerScreen(game.currentPlayer)), {local: true})
        } else if (isPlayOccupations(action) && (action !== Action.PlayOneOccupation || pendingAction.costPaid)) {
          play(displayPlayerCardsMove(game.currentPlayer, true), {local: true})
        } else if (action === Action.Imitation1 || action === Action.Imitation2 || action === Action.Imitation3 || action === Action.Imitation4) {
          play(displayScreenMove(Screen.ActionBoard), {local: true})
        } else {
          play(setRulesDialog(actionRulesDialog(action)), {local: true})
        }
      }
    }
  }, [effect, pendingAction])
  return (
    <Dialog css={largeDialogCss} onBackdropClick={close} open={!!game.rulesDialog}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <Scrollbars autoHeight css={scrollableContainer}>
        {game.rulesDialog?.type === RulesDialogType.Action && <ActionRulesDialogContent game={game} action={game.rulesDialog.action} close={close}/>}
        {game.rulesDialog?.type === RulesDialogType.Occupation &&
          <OccupationRulesDialogContent game={game} occupation={game.rulesDialog.occupation} close={close}/>
        }
        {game.rulesDialog?.type === RulesDialogType.Boat &&
          <BoatRulesDialogContent game={game} {...game.rulesDialog} close={close}/>
        }
      </Scrollbars>
    </Dialog>
  )
}

export const SET_RULES_DIALOG = 'SET_RULES_DIALOG'

export type SetRulesDialog = {
  type: typeof SET_RULES_DIALOG
  rulesDialog?: RulesDialog
}

export type RulesDialog = ActionRulesDialog | OccupationRulesDialog | BoatRulesDialog

export type ActionRulesDialog = {
  type: RulesDialogType.Action
  action: Action
}

export type OccupationRulesDialog = {
  type: RulesDialogType.Occupation
  occupation: Occupation
}

export type BoatRulesDialog = {
  type: RulesDialogType.Boat
  boatType: BoatType
  owner?: PlayerColor
  landingStage?: number
}

export enum RulesDialogType {
  Action, Occupation, Boat
}

export function setRulesDialog(rulesDialog?: RulesDialog): SetRulesDialog {
  return {type: SET_RULES_DIALOG, rulesDialog}
}

export function actionRulesDialog(action: Action): ActionRulesDialog {
  return {type: RulesDialogType.Action, action}
}

export function occupationRulesDialog(occupation: Occupation): OccupationRulesDialog {
  return {type: RulesDialogType.Occupation, occupation}
}

export function boatRulesDialog(boatType: BoatType, owner?: PlayerColor, landingStage?: number): BoatRulesDialog {
  return {type: RulesDialogType.Boat, boatType, owner, landingStage}
}

const scrollableContainer = css`
  max-height: 93em !important;

  > div {
    max-height: calc(93em + 17px) !important;
  }

  // trick to avoid very thin bar on some resolutions with react-custom-scrollbars-2
  > div {
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`