import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'

type ChooseMountainStrip = {
  type: MoveType.ChooseMountainStrip
  player: PlayerColor
  strip: number
}

export default ChooseMountainStrip

export function chooseMountainStripMove(player: PlayerColor, strip: number): ChooseMountainStrip {
  return {type: MoveType.ChooseMountainStrip, player, strip}
}
