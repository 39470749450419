import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {isCrafting} from '../../actions/Action'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import LocationType from '../LocationType'

export default class CraftLeader extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.StartPlayer) {
      let craftingVikings = 0
      for (const location of this.player.vikings) {
        if (location.type === LocationType.Action && isCrafting(location.action)) {
          craftingVikings++
        }
        if (craftingVikings >= 5) {
          return [receiveGoodsMove(this.player.color, Good.Oil)]
        }
      }
    }
    return []
  }
}
