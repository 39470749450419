import Game from '../Game'
import GameView from '../GameView'
import Building from '../material/Building'
import BuildingGoodsArea from '../material/goods/BuildingGoodsArea'
import GoodsAreaType from '../material/goods/GoodsAreaType'
import PlacedGoodsArea, {isBuildingPlacedGoodsArea} from '../material/goods/PlacedGoodsArea'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type TakeBuilding = {
  type: MoveType.TakeBuilding
  player: PlayerColor
  building: Building
}

export default TakeBuilding

export function takeBuildingMove(player: PlayerColor, building: Building): TakeBuilding {
  return {type: MoveType.TakeBuilding, player, building}
}

export function takeBuilding(game: Game | GameView, move: TakeBuilding) {
  const player = game.players.find(player => player.color === move.player)!
  game.buildingSupply[move.building]--
  const id = player.placedGoodsAreas.filter(isBuildingPlacedGoodsArea).filter(area => area.goodsArea.building === move.building).length + 1
  const area: PlacedGoodsArea<BuildingGoodsArea> = {goodsArea: {type: GoodsAreaType.Building, building: move.building, id}, placedGoods: [], extraGoods: []}
  player.placedGoodsAreas.push(area)
}
