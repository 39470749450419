import MoveType from './MoveType'
import Good from '../material/goods/Good'
import Game from '../Game'
import GameView from '../GameView'
import {addGoodsToPlayer} from './ReceiveGoods'
import PlayerColor from '../PlayerColor'

type TakeGoodsFromMountainStrip = {
  type: MoveType.TakeGoodsFromMountainStrip
  player: PlayerColor
  strip: number
  goods: Good[][]
}

export default TakeGoodsFromMountainStrip

export function takeGoodsFromMountainStripMove(player: PlayerColor, strip: number, goods: Good[][]): TakeGoodsFromMountainStrip {
  return {type: MoveType.TakeGoodsFromMountainStrip, player, strip, goods}
}

export function takeGoodsFromMountainStrip(game: Game | GameView, move: TakeGoodsFromMountainStrip) {
  const player = game.players.find(player => player.color === move.player)!
  const strip = game.mountainStrips.find(strip => strip.id === move.strip)!
  strip.goods.splice(-move.goods.length)
  for (const good of move.goods.flatMap(goods => goods)) {
    addGoodsToPlayer(game, player, good)
  }
  if (strip.goods.length === 0) {
    // Warning: if a separate move is created to remove the mountain strip, Mineworker occupation needs a refactoring to keep working.
    game.mountainStrips.splice(game.mountainStrips.indexOf(strip), 1)
  }
}
