import BoatType from '../../material/boats/BoatType'
import RaidingRules from './RaidingRules'
import Good from '../../material/goods/Good'
import Occupation from '../../material/Occupation'

export default abstract class PillagingRules extends RaidingRules {
  dice = 12
  vikingsFailBack = 1

  getDiceModifier(): number {
    return super.getDiceModifier() + this.getBestLongshipOre()
  }

  getBestLongshipOre(): number {
    return Math.max(...this.player.landingStages.longBoats.map(boat => boat?.type === BoatType.Longship ? boat.ore : 0))
  }

  getGoodValue(good: Good): number {
    if (good === Good.Stone && this.player.occupations.includes(Occupation.Catapulter)) return 2
    return super.getGoodValue(good)
  }
}
