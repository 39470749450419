import Good from '../../material/goods/Good'
import Weapon from '../../material/Weapon'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'
import HuntingRules from './HuntingRules'
import Action from '../Action'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'

export default class LayingSnareRules extends HuntingRules {
  action = Action.LayingSnare
  dice = 8
  successGoods = [Good.Fur]
  consumableWeapons = [Weapon.Snare]
  consumableGoods = [Good.Wood]
  vikingsFailBack = 1

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      consequences.push(receiveWeaponsMove(this.player.color, [Weapon.Snare]))
    }
    return consequences
  }
}
