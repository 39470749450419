import EffectRules from '../../effects/EffectRules'
import Move from '../../moves/Move'
import Action from '../../actions/Action'

export default abstract class OccupationRules extends EffectRules {
  immediateEffect?(): Move[]

  eachTimeEffect?(move: Move): Move[]

  canUseAnyTimeEffect(): boolean {
    return false
  }

  anyTimeEffect?(): Move[]

  actionSilverDiscount?(action: Action, cost: number): number
}
