import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'

type DeclareFailure = {
  type: MoveType.DeclareFailure
  player: PlayerColor
}

export default DeclareFailure

export function declareFailureMove(player: PlayerColor): DeclareFailure {
  return {type: MoveType.DeclareFailure, player}
}
