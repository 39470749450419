import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import GameLocalView from '../GameLocalView'
import {getPlayerScreen} from '../navigation/Screen'

export const DISPLAY_BOARD = 'DisplayBoard'

export default interface DisplayBoard {
  type: typeof DISPLAY_BOARD
  player: PlayerColor
  board: number
}

export const displayBoardMove = (player: PlayerColor, board: number): DisplayBoard => ({
  type: DISPLAY_BOARD, player, board
})

export function displayBoard(game: GameLocalView, move: DisplayBoard) {
  const player = game.players.find(p => p.color === move.player)!
  player.board = move.board
  game.screen = getPlayerScreen(move.player)
  delete game.rulesDialog
}
