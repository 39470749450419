import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import Building from '../Building'
import {takeBuildingMove} from '../../moves/TakeBuilding'

export default class Earl extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Earl})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (this.game.buildingSupply[Building.StoneHouse] > 0 && playerHasGoods(this.player, {[Good.Silver]: this.game.round})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: this.game.round}))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          consequences.push(takeBuildingMove(this.player.color, Building.StoneHouse))
        }
        break
      case MoveType.Pass:
      case MoveType.TakeBuilding:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }
}
