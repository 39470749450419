import Player from './Player'

type PlayerView = Omit<Player, 'hand'> & {
  hand: number
}

export default PlayerView

export function isPlayerView(player: Player | PlayerView): player is PlayerView {
  return typeof player.hand === 'number'
}
