import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import GameLocalView from '../../GameLocalView'
import {getPlayerScreen} from '../../navigation/Screen'

export const DISPLAY_PLAYER_GOODS = 'DisplayPlayerGoods'

export default interface DisplayPlayerGoods {
  type: typeof DISPLAY_PLAYER_GOODS
  player: PlayerColor
  display: boolean
}

export const displayPlayerGoodsMove = (player: PlayerColor, display: boolean): DisplayPlayerGoods => ({
  type: DISPLAY_PLAYER_GOODS, player, display
})

export function displayPlayerGoods(game: GameLocalView, move: DisplayPlayerGoods) {
  const player = game.players.find(p => p.color === move.player)!
  if (move.display) {
    game.screen = getPlayerScreen(move.player)
    delete game.rulesDialog
    player.view = 'goods'
  } else {
    delete player.view
  }
}
