/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import Phase from '@gamepark/a-feast-for-odin/phases/Phase'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import FeastHeaderText from './FeastHeaderText'
import BonusHeaderText from './BonusHeaderText'
import IncomeHeaderText from './IncomeHeaderText'
import ActionsHeaderText from './ActionsHeaderText'
import OccupationEffectHeaderText from './OccupationEffectHeaderText'
import {PlayMoveButton} from '../PlayMoveButton'
import {occupationRulesDialog, setRulesDialog} from '../RulesDialog'
import OccupationCardsDetails from '../occupations/OccupationCardsDetails'
import useRules from '../util/useRules'
import EndOfGameHeaderText from './EndOfGameHeaderText'

type Props = {
  game: GameLocalView
}

export default function HeaderText({game}: Props) {
  const playerId = usePlayerId<PlayerColor>()
  const {t} = useTranslation()
  const player = game.players.find(p => p.color === playerId)
  const rules = useRules(game)
  if (player?.effects.length) {
    return <OccupationEffectHeaderText game={game} player={player} effect={player.effects[0]}/>
  }
  if (!playerId || !rules.isTurnToPlay(playerId)) {
    const otherPlayerEffect = game.players.find(player => player.effects.length > 0)
    if (otherPlayerEffect) {
      const occupation = otherPlayerEffect.effects[0].occupation
      return <Trans defaults="occupation.effect.player"
                    values={{player: getPlayerName(otherPlayerEffect.color, t), occupation: OccupationCardsDetails[occupation - 1].title(t)}}
                    components={[<PlayMoveButton move={setRulesDialog(occupationRulesDialog(occupation))}/>]}/>
    }
  }
  switch (game.phase) {
    case Phase.Actions:
      return <ActionsHeaderText game={game}/>
    case Phase.Income:
      return <IncomeHeaderText game={game}/>
    case Phase.Feast:
      return <FeastHeaderText game={game}/>
    case Phase.Bonus:
      return <BonusHeaderText game={game}/>
    case Phase.EndOfGame:
      return <EndOfGameHeaderText game={game}/>
  }
  return <>...</>
}
