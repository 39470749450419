/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import {HornturnerEffect} from '@gamepark/a-feast-for-odin/material/occupations/Hornturner'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {PayButton} from '../PayButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
  effect: HornturnerEffect
  hornAvailable: boolean
}

export default function HornturnerChoice({player, effect, hornAvailable}: Props) {
  const sheep = playerHasGoods(player, Good.Sheep) ? Good.Sheep : Good.PregnantSheep
  return (
    <div>
      {effect.spent !== Good.Sheep &&
        <p>
          <Trans defaults="hornturner.effect.sheep" components={[
            <PayButton player={player} goods={sheep}/>,
            <GoodItem good={Good.SaltMeat} height={1.5} css={inlineGood}/>,
            <GoodItem good={Good.Hide} height={1.5} css={inlineGood}/>,
            <GoodItem good={Good.Wool} height={3} css={inlineGood}/>
          ]}/>
        </p>
      }
      {effect.spent !== Good.Silver &&
        <p>
          <Trans defaults="pay.silver.effect" values={{cost: 3}} components={[
            <PayButton player={player} goods={{[Good.Silver]: 3}} disabled={!playerHasGoods(player, {[Good.Silver]: 3}) || !hornAvailable}/>,
            <GoodItem good={Good.DrinkingHorn} height={4.5} css={inlineGood}/>
          ]}/>
        </p>
      }
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
