import {Rules} from '@gamepark/rules-api'
import {takeNewVikingMove} from '../moves/TakeNewViking'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'

export default class VikingPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    return [takeNewVikingMove, startNextPhaseMove]
  }
}
