import Good from '../goods/Good'
import {_1, __, PlacementSpace, XX} from '../goods/PlacementSpace'

const pe: PlacementSpace = {bonus: [Good.Peas]}
const oi: PlacementSpace = {bonus: [Good.Oil]}
const be: PlacementSpace = {bonus: [Good.Beans]}

export const longHouse: PlacementSpace[][] = [
  [__, _1, __, _1, __, _1, __, _1, __, _1, pe],
  [_1, oi, _1, XX, _1, __, _1, XX, _1, __, _1],
  [__, _1, __, _1, __, be, __, _1, __, _1, XX]
]
