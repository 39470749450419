/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import PlayerLocal from '../PlayerLocal'
import GoodItem from '../goods/GoodItem'
import {PassButton} from '../PassButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function HideBuyerChoice({player}: Props) {
  return <>
    <p>
      <Trans defaults="hide.buyer.effect" values={{cost: 2, hide: 1}} components={[
        <PayButton player={player} goods={{[Good.Silver]: 2}}/>,
        <GoodItem css={inlineGood} good={Good.Hide} height={1.5}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="hide.buyer.effect" values={{cost: 4, hide: 2}} components={[
        <PayButton player={player} goods={{[Good.Silver]: 4}}/>,
        <GoodItem css={inlineGood} good={Good.Hide} height={1.5}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="hide.buyer.effect" values={{cost: 6, hide: 3}} components={[
        <PayButton player={player} goods={{[Good.Silver]: 6}}/>,
        <GoodItem css={inlineGood} good={Good.Hide} height={1.5}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
