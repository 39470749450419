import Good, {isGood} from '../../material/goods/Good'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import ActionRules from '../ActionRules'
import Action from '../Action'
import PendingAction from '../../effects/PendingAction'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import TradeRules from '../mountainsAndTrade/TradeRules'
import Trade from '../mountainsAndTrade/Trade'

export default class CraftRobeAndJewelryRules extends ActionRules {
  action = Action.CraftRobeAndJewelry

  createPendingAction(): PendingAction {
    return {action: this.action, goodsTraded: []}
  }

  delegate() {
    const trades: Trade[] = []
    if (!this.goodsTraded.includes(Good.Wool)) {
      trades.push({spend: Good.Wool, receive: Good.Robe})
    }
    if (!this.goodsTraded.includes(Good.Silverware)) {
      trades.push({spend: Good.Silverware, receive: Good.Jewelry})
    }
    return new TradeRules(this.game, this.player, trades)
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.goodsReceived) {
      moves.push(receiveGoodsMove(this.player.color, {[Good.Silver]: 4}))
    }
    return moves
  }

  get goodsTraded() {
    return this.pendingAction.goodsTraded!
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color && isGood(move.goods)) {
          this.goodsTraded.push(move.goods)
          if (this.goodsTraded.length === 2) {
            this.complete()
          }
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
    }
    return consequences
  }
}
