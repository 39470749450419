import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import Action, {baseGameActions} from '../actions/Action'
import {passMove} from '../moves/Pass'
import {chooseActionMove} from '../moves/ChooseAction'
import MoveType from '../moves/MoveType'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import {changeCurrentPlayerMove} from '../moves/ChangeCurrentPlayer'
import MoveRandomized from '../moves/MoveRandomized'
import ActionsRules from '../actions/ActionsRules'
import LocationType from '../material/LocationType'
import {moveViking} from '../moves/MoveViking'
import {getImitationAction} from '../actions/imitation/ImitationRules'
import BonusForPlacingFourVikingsRules, {hasBonusForPlacingFourVikings} from '../actions/BonusForPlacingFourVikingsRules'
import {prepareToDrawOccupation} from '../actions/occupation/DrawOccupationRules'
import {getActionRules} from '../AFeastForOdin'

export default class ActionsPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  get currentPlayer() {
    return this.game.players.find(player => player.color === this.game.currentPlayer)!
  }

  delegate(): Rules<Game | GameView, Move | MoveView, PlayerColor> | undefined {
    if (this.currentPlayer.effects.length > 0) return
    if (this.game.pendingAction && hasBonusForPlacingFourVikings(this.game.pendingAction)) {
      return new BonusForPlacingFourVikingsRules(this.game)
    } else {
      return getActionRules(this.game)
    }
  }

  isTurnToPlay(playerId: PlayerColor): boolean {
    return this.game.currentPlayer === playerId
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.currentPlayer.effects.length > 0) return []
    if (this.game.pendingAction) return super.getAutomaticMoves()
    if (!this.gameActions.some(action => this.isLegalAction(action))) {
      return [passMove(this.game.currentPlayer)]
    }
    return []
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    if (playerId !== this.game.currentPlayer || this.currentPlayer.effects.length > 0) return []
    if (this.game.pendingAction) return super.getLegalMoves(playerId)
    const moves: Move[] = []
    for (const action of this.gameActions) {
      if (this.isLegalAction(action)) {
        moves.push(chooseActionMove(playerId, action))
      }
    }
    moves.push(passMove(playerId))
    return moves
  }

  play(move: MoveRandomized | MoveView) {
    if (this.currentPlayer.effects.length > 0) return []
    if (this.game.pendingAction) return super.play(move)
    switch (move.type) {
      case MoveType.ChooseAction:
        if (move.player === this.game.currentPlayer) {
          const consequences: Move[] = []
          const player = this.game.players.find(player => player.color === move.player)!
          const actionRules = new ActionsRules[move.action](this.game, player)
          const vikings = player.vikings.map((location, viking) => ({location, viking})).filter(({location}) => location.type === LocationType.ThingSquare)
            .map(({viking}) => viking).slice(0, actionRules.getVikingsCost())
          for (const viking of vikings) {
            consequences.push(moveViking(player.color, viking, {type: LocationType.Action, action: move.action}))
          }
          switch (move.action % 5) {
            case 3:
              consequences.push(...prepareToDrawOccupation(this.game, player))
              break
          }
          this.game.pendingAction = actionRules.createPendingAction()
          return consequences
        }
        break
      case MoveType.Pass:
        if (move.player === this.game.currentPlayer) {
          this.currentPlayer.passed = true
          return this.game.players.some(player => !player.passed) ? [changeCurrentPlayerMove] : [startNextPhaseMove]
        }
        break
      case MoveType.StartNextPhase:
        for (const player of this.game.players) {
          delete player.passed
        }
        break
    }
    return []
  }

  get gameActions(): Action[] {
    const gameActions = [...baseGameActions]
    if (this.game.imitations) {
      for (const imitation of this.game.imitations) {
        gameActions.push(getImitationAction(imitation))
      }
    }
    return gameActions // TODO: Norwegians expansion
  }

  isLegalAction(action: Action): boolean {
    if (this.isActionOccupied(action)) return false
    const player = this.game.players.find(player => player.color === this.game.currentPlayer)!
    const actionRules = new ActionsRules[action](this.game)
    const availableVikings = player.vikings.reduce((sum, vikingLocation) => vikingLocation.type === LocationType.ThingSquare ? sum + 1 : sum, 0)
    return availableVikings >= actionRules.getVikingsCost() && actionRules.canUseEffect()
  }

  isActionOccupied(action: Action) {
    return this.game.players.some(player => player.vikings.some(location => location.type === LocationType.Action && location.action === action))
      || this.game.blockerVikings?.includes(action)
  }
}

