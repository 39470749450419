import Game from '../Game'
import GameView from '../GameView'
import Good from '../material/goods/Good'
import GoodsAreaType from '../material/goods/GoodsAreaType'
import Territory from '../material/Territory'
import MoveType from './MoveType'
import {receiveGoodsMove} from './ReceiveGoods'
import Move from './Move'
import PlayerColor from '../PlayerColor'

type ClaimTerritory = {
  type: MoveType.ClaimTerritory
  player: PlayerColor
  territory: Territory
}

export default ClaimTerritory

export function claimTerritoryMove(player: PlayerColor, territory: Territory): ClaimTerritory {
  return {type: MoveType.ClaimTerritory, player, territory}
}

export function claimTerritory(game: Game | GameView, move: ClaimTerritory): Move[] {
  const player = game.players.find(player => player.color === game.currentPlayer)!
  const index = game.unexploredTerritories.findIndex(({territory}) => territory === move.territory)
  const {silver, territory} = game.unexploredTerritories.splice(index, 1)[0]
  player.placedGoodsAreas.push({goodsArea: {type: GoodsAreaType.Territory, territory}, placedGoods: []})
  return silver ? [receiveGoodsMove(player.color, {[Good.Silver]: silver})] : []
}
