import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import {isWeeklyMarket} from './Messenger'

export default class Milkman extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && isWeeklyMarket(move.action)) {
      return [receiveGoodsMove(this.player.color, Good.Milk)]
    }
    return []
  }
}
