/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Phase from '@gamepark/a-feast-for-odin/phases/Phase'
import {Picture} from '@gamepark/react-components'
import Images from '../images/Images'
import {headerHeight, lightBackground, margin} from '../styles'
import {useTranslation} from 'react-i18next'
import {Fragment, useState} from 'react'
import {TFunction} from 'i18next'
import Scrollbars from 'react-custom-scrollbars-2'

type Props = {
  phase: number
  shortGame?: boolean
}

export default function RoundOverview({phase, shortGame}: Props) {
  const {t} = useTranslation()
  const [rules, setRules] = useState(phase !== Phase.EndOfGame ? phase : undefined)
  return (
    <>
      <div css={style}>
        {phase !== Phase.EndOfGame && <Picture src={Images.roundTrackingCube} css={[cubeCss, cubePosition(phase)]}/>}
        <span css={[text(50, 74.2, 100), css`font-size: 1.2em;`]}>{t('game.end.after', {round: shortGame ? 6 : 7})}</span>
        {phases.map(phase => {
          const info = phasesInfo[phase]!
          return <Fragment key={phase}>
            <span css={text(info.textLeft, info.textTop, info.textWidth)}>{info.title(t)}</span>
            <div css={phaseLocation(info.top, info.height)} onClick={() => setRules(phase)}/>
          </Fragment>
        })}
      </div>
      {rules &&
        <div css={rulesCss}>
          <Scrollbars autoHeight css={scrollableContainer}>
            <h2>{phasesInfo[rules]!.title(t)}</h2>
            <p>{phasesInfo[rules]!.rules(t)}</p>
          </Scrollbars>
        </div>
      }
    </>
  )
}

const roundOverviewHeight = 100 - headerHeight - margin * 2
const roundOverviewRatio = 430 / 1000

const style = css`
  position: absolute;
  top: ${headerHeight + margin}em;
  left: ${margin}em;
  width: ${roundOverviewHeight * roundOverviewRatio}em;
  height: ${roundOverviewHeight}em;
  background-image: url(${Images.roundOverview});
  background-size: cover;
  border-radius: 2em;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
  overflow: hidden;
`

const cubeCss = css`
  position: absolute;
  width: 4em;
  height: ${4 * 1.23}em;
  left: 3.3em;
  filter: drop-shadow(0 0 0.2em black) drop-shadow(0 0 0.2em black);
`

const cubePosition = (phase: Phase) => css`
  top: ${2.6 + (phase - 1) * 7.36}em;
`


const phases = [Phase.Viking, Phase.Harvest, Phase.Exploration, Phase.Weapon, Phase.Actions, Phase.StartPlayer,
  Phase.Income, Phase.Breeding, Phase.Feast, Phase.Bonus, Phase.Mountain, Phase.Return]

type PhaseInfo = {
  title: (t: TFunction) => string
  rules: (t: TFunction) => string
  top: number
  height: number
  textLeft: number
  textTop: number
  textWidth: number
}

const phasesInfo: Partial<Record<Phase, PhaseInfo>> = {
  [Phase.Viking]: {
    title: t => t('phase.viking'),
    rules: t => t('phase.viking.rules'),
    top: 0, height: 9.7, textLeft: 76, textTop: 5, textWidth: 45
  },
  [Phase.Harvest]: {
    title: t => t('phase.harvest'),
    rules: t => t('phase.harvest.rules'),
    top: 9.7, height: 8, textLeft: 76, textTop: 13.5, textWidth: 45
  },
  [Phase.Exploration]: {
    title: t => t('phase.exploration'),
    rules: t => t('phase.exploration.rules'),
    top: 17.7, height: 8.2, textLeft: 74, textTop: 21.8, textWidth: 49
  },
  [Phase.Weapon]: {
    title: t => t('phase.weapon'),
    rules: t => t('phase.weapon.rules'),
    top: 25.9, height: 8, textLeft: 74, textTop: 30, textWidth: 49
  },
  [Phase.Actions]: {
    title: t => t('phase.actions'),
    rules: t => t('phase.actions.rules'),
    top: 33.9, height: 8.1, textLeft: 80, textTop: 38, textWidth: 36
  },
  [Phase.StartPlayer]: {
    title: t => t('phase.startPlayer'),
    rules: t => t('phase.startPlayer.rules'),
    top: 42, height: 8, textLeft: 73, textTop: 46, textWidth: 51
  },
  [Phase.Income]: {
    title: t => t('phase.income'),
    rules: t => t('phase.income.rules'),
    top: 50, height: 8, textLeft: 73, textTop: 54, textWidth: 51
  },
  [Phase.Breeding]: {
    title: t => t('phase.breeding'),
    rules: t => t('phase.breeding.rules'),
    top: 58, height: 8.3, textLeft: 77, textTop: 62, textWidth: 43
  },
  [Phase.Feast]: {
    title: t => t('phase.feast'),
    rules: t => t('phase.feast.rules'),
    top: 66.3, height: 7.8, textLeft: 76, textTop: 70, textWidth: 45
  },
  [Phase.Bonus]: {
    title: t => t('phase.bonus'),
    rules: t => t('phase.bonus.rules'),
    top: 74.1, height: 8.1, textLeft: 77, textTop: 78, textWidth: 43
  },
  [Phase.Mountain]: {
    title: t => t('phase.mountains'),
    rules: t => t('phase.mountains.rules'),
    top: 82.2, height: 8.1, textLeft: 76, textTop: 86.3, textWidth: 45
  },
  [Phase.Return]: {
    title: t => t('phase.return'),
    rules: t => t('phase.return.rules'),
    top: 90.3, height: 9.7, textLeft: 74, textTop: 95, textWidth: 49
  }
}

const text = (left: number, top: number, width: number) => css`
  position: absolute;
  color: black;
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
  width: ${width}%;
  left: ${left}%;
  top: ${top}%;
  transform: translate(-50%, -50%);
`

const phaseLocation = (top: number, height: number) => css`
  position: absolute;
  width: 100%;
  height: ${height}%;
  top: ${top}%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`

const rulesCss = css`
  position: absolute;
  top: 8em;
  left: 42em;
  width: 102em;
  height: 91em;
  color: black;
  background-color: ${lightBackground};
  border-radius: 2em;
  overflow: hidden;

  h2 {
    margin: 1em;
    font-size: 4em;
  }

  p {
    font-size: 3.5em;
    text-align: justify;
    white-space: break-spaces;
    margin: 0 ${4 / 3.5}em 2em;
  }
`

const scrollableContainer = css`
  max-height: 91em !important;

  > div {
    max-height: calc(91em + 17px) !important;
  }

  // trick to avoid very thin bar on some resolutions with react-custom-scrollbars-2
  > div {
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`
