import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import EmigrationOccupation from './EmigrationOccupation'
import {getActionRules} from '../../AFeastForOdin'

export default class BirkaSettler extends EmigrationOccupation {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      const actionRules = getActionRules(this.game)
      if (actionRules?.action === Action.TradeOverseas2 && actionRules.player.color === this.player.color && actionRules.pendingAction.complete) {
        this.player.effects.unshift({occupation: Occupation.BirkaSettler})
      }
    }
    return []
  }
}
