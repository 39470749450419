import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {harvest} from '../../phases/HarvestPhaseRules'

export default abstract class NoHarvestOccupation extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Harvest && !harvest(this.game).length) {
      return this.noHarvestEffect()
    }
    return []
  }

  abstract noHarvestEffect(): Move[]
}
