import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import Good, {isGood, upgradableGoods} from '../goods/Good'
import Action from '../../actions/Action'
import {isEffectWithPendingAction, playerHasGoods} from '../../AFeastForOdin'
import ReceiveGoods from '../../moves/ReceiveGoods'
import {startEffectMove} from '../../moves/StartEffect'

export type DorestadTravellerEffect = {
  occupation: Occupation.DorestadTraveller
  traded: Good[]
}

export default class DorestadTraveller extends UpgradeGoodOccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && move.action === Action.Raiding) {
      return [startEffectMove(this.player.color, {occupation: Occupation.DorestadTraveller, traded: []})]
    }
    return []
  }

  get upgradableGoods(): Good[] {
    if (this.effect?.traded?.length) {
      return upgradableGoods.filter(good =>
        playerHasGoods(this.player, {[good]: this.effect.traded.reduce<number>((sum, e) => e === good ? sum + 1 : sum, 1)})
      )
    } else {
      return upgradableGoods
    }
  }

  get effect(): DorestadTravellerEffect {
    return this.player.effects[0] as DorestadTravellerEffect
  }

  onReceiveGoods(move: ReceiveGoods) {
    if (isGood(move.goods)) {
      this.effect.traded.push(move.goods)
      const nextEffect = this.player.effects[1]
      const pendingAction = isEffectWithPendingAction(nextEffect) ? nextEffect.pendingAction : this.game.pendingAction
      pendingAction!.complete = true
      if (this.effect.traded.length === 2) {
        this.player.effects.shift()
      }
    }
  }
}
