import Move from '../../moves/Move'
import WhalingSuccessOccupationRules from './WhalingSuccessOccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good, {goodsToMap} from '../goods/Good'
import MoveType from '../../moves/MoveType'
import {getActionRules, getPendingAction} from '../../AFeastForOdin'
import {isWhalingRules} from '../../actions/hunting/WhalingRules'
import Action from '../../actions/Action'
import {countWhalingBoats} from '../boats/LandingStages'

export default class IvorySculptor extends WhalingSuccessOccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && !this.player.effects.length) {
      const wood = goodsToMap(move.goods)[Good.Wood] ?? 0
      if (wood) {
        const rules = getActionRules(this.game)
        if (isWhalingRules(rules) && rules.player.color === this.player.color) {
          rules.pendingAction.goodsTraded = [Good.Wood]
        }
      }
    }
    return super.eachTimeEffect(move)
  }

  onWhalingSuccess(): Move[] {
    const pendingAction = getPendingAction(this.game)
    if (pendingAction && !pendingAction.goodsTraded?.includes(Good.Wood)) {
      const whalingBoats = pendingAction.action === Action.WhalingWithOneBoat ? 1 : countWhalingBoats(this.player)
      return [receiveGoodsMove(this.player.color, whalingBoats === 1 ? Good.Cabbage : whalingBoats === 2 ? Good.Beans : Good.Peas)]
    }
    return []
  }
}
