import OccupationRules from './OccupationRules'
import {countSheep} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class SheepShearer extends OccupationRules {
  immediateEffect() {
    const sheep = countSheep(this.player)
    return sheep >= 3 ? [receiveGoodsMove(this.player.color, {[Good.Wool]: sheep >= 6 ? 3 : sheep >= 4 ? 2 : 1})] : []
  }
}
