import Orientation from '../Orientation'
import Good, {isSpecialTile} from './Good'
import {getPolyomino, orientMatrix} from './Polyomino'

type OrientedGood = {
  good: Good
  orientation: Orientation
}

export default OrientedGood

export function getOrientedPolyomino(orientedGood: OrientedGood) {
  const polyomino = getPolyomino(orientedGood.good)
  return orientMatrix(polyomino, orientedGood.orientation)
}

export function rotateGood(orientedGood: OrientedGood) {
  if (isSpecialTile(orientedGood.good)) {
    return {good: orientedGood!.good, orientation: (orientedGood!.orientation + 1) % 8}
  }
  switch (Math.floor(orientedGood.good / 10)) {
    case 0:
    case 3:
    case 7:
      return orientedGood
    default:
      return {good: orientedGood!.good, orientation: (orientedGood!.orientation + 1) % 2}
  }
}

export function isPlacedHorizontally(orientedGood: OrientedGood) {
  const polyomino = getOrientedPolyomino(orientedGood)
  return polyomino.length < polyomino[0].length
}
