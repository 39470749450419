import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import LocationType from '../LocationType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import Action from '../../actions/Action'

export default class EarlOfLade extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    switch (move.type) {
      case MoveType.ReturnVikings:
        if (move.player === this.player.color && this.vikingsOnThingSquare() + move.actions.length === 3) {
          return [receiveGoodsMove(this.player.color, Good.Silver)]
        }
        break
      case MoveType.MoveViking:
        if (move.player === this.player.color && move.location.type === LocationType.Action && this.allVikingsPlaceOnAction(move.location.action)
          && this.vikingsOnThingSquare() === 3) {
          return [receiveGoodsMove(this.player.color, Good.Silver)]
        }
    }
    return []
  }

  vikingsOnThingSquare() {
    return this.player.vikings.reduce((sum, location) => location.type === LocationType.ThingSquare ? sum + 1 : sum, 0)
  }

  allVikingsPlaceOnAction(action: Action) {
    return this.player.vikings.reduce((sum, location) => location.type === LocationType.Action && location.action === action ? sum + 1 : sum, 0) === action % 5
  }
}
