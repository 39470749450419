import {getDrawWeaponMoves} from '../../moves/DrawWeapon'
import Action from '../Action'
import MountainsAndTradeRules from './MountainsAndTradeRules'
import MoveView from '../../moves/MoveView'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'

export default class Trade3AndWeaponsRules extends MountainsAndTradeRules {
  action = Action.Trade3AndWeapons
  trades = [1, 1, 1]

  canUseEffect(): boolean {
    return true
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.goodsReceived) {
      moves.push(...getDrawWeaponMoves(this.game, this.player.color, 4))
    }
    return moves
  }

  canPass(): boolean {
    return true
  }

  play(move: MoveRandomized | MoveView) {
    const consequences = super.play(move)
    if (move.type === MoveType.DrawWeapon && move.player === this.player.color) {
      this.pendingAction.goodsReceived = true
    }
    return consequences
  }
}
