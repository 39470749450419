/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function CooperChoice({player}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="cooper.effect" components={[
          <PayButton player={player} goods={Good.Wood}/>,
          <GoodItem css={inlineGood} good={Good.Stockfish} height={1.5}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
