import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import BoatType from '../boats/BoatType'
import {discardBoatMove} from '../../moves/DiscardBoat'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class WharfOwner extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.WharfOwner})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    for (let landingStage = 0; landingStage < this.player.landingStages.longBoats.length; landingStage++) {
      const boat = this.player.landingStages.longBoats[landingStage]
      if (boat?.type === BoatType.Longship) {
        moves.push(discardBoatMove(this.player.color, landingStage, true))
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
    } else if (move.type === MoveType.DiscardBoat && move.player === this.player.color) {
      consequences.push(receiveGoodsMove(this.player.color, {[Good.Silver]: 8}))
      this.player.effects.shift()
    }
    return consequences
  }
}
