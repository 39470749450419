import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type Pass = {
  type: MoveType.Pass
  player: PlayerColor
}

export default Pass

export function passMove(player: PlayerColor): Pass {
  return {type: MoveType.Pass, player}
}
