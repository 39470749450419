import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'

export default class ForeignTrader extends UpgradeGoodOccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.TradeOverseas1 || move.action === Action.TradeOverseas2)) {
      this.player.effects.unshift({occupation: Occupation.ForeignTrader})
    }
    return []
  }
}
