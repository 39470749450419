import OccupationRules from './OccupationRules'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Courier extends OccupationRules {
  immediateEffect() {
    for (const player of this.game.players) {
      for (const placedGoodsArea of player.placedGoodsAreas) {
        const cloakpinIndex = placedGoodsArea.placedGoods.findIndex(placedGood => placedGood.good === Good.Cloakpin)
        if (cloakpinIndex !== -1) {
          const cloakpin = placedGoodsArea.placedGoods[cloakpinIndex]
          placedGoodsArea.placedGoods.splice(cloakpinIndex, 1)
          placedGoodsArea.placedGoods.push({
            good: Good.RuneStone,
            x: [2, 4].includes(cloakpin.orientation) ? cloakpin.x + 3 : cloakpin.x,
            y: [1, 7].includes(cloakpin.orientation) ? cloakpin.y + 3 : cloakpin.y,
            orientation: (cloakpin.orientation + 1) % 2
          }, {
            good: Good.Silverware,
            x: [0, 3, 6, 7].includes(cloakpin.orientation) ? cloakpin.x + 1 : cloakpin.x,
            y: [2, 3, 5, 6].includes(cloakpin.orientation) ? cloakpin.y + 1 : cloakpin.y,
            orientation: cloakpin.orientation % 2
          })
          return [receiveGoodsMove(this.player.color, Good.Cloakpin)]
        }
      }
    }
    return []
  }
}
