import Coordinates from '../Coordinates'
import Orientation from '../Orientation'
import Good from './Good'

type Polyomino = (0 | 1)[][]

export default Polyomino

export function getPolyomino(good: Good): Polyomino {
  switch (good) {
    case Good.Wood:
    case Good.Stone:
    case Good.Silver:
    case Good.Ore:
      return [[1]]
    case Good.Peas:
    case Good.Mead:
    case Good.Oil:
    case Good.RuneStone:
      return [[1, 1]]
    case Good.Flax:
    case Good.Stockfish:
    case Good.Hide:
    case Good.Silverware:
      return [[1, 1, 1]]
    case Good.Beans:
    case Good.Milk:
    case Good.Wool:
    case Good.Chest:
      return [
        [1, 1],
        [1, 1]
      ]
    case Good.Grain:
    case Good.SaltMeat:
    case Good.Linen:
    case Good.Silk:
      return [[1, 1, 1, 1]]
    case Good.Cabbage:
    case Good.GameMeat:
    case Good.SkinAndBones:
    case Good.Spices:
      return [
        [1, 1, 1],
        [1, 1, 1]
      ]
    case Good.Sheep:
    case Good.PregnantSheep:
    case Good.Fur:
    case Good.Jewelry:
      return [
        [1, 1, 1, 1],
        [1, 1, 1, 1]
      ]
    case Good.Fruits:
    case Good.WhaleMeat:
    case Good.Robe:
    case Good.TreasureChest:
      return [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1]
      ]
    case Good.Cattle:
    case Good.PregnantCattle:
    case Good.Clothing:
    case Good.SilverHoard:
      return [
        [1, 1, 1, 1],
        [1, 1, 1, 1],
        [1, 1, 1, 1]
      ]
    case Good.GlassBeads:
      return [
        [0, 1, 0],
        [1, 1, 1],
        [0, 1, 0]
      ]
    case Good.Helmet:
      return [
        [1, 1],
        [1, 1],
        [1, 0]
      ]
    case Good.Cloakpin:
      return [
        [1, 0, 0, 0],
        [1, 1, 1, 1]
      ]
    case Good.Belt:
      return [[1, 1, 1, 1, 1]]
    case Good.Crucifix:
      return [
        [0, 1, 0],
        [1, 1, 1],
        [0, 1, 0],
        [0, 1, 0]
      ]
    case Good.DrinkingHorn:
      return [
        [0, 1, 1],
        [1, 1, 1],
        [1, 0, 0]
      ]
    case Good.AmberFigure:
      return [
        [0, 1, 0],
        [1, 1, 1],
        [1, 1, 1]
      ]
    case Good.Horseshoe:
      return [
        [1, 0, 1],
        [1, 0, 1],
        [1, 1, 1]
      ]
    case Good.GoldBrooch:
      return [
        [0, 1, 0],
        [1, 1, 1],
        [1, 1, 1],
        [0, 1, 0]
      ]
    case Good.ForgeHammer:
      return [
        [1, 1, 1],
        [1, 1, 1],
        [0, 1, 0],
        [0, 1, 0],
        [0, 1, 0]
      ]
    case Good.Fibula:
      return [
        [1, 1, 0],
        [1, 0, 0],
        [1, 1, 1],
        [1, 0, 0],
        [1, 1, 0]
      ]
    case Good.ThrowingAxe:
      return [
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0]
      ]
    case Good.Chalice:
      return [
        [1, 1, 1],
        [1, 1, 1],
        [0, 1, 0],
        [1, 1, 1]
      ]
    case Good.RoundShield:
      return [
        [0, 1, 1, 0],
        [1, 1, 1, 1],
        [1, 1, 1, 1],
        [0, 1, 1, 0]
      ]
    case Good.EnglishCrown:
      return [
        [1, 0, 1, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1]
      ]
    case Good.WhalingBoat:
      return [
        [1, 1],
        [1, 1],
        [1, 1]
      ]
    default:
      throw new Error(`This good does not have any shape for placement: ${Good[good]}`,)
  }
}

export function orientMatrix<T = any>(matrix: T[][], orientation: Orientation): T[][] {
  switch (orientation) {
    case Orientation.Right:
      return matrix[0].map((_, x) => matrix.map((_, y) => matrix[matrix.length - 1 - y][x]))
    case Orientation.Bottom:
      return matrix.map((row, y) => row.map((_, x) => matrix[matrix.length - 1 - y][row.length - 1 - x]))
    case Orientation.Left:
      return matrix[0].map((_, x) => matrix.map((row, y) => matrix[y][row.length - 1 - x]))
    case Orientation.Backside:
      return matrix.map(row => [...row].reverse())
    case Orientation.BacksideRight:
      return matrix[0].map((_, x) => matrix.map((row, y) => matrix[matrix.length - 1 - y][row.length - 1 - x]))
    case Orientation.BacksideBottom:
      return matrix.map((row, y) => row.map((_, x) => matrix[matrix.length - 1 - y][x]))
    case Orientation.BacksideLeft:
      return matrix[0].map((_, x) => matrix.map((_, y) => matrix[y][x]))
    default:
      return matrix
  }
}

export function getPolyominoCoordinates(polyomino: Polyomino): Coordinates[] {
  const coordinates: Coordinates[] = []
  for (let y = 0; y < polyomino.length; y++) {
    for (let x = 0; x < polyomino[y].length; x++) {
      if (polyomino[y][x]) {
        coordinates.push({x, y: polyomino.length - 1 - y})
      }
    }
  }
  return coordinates
}

export function getPolyominoAdjacentCoordinates(polyomino: Polyomino): Coordinates[] {
  const coordinates: Coordinates[] = []
  for (let y = 0; y < polyomino.length; y++) {
    for (let x = 0; x < polyomino[y].length; x++) {
      if (!polyomino[y][x]
        && (polyomino[y][x - 1] || polyomino[y][x + 1] || (polyomino[y - 1] && polyomino[y - 1][x]) || (polyomino[y + 1] && polyomino[y + 1][x]))) {
        coordinates.push({x, y: polyomino.length - 1 - y})
      }
    }
  }
  for (let y = 0; y < polyomino.length; y++) {
    if (polyomino[y][0]) {
      coordinates.push({x: -1, y: polyomino.length - 1 - y})
    }
    if (polyomino[y][polyomino[0].length - 1]) {
      coordinates.push({x: polyomino[0].length, y: polyomino.length - 1 - y})
    }
  }
  for (let x = 0; x < polyomino[0].length; x++) {
    if (polyomino[0][x]) {
      coordinates.push({x, y: polyomino.length})
    }
    if (polyomino[polyomino.length - 1][x]) {
      coordinates.push({x, y: -1})
    }
  }
  return coordinates
}
