/** @jsxImportSource @emotion/react */
import {css, SerializedStyles} from '@emotion/react'
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import {isStartingOccupation} from '@gamepark/a-feast-for-odin/material/Occupation'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import Player from '@gamepark/a-feast-for-odin/Player'
import PlayerView, {isPlayerView} from '@gamepark/a-feast-for-odin/PlayerView'
import {Avatar, GamePoints, PlayerTimer, usePlayer} from '@gamepark/react-client'
import {HTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'
import {margin, playerPanelWidth} from '../styles'
import PlayerHandOverview from './PlayerHandOverview'
import PlayerWeaponsOverview from './PlayerWeaponsOverview'
import LocationType from '@gamepark/a-feast-for-odin/material/LocationType'
import Viking from './Viking'
import PlayerResourcesOverview from './PlayerResourcesOverview'

type Props = {
  player: Player | PlayerView
  index: number
  selected?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function PlayerPanel({player, index, selected, ...props}: Props) {
  const {t} = useTranslation()
  const info = usePlayer(player.color)
  return (
    <div css={[style, position(index), background[player.color],
      selected ? selectedPlayer[player.color] : border[player.color], !selected && canSelect]}
         {...props}>
      <Avatar playerId={player.color} css={avatarCss}/>
      <h3 css={titleStyle}>
        <span css={[nameStyle]}>{info?.name || getPlayerName(player.color, t)}</span>
        <PlayerTimer playerId={player.color} css={css`flex-shrink: 0`}/>
        <GamePoints playerId={player.color} css={css`flex-shrink: 0`}/>
      </h3>
      <PlayerWeaponsOverview weapons={player.weapons}/>
      <PlayerHandOverview hand={isPlayerView(player) ? player.hand : player.hand.length} startingOccupation={!player.occupations.some(isStartingOccupation)}/>
      <Viking key={index} color={player.color} css={viking}/>
      <span css={vikingCount}>{player.vikings.filter(location => location.type === LocationType.ThingSquare).length}</span>
      <PlayerResourcesOverview goods={player.goods}/>
    </div>
  )
}

const style = css`
  position: absolute;
  right: ${margin}em;
  width: ${playerPanelWidth}em;
  height: 17.5em;
  border: 0.5em solid transparent;
  border-radius: 2em;
  background-color: white;
`

const background: Record<PlayerColor, SerializedStyles> = {
  [PlayerColor.Blue]: css`background-color: #bccfec;`,
  [PlayerColor.Red]: css`background-color: #f2b7b8;`,
  [PlayerColor.Black]: css`background-color: #b9b7c3;`,
  [PlayerColor.Yellow]: css`background-color: #f9f3b8;`
}

const border: Record<PlayerColor, SerializedStyles> = {
  [PlayerColor.Blue]: css`&:hover{background-color: #a7bee5;}`,
  [PlayerColor.Red]: css`&:hover{background-color: #efa1a2;}`,
  [PlayerColor.Black]: css`&:hover{background-color: #a19faf;}`,
  [PlayerColor.Yellow]: css`&:hover{background-color: #f7efa1;}`
}

const selectedPlayer: Record<PlayerColor, SerializedStyles> = {
  [PlayerColor.Blue]: css`background-color: #92aede;`,
  [PlayerColor.Red]: css`background-color: #eb8a8b;`,
  [PlayerColor.Black]: css`background-color: #8a879c;`,
  [PlayerColor.Yellow]: css`background-color: #f5eb89;`
}

/*const selectedBorder: Record<PlayerColor, SerializedStyles> = {
  [PlayerColor.Blue]: css`border-color: #3462b0;`,
  [PlayerColor.Red]: css`border-color: #cb2225;`,
  [PlayerColor.Black]: css`border-color: #222128;`,
  [PlayerColor.Yellow]: css`border-color: #ecd714;`
}*/

const canSelect = css`
  cursor: pointer;
`

const position = (index: number) => css`
  top: ${index * 18.5 + 26}em
`

const avatarCss = css`
  position: absolute;
  top: 0.75em;
  left: 1em;
  width: 5em;
  height: 5em;
`

const titleStyle = css`
  color: #333333;
  position: absolute;
  top: 0.5em;
  left: 2.5em;
  right: 0.5em;
  margin: 0;
  font-size: 2.9em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`

const nameStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const viking = css`
  font-size: 0.5em;
  top: 24em;
  left: 2em;
`

const vikingCount = css`
  position: absolute;
  color: white;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
  font-size: 3em;
  top: 4.2em;
  left: 1.1em;
`
