import Move from '../../moves/Move'
import Good from '../goods/Good'
import {playerHasGoods} from '../../AFeastForOdin'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {harvest} from '../../phases/HarvestPhaseRules'

export default class SpiceMerchant extends TradeOccupationRules {
  trades = [{spend: {[Good.Oil]: 2}, receive: Good.Spices}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Harvest && !harvest(this.game).length) {
      return this.noHarvestEffect()
    }
    return []
  }

  noHarvestEffect(): Move[] {
    if (playerHasGoods(this.player, {[Good.Oil]: 2})) {
      this.player.effects.unshift({occupation: Occupation.SpiceMerchant})
    }
    return []
  }
}
