import MountainsAndTradeRules from './MountainsAndTradeRules'
import Action from '../Action'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import Game from '../../Game'
import GameView from '../../GameView'

export default class Take8ResourcesOrTrade3Rules extends MountainsAndTradeRules {
  action = Action.Take8ResourcesOrTrade3

  constructor(game: Game | GameView) {
    super(game)
    if (!game.pendingAction?.goodsTraded?.length) {
      this.mountainsExtraction = [2, 2, 2, 2]
    }
    if (!game.pendingAction?.mountainsExtracted?.length) {
      this.trades = [2, 2, 2]
    }
  }

  play(move: MoveRandomized | MoveView): Move[] {
    switch (move.type) {
      case MoveType.ChooseMountainStrip:
        if (move.player === this.player.color) {
          delete this.pendingAction.goodsTraded
        }
        break
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          delete this.pendingAction.mountainsExtracted
        }
        break
    }
    return super.play(move)
  }
}