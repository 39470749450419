import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import Player from '../Player'
import PlayerView from '../PlayerView'
import Good from '../material/goods/Good'
import {playerHasGoods} from '../AFeastForOdin'
import {spendGoodsMove} from '../moves/SpendGoods'
import {receiveGoodsMove} from '../moves/ReceiveGoods'

export default class BreedingPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    const moves: Move[] = []
    for (const player of this.game.players) {
      moves.push(...getAnimalBreedingMoves(player))
    }
    moves.push(startNextPhaseMove)
    return moves
  }
}

export function getAnimalBreedingMoves(player: Player | PlayerView): Move[] {
  const moves: Move[] = []
  for (const animalType of [Good.Sheep, Good.Cattle]) {
    const pregnantAnimal = animalType + 5 as Good
    if (playerHasGoods(player, pregnantAnimal)) {
      moves.push(spendGoodsMove(player.color, pregnantAnimal), receiveGoodsMove(player.color, {[animalType]: 2}))
    } else if (playerHasGoods(player, {[animalType]: 2})) {
      moves.push(spendGoodsMove(player.color, animalType), receiveGoodsMove(player.color, pregnantAnimal))
    }
  }
  return moves
}
