import Game from '../Game'
import GameView from '../GameView'
import Good, {Goods, isGood, isSpecialTile} from '../material/goods/Good'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'

type ReceiveGoods = {
  type: MoveType.ReceiveGoods
  player: PlayerColor
  goods: Goods
}

export default ReceiveGoods

export function receiveGoodsMove(player: PlayerColor, goods: Goods): ReceiveGoods {
  return {type: MoveType.ReceiveGoods, player, goods}
}

export function receiveGoods(game: Game | GameView, move: ReceiveGoods) {
  const player = game.players.find(player => player.color === move.player)!
  if (isGood(move.goods)) {
    addGoodsToPlayer(game, player, move.goods)
  } else if (Array.isArray(move.goods)) {
    for (const good of move.goods) {
      addGoodsToPlayer(game, player, good)
    }
  } else {
    for (const good in move.goods) {
      addGoodsToPlayer(game, player, parseInt(good), move.goods[good])
    }
  }
}

export function addGoodsToPlayer(game: Game | GameView, player: Player | PlayerView, good: Good, quantity: number = 1) {
  const previousQuantity = player.goods[good] ?? 0
  player.goods[good] = previousQuantity + quantity
  if (quantity === 1 && isSpecialTile(good)) {
    game.specialTilesSupply = game.specialTilesSupply.filter(specialTile => specialTile !== good)
  }
}
