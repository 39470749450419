import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Taster extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Feast && playerHasGoods(this.player, Good.Spices)) {
      return [receiveGoodsMove(this.player.color, {[Good.Silver]: this.player.goods[Good.Spices] === 1 ? 1 : 2})]
    }
    return []
  }
}
