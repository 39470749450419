import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import {countStoneAndLongHouses} from './Cottager'

export default class Custodian extends OccupationRules {
  immediateEffect() {
    const houses = countStoneAndLongHouses(this.player)
    return houses > 0 ? [receiveGoodsMove(this.player.color, {[Good.Silver]: houses * 2})] : []
  }
}
