import {hasSilver} from '../../AFeastForOdin'
import {hasKnarr} from '../../material/boats/LandingStages'
import Good from '../../material/goods/Good'
import {getSpecialTileCost} from '../../material/goods/SpecialTile'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'
import ActionRules from '../ActionRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'

export default class SpecialSaleRules extends ActionRules {
  action = Action.SpecialSale

  canUseEffect() {
    const minCost = Math.min(Infinity, ...this.game.specialTilesSupply.map(good => this.getSilverCost([good])))
    return hasKnarr(this.player) && hasSilver(this.player, minCost)
  }

  getPlayerSilver() {
    return this.player.goods[Good.Silver] ?? 0
  }

  getPlayerMoves(): Move[] {
    const playerSilver = this.getPlayerSilver()
    const affordableGoods = this.game.specialTilesSupply.filter(good => this.getSilverCost([good]) <= playerSilver)
    const moves: Move[] = affordableGoods.map(good => receiveGoodsMove(this.player.color, [good]))
    for (const good1 of affordableGoods) {
      for (const good2 of affordableGoods) {
        if (good1 !== good2 && this.getSilverCost([good1, good2]) <= playerSilver) {
          moves.push(receiveGoodsMove(this.player.color, [good1, good2]))
        }
      }
    }
    return moves
  }

  getSilverCost(goods: Good[]): number {
    const tilesCost = goods.reduce((sum, good) => sum + getSpecialTileCost(good), 0)
    return tilesCost - this.getSilverDiscount(tilesCost)
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    const consequences = super.play(move)
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && Array.isArray(move.goods)) {
      const cost = this.getSilverCost(move.goods)
      if (cost > 0) {
        consequences.push(spendGoodsMove(move.player, {[Good.Silver]: cost}))
      }
      this.complete()
    }
    return consequences
  }
}
