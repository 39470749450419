import Move from '../../moves/Move'
import Good, {goodsToArray} from '../goods/Good'
import MoveType from '../../moves/MoveType'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'

export default class Locksmith extends TradeOccupationRules {
  trades = [{spend: Good.Silver, receive: Good.Oil}]

  eachTimeEffect(move: Move): Move[] {
    const moves: Move[] = []
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const goods = goodsToArray(move.goods)
      for (const good of goods) {
        if (good === Good.Chest || good === Good.TreasureChest) {
          this.player.effects.unshift({occupation: Occupation.Locksmith})
        }
      }
    }
    return moves
  }
}
