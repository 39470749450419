/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Action, {baseGameActions} from '@gamepark/a-feast-for-odin/actions/Action'
import GameView from '@gamepark/a-feast-for-odin/GameView'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {useMemo, useState} from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import {useTranslation} from 'react-i18next'
import GameLocalView from '../../GameLocalView'
import Images from '../../images/Images'
import {
  baseGameActionBoardHeight,
  baseGameActionBoardWidth,
  headerHeight,
  imitationBoard1Width,
  imitationBoard2Width,
  imitationBoardsHeight,
  margin,
  materialText,
  playerPanelWidth
} from '../../styles'
import LocationType from '@gamepark/a-feast-for-odin/material/LocationType'
import ActionButton from './ActionButton'
import {getImitationAction} from '@gamepark/a-feast-for-odin/actions/imitation/ImitationRules'

type Props = {
  game: GameLocalView
}

export default function ActionBoard({game}: Props) {
  const {t} = useTranslation()
  const occupiedActions = useMemo(() => getOccupiedActions(game), [game])
  const [scrolling, setScrolling] = useState(false)
  return (
    <Scrollbars style={{position: 'absolute', width: `${baseGameActionBoardWidth}em`, height: `calc(100vh - ${headerHeight + margin}em)`}}
                css={scrollableContainer} onScrollStart={() => setScrolling(true)} onScrollStop={() => setScrolling(false)}>
      <div css={actionBoardsContainer}>
        <div css={style}>
          <span css={materialText(1.9, 4.8)}>{t('GAME START')}</span>
          <span css={materialText(5.5, 4.9, 9.2)}>{t('Build Houses')}</span>
          <span css={materialText(10.1, 4.9, 9.2)}>{t('Build Ships')}</span>
          <span css={materialText(15.3, 4.9, 9.2)}>{t('Hunting')}</span>
          <span css={materialText(28.3, 4.9, 9.2)}>{t('Livestock Market')}</span>
          <span css={materialText(38.2, 4.9, 9.2)}>{t('Weekly Market')}</span>
          <span css={materialText(43.5, 4.9, 9.2)}>{t('Products')}</span>
          <span css={materialText(48.6, 4.9, 9.2)}>{t('Crafting')}</span>
          <span css={materialText(58.7, 4.9, 9.2)}>{t('Mountains and Trade')}</span>
          <span css={materialText(75.3, 4.9, 9.2)}>{t('Sailing')}</span>
          <span css={materialText(90, 4.9, 9.2)}>{t('Emigration and Occupation')}</span>
          {baseGameActions.map(action =>
            <ActionButton key={action} game={game} action={action} occupied={occupiedActions[action]} scrolling={scrolling}/>
          )}
        </div>
        {game.imitations &&
          <div css={imitationBoards}>
            {game.imitations.map(imitation =>
              <div key={imitation} css={imitationBoard(imitation)}>
                <ActionButton game={game} action={getImitationAction(imitation)} occupied={occupiedActions[getImitationAction(imitation)]}
                              scrolling={scrolling}/>
              </div>
            )}
          </div>
        }
      </div>
    </Scrollbars>
  )
}

const scrollableContainer = css`
  top: ${headerHeight + margin}em;
  right: ${playerPanelWidth + margin * 2}em;

  // trick to avoid very thin bar on some resolutions with react-custom-scrollbars-2
  & > div {
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`

const actionBoardsContainer = css`
  padding-bottom: ${margin * 4}em;
`

const style = css`
  position: relative;
  width: ${baseGameActionBoardWidth}em;
  height: ${baseGameActionBoardHeight}em;
  background-image: url(${Images.actionBoard});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 2em;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;

  & > span {
    color: black;
    font-weight: bold;
    font-size: 1.7em;
    position: absolute;
    text-align: center;
  }
`

function getOccupiedActions(game: GameView): Partial<Record<Action, { playerId: PlayerColor, vikings: number }>> {
  const occupiedActions: Partial<Record<Action, { playerId: PlayerColor, vikings: number }>> = {}
  for (const player of game.players) {
    for (const vikingLocation of player.vikings) {
      if (vikingLocation.type === LocationType.Action) {
        const occupiedAction = occupiedActions[vikingLocation.action]
        if (!occupiedAction) {
          occupiedActions[vikingLocation.action] = {playerId: player.color, vikings: 1}
        } else {
          occupiedAction.vikings++
        }
      }
    }
  }
  if (game.blockerVikings) {
    const neutralColor = game.players[0].color === PlayerColor.Black ? PlayerColor.Red : PlayerColor.Black
    for (const action of game.blockerVikings) {
      const occupiedAction = occupiedActions[action]
      if (!occupiedAction) {
        occupiedActions[action] = {playerId: neutralColor, vikings: 1}
      } else {
        occupiedAction.vikings++
      }
    }
  }
  return occupiedActions
}

const imitationBoards = css`
  display: flex;
`

const imitationBoard = (imitation: number) => css`
  position: relative;
  width: ${imitation <= 2 ? imitationBoard1Width : imitationBoard2Width}em;
  height: ${imitationBoardsHeight}em;
  background-image: url(${imitationBoardsImages[imitation - 1]});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 2em;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
`

const imitationBoardsImages = [
  Images.imitationBoard1, Images.imitationBoard2, Images.imitationBoard3, Images.imitationBoard4
]
