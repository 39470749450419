import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class LinenWeaver extends OccupationRules {
  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, {[Good.Flax]: 2, [Good.Silver]: 1})
  }

  anyTimeEffect(): Move[] {
    return [
      spendGoodsMove(this.player.color, {[Good.Flax]: 2, [Good.Silver]: 1}),
      receiveGoodsMove(this.player.color, {[Good.Linen]: 2})
    ]
  }
}
