import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Plower extends OccupationRules {
  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, Good.Cattle) || playerHasGoods(this.player, Good.PregnantCattle)
  }

  anyTimeEffect(): Move[] {
    return [
      spendGoodsMove(this.player.color, playerHasGoods(this.player, Good.Cattle) ? Good.Cattle : Good.PregnantCattle),
      receiveGoodsMove(this.player.color, [Good.Peas, Good.Flax, Good.Beans, Good.Grain, Good.Cabbage])
    ]
  }
}
