/** @jsxImportSource @emotion/react */
import GameLocalView from '../GameLocalView'
import {Trans, useTranslation} from 'react-i18next'
import BoatTile from './BoatTile'
import BoatType, {boatsValue} from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {titleButtonCss} from '../styles'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import GoodItem from '../goods/GoodItem'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {css} from '@emotion/react'
import {TFunction} from 'i18next'
import {buyBoatMove, canBuyBoat} from '@gamepark/a-feast-for-odin/moves/BuyBoat'
import {useMemo} from 'react'
import {getPendingAction} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import {discardBoatMove} from '@gamepark/a-feast-for-odin/moves/DiscardBoat'
import {BoatRulesDialog} from '../RulesDialog'
import {emigrateBoatMove} from '@gamepark/a-feast-for-odin/moves/EmigrateBoat'
import {displayScreenMove} from '../navigation/DisplayScreen'
import {getPlayerScreen} from '../navigation/Screen'
import {armBoatMove} from '@gamepark/a-feast-for-odin/moves/ArmBoat'
import PlayerBoat from './PlayerBoat'
import {disarmBoatMove} from '@gamepark/a-feast-for-odin/moves/DisarmBoat'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import useRules from '../util/useRules'

type Props = {
  game: GameLocalView
  close: () => void
} & Omit<BoatRulesDialog, 'type'>

export default function BoatRulesDialogContent({game, boatType, owner, landingStage}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(player => player.color === playerId)
  const ownerPlayer = owner && game.players.find(player => player.color === owner)
  const boat = ownerPlayer && landingStage !== undefined && (
    boatType === BoatType.WhalingBoat ? ownerPlayer.landingStages.whalingBoats[landingStage] : ownerPlayer.landingStages.longBoats[landingStage]
  )
  const play = usePlay()
  const rules = useRules(game)
  const pendingAction = getPendingAction(game)
  const canTradeWhalingBoat = useMemo(() =>
      !!playerId && playerId === owner && boatType === BoatType.WhalingBoat
      && pendingAction?.action === Action.ExchangeBoatAndEmigrate && !pendingAction.boatUpgraded,
    [game])
  const canEmigrate = useMemo(() =>
      !!playerId && playerId === owner && boatType !== BoatType.WhalingBoat
      && rules.getLegalMoves(playerId).some(move => move.type === MoveType.EmigrateBoat),
    [game])
  const armBoat = useMemo(() => {
      if (!playerId || playerId !== owner || landingStage === undefined) return
      const move = armBoatMove(playerId, landingStage, boatType === BoatType.WhalingBoat)
      if (!rules.isLegalMove(playerId, move)) return
      return move
    }, [game]
  )
  const disarmBoat = useMemo(() => {
      if (!playerId || playerId !== owner || landingStage === undefined) return
      const move = disarmBoatMove(playerId, landingStage, boatType === BoatType.WhalingBoat)
      if (!rules.isLegalMove(playerId, move)) return
      return move
    }, [game]
  )

  return <>
    <h2>{t('Boat rules')}</h2>
    <div css={flexContainer}>
      {boat ? <PlayerBoat boat={boat} css={boatCss}/> : <BoatTile boatType={boatType} css={boatCss}/>}
      <div css={rightColumn}>
        <p css={rulesCss}>{boatRulesTexts[boatType](t)}</p>
        {!owner && player &&
          <p>
            <button css={titleButtonCss} onClick={() => {
              play(buyBoatMove(player.color, boatType))
              play(displayScreenMove(getPlayerScreen(player.color)), {local: true})
            }} disabled={!canBuyBoat(player, boatType)}>
              <Trans defaults="buy.for" values={{amount: boatsValue[boatType]}} components={[<GoodItem good={Good.Silver} height={1} css={alignBottom}/>]}/>
            </button>
          </p>
        }
        {canTradeWhalingBoat && playerId && landingStage !== undefined &&
          <p>
            <button css={titleButtonCss} onClick={() => play(discardBoatMove(playerId, landingStage))}>
              {t('trade.whaling.boat')}
            </button>
          </p>
        }
        {canEmigrate && playerId && landingStage !== undefined &&
          <p>
            <button css={titleButtonCss} onClick={() => play(emigrateBoatMove(playerId, landingStage))}>
              {t('emigrate.button')}
            </button>
          </p>
        }
        {player && player.color === owner && player.effects[0]?.occupation === Occupation.WharfOwner && boatType === BoatType.Longship &&
          <p>
            <button css={titleButtonCss} onClick={() => play(discardBoatMove(player.color, landingStage!, true))}>
              {t('wharf.owner.sell')}
            </button>
          </p>
        }
        {player && player.color === owner && player.effects[0]?.occupation === Occupation.ShipBuilder && boatType === BoatType.WhalingBoat &&
          <p>
            <button css={titleButtonCss} onClick={() => play(discardBoatMove(player.color, landingStage!))}>
              {t('Discard this boat')}
            </button>
          </p>
        }
        {armBoat &&
          <p>
            <button css={titleButtonCss} onClick={() => play(armBoat)}>
              <Trans defaults="arm.boat" values={{amount: boatsValue[boatType]}} components={[<GoodItem good={Good.Ore} height={1} css={alignBottom}/>]}/>
            </button>
          </p>
        }
        {disarmBoat &&
          <p>
            <button css={titleButtonCss} onClick={() => play(disarmBoat)}>
              <Trans defaults="disarm.boat" values={{amount: boatsValue[boatType]}} components={[<GoodItem good={Good.Ore} height={1} css={alignBottom}/>]}/>
            </button>
          </p>
        }
      </div>
    </div>
  </>
}

const flexContainer = css`
  display: flex;
  margin: 5em;
  align-items: start;
`

const rightColumn = css`
  flex-grow: 1;
  margin-left: 5em;
  text-align: left;
`

const rulesCss = css`
  white-space: break-spaces;
  text-align: start;
  margin: 0;
`

const boatRulesTexts: Record<BoatType, (t: TFunction) => string> = {
  [BoatType.WhalingBoat]: t => t('whaling.boat.rules'),
  [BoatType.Knarr]: t => t('knarr.rules'),
  [BoatType.Longship]: t => t('longship.rules')
}

const boatCss = css`
  position: relative;
  font-size: 3em;
  flex-grow: 0;
  flex-shrink: 0;
`

const alignBottom = css`
  vertical-align: text-bottom;
`
