import Action from '../../actions/Action'
import LocationType from '../LocationType'
import VikingLocation from './VikingLocation'

type ActionLocation = {
  type: LocationType.Action
  action: Action
}

export default ActionLocation

export function isActionLocation(location: VikingLocation): location is ActionLocation {
  return location.type === LocationType.Action
}
