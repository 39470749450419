import Game from '../Game'
import GameView from '../GameView'
import BoatType from '../material/boats/BoatType'
import {hasStageForLargeShip, hasStageForSmallBoat} from '../material/boats/LandingStages'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'
import BuildBoat from './BuildBoat'

type TakeBoat = {
  type: MoveType.TakeBoat
  player: PlayerColor
  boat: BoatType
}

export default TakeBoat

export function takeBoatMove(player: PlayerColor, boat: BoatType): TakeBoat {
  return {type: MoveType.TakeBoat, player, boat}
}

export function takeBoat(game: Game | GameView, move: TakeBoat | BuildBoat) {
  const player = game.players.find(player => player.color === move.player)!
  switch (move.boat) {
    case BoatType.WhalingBoat:
      player.landingStages.whalingBoats[player.landingStages.whalingBoats.findIndex(boat => boat === null)] = {type: move.boat}
      break
    case BoatType.Knarr:
      player.landingStages.longBoats[player.landingStages.longBoats.findIndex(boat => boat === null)] = {type: move.boat}
      break
    case BoatType.Longship:
      player.landingStages.longBoats[player.landingStages.longBoats.findIndex(boat => boat === null)] = {type: move.boat, ore: 0}
      break
  }
}

export function canTakeBoat(player: Player | PlayerView, boat: BoatType): boolean {
  if (boat === BoatType.WhalingBoat) {
    return hasStageForSmallBoat(player)
  } else {
    return hasStageForLargeShip(player)
  }
}
