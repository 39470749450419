import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import {isCrafting} from '../../actions/Action'
import Occupation from '../Occupation'
import {getPendingAction, playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {startEffectMove} from '../../moves/StartEffect'
import {passMove} from '../../moves/Pass'
import {returnVikingsMove} from '../../moves/ReturnVikings'

export default class Ironsmith extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && move.goods === Good.Ore) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction && isCrafting(pendingAction.action) && pendingAction.costPaid) {
        return [startEffectMove(this.player.color, {occupation: Occupation.Ironsmith})]
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, Good.Silver)) {
      moves.push(spendGoodsMove(this.player.color, Good.Silver))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color && move.goods === Good.Silver) {
          const action = getPendingAction(this.game)!.action
          return [returnVikingsMove(this.player.color, [action])]
        }
        break
      case MoveType.Pass:
      case MoveType.MoveViking:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
    }
    return []
  }
}
