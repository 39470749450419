import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'

export default class Peacemaker extends OccupationRules {
  getPlayerMoves(): Move[] {
    return [Good.Wood, Good.Stone, Good.Ore].map(good => receiveGoodsMove(this.player.color, good))
  }

  play(move: MoveRandomized | MoveView) {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
