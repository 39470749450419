import Move from '../../moves/Move'
import Occupation from '../Occupation'
import OccupationRules from './OccupationRules'
import {takeBoatMove} from '../../moves/TakeBoat'
import BoatType from '../boats/BoatType'

export default class BoatBuilder extends OccupationRules {
  eachTimeEffect(): Move[] {
    if (this.player.landingStages.longBoats.every(stage => stage !== null) && !this.player.exhaustedOccupations.includes(Occupation.BoatBuilder)) {
      this.player.exhaustedOccupations.push(Occupation.BoatBuilder)
      const emptyStages = this.player.landingStages.whalingBoats.reduce((sum, stage) => stage === null ? sum + 1 : sum, 0)
      const moves: Move[] = []
      for (let i = 0; i < Math.min(2, emptyStages); i++) {
        moves.push(takeBoatMove(this.player.color, BoatType.WhalingBoat))
      }
      return moves
    }
    return []
  }
}
