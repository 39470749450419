import BoatType from '../boats/BoatType'
import Building from '../Building'

enum Good {
  Silver = 1, Wood, Stone, Ore,
  Shed, StoneHouse, LongHouse,
  WhalingBoat, Knarr, Longship,

  Peas = 11, Mead, Oil, RuneStone,
  Flax = 21, Stockfish, Hide, Silverware,
  Beans = 31, Milk, Wool, Chest,
  Grain = 41, SaltMeat, Linen, Silk,
  Cabbage = 51, GameMeat, SkinAndBones, Spices,
  Sheep = 62, Fur, Jewelry, PregnantSheep = 67,

  Fruits = 71, WhaleMeat, Robe, TreasureChest,
  Cattle = 82, Clothing, SilverHoard, PregnantCattle = 87,

  GlassBeads = 101, Helmet, Cloakpin, Belt, Crucifix, DrinkingHorn, AmberFigure, Horseshoe, GoldBrooch,
  ForgeHammer, Fibula, ThrowingAxe, Chalice, RoundShield, EnglishCrown
}

export default Good

export type Goods = Good | Good[] | Partial<Record<Good, number>>

export function goodsArrayToGoodsMap(goods: Good[]) {
  return goods.reduce<Partial<Record<Good, number>>>((map, good) => {
    if (!map[good]) map[good] = 0
    map[good]!++
    return map
  }, {})
}

export function goodsToMap(goods: Goods) {
  if (isGood(goods)) return {[goods]: 1}
  else if (Array.isArray(goods)) return goodsArrayToGoodsMap(goods)
  else return goods
}

export function goodsToArray(goods: Goods): Good[] {
  if (isGood(goods)) return [goods]
  else if (Array.isArray(goods)) return goods
  const result: Good[] = []
  for (const good in goods) {
    for (let i = 0; i < goods[good]; i++) {
      result.push(parseInt(good))
    }
  }
  return result
}

export function isGood(goods: Goods): goods is Good {
  return typeof goods === 'number'
}

export const farmProducts = [Good.Peas, Good.Flax, Good.Beans, Good.Grain, Good.Cabbage, Good.Fruits]
export const animalProducts = [Good.Mead, Good.Stockfish, Good.Milk, Good.SaltMeat, Good.GameMeat, Good.WhaleMeat]
export const animals = [Good.Sheep, Good.PregnantSheep, Good.Cattle, Good.PregnantCattle]
export const craftProducts = [Good.Oil, Good.Hide, Good.Wool, Good.Linen, Good.SkinAndBones, Good.Fur, Good.Robe, Good.Clothing]
export const luxuryGoods = [Good.RuneStone, Good.Silverware, Good.Chest, Good.Silk, Good.Spices, Good.Jewelry, Good.TreasureChest, Good.SilverHoard]

export const standardGoods = [...farmProducts, ...animalProducts, ...animals, ...craftProducts, ...luxuryGoods]
export const resourceGoods = [Good.Silver, Good.Wood, Good.Stone, Good.Ore]

export const upgradableGoods = [...farmProducts, ...animalProducts, ...animals, ...craftProducts]

export function isBuilding(good: Good): good is Good & Building {
  return good >= 5 && good <= 7
}

export function isBoat(good: Good): good is Good & BoatType {
  return good >= 8 && good <= 10
}

export function isTile(good: Good) {
  return good > 10
}

export function isStandardGoodTile(good: Good) {
  return good > 10 && good < 100
}

export function isFarmProduct(good: Good) {
  return isStandardGoodTile(good) && good % 5 === 1
}

export function isAnimalProduct(good: Good) {
  return isStandardGoodTile(good) && good % 5 === 2
}

export function isFood(good: Good) {
  return isStandardGoodTile(good) && (good % 5 === 1 || good % 5 === 2)
}

export function isCraftProduct(good: Good) {
  return isStandardGoodTile(good) && good % 5 === 3
}

export function isLuxuryGood(good: Good) {
  return isStandardGoodTile(good) && good % 5 === 4
}

export function isSpecialTile(good: Good) {
  return good > 100
}

export function isPregnantAnimal(good: Good) {
  return isStandardGoodTile(good) && good % 10 === 7
}

export const swordValues: Partial<Record<Good, number>> = {
  [Good.RuneStone]: 6,
  [Good.Silverware]: 7,
  [Good.Chest]: 8,
  [Good.Silk]: 8,
  [Good.Spices]: 9,
  [Good.Jewelry]: 10,
  [Good.TreasureChest]: 11,
  [Good.SilverHoard]: 15,
  [Good.GlassBeads]: 7,
  [Good.Helmet]: 8,
  [Good.Cloakpin]: 8,
  [Good.Belt]: 8,
  [Good.Crucifix]: 8,
  [Good.DrinkingHorn]: 8,
  [Good.AmberFigure]: 9,
  [Good.Horseshoe]: 9,
  [Good.GoldBrooch]: 9,
  [Good.ForgeHammer]: 10,
  [Good.Fibula]: 10,
  [Good.ThrowingAxe]: 11,
  [Good.Chalice]: 12,
  [Good.RoundShield]: 13,
  [Good.EnglishCrown]: 16
}

export const goodScore: Partial<Record<Good, any>> = {
  [Good.Sheep]: 2,
  [Good.PregnantSheep]: 3,
  [Good.Cattle]: 3,
  [Good.PregnantCattle]: 4,
  [Good.EnglishCrown]: 2
}
