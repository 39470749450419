/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import SpecialSaleRules from '@gamepark/a-feast-for-odin/actions/sailing/SpecialSaleRules'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {useMemo, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import {goodButtonCss, inlineGood} from '../../styles'

type Props = {
  specialSale: SpecialSaleRules
}

export default function SpecialSaleChoice({specialSale}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const play = usePlay()
  const playerSilver = specialSale.getPlayerSilver()
  const [selectedGoods, setSelectedGoods] = useState<Good[]>([])
  const silverCost = useMemo(() => specialSale.getSilverCost(selectedGoods), [specialSale, selectedGoods])
  return (
    <div>
      <p>
        <strong>
          <Trans defaults="Choose up to 2 tiles to buy. You have {quantity} <0/>." values={{quantity: playerSilver}} components={[
            <GoodItem css={inlineGood} good={Good.Silver} height={1}/>
          ]}/>
        </strong>
        <button css={[goodButtonCss, buttonCss, !selectedGoods.length && hidden]} disabled={!selectedGoods.length}
                onClick={() => play(receiveGoodsMove(playerId, selectedGoods))}>
          {silverCost > 0 ?
            <Trans defaults="Pay {number} <0/>" values={{number: silverCost}} components={[<GoodItem good={Good.Silver} height={1}/>]}/>
            : t('Take for free')
          }
        </button>
      </p>
      <div css={gridContainer}>
        {specialSale.game.specialTilesSupply.map(good => {
            const selected = selectedGoods.includes(good)
            const cost = selected ? 0 : specialSale.getSilverCost([...selectedGoods, good])
            const disabled = !selected && (selectedGoods.length === 2 || cost > playerSilver)
            return <GoodItem key={good} good={good} css={[selected && selectedCss, disabled && disabledCss]}
                             onClick={() => !disabled && setSelectedGoods(goods => goods.includes(good) ? goods.filter(g => g !== good) : [...goods, good])}/>
          }
        )}
      </div>
    </div>
  )
}

const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1em;
  align-items: center;
  justify-items: center;
  margin: 1em;
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const disabledCss = css`
  filter: grayscale();
`

const buttonCss = css`
  height: 1.5em;
  margin-left: 2em;
`

const hidden = css`
  opacity: 0;
`
