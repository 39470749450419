import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import Good, {farmProducts, isFarmProduct, isGood} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {playerHasGoods} from '../../AFeastForOdin'

export default class FieldFarmer extends UpgradeGoodOccupationRules {
  get upgradableGoods() {
    return farmProducts
  }

  get upgrade() {
    return 22
  }

  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.FieldFarmer})
    return []
  }

  delegate() {
    if (!playerHasGoods(this.player, Good.Silver)) return
    return super.delegate()
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color && isGood(move.goods) && isFarmProduct(move.goods)) {
          consequences.push(spendGoodsMove(this.player.color, Good.Silver))
        }
        break
    }
    return consequences
  }
}
