import Coordinates from '../Coordinates'
import OrientedGood, {getOrientedPolyomino} from './OrientedGood'
import {getPolyomino, getPolyominoAdjacentCoordinates, getPolyominoCoordinates, orientMatrix} from './Polyomino'

type PlacedGood = OrientedGood & Coordinates

export default PlacedGood

export function getCoveredCoordinates(placedGood: PlacedGood) {
  const polyomino = getOrientedPolyomino(placedGood)
  return getPolyominoCoordinates(polyomino).map(({x, y}) => ({x: x + placedGood.x, y: y + placedGood.y}))
}

export function getAdjacentCoordinates(placedGood: PlacedGood) {
  const polyomino = getPolyomino(placedGood.good)
  const oriented = orientMatrix(polyomino, placedGood.orientation)
  return getPolyominoAdjacentCoordinates(oriented).map(({x, y}) => ({x: x + placedGood.x, y: y + placedGood.y}))
}
