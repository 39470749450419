/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import BuildingGoodsArea from '@gamepark/a-feast-for-odin/material/goods/BuildingGoodsArea'
import BuildingPlacementArea from '@gamepark/a-feast-for-odin/material/goods/BuildingPlacementArea'
import PlacedGoodsArea from '@gamepark/a-feast-for-odin/material/goods/PlacedGoodsArea'
import {HTMLAttributes, useMemo} from 'react'
import ExtraGoodsDropArea from '../player/board/ExtraGoodsDropArea'
import GoodsDropArea from '../player/board/GoodsDropArea'
import BuildingBoard from './BuildingBoard'

type Props = {
  area: PlacedGoodsArea<BuildingGoodsArea>
  masterJoiner?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function BuildingBoardWithGoods({area, masterJoiner, ...props}: Props) {
  const placementArea = useMemo(() => new BuildingPlacementArea(area, masterJoiner), [area])
  return (
    <BuildingBoard building={area.goodsArea.building} {...props}>
      {placementArea.width > 0 &&
      <GoodsDropArea area={placementArea} placedGoods={area.placedGoods} css={positionDropArea(area.goodsArea.building)} goodsArea={area.goodsArea}/>
      }
      {placementArea.extraSpaces.length > 0 && <ExtraGoodsDropArea area={placementArea} goodsArea={area.goodsArea} extraGoods={area.extraGoods}/>}
    </BuildingBoard>
  )
}

function positionDropArea(building: Building) {
  switch (building) {
    case Building.StoneHouse:
      return css`
        left: 39.2em;
        top: 4em;
      `
    case Building.LongHouse:
      return css`
        left: 4.5em;
        top: 9.1em;
      `
    default:
      return null
  }
}
