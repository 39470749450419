import {countCattle} from '../../AFeastForOdin'
import ProduceRules from './ProduceRules'
import Action from '../Action'

export default class MilkingRules extends ProduceRules {
  action = Action.Milking

  get goods() {
    return Math.min(3, countCattle(this.player))
  }

  canUseEffect() {
    return countCattle(this.player) > 0
  }
}
