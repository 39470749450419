import Move from '../../moves/Move'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import DisarmBoat from '../../moves/DisarmBoat'
import {getPendingAction} from '../../AFeastForOdin'
import Pillaging1Rules from '../../actions/sailing/Pillaging1Rules'
import Longship from '../boats/Longship'
import DisarmLongshipOccupationRules from './DisarmLongshipOccupationRules'
import Effect from '../../effects/Effect'

export default class Berserker extends DisarmLongshipOccupationRules {
  effect: Effect = {occupation: Occupation.Berserker}

  isEligibleAction(action: Action): boolean {
    return action === Action.Pillaging1 || action === Action.Pillaging2
  }

  onDisarm(move: DisarmBoat): Move[] {
    const bestLongshipOre = new Pillaging1Rules(this.game, this.player).getBestLongshipOre()
    const pillagingLongshipOre = (this.player.landingStages.longBoats[move.landingStage] as Longship).ore
    getPendingAction(this.game)!.dice!.diceModifier += 3 + pillagingLongshipOre - bestLongshipOre
    return []
  }
}
