/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import PlayerLocal from '../../PlayerLocal'
import {css} from '@emotion/react'
import {PayButton} from '../../PayButton'

type Props = {
  player: PlayerLocal
}

export default function CraftChestActionChoice({player}: Props) {
  const {t} = useTranslation()

  return (
    <div>
      <p>
        <strong>{t('choose.pay')}</strong>
        <PayButton css={buttonCss} player={player} goods={Good.Wood}>
          <GoodItem good={Good.Wood} height={2} alt="Wood"/>
        </PayButton>
        <PayButton css={buttonCss} player={player} goods={Good.Ore}>
          <GoodItem good={Good.Ore} height={2} alt="Ore"/>
        </PayButton>
      </p>
    </div>
  )
}

const buttonCss = css`
  margin-left: 1em;
  padding-top: 0.2em;
  min-width: 4em;
`
