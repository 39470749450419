import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {getActionRules} from '../../AFeastForOdin'
import Occupation from '../Occupation'
import PendingAction from '../../effects/PendingAction'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'
import Weapon, {weaponsMap} from '../Weapon'

export default class LoneWolf extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    const rules = getActionRules(this.game)
    const action = getActionRules(this.game)?.action
    if (rules?.player.color === this.player.color && (action === Action.Raiding || action === Action.Pillaging1 || action === Action.Pillaging2)) {
      if (move.type === MoveType.SpendWeapons && move.player === this.player.color && !this.player.effects.length) {
        const weapons = weaponsMap(move.weapons)
        const swords = weapons[Weapon.Sword] ?? 0
        for (let i = 0; i < swords; i++) {
          this.addLoneWolfAfterEffect(rules.pendingAction)
        }
      }
      if (rules.pendingAction.complete && !hasBonusForPlacingFourVikings(rules.pendingAction)
        && rules.pendingAction.afterEffects?.includes(Occupation.LoneWolf)) {
        const isSingle = rules.pendingAction.afterEffects.reduce((sum, effect) => effect === Occupation.LoneWolf ? sum + 1 : sum, 0) === 1
        if (isSingle) {
          rules.pendingAction.afterEffects = rules.pendingAction.afterEffects.filter(effect => effect !== Occupation.LoneWolf)
          return [receiveWeaponsMove(this.player.color, [Weapon.Sword])]
        }
      }
    }

    return []
  }

  // use "afterEffects" to count how many swords were spent
  addLoneWolfAfterEffect(pendingAction: PendingAction) {
    if (!pendingAction.afterEffects) pendingAction.afterEffects = []
    pendingAction.afterEffects.push(Occupation.LoneWolf)
  }
}
