import Occupation from '../Occupation'
import ExtractToPayActionRules from './ExtractToPayActionRules'
import Effect from '../../effects/Effect'
import Action from '../../actions/Action'

export default class Forester extends ExtractToPayActionRules {
  effect: Effect = {occupation: Occupation.Forester}

  isEligibleAction(action: Action): boolean {
    return action === Action.HuntingGameWithOneViking || action === Action.HuntingGameWithTwoVikings
  }
}
