/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Boat from '@gamepark/a-feast-for-odin/material/boats/Boat'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {armBoatMove} from '@gamepark/a-feast-for-odin/moves/ArmBoat'
import {HTMLAttributes} from 'react'
import {DropTargetMonitor, useDrop} from 'react-dnd'
import {GOOD, GoodDragItem} from '../goods/DraggableGood'
import {boatOreSquareSize, longshipOreGap, longshipOreLeft, longshipOreTop, whalingBoatOreLeft, whalingBoatOreTop} from '../styles'
import PlayerBoat from './PlayerBoat'

type Props = {
  boat: Boat
  playerId: number
  landingStage: number
  canArm: boolean
} & HTMLAttributes<HTMLDivElement>

export default function ArmBoat({boat, playerId, landingStage, canArm, ...props}: Props) {
  const [{canDrop, isOver}, ref] = useDrop({
    accept: GOOD,
    canDrop: (item: GoodDragItem) => canArm && landingStage !== undefined && item.orientedGood.good === Good.Ore,
    drop: () => armBoatMove(playerId, landingStage, boat.type === BoatType.WhalingBoat),
    collect: (monitor: DropTargetMonitor<GoodDragItem>) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    })
  })
  return (
    <PlayerBoat ref={ref} boat={boat} {...props}
                css={[boat.type === BoatType.WhalingBoat ? whalingBoatOreArea : longshipOreArea, canDrop && canDropCss, isOver && isOverCss]}/>
  )
}


const whalingBoatOreArea = css`
  &:after {
    content: '';
    position: absolute;
    top: ${whalingBoatOreTop}em;
    left: ${whalingBoatOreLeft}em;
    width: ${boatOreSquareSize}em;
    height: ${boatOreSquareSize}em;
  }
`

const longshipOreArea = css`
  &:after {
    content: '';
    position: absolute;
    top: ${longshipOreTop}em;
    left: ${longshipOreLeft}em;
    width: ${boatOreSquareSize}em;
    height: ${boatOreSquareSize * 3 + longshipOreGap * 2}em;
  }
`

const canDropCss = css`
  &:after {
    background-color: rgba(0, 128, 0, 0.3);
  }
`

const isOverCss = css`
  &:after {
    background-color: rgba(255, 255, 255, 0.5);
  }
`
