import Game from '../Game'
import GameView from '../GameView'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type DiscardBoat = {
  type: MoveType.DiscardBoat
  player: PlayerColor
  landingStage: number
  largeBoat?: boolean
}

export default DiscardBoat

export function discardBoatMove(player: PlayerColor, landingStage: number, largeBoat?: boolean): DiscardBoat {
  if (largeBoat) {
    return {type: MoveType.DiscardBoat, player, landingStage, largeBoat}
  } else {
    return {type: MoveType.DiscardBoat, player, landingStage}
  }
}

export function discardBoat(game: Game | GameView, move: DiscardBoat) {
  const player = game.players.find(player => player.color === move.player)!
  if (move.largeBoat) {
    player.landingStages.longBoats[move.landingStage] = null
  } else {
    player.landingStages.whalingBoats[move.landingStage] = null
  }
}
