import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import LayingSnareSuccessOccupationRules from './LayingSnareSuccessOccupationRules'

export default class Angler extends LayingSnareSuccessOccupationRules {
  onLayingSnareSuccess(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Stockfish)]
  }
}
