import BuildBuildingRules from './BuildBuildingRules'
import Building from '../../material/Building'
import Good from '../../material/goods/Good'
import Action from '../Action'

export default class BuildShedRules extends BuildBuildingRules {
  action = Action.BuildShed

  get cost() {
    return {[Good.Wood]: 2}
  }

  building = Building.Shed
}