import {OptionsSpec, OptionsValidationError} from '@gamepark/rules-api'
import {TFunction} from 'i18next'
import Game from './Game'
import PlayerColor, {playerColors} from './PlayerColor'
import GameView from './GameView'

/**
 * This is the options for each player in the game.
 */
type AFeastForOdinPlayerOptions = { id: PlayerColor }

/**
 * This is the type of object that the game receives when a new game is started.
 * The first generic parameter, "{}", can be changed to include game options like variants or expansions.
 */
export type AFeastForOdinOptions = {
  players: AFeastForOdinPlayerOptions[]
  deckA: boolean
  deckB: boolean
  deckC: boolean
  shortGame: boolean
}

/**
 * Typeguard to help Typescript distinguish between a GameState and new game's options, for you main class constructor.
 * @param arg GameState or Game options
 * @return true if arg is a Game options
 */
export function isGameOptions(arg: Game | GameView | AFeastForOdinOptions): arg is AFeastForOdinOptions {
  return typeof (arg as Game | GameView).round === 'undefined'
}

/**
 * This object describes all the options a game can have, and will be used by GamePark website to create automatically forms for you game
 * (forms for friendly games, or forms for matchmaking preferences, for instance).
 */
export const AFeastForOdinOptionsSpec: OptionsSpec<AFeastForOdinOptions> = {
  players: {
    id: {
      label: (t: TFunction) => t('Players color'),
      values: playerColors,
      valueSpec: color => ({label: t => getPlayerName(color, t)})
    }
  },
  deckA: {
    label: t => t('Occupations deck A'),
    help: t => t('deckA.help')
  },
  deckB: {
    label: t => t('Occupations deck B'),
    help: t => t('deckB.help'),
    subscriberRequired: true
  },
  deckC: {
    label: t => t('Occupations deck C'),
    help: t => t('deckB.help'),
    subscriberRequired: true
  },
  shortGame: {
    label: t => t('Short game'),
    help: t => t('short.game.help')
  },
  validate: (options, t) => {
    if (!options.deckA && !options.deckB && !options.deckC) {
      throw new OptionsValidationError(t('deck.required'), ['deckA', 'deckB', 'deckC'])
    }
  }
}

export function getPlayerName(playerId: PlayerColor, t: TFunction) {
  switch (playerId) {
    case PlayerColor.Red:
      return t('Red')
    case PlayerColor.Blue:
      return t('Blue')
    case PlayerColor.Black:
      return t('Black')
    case PlayerColor.Yellow:
      return t('Yellow')
  }
}