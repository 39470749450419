/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlacedGoodsArea from '@gamepark/a-feast-for-odin/material/goods/PlacedGoodsArea'
import TerritoryGoodsArea from '@gamepark/a-feast-for-odin/material/goods/TerritoryGoodsArea'
import TerritoryPlacementArea from '@gamepark/a-feast-for-odin/material/goods/TerritoryPlacementArea'
import Territory from '@gamepark/a-feast-for-odin/material/Territory'
import {HTMLAttributes, useMemo} from 'react'
import GoodsDropArea from '../player/board/GoodsDropArea'
import ExplorationBoard from './ExplorationBoard'

type Props = {
  area: PlacedGoodsArea<TerritoryGoodsArea>
} & HTMLAttributes<HTMLDivElement>

export default function ExplorationBoardWithGoods({area, ...props}: Props) {
  const placementArea = useMemo(() => new TerritoryPlacementArea(area), [area])
  const territory = area.goodsArea.territory
  return (
    <ExplorationBoard territory={territory} {...props}>
      <GoodsDropArea area={placementArea} placedGoods={area.placedGoods} css={positionDropArea(territory)} goodsArea={area.goodsArea}/>
    </ExplorationBoard>
  )
}

function positionDropArea(territory: Territory) {
  switch (territory) {
    case Territory.Shetland:
      return css`
        left: 7.3em;
        top: 8.6em;
      `
    case Territory.FaroeIslands:
      return css`
        left: 5.5em;
        top: 9.8em;
      `
    case Territory.Iceland:
      return css`
        left: 12.3em;
        top: 14.3em;
      `
    case Territory.Greenland:
      return css`
        left: 7.4em;
        top: 13.5em;
      `
    case Territory.BearIsland:
      return css`
        left: 12.7em;
        top: 8.6em;
      `
    case Territory.BaffinIsland:
      return css`
        left: 7.3em;
        top: 8.4em;
      `
    case Territory.Labrador:
      return css`
        left: 7.3em;
        top: 8.5em;
      `
    case Territory.Newfoundland:
      return css`
        left: 7.2em;
        top: 8.4em;
      `
  }
}
