import Move from '../../moves/Move'
import NoHarvestOccupation from './NoHarvestOccupation'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class PeaCounter extends NoHarvestOccupation {
  noHarvestEffect(): Move[] {
    const peas = Math.min(4, this.player.goods[Good.Peas] ?? 0)
    if (peas > 0) {
      return [receiveGoodsMove(this.player.color, {[Good.Peas]: peas})]
    }
    return []
  }
}
