import Move from '../../moves/Move'
import NoHarvestOccupation from './NoHarvestOccupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import {countWhalingBoats} from '../boats/LandingStages'

export default class HelperInTimeOfNeed extends NoHarvestOccupation {
  noHarvestEffect(): Move[] {
    const knarrs = countWhalingBoats(this.player)
    switch (knarrs) {
      case 1:
        return [receiveGoodsMove(this.player.color, Good.Beans)]
      case 2:
        return [receiveGoodsMove(this.player.color, Good.Grain)]
      case 3:
        return [receiveGoodsMove(this.player.color, Good.Cabbage)]
    }
    return []
  }
}
