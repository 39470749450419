import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {weapons} from '../Weapon'
import SpendWeapons, {spendWeaponsMove} from '../../moves/SpendWeapons'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class ArmsDealer extends OccupationRules {
  canUseAnyTimeEffect() {
    let playerWeapons = 0
    for (const weapon of weapons) {
      playerWeapons += this.player.weapons[weapon] ?? 0
      if (playerWeapons >= 2) return true
    }
    return false
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.ArmsDealer})
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: SpendWeapons[] = []
    for (const weapon of weapons) {
      if (this.player.weapons[weapon]) {
        if (this.player.weapons[weapon]! >= 2) {
          moves.push(spendWeaponsMove(this.player.color, [weapon, weapon]))
        }
        for (const weapon2 of weapons) {
          if (this.player.weapons[weapon2]) {
            moves.push(spendWeaponsMove(this.player.color, [weapon, weapon2]))
          }
        }
      }
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.SpendWeapons && move.player === this.player.color) {
      return [receiveGoodsMove(this.player.color, Good.Ore)]
    }
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && move.goods === Good.Ore) {
      this.player.effects.shift()
    }
    return []
  }
}
