import {css} from '@emotion/react'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import OrientedGood, {rotateGood} from '@gamepark/a-feast-for-odin/material/goods/OrientedGood'
import Orientation from '@gamepark/a-feast-for-odin/material/Orientation'
import ArmBoat from '@gamepark/a-feast-for-odin/moves/ArmBoat'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import PlaceGoods from '@gamepark/a-feast-for-odin/moves/PlaceGoods'
import {usePlay} from '@gamepark/react-client'
import {Draggable} from '@gamepark/react-components'
import {DraggableProps} from '@gamepark/react-components/dist/Draggable/Draggable'
import {useEffect, useState} from 'react'
import GoodItem from './GoodItem'
import {isPlacedGoodsLocal} from '@gamepark/a-feast-for-odin/moves/PlacedGoodsLocal'

type Props = {
  good: Good
} & Omit<DraggableProps, 'type'>

export const GOOD = 'Good'
export type GoodDragItem = { orientedGood: OrientedGood }

export default function DraggableGood({good, ...props}: Props) {
  const play = usePlay()
  const [orientedGood, setOrientedGood] = useState<OrientedGood>({good, orientation: Orientation.Default})
  const [rotation, setRotation] = useState(0)
  useEffect(() => {
    setRotation(rotation => 4 * Math.round((rotation - (orientedGood.orientation % 4)) / 4) + orientedGood.orientation % 4)
  }, [orientedGood.orientation])
  return (
    <Draggable type={GOOD} item={{orientedGood}}
               onClick={() => setOrientedGood(rotateGood(orientedGood))}
               drop={(move: PlaceGoods | ArmBoat) => play(move, {local: move.type === MoveType.PlaceGoods && move.placedGoods.some(isPlacedGoodsLocal)})}
               css={style}
               postTransform={`rotate(${rotation * 90}deg) rotateY(${orientedGood.orientation >= 4 ? 180 : 0}deg)`}
               {...props}>
      <GoodItem good={good}/>
    </Draggable>
  )
}

const style = css`
  position: absolute;
  transform-style: preserve-3d;
`
