import Good from '../../material/goods/Good'
import Weapon from '../../material/Weapon'
import HuntingRules from './HuntingRules'
import ActionRules from '../ActionRules'
import Action from '../Action'

export default abstract class HuntingGameRules extends HuntingRules {
  dice = 8
  successGoods = [Good.Hide, Good.GameMeat]
  consumableWeapons = [Weapon.Bow]
  consumableGoods = [Good.Wood]
}

export function isHuntingGameRules(rules?: ActionRules): rules is HuntingGameRules {
  return rules?.action === Action.HuntingGameWithOneViking || rules?.action === Action.HuntingGameWithTwoVikings
}
