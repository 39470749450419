import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {getBanquet} from '../../phases/Feast'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import GoodsAreaType from '../goods/GoodsAreaType'
import {getDrawWeaponMoves} from '../../moves/DrawWeapon'

export default class Barbarian extends OccupationRules {

  eachTimeEffect(move: Move): Move[] {
    if (this.game.phase === Phase.Feast) {
      switch (move.type) {
        case MoveType.StartNextPhase: {
          const meat = this.countMeat()
          if (meat > 0) {
            return [receiveGoodsMove(this.player.color, {[Good.Silver]: meat})]
          }
          break
        }
        case MoveType.PlaceGoods:
          if (move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Banquet) {
            const meat = this.countMeat(move.placedGoods)
            if (meat > 0) {
              return [receiveGoodsMove(this.player.color, {[Good.Silver]: meat})]
            }
          }
          break
        case MoveType.Pass:
          if (move.player === this.player.color && !this.player.effects.length) {
            const meat = this.countMeat()
            if (meat > 0) {
              return getDrawWeaponMoves(this.game, this.player.color, meat * 3)
            }
          }
      }
    }
    return []
  }

  countMeat(placedGoods = getBanquet(this.player).placedGoods) {
    return placedGoods.reduce((sum, placedGood) => placedGood.good === Good.GameMeat || placedGood.good === Good.WhaleMeat ? sum + 1 : sum, 0)
  }
}
