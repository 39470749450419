import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class StoneCarver extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    let silver = 0
    if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color) {
      for (const good of move.goods.flatMap(goods => goods)) {
        if (good === Good.Stone) silver++
      }
    }
    return silver > 0 ? [receiveGoodsMove(this.player.color, {[Good.Silver]: silver})] : []
  }
}
