import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {getActionRules, getPendingAction} from '../../AFeastForOdin'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import Good, {isGood} from '../goods/Good'
import RaidingRules, {isRaidingRules} from '../../actions/sailing/RaidingRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {startEffectMove} from '../../moves/StartEffect'

export type RaiderEffect = {
  occupation: Occupation.Raider
  value: number
}

export default class Raider extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.Raiding || move.action === Action.Pillaging1 || move.action === Action.Pillaging2)) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.dice) {
        pendingAction.dice.diceModifier += 1
      }
    }
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && isGood(move.goods)) {
      const rules = getActionRules(this.game)
      if (isRaidingRules(rules)) {
        const swordValue = rules.swordValue(move.goods)
        if (this.player.effects.length && this.player.effects[0].occupation === Occupation.Raider) {
          this.player.effects.shift()
        } else if (!this.player.effects.length && swordValue) {
          const remainingValue = rules.getBattleResult() - swordValue
          if (remainingValue >= 6) {
            return [startEffectMove(this.player.color, {occupation: Occupation.Raider, value: remainingValue})]
          }
        }
      }
    }
    return []
  }

  getEligibleGoods(): Good[] {
    return new RaidingRules(this.game, this.player).validLoot((this.player.effects[0] as RaiderEffect).value)
  }

  getPlayerMoves(): Move[] {
    return this.getEligibleGoods().map(good => receiveGoodsMove(this.player.color, good))
  }
}
