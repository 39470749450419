import Move from '../../moves/Move'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import {getPendingAction} from '../../AFeastForOdin'
import DisarmLongshipOccupationRules from './DisarmLongshipOccupationRules'
import Effect from '../../effects/Effect'

export default class SwordFighter extends DisarmLongshipOccupationRules {
  effect: Effect = {occupation: Occupation.SwordFighter}

  isEligibleAction(action: Action): boolean {
    return action === Action.Raiding
  }

  onDisarm(): Move[] {
    getPendingAction(this.game)!.dice!.diceModifier += 2
    return []
  }
}
