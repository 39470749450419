/** @jsxImportSource @emotion/react */
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dialog} from '@gamepark/react-components'
import GameLocalView from './GameLocalView'
import {dialogCloseIcon, dialogCss, titleButtonCss} from './styles'
import {usePlayerId} from '@gamepark/react-client'
import {useEffect, useState} from 'react'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import Phase from '@gamepark/a-feast-for-odin/phases/Phase'
import {useTranslation} from 'react-i18next'
import {css} from '@emotion/react'

type Props = {
  game: GameLocalView
}

export default function EndOfGameDialog({game}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(player => player.color === playerId)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (game.phase === Phase.EndOfGame && player && !player.passed) {
      setOpen(true)
    }
  }, [game.phase, player?.passed])

  return (
    <Dialog css={[dialogCss, css`max-width: 100em;`]} onBackdropClick={() => setOpen(false)} open={open}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setOpen(false)}/>
      <h2>{t('end.of.game')}</h2>
      <p css={infoCss}>{t('end.of.game.info')}</p>
      <p css={infoCss}><strong>{t('end.of.game.info2')}</strong></p>
      <p>
        <button css={titleButtonCss} onClick={() => setOpen(false)}>{t('OK')}</button>
      </p>
    </Dialog>
  )
}

const infoCss = css`
  margin: 0.5em;
  text-align: justify;
`
