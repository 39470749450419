import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../../material/goods/Good'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'
import ActionRules from '../ActionRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import {chooseEffectMove} from '../../moves/ChooseEffect'

export enum SheepOrCattleEffect {
  SHEEP = 1, CATTLE = 2
}

export default class BuySheepOrCattleRules extends ActionRules {
  action = Action.BuySheepOrCattle

  getPlayerMoves(): Move[] {
    const moves: Move[] = [chooseEffectMove(this.player.color, SheepOrCattleEffect.SHEEP)]
    if (this.getSilverDiscount(1) || playerHasGoods(this.player, Good.Silver)) {
      moves.push(chooseEffectMove(this.player.color, SheepOrCattleEffect.CATTLE))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.ChooseEffect && move.player === this.player.color) {
      switch (move.id) {
        case SheepOrCattleEffect.SHEEP:
          consequences.push(receiveGoodsMove(this.player.color, Good.Sheep))
          break
        case SheepOrCattleEffect.CATTLE:
          if (!this.getSilverDiscount(1)) consequences.push(spendGoodsMove(this.player.color, Good.Silver))
          consequences.push(receiveGoodsMove(this.player.color, Good.Cattle))
          break
      }
      this.complete()
    }
    return consequences
  }
}
