import OccupationRules from './OccupationRules'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'

export default class Mineworker extends OccupationRules {
  immediateEffect() {
    if (this.game.players.length > 1) {
      return [receiveGoodsMove(this.player.color, {[Good.Silver]: this.game.players.length - 1})]
    }
    return []
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color
      && !this.game.mountainStrips.some(strip => strip.id === move.strip)) {
      const lastGoods = move.goods[0]
      if (lastGoods.length === 2 && lastGoods[0] === Good.Silver && lastGoods[1] === Good.Silver) {
        return [receiveGoodsMove(this.player.color, Good.Silver)]
      }
    }
    return []
  }
}
