import MoveView from '@gamepark/a-feast-for-odin/moves/MoveView'
import GameLocalView from './GameLocalView'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {DISPLAY_SCREEN, displayScreen} from './navigation/DisplayScreen'
import {DISPLAY_BOARD, displayBoard} from './player/DisplayBoard'
import {DISPLAY_PLAYER_GOODS, displayPlayerGoods} from './player/goods/DisplayPlayerGoods'
import {DISPLAY_PLAYER_CARDS, displayPlayerCards} from './player/cards/DisplayPlayerCards'
import MoveRandomized from '@gamepark/a-feast-for-odin/moves/MoveRandomized'
import MoveLocal from './MoveLocal'
import Move from '@gamepark/a-feast-for-odin/moves/Move'
import {SET_RULES_DIALOG} from './RulesDialog'
import {TAKE_BACK_LOCAL_PLACED_GOODS, takeBackLocalPlacedGoods} from './goods/TakeBackLocalPlacedGoods'

export default class AFeastForOdinView extends AFeastForOdin {
  get localGame(): GameLocalView {
    return this.game as GameLocalView
  }

  getAutomaticMoves(): MoveView[] {
    return this.keepPredictableMoves(super.getAutomaticMoves())
  }

  play(_move: MoveRandomized | MoveView): MoveView[] {
    const move = _move as MoveRandomized | MoveLocal
    switch (move.type) {
      case DISPLAY_SCREEN:
        displayScreen(this.game as GameLocalView, move)
        return []
      case DISPLAY_BOARD:
        displayBoard(this.game as GameLocalView, move)
        return []
      case DISPLAY_PLAYER_GOODS:
        displayPlayerGoods(this.game as GameLocalView, move)
        return []
      case DISPLAY_PLAYER_CARDS:
        displayPlayerCards(this.game as GameLocalView, move)
        return []
      case SET_RULES_DIALOG:
        (this.game as GameLocalView).rulesDialog = move.rulesDialog
        return []
      case TAKE_BACK_LOCAL_PLACED_GOODS:
        takeBackLocalPlacedGoods(this.game as GameLocalView, move)
        return []
      default:
        return this.keepPredictableMoves(super.play(move))
    }
  }

  keepPredictableMoves(moves: Move[]): (Move & MoveView)[] {
    const firstUnpredictableMoveIndex = moves.findIndex(move => !this.isPredictableMove(move))
    return (firstUnpredictableMoveIndex !== -1 ? moves.slice(0, firstUnpredictableMoveIndex) : moves) as (Move & MoveView)[]
  }

  restoreLocalMoves(game: GameLocalView) {
    this.localGame.screen = game.screen
    for (let i = 0; i < this.localGame.players.length; i++) {
      this.localGame.players[i].view = game.players[i].view
    }
    this.localGame.rulesDialog = game.rulesDialog
  }
}
