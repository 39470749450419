import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Good from '../goods/Good'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import LocationType from '../LocationType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class WhalingAssistant extends OccupationRules {
  good: Good = Good.Cabbage

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Bonus) {
      const vikings = this.player.vikings.reduce((sum, location) => location.type === LocationType.Action && location.action % 5 === 3 ? sum + 1 : sum, 0)
      if (vikings >= 7) {
        return [receiveGoodsMove(this.player.color, this.good)]
      }
    }
    return []
  }
}
