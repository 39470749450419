/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {Avatar, usePlayerId} from '@gamepark/react-client'
import {useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {useState} from 'react'
import {Dialog} from '@gamepark/react-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {dialogCloseIcon, dialogCss, titleButtonCss} from '../styles'
import {css} from '@emotion/react'

type Props = {
  game: GameLocalView
}

export default function GameOverHeaderText({game}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const rules = new AFeastForOdin(game)
  const scores = game.players.map(player => rules.getScore(player.color))
  const highScore = Math.max(...scores)
  const winners = game.players.filter((_, index) => scores[index] === highScore)
  const [scoreOpen, setScoreOpen] = useState(true)

  return <>
    {winners.length === 1 ?
      winners[0].color === playerId ?
        game.players.length === 1 ?
          t('solo.score', {score: highScore}) :
          t('winner.you', {score: highScore}) :
        t('winner.other', {player: getPlayerName(winners[0].color, t), score: highScore}) :
      t('winner.tie', {score: highScore})
    }&nbsp;
    <button css={titleButtonCss} onClick={() => setScoreOpen(true)}>{t('Show scores')}</button>
    <Dialog open={scoreOpen} onBackdropClick={() => setScoreOpen(false)} css={dialogCss}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setScoreOpen(false)}/>
      <h2>{t('Scores')}</h2>
      <table css={tableCss}>
        <thead>
        <tr>
          <th/>
          {game.players.map(player => <th key={player.color}><Avatar playerId={player.color} css={avatarCss}/> {getPlayerName(player.color, t)}</th>)}
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{t('Ships')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreShips(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Emigrations')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreEmigration(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Exploration boards')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreExploration(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Sheds and houses')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreBuildings(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Animals')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreAnimals(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Occupations')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreOccupations(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Silver')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreSilver(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Final income')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreIncome(player)}</th>)}
        </tr>
        <tr>
          <td>{t('English Crown')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreGoods(player)}</th>)}
        </tr>
        <tr css={subTotalLine}>
          <td>{t('Positive sub-total')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scorePositiveSubTotal(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Home board')}</td>
          {game.players.map(player => <th key={player.color}>{rules.homeBoardMalus(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Exploration boards')}</td>
          {game.players.map(player => <th key={player.color}>{rules.explorationBoardMalus(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Sheds and houses')}</td>
          {game.players.map(player => <th key={player.color}>{rules.buildingsMalus(player)}</th>)}
        </tr>
        <tr css={darkerLine}>
          <td>{t('Thing Penalty')}</td>
          {game.players.map(player => <th key={player.color}>{rules.thingPenalties(player)}</th>)}
        </tr>
        <tr css={subTotalLine}>
          <td>{t('Negative sub-total')}</td>
          {game.players.map(player => <th key={player.color}>{rules.scoreNegativeSubTotal(player)}</th>)}
        </tr>
        <tr>
          <td>{t('Total')}</td>
          {game.players.map(player => <th key={player.color}>{rules.getScore(player.color)}</th>)}
        </tr>
        </tbody>
      </table>
    </Dialog>
  </>
}

const tableCss = css`
  margin: 2em;

  th, td {
    font-size: 3.5em;
    padding: 0 0.5em;
    text-align: right;
  }
`

const darkerLine = css`
  background-color: rgba(0, 0, 0, 0.1);
`

const subTotalLine = css`
  background-color: rgba(0, 0, 0, 0.3);
`

const avatarCss = css`
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.3em;
  top: 0.4em;
`