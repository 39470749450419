import Game from '../Game'
import GameView from '../GameView'
import Occupation from '../material/Occupation'
import {isPlayerView} from '../PlayerView'
import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'
import OccupationsRules from '../material/occupations/OccupationsRules'
import Move from './Move'
import MoveView from './MoveView'

type PlayOccupation = {
  type: MoveType.PlayOccupation
  player: PlayerColor
  occupation: Occupation
}

export default PlayOccupation

export function playOccupationMove(player: PlayerColor, occupation: Occupation): PlayOccupation {
  return {type: MoveType.PlayOccupation, player, occupation}
}

export function playOccupation(game: Game | GameView, move: PlayOccupation): (Move | MoveView)[] {
  const player = game.players.find(player => player.color === move.player)!
  player.occupations.push(move.occupation)
  if (isPlayerView(player)) {
    player.hand--
  } else {
    player.hand.splice(player.hand.indexOf(move.occupation), 1)
  }
  const occupationRules = new OccupationsRules[move.occupation](game, player)
  return occupationRules.immediateEffect ? occupationRules.immediateEffect() : []
}

