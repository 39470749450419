/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans, useTranslation} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {css} from '@emotion/react'
import {PassButton} from '../PassButton'
import {inlineGood, titleButtonCss} from '../styles'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import {useState} from 'react'
import {usePlay} from '@gamepark/react-client'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'

type Props = {
  player: PlayerLocal
  specialTilesSupply: Good[]
}

export default function FineBlacksmithChoice({player, specialTilesSupply}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const [selectedGood, setSelectedGood] = useState<Good>()
  return (
    <div>
      {playerHasGoods(player, [Good.Ore, Good.Silver]) ?
        <>
          <p>
            <strong><Trans defaults="fine.blacksmith.effect" components={[
              <GoodItem good={Good.Ore} height={1.5} css={inlineGood}/>,
              <GoodItem good={Good.Silver} height={1.5} css={inlineGood}/>
            ]}/></strong>
          </p>
          <div css={flexContainer}>
            <GoodItem good={Good.Crucifix} onClick={() => specialTilesSupply.includes(Good.Crucifix) && setSelectedGood(Good.Crucifix)}
                      css={[Good.Crucifix === selectedGood && selectedCss, !specialTilesSupply.includes(Good.Crucifix) && disable]}/>
            <GoodItem good={Good.Cloakpin} onClick={() => specialTilesSupply.includes(Good.Cloakpin) && setSelectedGood(Good.Cloakpin)}
                      css={[Good.Cloakpin === selectedGood && selectedCss]}/>
          </div>
          <p>
            <button css={titleButtonCss} disabled={!selectedGood} onClick={() => selectedGood && play(receiveGoodsMove(player.color, selectedGood))}>
              {t('Choose this good')}
            </button>
          </p>
        </>
        : <p><strong>{t('effect.miss')}</strong></p>
      }
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}

const flexContainer = css`
  display: flex;
  max-width: 180em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin: 2em;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const disable = css`
  cursor: auto;
  filter: grayscale();
`
