import Good from './Good'

export const specialTiles = [
  Good.GlassBeads,
  Good.Helmet,
  Good.Cloakpin,
  Good.Belt,
  Good.Crucifix,
  Good.DrinkingHorn,
  Good.AmberFigure,
  Good.Horseshoe,
  Good.GoldBrooch,
  Good.ForgeHammer,
  Good.Fibula,
  Good.ThrowingAxe,
  Good.Chalice,
  Good.RoundShield,
  Good.EnglishCrown
]

export function getSpecialTileCost(specialTile: Good) {
  switch (specialTile) {
    case Good.GlassBeads:
      return 0
    case Good.Helmet:
    case Good.Cloakpin:
      return 1
    case Good.Belt:
    case Good.Crucifix:
    case Good.DrinkingHorn:
    case Good.AmberFigure:
    case Good.Horseshoe:
      return 2
    case Good.GoldBrooch:
      return 3
    case Good.ForgeHammer:
    case Good.Fibula:
    case Good.ThrowingAxe:
      return 4
    case Good.Chalice:
      return 5
    case Good.RoundShield:
      return 6
    default:
      return Infinity
  }
}