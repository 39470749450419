import BuildBuildingRules from './BuildBuildingRules'
import Good from '../../material/goods/Good'
import Action from '../Action'
import Building from '../../material/Building'
import Occupation from '../../material/Occupation'

export default class BuildLongHouseRules extends BuildBuildingRules {
  action = Action.BuildLongHouse

  get cost() {
    const stoneCost = this.player.occupations.includes(Occupation.MasterBricklayer) ? 1 : 2
    return {[Good.Stone]: stoneCost}
  }

  building = Building.LongHouse
}