import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import {countKnarrs} from '../boats/LandingStages'
import Action from '../../actions/Action'
import {getActionRules} from '../../AFeastForOdin'

export default class MasterCount extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      const actionRules = getActionRules(this.game)
      if (actionRules?.action === Action.TradeOverseas2 && actionRules.player.color === this.player.color && actionRules.pendingAction.complete) {
        return [receiveGoodsMove(this.player.color, {[Good.Silver]: countKnarrs(this.player)})]
      }
    }
    return []
  }

}
