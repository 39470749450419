/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import GameLocalView from '../GameLocalView'
import {headerHeight, margin, stripHeight} from '../styles'
import MountainStripTile from './MountainStripTile'
import {usePlayerId} from '@gamepark/react-client'
import MountainsAndTradeRules from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'
import {useCallback, useMemo} from 'react'
import useRules from '../util/useRules'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import GameView from '@gamepark/a-feast-for-odin/GameView'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {getActionRules} from '@gamepark/a-feast-for-odin/AFeastForOdin'

type Props = {
  game: GameLocalView
}

export default function MountainsScreen({game}: Props) {
  const playerId = usePlayerId()
  const rules = useRules(game)
  const legalMoves = useMemo(() => playerId ? rules.getLegalMoves(playerId) : [], [rules, playerId])
  const canExtract = useCallback((stripId: number) => legalMoves.some(move => move.type === MoveType.ChooseMountainStrip && move.strip === stripId), [legalMoves])
  return (
    <>
      {[...Array(game.mountainStripsSupply)].map((_, index) => <MountainStripTile key={index} css={drawPile(index)}/>)}
      {game.mountainStrips.map((strip, index) =>
        <MountainStripTile key={strip.id} strip={strip} css={stripPosition(index)}
                           canExtract={canExtract(strip.id) ? getNextMountainExtraction(game, playerId) : 0}/>
      )}
    </>
  )
}

const stripPosition = (index: number) => index < 6 ? css`
  transform: translate(${50}em, ${headerHeight + margin + index * (stripHeight + margin)}em);
` : css`
  transform: translate(${4 + (8 - index) * (stripHeight + margin)}em, ${headerHeight + margin}em) translate(-50%, -50%) rotate(-90deg) translate(-50%, 50%);
`

const drawPile = (index: number) => css`
  transform: translate(${4 - (index * 0.4)}em, ${headerHeight + margin + (index * 0.4)}em) translate(-50%, -50%) rotate(-90deg) translate(-50%, 50%) rotateY(180deg);
`

function getNextMountainExtraction(game: GameView, playerId: PlayerColor): number {
  const player = game.players.find(player => player.color === playerId)
  if (player?.effects.length) {
    switch (player.effects[0].occupation) {
      case Occupation.Preacher:
        return 4
      case Occupation.Wanderer:
        return 2
      case Occupation.ForestBlacksmith:
      case Occupation.ApprenticeCraftsman:
      case Occupation.Carpenter:
      case Occupation.Digger:
      case Occupation.Forester:
      case Occupation.ArmedFighter:
        return 1
    }
  }
  return (getActionRules(game) as MountainsAndTradeRules)?.nextMountainExtraction ?? 0
}