import Good, {goodsToMap} from '../material/goods/Good'
import Weapon, {weaponsMap} from '../material/Weapon'
import {declareFailureMove} from '../moves/DeclareFailure'
import Move from '../moves/Move'
import {receiveGoodsMove} from '../moves/ReceiveGoods'
import {receiveWeaponsMove} from '../moves/ReceiveWeapons'
import {spendGoodsMove} from '../moves/SpendGoods'
import {spendWeaponsMove} from '../moves/SpendWeapons'
import {throwDiceMove} from '../moves/ThrowDice'
import ActionRules from './ActionRules'
import PendingAction from '../effects/PendingAction'
import MoveView from '../moves/MoveView'
import MoveType from '../moves/MoveType'
import MoveRandomized from '../moves/MoveRandomized'
import Occupation from '../material/Occupation'
import {returnVikingsMove} from '../moves/ReturnVikings'

export default abstract class DiceActionRules extends ActionRules {
  abstract dice: number
  abstract consumableGoods: Good[]

  abstract get consumableWeapons(): Weapon[]

  vikingsFailBack = 0

  createPendingAction(): PendingAction {
    return {action: this.action, dice: {side: 0, rollNumber: 0, maxRolls: 3, valueSpent: 0, diceModifier: 0}}
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingDice.rollNumber) {
      moves.push(throwDiceMove(this.dice))
    }
    return moves
  }

  get pendingDice() {
    return this.pendingAction.dice!
  }

  getDiceModifier(): number {
    return this.pendingDice.diceModifier
  }

  abstract isBestRoll(roll: number): boolean

  canPayForSuccess() {
    let resourcesAvailable = 0
    for (const weapon of this.consumableWeapons) {
      resourcesAvailable += (this.player.weapons[weapon] ?? 0) * this.getWeaponValue(weapon)
    }
    for (const good of this.consumableGoods) {
      resourcesAvailable += (this.player.goods[good] ?? 0) * this.getGoodValue(good)
    }
    return resourcesAvailable >= this.getRequiredPayment()
  }

  getWeaponValue(_weapon: Weapon) {
    return 1
  }

  getGoodValue(_good: Good) {
    return 1
  }

  abstract getRequiredPayment(): number

  abstract getMaxPayment(): number

  canDeclareFailure() {
    return !this.pendingDice.valueSpent
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (this.canDeclareFailure()) {
      moves.push(declareFailureMove(this.player.color))
      if (this.pendingDice.rollNumber < this.pendingDice.maxRolls && !this.isBestRoll(this.pendingDice.side)) {
        moves.push(throwDiceMove(this.dice))
      }
    }
    const maxPayment = this.getMaxPayment()
    if (this.pendingDice.valueSpent || this.canPayForSuccess()) {
      for (const weapon of this.consumableWeapons) {
        for (let quantity = 1; quantity <= Math.min(this.player.weapons[weapon] ?? 0, maxPayment); quantity++) {
          moves.push(spendWeaponsMove(this.player.color, {[weapon]: quantity}))
        }
      }
      for (const good of this.consumableGoods) {
        for (let quantity = 1; quantity <= Math.min(this.player.goods[good] ?? 0, maxPayment); quantity++) {
          moves.push(spendGoodsMove(this.player.color, {[good]: quantity}))
        }
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.ThrowDice:
        this.pendingDice.side = move.side
        this.pendingDice.rollNumber++
        break
      case MoveType.SpendWeapons:
        if (move.player === this.player.color) {
          const weapons = weaponsMap(move.weapons)
          for (const weapon in weapons) {
            this.pendingDice.valueSpent += weapons[weapon] * this.getWeaponValue(parseInt(weapon))
          }
        }
        break
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          const goods = goodsToMap(move.goods)
          for (const good of this.consumableGoods) {
            this.pendingDice.valueSpent += (goods[good] ?? 0) * this.getGoodValue(good)
          }
        }
        break
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
      case MoveType.DeclareFailure:
        if (move.player === this.player.color) {
          if (this.consumableWeapons.length > 0) {
            consequences.push(receiveWeaponsMove(this.player.color, this.consumableWeapons))
          }
          if (this.consumableGoods.length > 0) {
            consequences.push(receiveGoodsMove(this.player.color, this.consumableGoods))
          }
          if (this.vikingsFailBack && this.player.effects[0]?.occupation !== Occupation.Follower) {
            consequences.push(returnVikingsMove(this.player.color, Array(this.vikingsFailBack).fill(this.action)))
          }
        }
        break
    }
    return consequences
  }
}