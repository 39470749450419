import OccupationRules from './OccupationRules'
import Action from '../../actions/Action'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {getPendingAction} from '../../AFeastForOdin'

export default class Sledger extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.HuntingGameWithOneViking || move.action === Action.HuntingGameWithTwoVikings || move.action === Action.LayingSnare)) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.dice) {
        pendingAction.dice.diceModifier -= 1
      }
    }
    return []
  }
}
