import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class SilkMerchant extends OccupationRules {
  immediateEffect() {
    const silk = this.player.goods[Good.Silk] ?? 0
    return silk > 0 ? [receiveGoodsMove(this.player.color, {[Good.Silver]: silk === 1 ? 1 : silk === 2 ? 3 : 6})] : []
  }
}
