import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {playerHasGoods} from '../../AFeastForOdin'
import Good, {goodsToMap} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Metalsmith extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Metalsmith})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [receiveGoodsMove(this.player.color, Good.Silverware)]
    if (playerHasGoods(this.player, {[Good.Silver]: 2}) && this.game.specialTilesSupply.includes(Good.Crucifix)) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 2}))
    }
    if (playerHasGoods(this.player, {[Good.Silver]: 5}) && this.game.specialTilesSupply.includes(Good.Chalice)) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 5}))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          const silver = goodsToMap(move.goods)[Good.Silver] ?? 0
          if (silver === 2) {
            consequences.push(receiveGoodsMove(this.player.color, Good.Crucifix))
          } else if (silver === 5) {
            consequences.push(receiveGoodsMove(this.player.color, Good.Chalice))
          }
        }
        break
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return consequences
  }
}
