import GoodsAreaType from '../material/goods/GoodsAreaType'
import PlacedGoodsArea from '../material/goods/PlacedGoodsArea'
import TerritoryPlacementArea from '../material/goods/TerritoryPlacementArea'
import Player from '../Player'
import PlayerView from '../PlayerView'

export function getIncome(player: Player | PlayerView) {
  return player.placedGoodsAreas.reduce((income, area) => income + getPlacementAreaIncome(area), 0)
}

function getPlacementAreaIncome(area: PlacedGoodsArea): number {
  switch (area.goodsArea.type) {
    case GoodsAreaType.Home:
    case GoodsAreaType.Territory:
      return new TerritoryPlacementArea(area).getIncome()
    /*case GoodsAreaType.Building: // Expansion have income on artisan sheds
      return new BuildingPlacementArea(area).getIncome()*/
    default:
      return 0
  }
}