import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import OccupationRules from './OccupationRules'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Punchcutter extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.RemoveGoodsFromMountainStrip) {
      const silver = move.goods.reduce((sum, good) => good === Good.Silver || good === Good.Ore ? sum + 1 : sum, 0)
      if (silver > 0) {
        return [receiveGoodsMove(this.player.color, {[Good.Silver]: silver})]
      }
    }
    return []
  }
}
