import Move from '../../moves/Move'
import NoHarvestOccupation from './NoHarvestOccupation'
import {countPlayerSpecialTiles} from './Host'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class SympatheticSoul extends NoHarvestOccupation {
  noHarvestEffect(): Move[] {
    const specialTiles = countPlayerSpecialTiles(this.player)
    if (specialTiles < 3) return []
    return [receiveGoodsMove(this.player.color, {[Good.Silver]: specialTiles === 3 ? 1 : specialTiles === 4 ? 2 : 3})]
  }
}
