/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {PassButton} from '../PassButton'
import PlayerLocal from '../PlayerLocal'
import GoodItem from '../goods/GoodItem'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function CowherdChoice({player}: Props) {
  return <>
    <p>
      <Trans defaults="cowherd.effect" components={[
        <PayButton player={player} goods={{[Good.Grain]: 1, [Good.Silver]: 2}}/>,
        <GoodItem css={inlineGood} good={Good.Cattle} height={3}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
