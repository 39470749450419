/** @jsxImportSource @emotion/react */
import {css, SerializedStyles} from '@emotion/react'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import Territory from '@gamepark/a-feast-for-odin/material/Territory'
import {claimTerritoryMove} from '@gamepark/a-feast-for-odin/moves/ClaimTerritory'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import BuildingBoard from '../buildings/BuildingBoard'
import ExplorationBoard from '../exploration/ExplorationBoard'
import GameLocalView from '../GameLocalView'
import GoodItem from '../goods/GoodItem'
import Images from '../images/Images'
import OccupationCardsDeck from '../occupations/OccupationCardsDeck'
import {glowingHighlight, squareSize} from '../styles'
import BoatTile from '../boats/BoatTile'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {boatRulesDialog, setRulesDialog} from '../RulesDialog'
import useRules from '../util/useRules'
import {Fragment, useCallback} from 'react'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'

type Props = {
  game: GameLocalView
}

export default function BoardsSupply({game}: Props) {
  const playerId = usePlayerId<PlayerColor>()
  const play = usePlay()
  const rules = useRules(game)
  const canClaim = useCallback((territory: Territory) =>
      !!playerId && rules.getLegalMoves(playerId).some(move => move.type === MoveType.ClaimTerritory && move.territory === territory)
    , [game, playerId])

  return (
    <>
      {game.unexploredTerritories.map(({territory, silver}, index) =>
        <Fragment key={territory}>
          <ExplorationBoard territory={territory}
                            css={[explorationBoardPosition(index), scaleDown, canClaim(territory) && glowingHighlight]}
                            onClick={playerId && canClaim(territory) ? () => play(claimTerritoryMove(playerId, territory)) : undefined}/>
          {[...Array(silver)].map((_, silver) => <GoodItem key={silver} good={Good.Silver} css={bonusSilverPosition(index, silver)}/>)}
        </Fragment>
      )}
      {Object.keys(game.buildingSupply).map((key, typeIndex) =>
        [...Array(game.buildingSupply[key])].map((_, pileIndex) =>
          <BuildingBoard key={key + '_' + pileIndex} building={parseInt(key) as Building} css={[buildingPosition(typeIndex, pileIndex), scaleDown]}/>
        )
      )}
      <div css={specialTilesSupply}>
        {game.specialTilesSupply.map(specialTile =>
          <GoodItem key={specialTile} good={specialTile} css={[specialTileStyle, specialTilesPosition[specialTile]]}/>
        )}
      </div>
      <div css={boatsSupply}>
        <BoatTile boatType={BoatType.WhalingBoat} css={whalingBoatCss}
                  onClick={() => play(setRulesDialog(boatRulesDialog(BoatType.WhalingBoat)), {local: true})}/>
        <BoatTile boatType={BoatType.Knarr} css={knarrCss}
                  onClick={() => play(setRulesDialog(boatRulesDialog(BoatType.Knarr)), {local: true})}/>
        <BoatTile boatType={BoatType.Longship} css={longshipCss}
                  onClick={() => play(setRulesDialog(boatRulesDialog(BoatType.Longship)), {local: true})}/>
      </div>
      <OccupationCardsDeck size={game.occupationsDeck}/>
    </>
  )
}

const explorationBoardPosition = (index: number) => css`
  top: 8em;
  left: ${4 + index * 34}em;
`

const bonusSilverPosition = (index: number, silver: number) => css`
  position: absolute;
  top: 10em;
  left: ${3 + index * 34 + silver * 2.5}em;
  transform: scale(0.5);
`

const buildingPosition = (typeIndex: number, pileIndex: number) => css`
  position: absolute;
  top: ${40 + pileIndex * 0.3}em;
  left: ${4 + typeIndex * 50 + pileIndex * 0.3}em;
`

const specialTileStyle = css`
  position: absolute;
  transform-origin: top left;
  transform: scale(0.5);
`

const scaleDown = css`
  transform-origin: top left;
  transform: scale(0.5);
`

const specialTilesRatio = 2917 / 2081
const specialTilesHeight = 8.137 * squareSize

const specialTilesSupply = css`
  position: absolute;
  width: ${specialTilesHeight * specialTilesRatio}em;
  height: ${specialTilesHeight}em;
  top: 56em;
  left: 4em;
  border-radius: 50%;
  background-image: url(${Images.specialTilesSupply});
  background-size: cover;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
`

const specialTilesPosition: Partial<Record<Good, SerializedStyles>> = {
  [Good.GlassBeads]: css`
    top: 18.4%;
    left: 9.8%;
  `,
  [Good.Helmet]: css`
    top: 42.7%;
    left: 4.9%;
  `,
  [Good.Cloakpin]: css`
    top: 42.4%;
    left: 33.3%;
  `,
  [Good.Belt]: css`
    top: 28%;
    left: 31%;
  `,
  [Good.Crucifix]: css`
    top: 9.9%;
    left: 20.5%;
  `,
  [Good.DrinkingHorn]: css`
    top: 5.1%;
    left: 52.4%;
  `,
  [Good.AmberFigure]: css`
    top: 5.5%;
    left: 36%;
  `,
  [Good.Horseshoe]: css`
    top: 58.1%;
    left: 46%;
  `,
  [Good.GoldBrooch]: css`
    top: 21.6%;
    left: 55%;
  `,
  [Good.ForgeHammer]: css`
    top: 42.9%;
    left: 16.7%;
  `,
  [Good.Fibula]: css`
    top: 58%;
    left: 29.1%;
  `,
  [Good.ThrowingAxe]: css`
    top: 79.7%;
    left: 40%;
  `,
  [Good.Chalice]: css`
    top: 15.2%;
    left: 70.1%;
  `,
  [Good.RoundShield]: css`
    top: 63%;
    left: 67.7%;
  `,
  [Good.EnglishCrown]: css`
    top: 42%;
    left: 73.4%;
  `
}

const boatsSupplyHeight = 38
const boatsSupplyRatio = 1400 / 950

const boatsSupply = css`
  position: absolute;
  width: ${boatsSupplyHeight * boatsSupplyRatio}em;
  height: ${boatsSupplyHeight}em;
  top: 56em;
  left: 66em;
  background-image: url(${Images.boatsSupply});
  background-size: cover;
  border-radius: 2em;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
`

const whalingBoatCss = css`
  position: absolute;
  top: 14.6em;
  left: 5.8em;
  cursor: pointer;
`

const knarrCss = css`
  position: absolute;
  top: 14em;
  left: 25.1em;
  cursor: pointer;
`

const longshipCss = css`
  position: absolute;
  top: 14em;
  left: 42em;
  cursor: pointer;
`
