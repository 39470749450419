import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good, {isGood} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {playerHasGoods} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export type HornturnerEffect = {
  occupation: Occupation.Hornturner
  spent?: Good.Sheep | Good.Silver
}

export default class Hornturner extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Hornturner})
    return []
  }

  get effect() {
    return this.player.effects[0] as HornturnerEffect
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (this.effect.spent !== Good.Sheep) {
      if (playerHasGoods(this.player, Good.Sheep)) {
        moves.push(spendGoodsMove(this.player.color, Good.Sheep))
      } else if (playerHasGoods(this.player, Good.PregnantSheep)) {
        moves.push(spendGoodsMove(this.player.color, Good.PregnantSheep))
      }
    }
    if (this.effect.spent !== Good.Silver && this.game.specialTilesSupply.includes(Good.DrinkingHorn)) {
      if (playerHasGoods(this.player, {[Good.Silver]: 3})) {
        moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 3}))
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
    } else if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      if (move.goods === Good.Sheep || move.goods === Good.PregnantSheep) {
        consequences.push(receiveGoodsMove(this.player.color, [Good.SaltMeat, Good.Hide, Good.Wool]))
        if (!this.effect.spent) {
          this.effect.spent = Good.Sheep
        } else {
          this.player.effects.shift()
        }
      } else if (!isGood(move.goods) && !Array.isArray(move.goods) && move.goods[Good.Silver] === 3) {
        consequences.push(receiveGoodsMove(this.player.color, Good.DrinkingHorn))
        if (!this.effect.spent) {
          this.effect.spent = Good.Silver
        } else {
          this.player.effects.shift()
        }
      }
    }
    return consequences
  }
}
