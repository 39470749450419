import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import {passMove} from '../moves/Pass'
import MoveType from '../moves/MoveType'
import {getIncome} from './Income'
import {receiveGoodsMove} from '../moves/ReceiveGoods'
import Good from '../material/goods/Good'
import MoveRandomized from '../moves/MoveRandomized'

export default class IncomePhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  isTurnToPlay(playerId: PlayerColor): boolean {
    const player = this.game.players.find(p => p.color === playerId)!
    return !player.passed
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const lastRound = this.isLastRound()
    if (this.game.players.every(player => (player.passed || lastRound) && !player.effects.length)) {
      return [startNextPhaseMove]
    }
    return []
  }

  isLastRound(): boolean {
    return this.game.shortGame ? this.game.round === 6 : this.game.round === 7
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    const player = this.game.players.find(p => p.color === playerId)!
    return !player.passed && !player.effects.length ? [passMove(playerId)] : []
  }

  play(move: MoveRandomized | MoveView) {
    switch (move.type) {
      case MoveType.Pass:
        const player = this.game.players.find(player => player.color === move.player)!
        if (!player.effects.length) {
          player.passed = true
          const income = getIncome(player)
          return income ? [receiveGoodsMove(move.player, {[Good.Silver]: income})] : []
        }
        break
      case MoveType.StartNextPhase:
        for (const player of this.game.players) {
          delete player.passed
        }
        break
    }
    return []
  }
}