import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import Weapon from '../Weapon'
import {spendWeaponsMove} from '../../moves/SpendWeapons'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Dragonslayer extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Dragonslayer})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    const snares = this.player.weapons[Weapon.Snare] ?? 0
    const spears = this.player.weapons[Weapon.Spear] ?? 0
    if (snares >= 2 && spears >= 2) {
      moves.push(spendWeaponsMove(this.player.color, {[Weapon.Snare]: 2, [Weapon.Spear]: 2}))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.Pass:
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
      case MoveType.SpendWeapons:
        if (move.player === this.player.color) {
          consequences.push(receiveGoodsMove(this.player.color, Good.TreasureChest))
        }
        break
    }
    return consequences
  }
}
