/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import {inlineGood} from '../styles'
import {getPolyomino} from '@gamepark/a-feast-for-odin/material/goods/Polyomino'

type Props = {
  player: PlayerLocal
  good: Good
}

export default function MaidChoice({player, good}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="maid.effect" components={[
          <PayButton player={player} goods={good}/>,
          <GoodItem css={inlineGood} good={Good.Silverware} height={1.5}/>,
          <GoodItem css={inlineGood} good={good} height={getPolyomino(good).length * 1.5}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
