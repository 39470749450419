/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import GameLocalView from '../GameLocalView'
import Images from '../images/Images'
import {canSelectMenuCss, headerHeight, margin, menuCss, menuHeight, menuWidth, selectedMenuCss, shineEffect} from '../styles'
import {displayScreenMove, getScreen} from './DisplayScreen'
import Screen from './Screen'
import useRules from '../util/useRules'
import {useMemo} from 'react'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'

type Props = {
  game: GameLocalView
}

export default function MountainsMenu({game}: Props) {
  const playerId = usePlayerId()
  const selected = getScreen(game, playerId) === Screen.Mountains
  const play = usePlay()
  const rules = useRules(game)
  const highlight = useMemo(() => !!playerId && rules.getLegalMoves(playerId).some(move => move.type === MoveType.ChooseMountainStrip)
    , [game, playerId])
  return (
    <button css={[menuCss, position, selected ? selectedMenuCss : canSelectMenuCss, highlight && !selected && shineEffect]}
            onClick={() => play(displayScreenMove(Screen.Mountains), {local: true})}>
      <Picture src={Images.mountainsIcon}/>
    </button>
  )
}

const position = css`
  top: ${headerHeight + margin * 2 + menuHeight}em;
  right: ${menuWidth + margin * 2}em;
`
