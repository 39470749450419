import Move from '../../moves/Move'
import Occupation from '../Occupation'
import BoatType, {boatsValue} from '../boats/BoatType'
import {countKnarrs} from '../boats/LandingStages'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Quartermaster from './Quartermaster'

export default class LongshipBuilder extends Quartermaster {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.LongshipBuilder})
    return []
  }

  getBoatCost(): number {
    return getLongshipBuilderCost(this.player)
  }
}

const longshipDiscountPerKnarr = [0, 2, 5, 7]

export function getLongshipBuilderCost(player: Player | PlayerView) {
  const knarrs = countKnarrs(player)
  const discount = longshipDiscountPerKnarr[knarrs]
  return Math.max(0, Math.floor(boatsValue[BoatType.Longship] - discount))
}
