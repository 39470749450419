import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Princess extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && isBuildingHouseAction(move.action)) {
      return [receiveGoodsMove(this.player.color, Good.Silverware)]
    }
    return []
  }
}

export function isBuildingHouseAction(action: Action) {
  switch (action) {
    case Action.BuildShed:
    case Action.BuildStoneHouse:
    case Action.BuildLongHouse:
    case Action.BuildHouseAndBoat:
      return true
  }
  return false
}
