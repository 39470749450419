import Game from './Game'
import Player from './Player'
import PlayerView from './PlayerView'

type GameView = Omit<Game, 'players' | 'weaponsDeck' | 'mountainStripsSupply' | 'occupationsDeck'> & {
  players: (Player | PlayerView)[]
  weaponsDeck: number
  mountainStripsSupply: number
  occupationsDeck: number
}

export default GameView

export function isGameView(game: Game | GameView): game is GameView {
  return !Array.isArray(game.weaponsDeck)
}
