import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import Good from '../goods/Good'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import PendingAction from '../../effects/PendingAction'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import {getActionRules} from '../../AFeastForOdin'
import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Woodcutter extends OccupationRules {
  occupation = Occupation.Woodcutter

  eachTimeEffect(move: Move): Move[] {
    const actionRules = getActionRules(this.game)
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && actionRules && actionRules.action === Action.TakeWoodAndOre) {
      for (let i = 0; i < this.game.players.length; i++) {
        this.addAfterEffect(actionRules.pendingAction)
      }
    } else if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color && !this.player.effects.length
      && actionRules && isMountainsAndTradeRules(actionRules)) {
      const goods = move.goods.flatMap(goods => goods)
      const wood = goods.reduce((sum, good) => good === Good.Wood ? sum + 1 : sum, 0)
      for (let i = 0; i < wood; i++) {
        this.addAfterEffect(actionRules.pendingAction)
      }
    }
    if (actionRules?.player.color === this.player.color
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && actionRules.pendingAction.afterEffects?.includes(this.occupation)) {
      const wood = actionRules.pendingAction.afterEffects.reduce((sum, effect) => effect === this.occupation ? sum + 1 : sum, 0)
      actionRules.pendingAction.afterEffects = actionRules.pendingAction.afterEffects.filter(effect => effect !== this.occupation)
      if (wood >= 2) {
        return this.triggerEffect()
      }
    }
    return []
  }

  addAfterEffect(pendingAction: PendingAction) {
    if (!pendingAction.afterEffects) pendingAction.afterEffects = []
    pendingAction.afterEffects.push(this.occupation)
  }

  triggerEffect(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Silver)]
  }
}
