/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import HuntingRules from '@gamepark/a-feast-for-odin/actions/hunting/HuntingRules'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import {declareFailureMove} from '@gamepark/a-feast-for-odin/moves/DeclareFailure'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import {spendGoodsMove} from '@gamepark/a-feast-for-odin/moves/SpendGoods'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'
import {throwDiceMove} from '@gamepark/a-feast-for-odin/moves/ThrowDice'
import {usePlay} from '@gamepark/react-client'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import {titleButtonCss} from '../../styles'
import Slider from '../../util/Slider'
import WeaponCard from '../../weapons/WeaponCard'

type Props = {
  hunting: HuntingRules
}

export default function HuntingChoice({hunting}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const availableWood = hunting.player.goods[Good.Wood] ?? 0
  const weapon = hunting.consumableWeapons[0]
  const weaponValue = hunting.getWeaponValue(weapon)
  const availableWeapons = hunting.player.weapons[weapon] ?? 0
  const pendingDice = hunting.pendingDice
  const [woodSpent, setWoodSpent] = useState(0)
  const [weaponsSpent, setWeaponsSpent] = useState(0)
  const weaponsValue = weaponsSpent * weaponValue
  const requiredPayment = hunting.getRequiredPayment() ?? 0
  const remainingPayment = Math.max(0, requiredPayment - woodSpent - weaponsValue)
  useMemo(() => {
    const weaponsAutoSelect = Math.max(0, Math.min(Math.ceil(requiredPayment / weaponValue), availableWeapons))
    const woodAutoSelect = Math.max(0, Math.min(Math.max(0, requiredPayment - weaponsAutoSelect * weaponValue), availableWood))
    setWeaponsSpent(weaponsAutoSelect)
    setWoodSpent(woodAutoSelect)
  }, [requiredPayment])

  function onSuccess() {
    if (woodSpent > 0) {
      play(spendGoodsMove(hunting.player.color, {[Good.Wood]: woodSpent}))
    }
    if (weaponsSpent > 0) {
      play(spendWeaponsMove(hunting.player.color, {[weapon]: weaponsSpent}))
    }
    play(receiveGoodsMove(hunting.player.color, hunting.successGoods))
  }

  const diceModifier = useMemo(() => hunting.getDiceModifier(), [hunting])

  return (
    <div css={huntingCss}>
      <div css={resultCss}>
        <p>[{pendingDice.side}]</p>
        {diceModifier !== 0 && (
          hunting.action === Action.Whaling || hunting.action === Action.WhalingWithOneBoat ?
            <p>{t('whaling.modifier', {modifier: diceModifier > 0 ? '+' + diceModifier : diceModifier})}</p>
            : <p>{t('occupations.modifier', {modifier: diceModifier > 0 ? '+' + diceModifier : diceModifier})}</p>
        )}
        <p>{t('dice.result', {result: pendingDice.side + diceModifier})}</p>
        <p>
          <button css={titleButtonCss} onClick={() => play(throwDiceMove(hunting.dice), {delayed: true})}
                  disabled={pendingDice.rollNumber === pendingDice.maxRolls || hunting.isBestRoll(pendingDice.side)}>
            {t('reroll.button', {number: pendingDice.maxRolls - pendingDice.rollNumber})}
          </button>
        </p>
      </div>
      <div css={[resultChoiceCss, successCss]}>
        <h3>{t('Success')}</h3>
        <div css={spendCss}>
          <p>{t('Spend:')}</p>
          <Slider css={sliderCss} value={weaponsSpent} onValueChange={(value) => setWeaponsSpent(value)}
                  max={Math.min(availableWeapons, weaponsSpent + Math.ceil(remainingPayment / weaponValue))}>
            <span css={sliderContent}>{weaponsSpent}/{availableWeapons}&nbsp;<WeaponCard weapon={weapon} css={css`font-size: 0.05em;`}/></span>
          </Slider>
          <Slider css={sliderCss} value={woodSpent} onValueChange={(value) => setWoodSpent(value)}
                  max={Math.min(availableWood, woodSpent + remainingPayment)}>
            <span css={sliderContent}>{woodSpent}/{availableWood}&nbsp;<GoodItem good={Good.Wood} height={1}/></span>
          </Slider>
        </div>
        <div css={[goodsCss, css`width: 100%;`]}>
          <p css={css`margin: 0;`}>{t('To get:')}</p>
          {hunting.successGoods.map(good => <GoodItem css={goodCss} key={good} good={good}/>)}
          {hunting.pendingAction.action === Action.LayingSnare && <WeaponCard css={goodCss} weapon={Weapon.Snare}/>}
        </div>
        <p css={css`margin-bottom: 0;`}>
          <button css={titleButtonCss} onClick={onSuccess} disabled={remainingPayment > 0}>
            {t('Claim success')}
          </button>
        </p>
      </div>
      <div css={[resultChoiceCss, failureCss]}>
        <h3>{t('Failure')}</h3>
        <div css={goodsCss}>
          {hunting.consumableWeapons.map(weapon => <WeaponCard css={goodCss} key={weapon} weapon={weapon}/>)}
          {hunting.consumableGoods.map(good => <GoodItem css={goodCss} key={good} good={good}/>)}
        </div>
        <p css={css`margin-bottom: 0;`}>
          <button css={titleButtonCss} onClick={() => play(declareFailureMove(hunting.player.color))} disabled={pendingDice.side + diceModifier <= 0}>
            {t('Declare failure')}
          </button>
        </p>
      </div>
    </div>
  )
}

const huntingCss = css`
  display: flex;
  justify-content: space-evenly;
`

const resultCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`

const resultChoiceCss = css`
  flex-shrink: 0;
  border: 0.5em solid #93C2DB;
  min-width: 50em;
  border-radius: 2em;
  padding: 2em;
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > h3 {
    font-size: 3.5em;
    font-weight: bold;
    margin: 0 0 0.5em;
  }
`

const successCss = css`
  background-color: #CAE1D4;

  > h3 {
    color: darkgreen;
  }
`

const failureCss = css`
  background-color: #D9D4D1;

  > h3 {
    color: darkred;
  }
`

const spendCss = css`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`

const sliderCss = css`
  width: fit-content;
  margin-left: 3em;

  > span {
    font-size: 3.5em;
    margin: 0 0.5em;
  }
`

const sliderContent = css`
  display: inline-flex;
  align-items: center;
`

const goodsCss = css`
  display: flex;
  align-items: center;
`

const goodCss = css`
  margin-left: 2em;
`