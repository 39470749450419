/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {HTMLAttributes} from 'react'
import Images from '../images/Images'
import {longBoatRatio, squareSize} from '../styles'

type Props = {
  boatType: BoatType
} & HTMLAttributes<HTMLDivElement>

export default function EmigrationTile({boatType, ...props}: Props) {
  return (
    <div css={[size(boatType === BoatType.WhalingBoat ? 1 : 2), background(boatType)]} {...props}/>
  )
}

const size = (width: number) => css`
  position: absolute;
  width: ${width * squareSize}em;
  height: ${longBoatRatio * 2 * squareSize}em;
`

const boatImage: Record<BoatType, string> = {
  [BoatType.WhalingBoat]: Images.whalingBoatEmigration,
  [BoatType.Knarr]: Images.knarrEmigration,
  [BoatType.Longship]: Images.longshipEmigration
}

const background = (type: BoatType) => css`
  background-image: url(${boatImage[type]});
  background-size: cover;
  border-radius: 1em;
  box-shadow: 0 0 0.5em black, 0 0 0.5em black, 0 0 0.5em black;
`
