import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import Good, {goodsToMap} from '../goods/Good'
import MoveType from '../../moves/MoveType'
import {getActionRules} from '../../AFeastForOdin'
import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class StoneCrusher extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    const actionRules = getActionRules(this.game)
    if (actionRules?.player.color === this.player.color && !actionRules.pendingAction.afterEffects?.includes(Occupation.StoneCrusher)
      && this.givesStone(move)) {
      if (!actionRules.pendingAction.afterEffects) actionRules.pendingAction.afterEffects = []
      actionRules.pendingAction.afterEffects.push(Occupation.StoneCrusher)
    }
    if (actionRules?.player.color === this.player.color
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && actionRules.pendingAction.afterEffects?.includes(Occupation.StoneCrusher)) {
      actionRules.pendingAction.afterEffects = actionRules.pendingAction.afterEffects.filter(effect => effect !== Occupation.StoneCrusher)
      return [receiveGoodsMove(this.player.color, Good.Silver)]
    }
    return []
  }

  private givesStone(move: Move) {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        return move.player === this.player.color && !!goodsToMap(move.goods)[Good.Stone]
      case MoveType.TakeGoodsFromMountainStrip:
        return move.player === this.player.color && move.goods.some(goods => goods.some(good => good === Good.Stone))
    }
    return false
  }
}
