import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {countLongBoats} from '../boats/LandingStages'
import EmigrationOccupation from './EmigrationOccupation'

export default class HarborGuard extends EmigrationOccupation {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.HarborGuard})
    return []
  }

  getEmigrationCost(): number {
    return Math.max(0, super.getEmigrationCost() - countLongBoats(this.player))
  }
}
