import ExploreRules from './ExploreRules'
import Action from '../Action'
import {hasBoat} from '../../material/boats/LandingStages'
import Occupation from '../../material/Occupation'

export default class ExploreIRules extends ExploreRules {
  action = Action.ExploreI

  canUseEffect() {
    return this.getAvailableTerritories().length > 0 && hasBoat(this.player)
  }

  getAvailableTerritories() {
    if (this.player.occupations.includes(Occupation.Adventurer)) {
      return this.game.unexploredTerritories
    } else {
      return super.getAvailableTerritories()
    }
  }
}