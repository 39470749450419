import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countKnarrs} from '../boats/LandingStages'
import {harvestQuality} from '../../phases/HarvestPhaseRules'

export default class GrainDeliveryman extends OccupationRules {
  immediateEffect() {
    const knarrs = countKnarrs(this.player)
    if (knarrs) {
      return [receiveGoodsMove(this.player.color, harvestQuality(knarrs))]
    }
    return []
  }
}
