import Occupation from '../material/Occupation'
import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'

type PlayOccupationEffect = {
  type: MoveType.PlayOccupationEffect
  player: PlayerColor
  occupation: Occupation
}

export default PlayOccupationEffect

export function playOccupationEffectMove(player: PlayerColor, occupation: Occupation): PlayOccupationEffect {
  return {type: MoveType.PlayOccupationEffect, player, occupation}
}
