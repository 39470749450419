import OccupationRules from './OccupationRules'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import GoodsAreaType from '../goods/GoodsAreaType'

export default class Seafarer extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.EndOfGame) {
      const explorationBoards = this.player.placedGoodsAreas.reduce((sum, area) => area.goodsArea.type === GoodsAreaType.Territory ? sum + 1 : sum, 0)
      if (explorationBoards >= 2) {
        return [receiveGoodsMove(this.player.color, {[Good.Silver]: explorationBoards === 2 ? 4 : explorationBoards === 3 ? 9 : 16})]
      }
    }
    return []
  }
}
