import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {getActionRules} from '../../AFeastForOdin'
import Good, {isGood} from '../goods/Good'
import Occupation from '../Occupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import MoveView from '../../moves/MoveView'
import {startEffectMove} from '../../moves/StartEffect'
import {endEffectMove} from '../../moves/EndEffect'

export default class LanceBearer extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && isGood(move.goods) && !this.player.effects.length) {
      const rules = getActionRules(this.game)
      if (rules?.player.color === this.player.color && rules.action === Action.Raiding) {
        return [startEffectMove(this.player.color, {occupation: Occupation.LanceBearer})]
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    return [
      receiveGoodsMove(this.player.color, Good.Peas),
      receiveGoodsMove(this.player.color, Good.Silver)
    ]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && (move.goods === Good.Peas || move.goods === Good.Silver)) {
      return [endEffectMove(this.player.color)]
    }
    return []
  }
}
