import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import TakeMountainsResourcesRules from '../../actions/mountainsAndTrade/TakeMountainsResourcesRules'
import Action from '../../actions/Action'
import PlayerColor from '../../PlayerColor'
import {getActionRules, getPendingAction, playerHasGoods} from '../../AFeastForOdin'
import Good, {Goods, goodsToMap} from '../goods/Good'
import {passMove} from '../../moves/Pass'
import {chooseMountainStripMove} from '../../moves/ChooseMountainStrip'
import MountainStrip from '../MountainStrip'
import Effect from '../../effects/Effect'

export default abstract class ExtractToPayActionRules extends OccupationRules {
  abstract effect: Effect

  abstract isEligibleAction(action: Action): boolean

  stripsToExtractMissingGoods(cost: Goods, _action: Action): MountainStrip[] {
    const costMap = goodsToMap(cost)
    return this.game.mountainStrips.filter(strip => this.isValidStrip(strip) && this.isEnoughToPay(strip.goods[strip.goods.length - 1], costMap))
  }

  getExtractedGoods(strip1: MountainStrip, strip2: MountainStrip): Good[] {
    if (strip1 === strip2) {
      if (strip1.goods.length === 1) {
        return strip1.goods[strip1.goods.length - 1]
      } else {
        return strip1.goods[strip1.goods.length - 1].concat(strip1.goods[strip1.goods.length - 2])
      }
    } else {
      return strip1.goods[strip1.goods.length - 1].concat(strip2.goods[strip2.goods.length - 1])
    }
  }

  isEnoughToPay(extractedGoods: Good[], costMap: Partial<Record<Good, number>>) {
    for (const good in costMap) {
      const playerGoods = (this.player.goods[good] ?? 0) + extractedGoods.reduce((sum, extractedGood) => extractedGood === parseInt(good) ? sum + 1 : sum, 0)
      if (playerGoods < costMap[good]) {
        return false
      }
    }
    return true
  }

  eachTimeEffect(move: MoveRandomized): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && this.isEligibleAction(move.action)) {
      this.onChooseEligibleAction(move.action)
    }
    if (this.player.effects.length && this.player.effects[0].occupation === this.effect.occupation) {
      return this.postActionPlayed(move)
    }
    return []
  }

  onChooseEligibleAction(_action: Action) {
    if (this.game.mountainStrips.some(this.isValidStrip)) {
      this.player.effects.unshift(this.effect)
    }
  }

  // When played before the 4th column action, it triggers immediately: https://boardgamegeek.com/thread/2650459/apprentice-craftsman
  immediateEffect(): Move[] {
    const pendingAction = getPendingAction(this.game)
    if (pendingAction && this.isEligibleAction(pendingAction.action) && pendingAction.action % 5 === 4
      && pendingAction.playOccupationBonus?.used && !pendingAction.complete) {
      this.player.effects.unshift(this.effect)
    }
    return []
  }

  isValidStrip(_strip: MountainStrip): boolean {
    return true
  }

  delegate() {
    return new TakeMountainsResourcesRules(this.game, this.player, 1, this.isValidStrip)
  }

  canPayAction() {
    const actionRules = getActionRules(this.game)!
    switch (actionRules.action) {
      case Action.CraftChest:
        return playerHasGoods(this.player, Good.Wood) || playerHasGoods(this.player, Good.Ore)
      default:
        return !actionRules.cost || playerHasGoods(this.player, actionRules.cost)
    }
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    if (playerId !== this.player.color) return []
    if (this.canPayAction()) {
      return super.getLegalMoves(playerId).concat(passMove(this.player.color))
    } else {
      const actionRules = getActionRules(this.game)!
      switch (actionRules.action) {
        case Action.CraftChest:
          return this.stripsToExtractMissingGoods(Good.Wood, actionRules.action).concat(this.stripsToExtractMissingGoods(Good.Ore, actionRules.action))
            .map(strip => chooseMountainStripMove(this.player.color, strip.id))
        default:
          return this.stripsToExtractMissingGoods(actionRules.cost!, actionRules.action)
            .map(strip => chooseMountainStripMove(this.player.color, strip.id))
      }
    }
  }

  postActionPlayed(move: MoveRandomized | MoveView): Move[] {
    switch (move.type) {
      case MoveType.TakeGoodsFromMountainStrip:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return []
  }
}

export function isExtractToPayActionRules(occupationRules: OccupationRules): occupationRules is ExtractToPayActionRules {
  return typeof (occupationRules as ExtractToPayActionRules).stripsToExtractMissingGoods === 'function'
}
