enum Phase {
  Viking = 1,
  Harvest,
  Exploration,
  Weapon,
  Actions,
  StartPlayer,
  Income,
  Breeding,
  Feast,
  Bonus,
  Mountain,
  Return,
  EndOfGame,
}

export default Phase