import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {passMove} from '../../moves/Pass'
import BoatType from '../boats/BoatType'
import DisarmBoat, {disarmBoatMove} from '../../moves/DisarmBoat'
import MoveView from '../../moves/MoveView'
import Effect from '../../effects/Effect'

export default abstract class DisarmLongshipOccupationRules extends OccupationRules {
  abstract effect: Effect

  abstract isEligibleAction(action: Action): boolean

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && this.isEligibleAction(move.action)) {
      this.player.effects.unshift(this.effect)
    } else if (this.player.effects.length && this.player.effects[0].occupation === this.effect.occupation) {
      this.postActionPlayed(move)
    }
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = [passMove(this.player.color)]
    for (let landingStage = 0; landingStage < this.player.landingStages.longBoats.length; landingStage++) {
      const longBoat = this.player.landingStages.longBoats[landingStage]
      if (longBoat?.type === BoatType.Longship && longBoat.ore > 0) {
        moves.push(disarmBoatMove(this.player.color, landingStage))
      }
    }
    return moves
  }

  postActionPlayed(move: Move | MoveView): (Move | MoveView)[] {
    const consequences = super.play(move)
    if (move.type === MoveType.DisarmBoat && move.player === this.player.color && !move.whalingBoat) {
      consequences.push(...this.onDisarm(move))
      this.player.effects.shift()
    } else if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return consequences
  }

  abstract onDisarm(move: DisarmBoat): Move[]
}
