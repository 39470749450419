import OccupationRules from './OccupationRules'
import Game from '../../Game'
import GameView from '../../GameView'
import {passMove} from '../../moves/Pass'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import BuildingPlacementArea from '../goods/BuildingPlacementArea'
import {isBuildingPlacedGoodsArea} from '../goods/PlacedGoodsArea'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {getBonusMoves} from '../../phases/BonusPhaseRules'

export default class Builder extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Builder})
    return []
  }

  getPlayerMoves() {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
      return getBonusMoves(this.game, this.player, getBonusGoodsForBuildings(this.game, this.player))
    }
    return []
  }
}

export function getBonusGoodsForBuildings(game: Game | GameView, player: Player | PlayerView) {
  return player.placedGoodsAreas.flatMap(placedGoodsArea => {
    return isBuildingPlacedGoodsArea(placedGoodsArea) ? new BuildingPlacementArea(placedGoodsArea).getBonusGoods(game) : []
  })
}
