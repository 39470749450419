import Game from '../Game'
import GameView from '../GameView'
import Longship, {isLongship} from '../material/boats/Longship'
import Good from '../material/goods/Good'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'
import {removeGoodsToPlayer} from './SpendGoods'
import Occupation from '../material/Occupation'
import {getActionRules} from '../AFeastForOdin'

type ArmBoat = {
  type: MoveType.ArmBoat
  player: PlayerColor
  landingStage: number
  whalingBoat?: boolean
}

export default ArmBoat

export function armBoatMove(player: PlayerColor, landingStage: number, whalingBoat = false): ArmBoat {
  if (whalingBoat) {
    return {type: MoveType.ArmBoat, player, landingStage, whalingBoat}
  } else {
    return {type: MoveType.ArmBoat, player, landingStage}
  }
}

export function isArmBoatValid(game: Game | GameView, playerId: PlayerColor, move: ArmBoat): boolean {
  if (move.player !== playerId) return false

  const player = game.players.find(player => player.color === playerId)

  if (!player?.goods[Good.Ore]) return false

  const actionRules = getActionRules(game)
  if (actionRules?.player.color === playerId) return false // Not during an action

  if (player.effects.length > 0 && player.effects[0].occupation === Occupation.OreBoatman) {
    return false
  }

  if (move.whalingBoat) {
    const boat = player.landingStages.whalingBoats[move.landingStage]
    return boat !== null && !boat.extraOre
  } else {
    const boat = player.landingStages.longBoats[move.landingStage]
    return isLongship(boat) && boat.ore < 3
  }
}

export function armBoat(game: Game | GameView, move: ArmBoat) {
  const player = game.players.find(player => player.color === move.player)!
  if (move.whalingBoat) {
    player.landingStages.whalingBoats[move.landingStage]!.extraOre = true
  } else {
    (player.landingStages.longBoats[move.landingStage] as Longship).ore++
  }
  removeGoodsToPlayer(player, Good.Ore)
}
