import Boat from './Boat'
import BoatType from './BoatType'

type Longship = {
  type: BoatType.Longship
  ore: number
}

export function isLongship(boat: Boat | null): boat is Longship {
  return boat?.type === BoatType.Longship
}

export default Longship