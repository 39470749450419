import Move from '../../moves/Move'
import {isCrafting} from '../../actions/Action'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import TradeOccupationRules from './TradeOccupationRules'
import {getActionRules} from '../../AFeastForOdin'

export default class SilkStitcher extends TradeOccupationRules {
  trades = [{spend: {[Good.Silver]: 3}, receive: Good.Silk}]

  eachTimeEffect(): Move[] {
    const actionRules = getActionRules(this.game)
    if (actionRules?.player.color === this.player.color && isCrafting(actionRules?.action)
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && !actionRules.pendingAction.afterEffects?.includes(Occupation.SilkStitcher)) {
      this.player.effects.unshift({occupation: Occupation.SilkStitcher})
      if (!actionRules.pendingAction.afterEffects) actionRules.pendingAction.afterEffects = []
      actionRules.pendingAction.afterEffects.push(Occupation.SilkStitcher)
    }
    return []
  }
}
