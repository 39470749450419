import Good from './Good'

export type PlacementSpace = null | {
  income?: number
  malus?: number
  bonus?: Good[]
  requiredGood?: Good
  good?: Good
}

export const __: PlacementSpace = {}
export const XX: PlacementSpace = null
export const _1: PlacementSpace = {malus: -1}

export const i0: PlacementSpace = {income: 0}
export const i1: PlacementSpace = {income: 1}
export const i2: PlacementSpace = {income: 2}
export const i3: PlacementSpace = {income: 3}
export const i4: PlacementSpace = {income: 4}
export const i5: PlacementSpace = {income: 5}
export const i6: PlacementSpace = {income: 6}
export const i7: PlacementSpace = {income: 7}
export const i9: PlacementSpace = {income: 9}
export const iC: PlacementSpace = {income: 12}
export const iE: PlacementSpace = {income: 15}