import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import {getActionRules} from '../../AFeastForOdin'

export default class MountainGuard extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color && !this.player.effects.length) {
      const rules = getActionRules(this.game)
      if (rules && isMountainsAndTradeRules(rules)) {
        const distinctGoods = new Set(move.goods.flatMap(goods => goods)).size
        switch (distinctGoods) {
          // We use an array of goods as a trick to prevent good from being flagged as "traded" for the "Mountains and Trade" action
          case 2:
            return [receiveGoodsMove(this.player.color, [Good.Mead])]
          case 3:
            return [receiveGoodsMove(this.player.color, [Good.Hide])]
          case 4:
            return [receiveGoodsMove(this.player.color, [Good.Chest])]
        }
      }
    }
    return []
  }
}
