/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {ButtonHTMLAttributes, useMemo} from 'react'
import GameLocalView from '../../GameLocalView'
import Viking from '../../player/Viking'
import {buttonReset} from '../../styles'
import {actionRulesDialog, setRulesDialog} from '../../RulesDialog'
import ActionTile from './ActionTile'
import {useLongPress} from 'use-long-press'
import {chooseActionMove} from '@gamepark/a-feast-for-odin/moves/ChooseAction'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'

type Props = {
  game: GameLocalView
  action: Action
  occupied?: { playerId: PlayerColor, vikings: number }
  scrolling?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export default function ActionButton({game, action, occupied, scrolling, ...props}: Props) {
  const playerId = usePlayerId()
  const available = useMemo(() =>
      playerId !== undefined && new AFeastForOdin(game).getLegalMoves(playerId).some(move => move.type === MoveType.ChooseAction && move.action === action)
    , [game, playerId])
  const play = usePlay()
  const bind = useLongPress(() => available && !scrolling && play(chooseActionMove(playerId, action)), {
    onCancel: () => !scrolling && setTimeout(() => play(setRulesDialog(actionRulesDialog(action)), {local: true}), 0)
    // setTimeout is required here otherwise on touch device, the dialog backdrop click is trigger immediately after the dialog opens...
  })
  return (
    <>
      <button css={[buttonReset, actionButtonCss(action)]} {...bind()} {...props}>
        <ActionTile action={action} css={[!available && unavailableCss]}/>
        {occupied && [...Array(occupied.vikings)].map((_, index) => <Viking key={index} color={occupied.playerId} css={vikingPosition(index)}/>)}
      </button>
    </>
  )
}

const actionButtonCss = (action: Action) => css`
  position: absolute;
  left: ${actionsPosition[action][0]}em;
  top: ${actionsPosition[action][1]}em;
  background-size: contain;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 0.2em black);
  text-align: center;
  line-height: 1;
  color: black;
  font-weight: bold;

  & > span {
    font-size: 1.7em;
  }

  &:hover:not(:disabled) {
    > div {
      transform: scale(1.05);
      filter: drop-shadow(0 0 0.2em white);
    }
  }
`

const unavailableCss = css`
  filter: brightness(50%);
`

const actionsPosition: Record<Action, [number, number]> = {
  [Action.BuildShed]: [18.1, 11.9],
  [Action.BuildStoneHouse]: [42.3, 11.4],
  [Action.BuildLongHouse]: [66.2, 11.7],
  [Action.BuildWhalingBoat]: [20.1, 23.8],
  [Action.BuildKnarr]: [43.7, 23.9],
  [Action.BuildLongship]: [66.6, 23.3],
  [Action.BuildHouseAndBoat]: [89.5, 11.5],
  [Action.HuntingGameWithOneViking]: [17.6, 35],
  [Action.HuntingGameWithTwoVikings]: [41.9, 34.6],
  [Action.Fishing]: [21.3, 50.7],
  [Action.LayingSnare]: [40.4, 49.8],
  [Action.Whaling]: [66.1, 35.9],
  [Action.WhalingWithOneBoat]: [90, 35.7],
  [Action.BuyFish]: [18.2, 65.8],
  [Action.BuySheep]: [41, 66.1],
  [Action.BuyMeat]: [17.7, 78.2],
  [Action.BuyCattleAndMilk]: [41, 77.6],
  [Action.BuySheepOrCattle]: [65.3, 65.4],
  [Action.BuySheepAndCattle]: [89.9, 65.5],
  [Action.GetBeans]: [20.1, 90.6],
  [Action.GetFlaxAndFish]: [40.8, 90.4],
  [Action.GetFruitsOilMeat]: [64.5, 89.9],
  [Action.Milking]: [17.8, 101.5],
  [Action.GetMead]: [43.2, 102.2],
  [Action.Shearing]: [63.8, 102.2],
  [Action.GetSpiceMilkWool]: [89.2, 89.7],
  [Action.CraftLinen]: [17.5, 115.3],
  [Action.CraftClothing]: [41.6, 113.9],
  [Action.Smithy]: [66.9, 114.2],
  [Action.CraftRuneStone]: [19.8, 126.5],
  [Action.CraftChest]: [42.8, 125.7],
  [Action.CraftChestsAndRuneStones]: [66.4, 125.5],
  [Action.CraftRobeAndJewelry]: [89.8, 113.6],
  [Action.Take2Resources]: [18.1, 136.6],
  [Action.TakeWoodAndOre]: [42.6, 137],
  [Action.Take5Resources]: [65.3, 137.1],
  [Action.Take1ResourceAndTrade]: [17.9, 148.5],
  [Action.Take3ResourcesAndTrade]: [42.4, 148.6],
  [Action.Trade3AndWeapons]: [66.8, 149.2],
  [Action.Take4ResourcesAndTrade2]: [90, 137],
  [Action.Trade2]: [21.7, 160.4],
  [Action.Trade3]: [43.6, 160.4],
  [Action.Trade4]: [66.5, 160.5],
  [Action.TradeOverseas1]: [17.1, 171.5],
  [Action.TradeOverseas2]: [41.6, 171.1],
  [Action.SpecialSale]: [65.9, 171.3],
  [Action.Take8ResourcesOrTrade3]: [88.9, 160.1],
  [Action.Raiding]: [17.9, 184.1],
  [Action.Pillaging1]: [41.6, 183.9],
  [Action.Pillaging2]: [65.3, 184],
  [Action.ExploreI]: [17.5, 199.5],
  [Action.ExploreII]: [42.8, 199.9],
  [Action.ExploreIII]: [65.9, 199.5],
  [Action.Plundering]: [89.9, 183.7],
  [Action.DrawOccupation]: [18.1, 211.4],
  [Action.Emigrate1]: [41.3, 211.6],
  [Action.Emigrate2]: [65.3, 211.8],
  [Action.PlayOneOccupation]: [17.6, 221.9],
  [Action.PlayTwoOccupations]: [43.4, 223],
  [Action.PlayFourOccupations]: [64.9, 223.3],
  [Action.ExchangeBoatAndEmigrate]: [89.1, 211.8],
  [Action.Imitation1]: [20.5, 3.7],
  [Action.Imitation2]: [39.5, 3.7],
  [Action.Imitation3]: [4, 3.6],
  [Action.Imitation4]: [22.6, 2.4]
}

const vikingPosition = (index: number) => css`
  top: 2em;
  right: ${3 + index * 4}em;
`
