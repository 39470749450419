import GameLocalView from '../GameLocalView'
import GoodsArea, {isSameGoodsArea} from '@gamepark/a-feast-for-odin/material/goods/GoodsArea'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {isPlacedGoodsLocal} from '@gamepark/a-feast-for-odin/moves/PlacedGoodsLocal'

export const TAKE_BACK_LOCAL_PLACED_GOODS = 'TakeBackLocalPlacedGoods'

export default interface TakeBackLocalPlacedGoods {
  type: typeof TAKE_BACK_LOCAL_PLACED_GOODS
  player: PlayerColor
  goodsArea: GoodsArea
}

export const takeBackLocalPlacedGoodsMove = function (player: PlayerColor, goodsArea: GoodsArea): TakeBackLocalPlacedGoods {
  return {type: TAKE_BACK_LOCAL_PLACED_GOODS, player, goodsArea}
}

export function takeBackLocalPlacedGoods(game: GameLocalView, move: TakeBackLocalPlacedGoods) {
  const player = game.players.find(player => player.color === move.player)!
  const area = player.placedGoodsAreas.find(area => isSameGoodsArea(area.goodsArea, move.goodsArea))!
  area.placedGoods = area.placedGoods.filter(placedGood => !isPlacedGoodsLocal(placedGood))
}
