import MoveType from './MoveType'

type StartNewRound = {
  type: MoveType.StartNewRound
}

export default StartNewRound

export const startNewRoundMove: StartNewRound = {type: MoveType.StartNewRound}

