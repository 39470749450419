/** @jsxImportSource @emotion/react */
import {getPlayerName} from '@gamepark/a-feast-for-odin/AFeastForOdinOptions'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import {PassButton} from '../PassButton'
import {getBonusGoods} from '@gamepark/a-feast-for-odin/phases/BonusPhaseRules'
import GameOverHeaderText from './GameOverHeaderText'

type Props = {
  game: GameLocalView
}

export default function EndOfGameHeaderText({game}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(p => p.color === playerId)

  if (player && !player.passed) {
    return <Trans defaults="end.of.game.you" values={{number: getBonusGoods(game, player).length}} components={[<PassButton/>]}/>
  }
  const players = game.players.filter(player => !player.passed)
  if (players.length > 1) {
    return <>{t('end.of.game.players')}</>
  } else if (players.length === 1) {
    return <>{t('end.of.game.player', {player: getPlayerName(players[0].color, t)})}</>
  } else {
    return <GameOverHeaderText game={game}/>
  }
}
