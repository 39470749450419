import {hasStageForLargeShip, hasWhalingBoat} from '../../material/boats/LandingStages'
import Good from '../../material/goods/Good'
import {discardBoatMove} from '../../moves/DiscardBoat'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import {spendGoodsMove} from '../../moves/SpendGoods'
import EmigrateRules from './EmigrateRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import {takeBoatMove} from '../../moves/TakeBoat'
import BoatType from '../../material/boats/BoatType'

export default class ExchangeBoatAndEmigrateRules extends EmigrateRules {
  action = Action.ExchangeBoatAndEmigrate

  get cost(): undefined {
    return // Emigration is optional here
  }

  canUseEffect() {
    return this.canExchangeWhalingBoat() || this.canEmigrate()
  }

  canEmigrate() {
    return super.canUseEffect()
  }

  canExchangeWhalingBoat() {
    return hasWhalingBoat(this.player) && hasStageForLargeShip(this.player)
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (!this.pendingAction.boatUpgraded && this.canExchangeWhalingBoat()) {
      for (let landingStage = 0; landingStage < this.player.landingStages.whalingBoats.length; landingStage++) {
        if (this.player.landingStages.whalingBoats[landingStage]) {
          moves.push(discardBoatMove(this.player.color, landingStage))
        }
      }
    }
    if (!this.pendingAction.emigrated && this.canEmigrate()) {
      moves.push(...super.getPlayerMoves())
    }
    if (this.pendingAction.boatUpgraded || this.pendingAction.emigrated) {
      moves.push(passMove(this.player.color))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.DiscardBoat:
        if (move.player === this.player.color) {
          this.pendingAction.boatUpgraded = true
          consequences.push(takeBoatMove(this.player.color, BoatType.Knarr))
        }
        break
      case MoveType.EmigrateBoat:
        if (move.player === this.player.color) {
          consequences.push(spendGoodsMove(this.player.color, {[Good.Silver]: this.getEmigrationCost()}))
          this.pendingAction.emigrated = true
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
    }
    if (this.pendingAction.boatUpgraded && this.pendingAction.emigrated) {
      this.complete()
    }
    return consequences
  }
}
