import OccupationRules from './OccupationRules'
import Action from '../../actions/Action'

export default class RefugeeHelper extends OccupationRules {
  actionSilverDiscount(action: Action): number {
    switch (action) {
      case Action.Emigrate1:
      case Action.Emigrate2:
      case Action.ExchangeBoatAndEmigrate:
        return 2
    }
    return 0
  }
}
