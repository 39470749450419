import Action from '../../actions/Action'
import Occupation from '../Occupation'
import ExtractToPayActionRules from './ExtractToPayActionRules'
import Effect from '../../effects/Effect'
import Good, {Goods, goodsToMap} from '../goods/Good'
import MountainStrip from '../MountainStrip'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import ArmedFighter from './ArmedFighter'

// TODO norwegian actions
export default class Carpenter extends ExtractToPayActionRules {
  effect: Effect = {occupation: Occupation.Carpenter}

  isEligibleAction(action: Action): boolean {
    return isBuildingHouseOrBoatAction(action)
  }

  onChooseEligibleAction(action: Action) {
    if (this.player.occupations.includes(Occupation.ArmedFighter) && new ArmedFighter(this.game, this.player).isEligibleAction(action)) {
      this.player.effects.unshift({occupation: Occupation.ArmedFighter})
    }
    this.player.effects.unshift({occupation: Occupation.Carpenter})
  }

  stripsToExtractMissingGoods(cost: Goods, action: Action): MountainStrip[] {
    const costMap = goodsToMap(cost)
    // Awful combo: need to check all the resources available by extracting two time 1 resource... (and one of those must be Stone or Ore)
    if (this.player.effects[1]?.occupation === Occupation.ArmedFighter) {
      return this.game.mountainStrips.filter(strip =>
        this.game.mountainStrips.some(strip2 => {
          const goods = this.getExtractedGoods(strip, strip2)
          return goods.some(good => good === Good.Stone || good === Good.Ore) && this.isEnoughToPay(goods, costMap)
        })
      )
    } else {
      return super.stripsToExtractMissingGoods(cost, action)
    }
  }

  postActionPlayed(move: MoveRandomized | MoveView) {
    const consequences = super.postActionPlayed(move)
    switch (move.type) {
      case MoveType.TakeGoodsFromMountainStrip:
        if (move.player === this.player.color) {
          if (this.player.effects[0]?.occupation === Occupation.ArmedFighter) {
            const alreadyExtractedStoneOrOre = move.type === MoveType.TakeGoodsFromMountainStrip
              && move.goods.some(goods => goods.some(good => good === Good.Stone || good === Good.Ore))
            // Player can extract Ore first with Armed Fighter, then some Silver with Carpenter.
            // So, we replace Armed Fighter effect with Carpenter effect again if Stone / Ore is already extracted...
            if (alreadyExtractedStoneOrOre) {
              this.player.effects[0]!.occupation = Occupation.Carpenter
            } else if (!this.game.mountainStrips.some(strip => new ArmedFighter(this.game, this.player).isValidStrip(strip))) {
              this.player.effects.shift()
            }
          }
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          if (this.player.effects[0]?.occupation === Occupation.ArmedFighter) {
            this.player.effects.shift()
          }
        }
    }
    return consequences
  }
}

function isBuildingHouseOrBoatAction(action: Action) {
  switch (action) {
    case Action.BuildShed:
    case Action.BuildStoneHouse:
    case Action.BuildLongHouse:
    case Action.BuildWhalingBoat:
    case Action.BuildKnarr:
    case Action.BuildLongship:
    case Action.BuildHouseAndBoat:
      return true
  }
  return false
}
