import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {isCrafting} from '../../actions/Action'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Barkeep extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && isCrafting(move.action)) {
      return [receiveGoodsMove(this.player.color, Good.Mead)]
    }
    return []
  }
}
