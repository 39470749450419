import Game from '../Game'
import GameView from '../GameView'
import BoatType from '../material/boats/BoatType'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type EmigrateBoat = {
  type: MoveType.EmigrateBoat
  player: PlayerColor
  landingStage: number
  whalingBoat?: boolean
}

export default EmigrateBoat

export function emigrateBoatMove(player: PlayerColor, landingStage: number, whalingBoat = false): EmigrateBoat {
  if (whalingBoat) {
    return {type: MoveType.EmigrateBoat, player, landingStage, whalingBoat}
  } else {
    return {type: MoveType.EmigrateBoat, player, landingStage}
  }
}

export function emigrateBoat(game: Game | GameView, move: EmigrateBoat) {
  const player = game.players.find(player => player.color === move.player)!
  if (move.whalingBoat) {
    player.emigration.push(BoatType.WhalingBoat)
    player.landingStages.whalingBoats[move.landingStage] = null
  } else {
    const boat = player.landingStages.longBoats[move.landingStage]!
    player.emigration.push(boat.type)
    player.landingStages.longBoats[move.landingStage] = null
  }
}
