/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Territory from '@gamepark/a-feast-for-odin/material/Territory'
import {TFunction} from 'i18next'
import {HTMLAttributes, PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../images/Images'
import {squareSize} from '../styles'

type Props = {
  territory: Territory
} & HTMLAttributes<HTMLDivElement>

export default function ExplorationBoard({children, territory, ...props}: PropsWithChildren<Props>) {
  const {t} = useTranslation()
  return (
    <div css={[style(territory)]} {...props}>
      <h3 css={title}>{getTerritoryName(territory, t)}</h3>
      {children}
    </div>
  )
}

const territorySize = 11.82 * squareSize
const style = (territory: Territory) => css`
  position: absolute;
  width: ${territorySize}em;
  height: ${territorySize}em;
  background-size: cover;
  background-image: url(${image(territory)});
  border-radius: 2em;
  box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
`

const title = css`
  position: absolute;
  left: 8.5%;
  right: 45.3%;
  top: 4%;
  font-size: 2.6em;
  color: black;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
`

const image = (territory: Territory) => {
  switch (territory) {
    case Territory.Shetland:
      return Images.shetland
    case Territory.FaroeIslands:
      return Images.faroeIslands
    case Territory.Iceland:
      return Images.iceland
    case Territory.Greenland:
      return Images.greenland
    case Territory.BearIsland:
      return Images.bearIsland
    case Territory.BaffinIsland:
      return Images.baffinIsland
    case Territory.Labrador:
      return Images.labrador
    case Territory.Newfoundland:
      return Images.newfoundland
    default:
      throw new Error(`Unknown territory: ${territory}`)
  }
}

function getTerritoryName(territory: Territory, t: TFunction) {
  switch (territory) {
    case Territory.Shetland:
      return t('Shetland')
    case Territory.FaroeIslands:
      return t('Faroe Islands')
    case Territory.Iceland:
      return t('Iceland')
    case Territory.Greenland:
      return t('Greenland')
    case Territory.BearIsland:
      return t('Bear Island')
    case Territory.BaffinIsland:
      return t('Baffin Island')
    case Territory.Labrador:
      return t('Labrador')
    case Territory.Newfoundland:
      return t('Newfoundland')
  }
}
