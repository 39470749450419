import Move from '../../moves/Move'
import Good from '../goods/Good'
import {playerHasGoods} from '../../AFeastForOdin'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {harvest} from '../../phases/HarvestPhaseRules'

export default class MeatMerchant extends TradeOccupationRules {
  trades = [{spend: Good.GameMeat, receive: Good.WhaleMeat}, {spend: Good.WhaleMeat, receive: {[Good.GameMeat]: 2}}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Harvest && !harvest(this.game).length) {
      return this.noHarvestEffect()
    }
    return []
  }

  noHarvestEffect(): Move[] {
    if (playerHasGoods(this.player, Good.GameMeat) || playerHasGoods(this.player, Good.WhaleMeat)) {
      this.player.effects.unshift({occupation: Occupation.MeatMerchant})
    }
    return []
  }
}
