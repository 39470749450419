import Move from '../../moves/Move'
import ActionRules from '../ActionRules'
import PendingAction from '../../effects/PendingAction'
import MoveView from '../../moves/MoveView'
import {Rules} from '@gamepark/rules-api'
import Game from '../../Game'
import GameView from '../../GameView'
import PlayerColor from '../../PlayerColor'
import TakeMountainsResourcesRules from './TakeMountainsResourcesRules'
import MoveRandomized from '../../moves/MoveRandomized'
import {passMove} from '../../moves/Pass'
import MoveType from '../../moves/MoveType'
import {isGood, upgradableGoods} from '../../material/goods/Good'
import UpgradeGoodRules from './UpgradeGoodRules'
import {playerHasGoods} from '../../AFeastForOdin'

export default abstract class MountainsAndTradeRules extends ActionRules {
  mountainsExtraction: number[] = []
  trades: number[] = []

  canUseEffect(): boolean {
    return (this.mountainsExtraction.length > 0 && this.game.mountainStrips.length > 0)
      || (this.trades.length > 0 && upgradableGoods.some(good => playerHasGoods(this.player, good)))
  }

  createPendingAction(): PendingAction {
    const action: PendingAction = {action: this.action}
    if (this.mountainsExtraction.length) {
      action.mountainsExtracted = []
    }
    if (this.trades.length) {
      action.goodsTraded = []
    }
    return action
  }

  delegates() {
    const delegates: Rules<Game | GameView, Move | MoveView, PlayerColor>[] = []
    if (this.pendingAction.mountainsExtracted) {
      delegates.push(new TakeMountainsResourcesRules(this.game, this.player, this.nextMountainExtraction,
        strip => !this.pendingAction.mountainsExtracted!.includes(strip.id)))
    }
    if (this.pendingAction.goodsTraded) {
      delegates.push(new UpgradeGoodRules(this.game, this.player, this.nextTrade, upgradableGoods.filter(good =>
        playerHasGoods(this.player, {[good]: this.pendingAction.goodsTraded!.reduce<number>((sum, e) => e === good ? sum + 1 : sum, 1)})
      )))
    }
    return delegates
  }

  get nextMountainExtraction() {
    if (!this.pendingAction.mountainsExtracted) return 0
    return this.mountainsExtraction[this.pendingAction.mountainsExtracted.length]
  }

  get nextTrade() {
    if (!this.pendingAction.goodsTraded) return 0
    return this.trades[this.pendingAction.goodsTraded.length]
  }

  get extractionComplete() {
    if (!this.mountainsExtraction.length) return false
    return !this.pendingAction.mountainsExtracted || this.pendingAction.mountainsExtracted.length === this.mountainsExtraction.length
  }

  get extractionStarted() {
    return !!this.pendingAction.mountainsExtracted?.length || this.extractionComplete
  }

  get tradeComplete() {
    if (!this.trades) return false
    return !this.pendingAction.goodsTraded || this.pendingAction.goodsTraded.length === this.trades.length
  }

  get tradeStarted() {
    return !!this.pendingAction.goodsTraded?.length || this.tradeComplete
  }

  canPass(): boolean {
    return this.extractionStarted || this.tradeStarted
  }

  getPlayerMoves(): Move[] {
    return this.canPass() ? [passMove(this.player.color)] : []
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.TakeGoodsFromMountainStrip:
        if (move.player === this.player.color && this.pendingAction.mountainsExtracted) {
          this.pendingAction.mountainsExtracted.push(move.strip)
          if (this.extractionComplete) {
            delete this.pendingAction.mountainsExtracted
          }
        }
        break
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color && isGood(move.goods) && this.pendingAction.goodsTraded) {
          this.pendingAction.goodsTraded.push(move.goods)
          if (this.tradeComplete) {
            delete this.pendingAction.goodsTraded
          }
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
    }
    if (!this.pendingAction.mountainsExtracted && !this.pendingAction.goodsTraded) {
      this.complete()
    }
    return consequences
  }
}

export function isMountainsAndTradeRules(rules: ActionRules): rules is MountainsAndTradeRules {
  return (rules as MountainsAndTradeRules).mountainsExtraction !== undefined
}
