/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import BuildingGoodsArea from '@gamepark/a-feast-for-odin/material/goods/BuildingGoodsArea'
import BuildingPlacementArea from '@gamepark/a-feast-for-odin/material/goods/BuildingPlacementArea'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {placeGoodsMove} from '@gamepark/a-feast-for-odin/moves/PlaceGoods'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {HTMLAttributes} from 'react'
import {DropTargetMonitor, useDrop} from 'react-dnd'
import {GOOD, GoodDragItem} from '../../goods/DraggableGood'
import GoodItem from '../../goods/GoodItem'

type Props = {
  area: BuildingPlacementArea
  goodsArea: BuildingGoodsArea
  extraGoods: Good[]
} & HTMLAttributes<HTMLDivElement>

export default function ExtraGoodsDropArea({area, goodsArea, extraGoods, ...props}: Props) {
  const playerId = usePlayerId<PlayerColor>()!
  const [{draggedGood, canDrop, isOver}, ref] = useDrop({
    accept: GOOD,
    canDrop: (item: GoodDragItem) => {
      return area.canPlaceGood(item.orientedGood.good)
    },
    drop: (item: GoodDragItem) => placeGoodsMove(playerId, goodsArea, [], item.orientedGood.good),
    collect: (monitor: DropTargetMonitor<GoodDragItem>) => {
      return ({
        draggedGood: monitor.getItem()?.orientedGood.good,
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver()
      })
    }
  })

  return (
    <>
      <div ref={ref}
           css={[
             extraGoodsZoneCss,
             draggedGood === Good.Stone && goodsArea.building === Building.Shed && shedRightArea,
             canDrop && canDropCss,
             canDrop && isOver && isOverCss
           ]}
           {...props}>
      </div>
      {goodsArea.building === Building.Shed && <>
        {extraGoods.filter(good => good === Good.Wood).map((good, index) => <GoodItem key={index} good={good} css={shedWoodCss(index)}/>)}
        {extraGoods.filter(good => good === Good.Stone).map((good, index) => <GoodItem key={index} good={good} css={shedStoneCss(index)}/>)}
      </>}
      {goodsArea.building === Building.StoneHouse &&
      extraGoods.map((good, index) => <GoodItem key={index} good={good} css={good === Good.Wood ? stoneHouseWoodCss : stoneHouseStoneCss}/>)
      }
    </>
  )
}

const extraGoodsZoneCss = css`
  position: absolute;
  top: 4em;
  left: 3em;
  height: 19em;
  width: 26em;
  border-radius: 50%;
`

const shedRightArea = css`
  left: 38.3em;
`

const canDropCss = css`
  background-color: rgba(0, 255, 0, 0.3);
`

const isOverCss = css`
  background-color: rgba(0, 255, 0, 0.5);
`

const shedWoodCss = (index: number) => css`
  position: absolute;
  top: 8em;
  left: ${5.6 + index * 6.8}em;
`


const shedStoneCss = (index: number) => css`
  position: absolute;
  top: 8.2em;
  left: ${40.1 + index * 7.6}em;
`

const stoneHouseWoodCss = css`
  position: absolute;
  top: 8em;
  left: 8.2em;
`

const stoneHouseStoneCss = css`
  position: absolute;
  top: 8.2em;
  left: 16em;
`