import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import Good, {upgradableGoods} from '../goods/Good'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import UpgradeGoodRules from '../../actions/mountainsAndTrade/UpgradeGoodRules'
import ReceiveGoods from '../../moves/ReceiveGoods'

export default abstract class UpgradeGoodOccupationRules extends OccupationRules {
  get upgradableGoods(): Good[] {
    return upgradableGoods
  }

  get upgrade(): number | ((good: Good) => number) {
    return 1
  }

  delegate(): UpgradeGoodRules | undefined {
    return new UpgradeGoodRules(this.game, this.player, this.upgrade, this.upgradableGoods)
  }

  getPlayerMoves(): Move[] {
    if (this.anyTimeEffect) return []
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): Move[] {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.onReceiveGoods(move)
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return super.play(move)
  }

  onReceiveGoods(_move: ReceiveGoods) {
    this.player.effects.shift()
  }
}
