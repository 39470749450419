import TradeOccupationRules from './TradeOccupationRules'
import Good from '../goods/Good'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {harvest} from '../../phases/HarvestPhaseRules'
import Occupation from '../Occupation'

export default class FlaxBaker extends TradeOccupationRules {
  trades = [{spend: Good.Flax, receive: [Good.Grain, Good.Silver]}]

  eachTimeEffect(move: Move): Move[] {
    if (this.harvestEnd(move)) {
      this.player.effects.unshift({occupation: Occupation.FlaxBaker})
    }
    return []
  }

  harvestEnd(move: Move) {
    switch (move.type) {
      case MoveType.StartNextPhase:
        return this.game.phase === Phase.Harvest + 1 && harvest(this.game).length > 0
      case MoveType.PlayOccupationEffect:
        return move.player === this.player.color && move.occupation === Occupation.Plower
    }
    return false
  }
}
