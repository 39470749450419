import BoatType from '../material/boats/BoatType'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type BuildBoat = {
  type: MoveType.BuildBoat
  player: PlayerColor
  boat: BoatType
}

export default BuildBoat

export function buildBoatMove(player: PlayerColor, boat: BoatType): BuildBoat {
  return {type: MoveType.BuildBoat, player, boat}
}
