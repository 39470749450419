import BoatType from '../../material/boats/BoatType'
import {hasStageForLargeShip} from '../../material/boats/LandingStages'
import Building from '../../material/Building'
import Good from '../../material/goods/Good'
import Move from '../../moves/Move'
import {takeBuildingMove} from '../../moves/TakeBuilding'
import ActionRules from '../ActionRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import {chooseEffectMove} from '../../moves/ChooseEffect'
import Occupation from '../../material/Occupation'
import {buildBoatMove} from '../../moves/BuildBoat'

export enum BuildHouseAndBoatEffect {
  STONE_HOUSE_LONG_BOAT = 1, LONG_HOUSE_KNARR = 2
}

export default class BuildHouseAndBoatRules extends ActionRules {
  action = Action.BuildHouseAndBoat

  get cost() {
    const stoneCost = this.player.occupations.includes(Occupation.MasterBricklayer) ? 1 : 2
    return {[Good.Wood]: 2, [Good.Stone]: stoneCost}
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (hasStageForLargeShip(this.player) || this.game.buildingSupply[Building.StoneHouse] > 0) {
      moves.push(chooseEffectMove(this.player.color, BuildHouseAndBoatEffect.STONE_HOUSE_LONG_BOAT))
    }
    if (hasStageForLargeShip(this.player) || this.game.buildingSupply[Building.LongHouse] > 0) {
      moves.push(chooseEffectMove(this.player.color, BuildHouseAndBoatEffect.LONG_HOUSE_KNARR))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.ChooseEffect && move.player === this.player.color) {
      switch (move.id) {
        case BuildHouseAndBoatEffect.STONE_HOUSE_LONG_BOAT:
          if (this.game.buildingSupply[Building.StoneHouse] > 0) {
            consequences.push(takeBuildingMove(move.player, Building.StoneHouse))
          }
          if (hasStageForLargeShip(this.player)) {
            consequences.push(buildBoatMove(move.player, BoatType.Longship))
          }
          break
        case BuildHouseAndBoatEffect.LONG_HOUSE_KNARR:
          if (this.game.buildingSupply[Building.LongHouse] > 0) {
            consequences.push(takeBuildingMove(move.player, Building.LongHouse))
          }
          if (hasStageForLargeShip(this.player)) {
            consequences.push(buildBoatMove(move.player, BoatType.Knarr))
          }
          break
      }
      this.complete()
    }
    return consequences
  }
}
