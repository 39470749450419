import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import MoveView from '../../moves/MoveView'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {passMove} from '../../moves/Pass'
import BoatType from '../boats/BoatType'
import {hasStageForLargeShip, hasStageForSmallBoat} from '../boats/LandingStages'
import {getPendingAction, playerHasGoods} from '../../AFeastForOdin'
import {buildBoatMove} from '../../moves/BuildBoat'

export default class KnarrBuilder extends OccupationRules {
  canUseEffect() {
    return playerHasGoods(this.player, {[Good.Wood]: 3}) && hasStageForLargeShip(this.player)
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.action === Action.BuildWhalingBoat) {
        this.player.effects.unshift({occupation: Occupation.KnarrBuilder})
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (hasStageForLargeShip(this.player) && playerHasGoods(this.player, {[Good.Wood]: 3})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Wood]: 3}))
    }
    if (hasStageForSmallBoat(this.player)) {
      moves.push(passMove(this.player.color))
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          getPendingAction(this.game)!.complete = true
          return [buildBoatMove(this.player.color, BoatType.Knarr)]
        }
        break
      case MoveType.Pass:
      case MoveType.BuildBoat:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
    }
    return []
  }
}
