/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import {HTMLAttributes} from 'react'
import Images from '../images/Images'
import {squareSize} from '../styles'

type Props = {
  building: Building
} & HTMLAttributes<HTMLDivElement>

export default function BuildingBoard({building, ...props}: Props) {
  return (
    <div css={[style(building)]} {...props}/>
  )
}

const buildingWidth = 12.75 * squareSize
const buildingHeight = buildingWidth * 650 / 1650

const style = (building: Building) => css`
  width: ${buildingWidth}em;
  height: ${buildingHeight}em;
  background-size: cover;
  background-image: url(${image(building)});
  border-radius: 2em;
  box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
`

const image = (building: Building) => {
  switch (building) {
    case Building.Shed:
      return Images.shed
    case Building.StoneHouse:
      return Images.stoneHouse
    case Building.LongHouse:
      return Images.longHouse
    default:
      throw new Error(`Unknown building: ${building}`)
  }
}
