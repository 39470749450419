/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {Hand} from '@gamepark/react-components'
import OccupationCard from '../occupations/OccupationCard'
import {cardHeight, cardWidth} from '../styles'

type Props = {
  hand: number
  startingOccupation?: boolean
}

export default function PlayerHandOverview({hand, startingOccupation = false}: Props) {
  return (
    <Hand maxAngle={12} css={handPosition}>
      {[...Array(hand)].map((_, index) =>
        <OccupationCard key={index} startingOccupation={index === 0 && startingOccupation}/>
      )}
    </Hand>
  )
}

const cardSize = 0.2

const handPosition = css`
  font-size: ${cardSize}em;
  right: ${6 / cardSize}em;
  top: ${6.5 / cardSize}em;
  width: ${cardWidth}em;
  height: ${cardHeight}em;
`
