import OccupationRules from './OccupationRules'
import Occupation from '../Occupation'
import PendingAction from '../../effects/PendingAction'
import Move from '../../moves/Move'
import Phase from '../../phases/Phase'
import MoveType from '../../moves/MoveType'
import Good from '../goods/Good'
import GoodsAreaType from '../goods/GoodsAreaType'
import {getBanquet} from '../../phases/Feast'
import {chooseActionMove} from '../../moves/ChooseAction'
import Action from '../../actions/Action'
import HuntingGameWithOneVikingRules from '../../actions/hunting/HuntingGameWithOneVikingRules'
import ActionsRules from '../../actions/ActionsRules'

export type FestiveHunterEffect = {
  occupation: Occupation.FestiveHunter
  pendingAction: PendingAction
}

export default class FestiveHunter extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    const effect = this.player.effects[0]
    if (effect?.occupation === Occupation.FestiveHunter && effect.pendingAction.complete) {
      this.player.effects.shift()
    } else {
      if (this.game.phase === Phase.Feast && !this.player.exhaustedOccupations.includes(Occupation.FestiveHunter)) {
        switch (move.type) {
          case MoveType.StartNextPhase: {
            if (getBanquet(this.player).placedGoods.some(placedGood => placedGood.good === Good.GameMeat)) {
              return this.huntMoreGame()
            }
            break
          }
          case MoveType.PlaceGoods:
            if (move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Banquet) {
              if (move.placedGoods.some(placedGood => placedGood.good === Good.GameMeat)) {
                return this.huntMoreGame()
              }
            }
            break
        }
      }
      if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Bonus) {
        this.player.exhaustedOccupations = this.player.exhaustedOccupations.filter(occupation => occupation !== Occupation.FestiveHunter)
      }
    }
    return []
  }

  private huntMoreGame() {
    this.player.exhaustedOccupations.push(Occupation.FestiveHunter)
    this.player.effects.unshift({
      occupation: Occupation.FestiveHunter,
      pendingAction: new HuntingGameWithOneVikingRules(this.game, this.player).createPendingAction()
    })
    return [chooseActionMove(this.player.color, Action.HuntingGameWithOneViking)]
  }

  delegate() {
    return new ActionsRules[Action.HuntingGameWithOneViking](this.game, this.player)
  }
}
