import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {isPlayerView} from '../../PlayerView'
import {playOccupationMove} from '../../moves/PlayOccupation'

export default class Tutor extends OccupationRules {
  canUseAnyTimeEffect() {
    return this.hasOccupationInHand() && playerHasGoods(this.player, Good.Silver)
  }

  hasOccupationInHand() {
    return isPlayerView(this.player) ? this.player.hand > 0 : this.player.hand.length > 0
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Tutor})
    return [spendGoodsMove(this.player.color, Good.Silver)]
  }

  getPlayerMoves(): Move[] {
    if (isPlayerView(this.player)) return []
    return this.player.hand.map(occupation => playOccupationMove(this.player.color, occupation))
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.PlayOccupation && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
