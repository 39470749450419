import {Rules} from '@gamepark/rules-api'
import {turnExplorationBoardsMove} from '../moves/TurnExplorationBoards'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'

export default class ExplorationPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): any[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    const roundsLeft = (this.game.shortGame ? 6 : 7) - this.game.round
    if (roundsLeft > 0 && roundsLeft < 5 && this.game.unexploredTerritories.length > 0) {
      return [turnExplorationBoardsMove, startNextPhaseMove]
    } else {
      return [startNextPhaseMove]
    }
  }
}