import Good, {isGood, isSpecialTile} from '../../material/goods/Good'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import ActionRules from '../ActionRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'

const forgeOptions = [Good.Jewelry, Good.Helmet, Good.Cloakpin, Good.Horseshoe, Good.ForgeHammer, Good.Fibula, Good.ThrowingAxe]

export default class SmithyRules extends ActionRules {
  action = Action.Smithy

  get cost() {
    return Good.Ore
  }

  forgeOptions() {
    return forgeOptions.filter(good => !isSpecialTile(good) || this.game.specialTilesSupply.includes(good))
  }

  getPlayerMoves(): Move[] {
    return this.forgeOptions().map(specialTile => receiveGoodsMove(this.player.color, specialTile))
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && isGood(move.goods) && this.forgeOptions().includes(move.goods)) {
      this.complete()
    }
    return super.play(move)
  }
}

