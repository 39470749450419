import PlacedGood from '../material/goods/PlacedGood'
import PlaceGoods, {placeExtraGood, placeGoodInArea} from './PlaceGoods'
import GameView from '../GameView'
import {isSameGoodsArea} from '../material/goods/GoodsArea'

type PlacedGoodsLocal = PlacedGood & {
  local?: true
}

export default PlacedGoodsLocal

export function isPlacedGoodsLocal(placedGood: PlacedGood): placedGood is PlacedGoodsLocal {
  return !!(placedGood as PlacedGoodsLocal).local
}

export function placeGoodsInView(game: GameView, move: PlaceGoods) {
  const player = game.players.find(player => player.color === move.player)!
  const area = player.placedGoodsAreas.find(({goodsArea}) => isSameGoodsArea(goodsArea, move.goodsArea))!
  for (const placedGood of move.placedGoods) {
    const alreadyPlaced = area.placedGoods.find(item => isPlacedGoodsLocal(item) && item.good === placedGood.good && item.x === placedGood.x && item.y === placedGood.y && item.orientation === placedGood.orientation)
    if (alreadyPlaced && !isPlacedGoodsLocal(placedGood) && isPlacedGoodsLocal(alreadyPlaced)) {
      delete alreadyPlaced.local
    } else {
      placeGoodInArea(player, area, placedGood)
    }
  }
  placeExtraGood(player, area, move)
}
