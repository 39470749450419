/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {titleButtonCss} from '../../styles'
import {usePlay} from '@gamepark/react-client'
import {chooseEffectMove} from '@gamepark/a-feast-for-odin/moves/ChooseEffect'
import BuySheepOrCattleRules, {SheepOrCattleEffect} from '@gamepark/a-feast-for-odin/actions/market/BuySheepOrCattleRules'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'

type Props = {
  rules: BuySheepOrCattleRules
}

export default function SheepOrCattleChoice({rules}: Props) {
  const play = usePlay()
  const player = rules.player
  return <p>
    {!rules.getSilverDiscount(1) ?
      <Trans defaults="sheep.or.cattle.choice"
             components={[
               <button css={titleButtonCss} onClick={() => play(chooseEffectMove(player.color, SheepOrCattleEffect.SHEEP))}/>,
               <button css={titleButtonCss} onClick={() => play(chooseEffectMove(player.color, SheepOrCattleEffect.CATTLE))}
                       disabled={!playerHasGoods(player, Good.Silver)}/>
             ]}/>
      :
      <Trans defaults="sheep.or.cattle.peddler.choice"
             components={[
               <button css={titleButtonCss} onClick={() => play(chooseEffectMove(player.color, SheepOrCattleEffect.SHEEP))}/>,
               <button css={titleButtonCss} onClick={() => play(chooseEffectMove(player.color, SheepOrCattleEffect.CATTLE))}/>
             ]}/>

    }
  </p>
}