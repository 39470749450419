import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {passMove} from '../moves/Pass'
import MoveType from '../moves/MoveType'
import MoveRandomized from '../moves/MoveRandomized'

export default class EndOfGameRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  isTurnToPlay(playerId: PlayerColor): boolean {
    return !this.game.players.find(p => p.color === playerId)!.passed
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    const player = this.game.players.find(p => p.color === playerId)!
    return !player.passed && !player.effects.length ? [passMove(playerId)] : []
  }

  play(move: MoveRandomized | MoveView) {
    switch (move.type) {
      case MoveType.Pass:
        const player = this.game.players.find(player => player.color === move.player)!
        if (!player.effects.length) {
          player.passed = true
        }
    }
    return []
  }
}
