import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {getBanquet} from '../../phases/Feast'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Occupation from '../Occupation'

export default class SoberMan extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color && !this.player.effects.length && this.isSober()) {
      if (this.player.exhaustedOccupations.includes(Occupation.SoberMan)) {
        this.player.exhaustedOccupations = this.player.exhaustedOccupations.filter(occupation => occupation !== Occupation.SoberMan)
      } else {
        return [receiveGoodsMove(this.player.color, Good.Silver)]
      }
    }
    return []
  }

  canUseAnyTimeEffect(): boolean {
    return !this.player.exhaustedOccupations.includes(Occupation.SoberMan) && this.isSober()
  }

  anyTimeEffect(): Move[] {
    this.player.exhaustedOccupations.push(Occupation.SoberMan)
    return [receiveGoodsMove(this.player.color, Good.Silver)]
  }

  isSober() {
    return this.game.phase === Phase.Feast && !getBanquet(this.player).placedGoods.some(placedGood => placedGood.good === Good.Mead)
  }
}
