import {TFunction} from 'i18next'
import Images from '../images/Images'

type OccupationCardDetails = {
  title: (t: TFunction) => string
  texts: OccupationCardText[]
  image: string
}

export type OccupationCardText = {
  text: (t: TFunction) => string
  top?: number
  bottom?: number
  left?: number
  right?: number
  size?: number
  lineHeight?: number
  justify?: 'left' | 'center' | 'right'
  align?: 'start' | 'center' | 'end'
}

const OccupationCardsDetails: OccupationCardDetails[] = [
  {
    title: t => t('Peddler'),
    texts: [
      {text: t => t('Livestock Market'), top: 27, bottom: 67, left: 56, right: 22, size: 0.75, lineHeight: 1},
      {text: t => t('total cost'), top: 63, bottom: 27}
    ],
    image: Images.Peddler
  },
  {
    title: t => t('Patron'),
    texts: [
      {text: t => t('patron.1'), bottom: 57},
      {text: t => t('total cost'), top: 66, bottom: 24}
    ],
    image: Images.Patron
  },
  {
    title: t => t('Furrier'),
    texts: [
      {text: t => t('furrier.1'), bottom: 57},
      {text: t => t('you also receive'), top: 56, bottom: 37}
    ],
    image: Images.Furrier
  },
  {
    title: t => t('Hunter'),
    texts: [
      {text: t => t('when rolling'), bottom: 66},
      {text: t => t('you receive on each roll'), top: 55, bottom: 32},
      {text: t => t('(including Raiding and Pillaging)'), top: 75, size: 1.1}
    ],
    image: Images.Hunter
  },
  {
    title: t => t('Chief'),
    texts: [
      {text: t => t('chief.1'), bottom: 54},
      {text: t => t('chief.2'), top: 63}
    ],
    image: Images.Chief
  },
  {
    title: t => t('Stone Carver'),
    texts: [
      {text: t => t('taken from a mountain strip'), top: 38, bottom: 43},
      {text: t => t('per'), top: 74, bottom: 13, left: 44, right: 37}
    ],
    image: Images.StoneCarver
  },
  {
    title: t => t('Miller'),
    texts: [
      {text: t => t('Feast'), bottom: 70},
      {text: t => t('then with at least'), top: 49, bottom: 42},
      {text: t => t('1 or 2'), top: 57, bottom: 33, right: 62},
      {text: t => t('in your supply'), top: 66, bottom: 25},
      {text: t => t('or'), top: 79, bottom: 10, left: 33, right: 47}
    ],
    image: Images.Miller
  },
  {
    title: t => t('Craft Leader'),
    texts: [
      {text: t => t('before the Income phase'), bottom: 68, left: 11, right: 13},
      {text: t => t('craft.leader.1'), top: 34, bottom: 26, right: 43},
      {text: t => t('Crafting'), top: 45, bottom: 50, left: 56, right: 22, size: 0.75}
    ],
    image: Images.CraftLeader
  },
  {
    title: t => t('Ore Boatman'),
    texts: [
      {text: t => t('before the Income phase'), bottom: 67, left: 11, right: 13},
      {text: t => t('per longship'), top: 34, bottom: 46, right: 58},
      {text: t => t('ore.boatman.1'), top: 61, left: 44}
    ],
    image: Images.OreBoatman
  },
  {
    title: t => t('Milker'),
    texts: [
      {text: t => t('milker.1'), bottom: 51}
    ],
    image: Images.Milker
  },
  {
    title: t => t('Trident Hunter'),
    texts: [
      {text: t => t('Whaling'), top: 47, bottom: 45},
      {text: t => t('is worth'), top: 56, bottom: 25, left: 43},
      {text: t => t('instead of'), top: 75, bottom: 18, left: 55, size: 1.1}
    ],
    image: Images.TridentHunter
  },
  {
    title: t => t('Helmsman'),
    texts: [
      {text: t => t('used a longship'), top: 47, bottom: 32, left: 36}
    ],
    image: Images.Helmsman
  },
  {
    title: t => t('Archer'),
    texts: [
      {text: t => t('Hunting Game'), top: 46, bottom: 40}
    ],
    image: Images.Archer
  },
  {
    title: t => t('Ship Owner'),
    texts: [
      {text: t => t('if you have at least'), bottom: 70},
      {text: t => t('1 or 2 of each'), top: 53, bottom: 34},
      {text: t => t('1 or 2'), top: 69, bottom: 11, right: 52}
    ],
    image: Images.ShipOwner
  },
  {
    title: t => t('Cottager'),
    texts: [
      {text: t => t('per'), bottom: 69, right: 65, justify: 'right'},
      {text: t => t('and per'), bottom: 69, left: 60},
      {text: t => t('when building'), top: 53, bottom: 39},
      {text: t => t('or'), top: 67, bottom: 24, right: 53, justify: 'right'}
    ],
    image: Images.Cottager
  },
  {
    title: t => t('Captain'),
    texts: [
      {text: t => t('with at least'), bottom: 68},
      {text: t => t('4/5/6/7'), top: 31, bottom: 56, right: 45, justify: 'right'},
      {text: t => t('total on all'), top: 43, bottom: 32, left: 14, right: 63, justify: 'right'}
    ],
    image: Images.Captain
  },
  {
    title: t => t('Orient Shipper'),
    texts: [],
    image: Images.OrientShipper
  },
  {
    title: t => t('Fighter'),
    texts: [
      {text: t => t('fighter.1'), bottom: 41, right: 42}
    ],
    image: Images.Fighter
  },
  {
    title: t => t('Host'),
    texts: [
      {text: t => t('host.1'), bottom: 41}
    ],
    image: Images.Host
  },
  {
    title: t => t('Builder'),
    texts: [
      {text: t => t('Bonus phase'), bottom: 68},
      {text: t => t('builder.1'), top: 58, bottom: 34}
    ],
    image: Images.Builder
  },
  {
    title: t => t('Collector'),
    texts: [
      {text: t => t('Bonus phase'), bottom: 70},
      {text: t => t('collector.1'), top: 52, bottom: 36}
    ],
    image: Images.Collector
  },
  {
    title: t => t('Miner'),
    texts: [
      {text: t => t('per longship'), bottom: 64}
    ],
    image: Images.Miner
  },
  {
    title: t => t('Custodian'),
    texts: [
      {text: t => t('per'), bottom: 64},
      {text: t => t('and'), top: 46, bottom: 46}
    ],
    image: Images.Custodian
  },
  {
    title: t => t('Whaling Equipper'),
    texts: [
      {text: t => t('per knarr'), bottom: 65, right: 51},
      {text: t => t('per'), top: 62, bottom: 28, right: 51}
    ],
    image: Images.WhalingEquipper
  },
  {
    title: t => t('Meat Trader'),
    texts: [
      {text: t => t('per knarr'), bottom: 62}
    ],
    image: Images.MeatTrader
  },
  {
    title: t => t('Meat Inspector'),
    texts: [
      {text: t => t('meat.inspector.1'), bottom: 54}
    ],
    image: Images.MeatInspector
  },
  {
    title: t => t('Fisherman'),
    texts: [
      {text: t => t('per'), top: 26, bottom: 63}
    ],
    image: Images.Fisherman
  },
  {
    title: t => t('Locksmith'),
    texts: [
      {text: t => t('per'), bottom: 69},
      {text: t => t('locksmith.1'), top: 52, bottom: 32}
    ],
    image: Images.Locksmith
  },
  {
    title: t => t('Meat Preserver'),
    texts: [
      {text: t => t('per pair of'), bottom: 55},
      {text: t => t('and'), top: 60, bottom: 34, right: 51, justify: 'right'},
      {text: t => t('in your supply'), top: 66, bottom: 26}
    ],
    image: Images.MeatPreserver
  },
  {
    title: t => t('Silk Merchant'),
    texts: [
      {text: t => t('with 1/2/3'), bottom: 58},
      {text: t => t('in your supply'), top: 52, bottom: 37}
    ],
    image: Images.SilkMerchant
  },
  {
    title: t => t('Storeman'),
    texts: [
      {text: t => t('storeman.1'), top: 37, bottom: 39},
      {text: t => t('storeman.2'), top: 64, bottom: 29},
      {text: t => t('storeman.3'), top: 71, bottom: 19, right: 48, size: 1.1, justify: 'right'},
      {text: t => t('storeman.4'), top: 81, size: 1.1}
    ],
    image: Images.Storeman
  },
  {
    title: t => t('Weapons Supplier'),
    texts: [
      {text: t => t('with 1/2/3 longships'), bottom: 39, right: 52}
    ],
    image: Images.WeaponsSupplier
  },
  {
    title: t => t('Sheep Shearer'),
    texts: [
      {text: t => t('with at least 3/4/6'), top: 29, bottom: 53, right: 61},
      {text: t => t('including'), top: 49, bottom: 40, right: 52}
    ],
    image: Images.SheepShearer
  },
  {
    title: t => t('Shepherd'),
    texts: [
      {text: t => t('with at least 2'), bottom: 69},
      {text: t => t('including'), top: 46, bottom: 44, right: 47},
      {text: t => t('with at least 4 sheep,'), top: 72, bottom: 19},
      {text: t => t('1 more'), top: 81, bottom: 9, right: 51}
    ],
    image: Images.Shepherd
  },
  {
    title: t => t('Cattle Breeder'),
    texts: [
      {text: t => t('cattle.breeder.1'), bottom: 46}
    ],
    image: Images.CattleBreeder
  },
  {
    title: t => t('Ship’s Cook'),
    texts: [
      {text: t => t('before Overseas Trading'), bottom: 67, size: 1.2},
      {text: t => t('but only for'), top: 50, bottom: 39}
    ],
    image: Images.ShipsCook
  },
  {
    title: t => t('Foreign Trader'),
    texts: [
      {text: t => t('before Overseas Trading'), bottom: 63}
    ],
    image: Images.ForeignTrader
  },
  {
    title: t => t('Peacemaker'),
    texts: [
      {text: t => t('peacemaker.1'), bottom: 60},
      {text: t => t('peacemaker.2'), top: 64, right: 44}
    ],
    image: Images.Peacemaker
  },
  {
    title: t => t('Modifier'),
    texts: [
      {text: t => t('modifier.1'), top: 28, bottom: 62, left: 54, justify: 'left'},
      {text: t => t('modifier.2'), top: 72}
    ],
    image: Images.Modifier
  },
  {
    title: t => t('Master Joiner'),
    texts: [
      {text: t => t('master.joiner.1'), top: 46, bottom: 30}
    ],
    image: Images.MasterJoiner
  },
  {
    title: t => t('Slowpoke'),
    texts: [
      {text: t => t('after the Bonus phase'), bottom: 66}
    ],
    image: Images.Slowpoke
  },
  {
    title: t => t('Steward'),
    texts: [
      {text: t => t('before the Income phase'), bottom: 67, left: 11, right: 13},
      {text: t => t('to receive'), top: 77, right: 62}
    ],
    image: Images.Steward
  },
  {
    title: t => t('Fruit Picker'),
    texts: [],
    image: Images.FruitPicker
  },
  {
      title: t => t('Master Tailor'),
    texts: [],
    image: Images.MasterTailor
  },
  {
    title: t => t('Pirate'),
    texts: [],
    image: Images.Pirate
  },
  {
    title: t => t('Ship Architect'),
    texts: [],
    image: Images.ShipArchitect
  },
  {
    title: t => t('Tanner'),
    texts: [],
    image: Images.Tanner
  },
  {
    title: t => t('Farmer'),
    texts: [
      {text: t => t('or'), top: 48, bottom: 44, right: 41, justify: 'right'}
    ],
    image: Images.Farmer
  },
  {
    title: t => t('Plower'),
    texts: [
      {text: t => t('or'), top: 44, bottom: 52, right: 41, justify: 'right'},
      {text: t => t('plower.1'), top: 82, size: 1.1}
    ],
    image: Images.Plower
  },
  {
    title: t => t('Rune Engraver'),
    texts: [],
    image: Images.RuneEngraver
  },
  {
    title: t => t('Master Mason'),
    texts: [],
    image: Images.MasterMason
  },
  {
    title: t => t('Tutor'),
    texts: [],
    image: Images.Tutor
  },
  {
    title: t => t('Farm Shop Owner'),
    texts: [],
    image: Images.FarmShopOwner
  },
  {
    title: t => t('Tradesman'),
    texts: [],
    image: Images.Tradesman
  },
  {
    title: t => t('Treasurer'),
    texts: [],
    image: Images.Treasurer
  },
  {
    title: t => t('Arms Dealer'),
    texts: [],
    image: Images.ArmsDealer
  },
  {
    title: t => t('Bean Farmer'),
    texts: [],
    image: Images.BeanFarmer
  },
  {
    title: t => t('Linseed Oil Presser'),
    texts: [],
    image: Images.LinseedOilPresser
  },
  {
    title: t => t('Linen Weaver'),
    texts: [],
    image: Images.LinenWeaver
  },
  {
    title: t => t('Inspector'),
    texts: [
      {text: t => t('inspector.1'), bottom: 59},
      {text: t => t('per round'), top: 44, bottom: 42, left: 47, justify: 'left'},
      {text: t => t('(as with Emigration)'), top: 57, bottom: 36, size: 1.1},
      {text: t => t('inspector.2'), top: 72}
    ],
    image: Images.Inspector
  },
  {
    title: t => t('Wharf Owner'),
    texts: [
      {text: t => t('longship'), bottom: 37, right: 54}
    ],
    image: Images.WharfOwner
  },
  {
    title: t => t('Fine Blacksmith'),
    texts: [],
    image: Images.FineBlacksmith
  },
  {
    title: t => t('Hornturner'),
    texts: [
      {text: t => t('or'), top: 36, bottom: 59, right: 43, justify: 'right'}
    ],
    image: Images.Hornturner
  },
  {
    title: t => t('Ship Builder'),
    texts: [],
    image: Images.ShipBuilder
  },
  {
    title: t => t('Dragonslayer'),
    texts: [],
    image: Images.Dragonslayer
  },
  {
    title: t => t('Field Farmer'),
    texts: [],
    image: Images.FieldFarmer
  },
  {
    title: t => t('Private Chef'),
    texts: [],
    image: Images.PrivateChef
  },
  {
    title: t => t('Weapon Supplier'),
    texts: [],
    image: Images.WeaponSupplier
  },
  {
    title: t => t('Equipment Supplier'),
    texts: [
      {text: t => t('equipment.supplier.1'), bottom: 59}
    ],
    image: Images.EquipmentSupplier
  },
  {
    title: t => t('Courier'),
    texts: [
      {text: t => t('courier.1'), bottom: 27, left: 35},
      {text: t => t('courier.2'), top: 71, left: 26, size: 1}
    ],
    image: Images.Courier
  },
  {
    title: t => t('Innkeeper'),
    texts: [
      {text: t => t('innkeeper.1'), bottom: 57, left: 43, align: 'end'},
      {text: t => t('innkeeper.2'), top: 42, bottom: 34, align: 'start'},
      {text: t => t('innkeeper.3'), top: 66, left: 44, size: 1.1}
    ],
    image: Images.Innkeeper
  },
  {
    title: t => t('Earl'),
    texts: [
      {text: t => t('per round'), top: 29, bottom: 57, left: 44},
      {text: t => t('(as with Emigration)'), top: 42, bottom: 45}
    ],
    image: Images.Earl
  },
  {
    title: t => t('Metalsmith'),
    texts: [],
    image: Images.Metalsmith
  },
  {
    title: t => t('Cowherd'),
    texts: [],
    image: Images.Cowherd
  },
  {
    title: t => t('Bone Collector'),
    texts: [],
    image: Images.BoneCollector
  },
  {
    title: t => t('Homecomer'),
    texts: [
      {text: t => t('homecomer.1'), bottom: 50}
    ],
    image: Images.Homecomer
  },
  {
    title: t => t('Follower'),
    texts: [
      {text: t => t('follower.1'), bottom: 44},
      {text: t => t('follower.2'), top: 74, size: 1.1}
    ],
    image: Images.Follower
  },
  {
    title: t => t('Traveling Merchant'),
    texts: [
      {text: t => t('with 1/2/3/4 knarrs'), top: 25, bottom: 46, right: 40},
      {text: t => t('traveling.mechant.1'), top: 54, bottom: 27}
    ],
    image: Images.TravelingMerchant
  },
  {
    title: t => t('Breeder'),
    texts: [],
    image: Images.Breeder
  },
  {
    title: t => t('Shepherd Boy'),
    texts: [],
    image: Images.ShepherdBoy
  },
  {
    title: t => t('Quartermaster'),
    texts: [
      {text: t => t('quartermaster.1'), bottom: 55, right: 42},
      {text: t => t('quartermaster.2'), top: 47, bottom: 42},
      {text: t => t('quartermaster.3'), top: 57, bottom: 32, left: 31},
      {text: t => t('and'), top: 65, bottom: 29, left: 31},
      {text: t => t('quartermaster.4'), top: 71, bottom: 19, left: 31},
      {text: t => t('quartermaster.5'), top: 82, size: 1.1}
    ],
    image: Images.Quartermaster
  },
  {
    title: t => t('Longship Builder'),
    texts: [
      {text: t => t('longship.builder.1'), bottom: 56, right: 41},
      {text: t => t('longship.builder.2'), top: 48, bottom: 39},
      {text: t => t('longship.builder.3'), top: 72, right: 40}
    ],
    image: Images.LongshipBuilder
  },
  {
    title: t => t('Wholesaler'),
    texts: [
      {text: t => t('wholesaler.1'), top: 53}
    ],
    image: Images.Wholesaler
  },
  {
    title: t => t('Harbor Guard'),
    texts: [
      {text: t => t('1 Emigration action'), bottom: 65},
      {text: t => t('harbor.guard.1'), top: 54, bottom: 34}
    ],
    image: Images.HarborGuard
  },
  {
    title: t => t('Hornblower'),
    texts: [
      {text: t => t('1 Hunting Game action'), bottom: 47, left: 57},
      {text: t => t('1 Laying a Snare action'), top: 57, left: 57}
    ],
    image: Images.Hornblower
  },
  {
    title: t => t('Hide Buyer'),
    texts: [],
    image: Images.HideBuyer
  },
  {
    title: t => t('Preacher'),
    texts: [],
    image: Images.Preacher
  },
  {
    title: t => t('Clerk'),
    texts: [
      {text: t => t('before Overseas Trading'), bottom: 67, size: 1.2},
      {text: t => t('once per knarr'), top: 67, left: 14, right: 60, align: 'start'}
    ],
    image: Images.Clerk
  },
  {
    title: t => t('Catapulter'),
    texts: [
      {text: t => t('Raiding/Pillaging'), bottom: 67},
      {text: t => t('is worth'), top: 34, bottom: 50, left: 43},
      {text: t => t('instead of'), top: 68, bottom: 22}
    ],
    image: Images.Catapulter
  },
  {
    title: t => t('Sword Fighter'),
    texts: [
      {text: t => t('Raiding'), bottom: 69},
      {text: t => t('sword.fighter.1'), top: 32, bottom: 23, left: 43}
    ],
    image: Images.SwordFighter
  },
  {
    title: t => t('Latecomer'),
    texts: [
      {text: t => t('latecome.1'), bottom: 68},
      {text: t => t('latecome.2'), top: 52}
    ],
    image: Images.Latecomer
  },
  {
    title: t => t('Sympathetic Soul'),
    texts: [
      {text: t => t('No harvest'), bottom: 70},
      {text: t => t('sympathetic.soul.1'), top: 42, bottom: 46}
    ],
    image: Images.SympatheticSoul
  },
  {
    title: t => t('Helper in Time of Need'),
    texts: [
      {text: t => t('No harvest'), bottom: 70},
      {text: t => t('with exactly 1/2/3'), top: 43, bottom: 37, right: 47, justify: 'right'}
    ],
    image: Images.HelperInTimeOfNeed
  },
  {
    title: t => t('Spice Merchant'),
    texts: [
      {text: t => t('No harvest'), bottom: 70}
    ],
    image: Images.SpiceMerchant
  },
  {
    title: t => t('Wanderer'),
    texts: [
      {text: t => t('No harvest'), bottom: 70}
    ],
    image: Images.Wanderer
  },
  {
    title: t => t('Meat Merchant'),
    texts: [
      {text: t => t('No harvest'), bottom: 70}
    ],
    image: Images.MeatMerchant
  },
  {
    title: t => t('Pea Counter'),
    texts: [
      {text: t => t('No harvest'), bottom: 70},
      {text: t => t('pea.counter.1'), top: 43, bottom: 35},
      {text: t => t('pea.counter.2'), top: 75, size: 1.1}
    ],
    image: Images.PeaCounter
  },
  {
    title: t => t('Nobleman'),
    texts: [
      {text: t => t('nobleman.1'), top: 44, bottom: 40, right: 42}
    ],
    image: Images.Nobleman
  },
  {
    title: t => t('Preceptor'),
    texts: [
      {text: t => t('preceptor.1'), bottom: 60},
      {text: t => t('preceptor.2'), top: 61, bottom: 26}
    ],
    image: Images.Preceptor
  },
  {
    title: t => t('Undertaker'),
    texts: [
      {text: t => t('undertaker.1'), bottom: 56}
    ],
    image: Images.Undertaker
  },
  {
    title: t => t('Judge'),
    texts: [
      {text: t => t('judge.1'), top: 56, bottom: 30}
    ],
    image: Images.Judge
  },
  {
    title: t => t('Fur Merchant'),
    texts: [
      {text: t => t('instead of taking that action'), top: 50, bottom: 37, right: 39, size: 1.2}
    ],
    image: Images.FurMerchant
  },
  {
    title: t => t('Forest Blacksmith'),
    texts: [
      {text: t => t('Crafting'), top: 29, bottom: 65, left: 13, right: 71, size: 0.7},
      {text: t => t('ore.craft'), bottom: 45, left: 32},
      {text: t => t('immediately before'), top: 56, bottom: 34}
    ],
    image: Images.ForestBlacksmith
  },
  {
    title: t => t('Disheartened Warrior'),
    texts: [
      {text: t => t('disheartened.warrior.1'), bottom: 40, left: 39}
    ],
    image: Images.DisheartenedWarrior
  },
  {
    title: t => t('Knarr Builder'),
    texts: [
      {text: t => t('instead of taking that action'), top: 51, bottom: 36}
    ],
    image: Images.KnarrBuilder
  },
  {
    title: t => t('Warmonger'),
    texts: [
      {text: t => t('warmonger.1'), top: 44, bottom: 29, right: 47},
      {text: t => t('warmonger.2'), top: 72, right: 59},
      {text: t => t('warmonger.3'), top: 72, left: 55}
    ],
    image: Images.Warmonger
  },
  {
    title: t => t('Adventurer'),
    texts: [
      {text: t => t('adventurer.1'), top: 41, bottom: 44, left: 18, right: 58, size: 1},
      {text: t => t('adventurer.2'), top: 62}
    ],
    image: Images.Adventurer
  },
  {
    title: t => t('Ironsmith'),
    texts: [
      {text: t => t('Crafting'), top: 28, bottom: 67, left: 14, right: 71, size: 0.75},
      {text: t => t('ore.craft'), bottom: 48, left: 32},
      {text: t => t('return a'), top: 73, right: 58},
      {text: t => t('from there'), top: 73, left: 58}
    ],
    image: Images.Ironsmith
  },
  {
    title: t => t('Master Count'),
    texts: [
      {text: t => t('then'), top: 54, bottom: 37},
      {text: t => t('per knarr'), top: 62, bottom: 10, right: 66}
    ],
    image: Images.MasterCount
  },
  {
    title: t => t('Birka Settler'),
    texts: [
      {text: t => t('birka.settler.1'), top: 59, right: 38}
    ],
    image: Images.BirkaSettler
  },
  {
    title: t => t('Artist'),
    texts: [
      {text: t => t('Crafting'), top: 27, bottom: 67, left: 60, right: 18, size: 0.75},
      {text: t => t('artist.1'), top: 60, right: 44}
    ],
    image: Images.Artist
  },
  {
    title: t => t('Barkeep'),
    texts: [
      {text: t => t('Crafting'), top: 27, bottom: 67, left: 61, right: 18, size: 0.75}
    ],
    image: Images.Barkeep
  },
  {
    title: t => t('Apprentice Craftsman'),
    texts: [
      {text: t => t('Crafting'), top: 27, bottom: 67, left: 60, right: 19, size: 0.75},
      {text: t => t('before taking the action'), top: 60, bottom: 32, left: 11, right: 13}
    ],
    image: Images.ApprenticeCraftsman
  },
  {
    title: t => t('Silk Stitcher'),
    texts: [
      {text: t => t('Crafting'), top: 25, bottom: 70, left: 60, right: 18, size: 0.75},
      {text: t => t('then'), top: 54, bottom: 40, left: 16}
    ],
    image: Images.SilkStitcher
  },
  {
    title: t => t('Princess'),
    texts: [
      {text: t => t('Build Houses'), top: 36, bottom: 58, left: 53, right: 19, size: 0.75}
    ],
    image: Images.Princess
  },
  {
    title: t => t('Master Bricklayer'),
    texts: [
      {text: t => t('Build Houses'), top: 36, bottom: 58, left: 53, right: 19, size: 0.75},
      {text: t => t('fewer'), top: 79}
    ],
    image: Images.MasterBricklayer
  },
  {
    title: t => t('Shipowner'),
    texts: [
      {text: t => t('built'), top: 56, bottom: 37},
      {text: t => t('(but not bought)'), top: 63, bottom: 31, size: 1.1}
    ],
    image: Images.Shipowner
  },
  {
    title: t => t('Messenger'),
    texts: [
      {text: t => t('Weekly market'), top: 34, bottom: 59, left: 55, right: 19, size: 0.75, lineHeight: 1}
    ],
    image: Images.Messenger
  },
  {
    title: t => t('Milkman'),
    texts: [
      {text: t => t('Weekly market'), top: 34, bottom: 59, left: 55, right: 19, size: 0.75, lineHeight: 1}
    ],
    image: Images.Milkman
  },
  {
    title: t => t('Middleman'),
    texts: [
      {text: t => t('Weekly market'), top: 34, bottom: 59, left: 55, right: 19, size: 0.75, lineHeight: 1},
      {text: t => t('then'), top: 56 , bottom: 34}
    ],
    image: Images.Middleman
  },
  {
    title: t => t('Carpenter'),
    texts: [
      {text: t => t('Build Houses'), top: 25, bottom: 70, left: 55, right: 19, size: 0.75},
      {text: t => t('Build Ships'), top: 40, bottom: 56, left: 55, right: 19, size: 0.75},
      {text: t => t('before taking the action'), top: 57, bottom: 33, left: 11, right: 13}
    ],
    image: Images.Carpenter
  },
  {
    title: t => t('Mountain Guard'),
    texts: [
      {text: t => t('different goods taken'), top: 47, bottom: 40}
    ],
    image: Images.MountainGuard
  },
  {
    title: t => t('Oil Seller'),
    texts: [
      {text: t => t('then'), top: 61, bottom: 30, right: 63}
    ],
    image: Images.OilSeller
  },
  {
    title: t => t('Laborer'),
    texts: [
      {text: t => t('laborer.1'), bottom: 64}
    ],
    image: Images.Laborer
  },
  {
    title: t => t('Scribe'),
    texts: [
      {text: t => t('scribe.1'), bottom: 61}
    ],
    image: Images.Scribe
  },
  {
    title: t => t('Trapper'),
    texts: [
      {text: t => t('succeeded Laying a Snare'), bottom: 36, left: 39, right: 15}
    ],
    image: Images.Trapper
  },
  {
    title: t => t('Angler'),
    texts: [
      {text: t => t('succeeded Laying a Snare'), bottom: 36, left: 39, right: 15}
    ],
    image: Images.Angler
  },
  {
    title: t => t('Digger'),
    texts: [
      {text: t => t('succeeded Laying a Snare'), bottom: 36, left: 39, right: 15}
    ],
    image: Images.Digger
  },
  {
    title: t => t('Meat Curer'),
    texts: [
      {text: t => t('succeeded Laying a Snare'), bottom: 36, left: 39, right: 15}
    ],
    image: Images.MeatCurer
  },
  {
    title: t => t('Snare Specialist'),
    texts: [
      {text: t => t('succeeded Laying a Snare'), bottom: 64, left: 37, right: 13},
      {text: t => t('return a'), top: 76, right: 58},
      {text: t => t('from there'), top: 76, left: 58}
    ],
    image: Images.SnareSpecialist
  },
  {
    title: t => t('Bait Layer'),
    texts: [
      {text: t => t('Laying a Snare'), bottom: 50, left: 43}
    ],
    image: Images.BaitLayer
  },
  {
    title: t => t('Berserker'),
    texts: [
      {text: t => t('Pillaging'), bottom: 68},
      {text: t => t('berserker.1'), top: 32, bottom: 30, left: 42},
      {text: t => t('berserker.2'), top: 69, bottom: 24}
    ],
    image: Images.Berserker
  },
  {
    title: t => t('Lance Bearer'),
    texts: [
      {text: t => t('succeeded Raiding'), bottom: 41, left: 39, right: 15}
    ],
    image: Images.LanceBearer
  },
  {
    title: t => t('Drunkard'),
    texts: [
      {text: t => t('failed Raiding'), bottom: 41, left: 39, right: 15}
    ],
    image: Images.Drunkard
  },
  {
    title: t => t('Lone Wolf'),
    texts: [
      {text: t => t('lone.wolf.1'), bottom: 54, left: 39, right: 15},
      {text: t => t('using exactly 1'), top: 52, bottom: 34, right: 37}
    ],
    image: Images.LoneWolf
  },
  {
    title: t => t('Raider'),
    texts: [
      {text: t => t('Raiding/Pillaging'), bottom: 61},
      {text: t => t('raider.1'), top: 61}
    ],
    image: Images.Raider
  },
  {
    title: t => t('Melee Fighter'),
    texts: [
      {text: t => t('Raiding/Pillaging'), bottom: 50, left: 40},
      {text: t => t('melee.fighter.1'), top: 51, bottom: 28}
    ],
    image: Images.MeleeFighter
  },
  {
    title: t => t('Robber'),
    texts: [
      {text: t => t('Raiding/Pillaging'), bottom: 66},
      {text: t => t('robber.1'), top: 35, bottom: 37},
      {text: t => t('robber.2'), top: 59, right: 31}
    ],
    image: Images.Robber
  },
  {
    title: t => t('Loot Hunter'),
    texts: [
      {text: t => t('Raiding/Pillaging'), bottom: 66},
      {text: t => t('loot.hunter.1'), top: 35, bottom: 43},
      {text: t => t('is reduced'), top: 76, right: 51}
    ],
    image: Images.LootHunter
  },
  {
    title: t => t('Blubber Cook'),
    texts: [
      {text: t => t('succeeded Whaling'), bottom: 36, left: 39, right: 15},
      {text: t => t('1 more'), top: 77}
    ],
    image: Images.BlubberCook
  },
  {
    title: t => t('Ivory Sculptor'),
    texts: [
      {text: t => t('ivory.sculptor.1'), bottom: 46, left: 35},
      {text: t => t('with exactly 1/2/3'), top: 55, bottom: 29, right: 41, justify: 'right'}
    ],
    image: Images.IvorySculptor
  },
  {
    title: t => t('Whale Catcher'),
    texts: [
      {text: t => t('succeeded Whaling'), bottom: 54, left: 37},
      {text: t => t('using exactly 1'), top: 54, bottom: 32, right: 37}
    ],
    image: Images.WhaleCatcher
  },
  {
    title: t => t('Antlers Seller'),
    texts: [
      {text: t => t('succeeded Hunting Game'), bottom: 40, left: 42, right: 15}
    ],
    image: Images.AntlersSeller
  },
  {
    title: t => t('Deerstalker'),
    texts: [
      {text: t => t('succeeded Hunting Game'), bottom: 40, left: 42, right: 15}
    ],
    image: Images.Deerstalker
  },
  {
    title: t => t('Forester'),
    texts: [
      {text: t => t('Hunting Game'), bottom: 43, left: 40},
      {text: t => t('before taking the action'), top: 57, bottom: 34, left: 11, right: 13}
    ],
    image: Images.Forester
  },
  {
    title: t => t('Sledger'),
    texts: [
      {text: t => t('Hunting Game'), bottom: 64, right: 58},
      {text: t => t('or'), top: 36, bottom: 54, left: 40, right: 40},
      {text: t => t('Laying a Snare'), bottom: 64, left: 58}
    ],
    image: Images.Sledger
  },
  {
    title: t => t('Steersman'),
    texts: [
      {text: t => t('steersman.1'), bottom: 48, left: 36},
      {text: t => t('steersman.2'), top: 54, right: 35}
    ],
    image: Images.Steersman
  },
  {
    title: t => t('Spice Trader'),
    texts: [
      {text: t => t('built a knarr'), bottom: 58, left: 50, right: 19, align: 'end'},
      {text: t => t('(but not bought)'), top: 42, left: 50, right: 19, size: 1.1, align: 'start'}
    ],
    image: Images.SpiceTrader
  },
  {
    title: t => t('Priest'),
    texts: [
      {text: t => t('before taking the action'), top: 62, bottom: 25}
    ],
    image: Images.Priest
  },
  {
    title: t => t('Meat Buyer'),
    texts: [
      {text: t => t('instead of'), bottom: 71},
      {text: t => t('instead of'), top: 58, bottom: 34}
    ],
    image: Images.MeatBuyer
  },
  {
    title: t => t('Thing Spokesman'),
    texts: [
      {text: t => t('thing.spokesman.1'), bottom: 69},
      {text: t => t('thing.spokesman.2'), top: 63, bottom: 24}
    ],
    image: Images.ThingSpokesman
  },
  {
    title: t => t('Merchant'),
    texts: [
      {text: t => t('merchant.1'), top: 56, bottom: 31},
      {text: t => t('instead of'), top: 70, bottom: 24, left: 59, size: 1.1}
    ],
    image: Images.Merchant
  },
  {
    title: t => t('Proficient Hunter'),
    texts: [
      {text: t => t('when rolling'), bottom: 65},
      {text: t => t('proficient.hunter.1'), top: 59}
    ],
    image: Images.ProficientHunter
  },
  {
    title: t => t('Woodcutter'),
    texts: [
      {text: t => t('woodcutter.1'), bottom: 60},
      {text: t => t('you also receive'), top: 52, bottom: 37}
    ],
    image: Images.Woodcutter
  },
  {
    title: t => t('Cooper'),
    texts: [
      {text: t => t('after a Viking action that provided at least'), bottom: 63}
    ],
    image: Images.Cooper
  },
  {
    title: t => t('Stone Crusher'),
    texts: [
      {text: t => t('stone.crusher.1'), bottom: 60},
      {text: t => t('you also receive'), top: 53, bottom: 36}
    ],
    image: Images.StoneCrusher
  },
  {
    title: t => t('Skinner'),
    texts: [
      {text: t => t('Feast'), bottom: 71},
      {text: t => t('per placed animal'), top: 49, bottom: 44},
      {text: t => t('or'), top: 51, bottom: 31, left: 41, right: 41}
    ],
    image: Images.Skinner
  },
  {
    title: t => t('Barbarian'),
    texts: [
      {text: t => t('Feast'), bottom: 71},
      {text: t => t('for each placed'), top: 46, bottom: 47}
    ],
    image: Images.Barbarian
  },
  {
    title: t => t('Festive Hunter'),
    texts: [
      {text: t => t('festive.hunter.1'), bottom: 64},
      {text: t => t('festive.hunter.2'), top: 68, left: 36}
    ],
    image: Images.FestiveHunter
  },
  {
    title: t => t('Sober Man'),
    texts: [
      {text: t => t('sober.man.1'), bottom: 68}
    ],
    image: Images.SoberMan
  },
  {
    title: t => t('Whaling Assistant'),
    texts: [
      {text: t => t('whaling.assistant.1'), bottom: 27, right: 45}
    ],
    image: Images.WhalingAssistant
  },
  {
    title: t => t('Flax Farmer'),
    texts: [
      {text: t => t('flax.farmer.1'), bottom: 27, right: 44}
    ],
    image: Images.FlaxFarmer
  },
  {
    title: t => t('Farmhand'),
    texts: [
      {text: t => t('farmhand.1'), top: 52, bottom: 25}
    ],
    image: Images.Farmhand
  },
  {
    title: t => t('Armed Fighter'),
    texts: [
      {text: t => t('armed.fighter.1'), top: 35, bottom: 53},
      {text: t => t('before taking the action'), top: 48, bottom: 39},
      {text: t => t('armed.fighter.2'), top: 78, size: 1.1}
    ],
    image: Images.ArmedFighter
  },
  {
    title: t => t('Village Leader'),
    texts: [
      {text: t => t('before the Feast'), bottom: 70},
      {text: t => t('with at least 1'), top: 49, bottom: 33, right: 57},
      {text: t => t('in your supply'), top: 67, bottom: 26}
    ],
    image: Images.VillageLeader
  },
  {
    title: t => t('Weapons Warden'),
    texts: [
      {text: t => t('weapons.warden.1'), bottom: 57, left: 39},
      {text: t => t('weapons.warden.2'), top: 43, bottom: 42, left: 24, size: 1.1},
      {text: t => t('before taking the action'), top: 59, bottom: 29}
    ],
    image: Images.WeaponsWarden
  },
  {
    title: t => t('Taster'),
    texts: [
      {text: t => t('before the Feast'), bottom: 71},
      {text: t => t('taster.1'), top: 49, bottom: 32, right: 53},
      {text: t => t('in your supply'), top: 66, bottom: 24},
      {text: t => t('or'), top: 78, left: 33, right: 48}
    ],
    image: Images.Taster
  },
  {
    title: t => t('Earl of Lade'),
    texts: [
      {text: t => t('earl.of.lade.1'), bottom: 52}
    ],
    image: Images.EarlOfLade
  },
  {
    title: t => t('Sower'),
    texts: [
      {text: t => t('sower.1'), top: 33, bottom: 59},
      {text: t => t('in your supply'), top: 59, bottom: 34},
      {text: t => t('sower.2'), top: 87, bottom: 7, size: 1.1}
    ],
    image: Images.Sower
  },
  {
    title: t => t('Refugee Helper'),
    texts: [
      {text: t => t('Emigration'), bottom: 63},
      {text: t => t('less required'), top: 81}
    ],
    image: Images.RefugeeHelper
  },
  {
    title: t => t('Flax Baker'),
    texts: [
      {text: t => t('flax.baker.1'), bottom: 62}
    ],
    image: Images.FlaxBaker
  },
  {
    title: t => t('Fish Cook'),
    texts: [
      {text: t => t('fish.cook.1'), bottom: 64, right: 45},
      {text: t => t('fish.cook.2'), top: 57, bottom: 28}
    ],
    image: Images.FishCook
  },
  {
    title: t => t('Punchcutter'),
    texts: [
      {text: t => t('punchcutter.1'), bottom: 57}
    ],
    image: Images.Punchcutter
  },
  {
    title: t => t('Bosporus Traveler'),
    texts: [
      {text: t => t('in your cattle stable'), bottom: 52, left: 54}
    ],
    image: Images.BosporusTraveller
  },
  {
    title: t => t('Codliver Oil Presser'),
    texts: [
      {text: t => t('codliver.oil.presser.1'), bottom: 63},
      {text: t => t('you may also'), top: 46, bottom: 46}
    ],
    image: Images.CodliverOilPresser
  },
  {
    title: t => t('Etiquette Teacher'),
    texts: [
      {text: t => t('etiquette.teacher.1'), bottom: 55}
    ],
    image: Images.EtiquetteTeacher
  },
  {
    title: t => t('Maid'),
    texts: [
      {text: t => t('maid.1'), top: 33, bottom: 43},
      {text: t => t('maid.2'), top: 57, bottom: 28}
    ],
    image: Images.Maid
  },
  {
    title: t => t('Boat Builder'),
    texts: [
      {text: t => t('boat.builder.1'), bottom: 65}
    ],
    image: Images.BoatBuilder
  },
  {
    title: t => t('Cutter Operator'),
    texts: [
      {text: t => t('cutter.operator.1'), bottom: 43, left: 43, right: 15},
      {text: t => t('per knarr'), top: 58, right: 63}
    ],
    image: Images.CutterOperator
  },
  {
    title: t => t('Beach Raider'),
    texts: [
      {text: t => t('beach.raider.1'), bottom: 48, left: 41},
      {text: t => t('total.income'), top: 65, bottom: 21},
      {text: t => t('max.'), top: 78, right: 67, justify: 'right'}
    ],
    image: Images.BeachRaider
  },
  {
    title: t => t('Sail Patcher'),
    texts: [
      {text: t => t('sail.patcher.1'), bottom: 50, left: 52},
      {text: t => t('total.income'), top: 67, bottom: 20},
      {text: t => t('max.'), top: 79, right: 61, justify: 'right'}
    ],
    image: Images.SailPatcher
  },
  {
    title: t => t('Herb Gardener'),
    texts: [
      {text: t => t('herb.gardener.1'), bottom: 63}
    ],
    image: Images.HerbGardener
  },
  {
    title: t => t('Grain Deliveryman'),
    texts: [
      {text: t => t('with 1/2/3/4 knarrs'), bottom: 47, right: 47},
      {text: t => t('grain.deliveryman.1'), top: 51, bottom: 36}
    ],
    image: Images.GrainDeliveryman
  },
  {
    title: t => t('Sponsor'),
    texts: [
      {text: t => t('sponsor.1'), top: 24, bottom: 66, right: 50, justify: 'right'},
      {text: t => t('sponsor.2'), top: 34, bottom: 58},
      {text: t => t('sponsor.3'), top: 66, bottom: 23},
      {text: t => t('place 3'), top: 74, bottom: 17, right: 39, justify: 'right'},
      {text: t => t('immediately'), top: 80}
    ],
    image: Images.Sponsor
  },
  {
    title: t => t('Dorestad Traveller'),
    texts: [
      {text: t => t('Raiding'), bottom: 44, left: 38},
      {text: t => t('instead of Raiding'), top: 56, bottom: 33}
    ],
    image: Images.DorestadTraveller
  },
  {
    title: t => t('Pea Flour Baker'),
    texts: [
      {text: t => t('Feast'), bottom: 71},
      {text: t => t('place up to 2'), top: 50, bottom: 42, right: 37, justify: 'right'},
      {text: t => t('pea.flour.baker.1'), top: 58, bottom: 28}
    ],
    image: Images.PeaFlourBaker
  },
  {
    title: t => t('Chronicler'),
    texts: [
      {text: t => t('chronicler.1'), top: 57, bottom: 30, right: 56, size: 1.1, align: 'start'}
    ],
    image: Images.Chronicler
  },
  {
    title: t => t('Mineworker'),
    texts: [
      {text: t => t('mineworker.1'), bottom: 62},
      {text: t => t('taken from a mountain strip'), top: 62, bottom: 21, left: 43},
      {text: t => t('per'), top: 80, left: 36, right: 50}
    ],
    image: Images.Mineworker
  },
  {
    title: t => t('Seafarer'),
    texts: [
      {text: t => t('seafarer.1'), bottom: 61},
      {text: t => t('seafarer.2'), top: 39, bottom: 42}
    ],
    image: Images.Seafarer
  },
  {
    title: t => t('Bosporus Merchant'),
    texts: [
      {text: t => t('received in your supply'), top: 47, bottom: 35}
    ],
    image: Images.BosporusMerchant
  }
]

export default OccupationCardsDetails