import {hasKnarr} from '../../material/boats/LandingStages'
import Good, {craftProducts, isGood} from '../../material/goods/Good'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import ActionRules from '../ActionRules'
import PendingAction from '../../effects/PendingAction'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import UpgradeGoodRules from '../mountainsAndTrade/UpgradeGoodRules'

export default abstract class TradeOverseasRules extends ActionRules {
  get cost() {
    return Good.Silver
  }

  canUseEffect() {
    return super.canUseEffect() && hasKnarr(this.player)
  }

  createPendingAction(): PendingAction {
    return {action: this.action, goodsTraded: []}
  }

  delegate() {
    return new UpgradeGoodRules(this.game, this.player, 1, craftProducts.filter(good => !this.goodsTraded.includes(good)))
  }

  get goodsTraded() {
    return this.pendingAction.goodsTraded!
  }

  getPlayerMoves() {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color && isGood(move.goods)) {
          this.pendingAction.goodsTraded!.push(move.goods)
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
    }
    return consequences
  }
}
