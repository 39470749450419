import Action from '../Action'
import Good from '../../material/goods/Good'
import ProduceRules from '../market/ProduceRules'

export default class CraftChestsAndRuneStonesRules extends ProduceRules {
  action = Action.CraftChestsAndRuneStones

  get cost() {
    return {[Good.Stone]: 2, [Good.Wood]: 2}
  }

  goods = {[Good.Chest]: 2, [Good.RuneStone]: 2}
}