import {Goods} from '../material/goods/Good'
import EffectRules from '../effects/EffectRules'
import {spendGoodsMove} from '../moves/SpendGoods'
import PendingAction from '../effects/PendingAction'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import MoveType from '../moves/MoveType'
import {changeCurrentPlayerMove} from '../moves/ChangeCurrentPlayer'
import Action from './Action'
import Game from '../Game'
import GameView from '../GameView'
import MoveRandomized from '../moves/MoveRandomized'
import OccupationsRules from '../material/occupations/OccupationsRules'
import {getPendingAction, playerHasGoods} from '../AFeastForOdin'
import {isExtractToPayActionRules} from '../material/occupations/ExtractToPayActionRules'

export default abstract class ActionRules extends EffectRules {
  constructor(game: Game | GameView, player = game.players.find(p => p.color === game.currentPlayer)!) {
    super(game, player)
  }

  abstract get action(): Action

  getVikingsCost(): number {
    return this.action % 5
  }

  get cost(): Goods | undefined {
    return undefined
  }

  get pendingAction(): PendingAction {
    return getPendingAction(this.game) ?? this.createPendingAction()
  }

  createPendingAction(): PendingAction {
    return {action: this.action}
  }

  canUseEffect(): boolean {
    const cost = this.cost
    return !cost || playerHasGoods(this.player, cost)
      || this.player.occupations
        .map(occupation => new OccupationsRules[occupation](this.game, this.player))
        .filter(isExtractToPayActionRules)
        .some(rules => rules.isEligibleAction(this.action) && rules.stripsToExtractMissingGoods(cost, this.action).length > 0)
  }

  getSilverDiscount(cost: number): number {
    return this.player.occupations.map(occupation => new OccupationsRules[occupation](this.game, this.player))
      .reduce((sum, rules) => rules.actionSilverDiscount ? sum + rules.actionSilverDiscount(this.action, cost) : sum, 0)
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.cost && !this.pendingAction.costPaid) {
      return [spendGoodsMove(this.player.color, this.cost)]
    } else if (this.pendingAction.complete) {
      return [changeCurrentPlayerMove]
    } else {
      return []
    }
  }

  play(move: MoveRandomized | MoveView) {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          this.pendingAction.costPaid = true
        }
        break
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.pendingAction.goodsReceived = true
        }
        break
    }
    return consequences
  }

  complete() {
    this.pendingAction.complete = true
  }
}
