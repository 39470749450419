/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good, {farmProducts, isCraftProduct, isFarmProduct, isLuxuryGood, resourceGoods, standardGoods} from '@gamepark/a-feast-for-odin/material/goods/Good'
import {specialTiles} from '@gamepark/a-feast-for-odin/material/goods/SpecialTile'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import {shipsCooksGoods} from '@gamepark/a-feast-for-odin/material/occupations/ShipsCook'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import useEfficientDragLayer from '@gamepark/react-components/dist/Draggable/useEfficientDragLayer'
import {useState} from 'react'
import DraggableGood, {GoodDragItem} from '../../goods/DraggableGood'
import Images from '../../images/Images'
import PlayerLocal from '../../PlayerLocal'
import {buttonReset, greyBackground, lightBackground, lighterBackground, squareSize} from '../../styles'
import GoodWithQuantity from './GoodWithQuantity'
import GameView from '@gamepark/a-feast-for-odin/GameView'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {isMountainsAndTradeRules} from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'
import {getActionRules, getPendingAction, playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {displayPlayerGoodsMove} from './DisplayPlayerGoods'

type Props = {
  game: GameView
  player: PlayerLocal
}

enum GoodType { STANDARD, SPECIAL}

export default function PlayerGoods({game, player}: Props) {
  const goods = player.goods
  const playerId = usePlayerId<PlayerColor>()
  const draggingGood = useEfficientDragLayer(monitor =>
    monitor.getItemType() === 'Good' && monitor.isDragging() ?
      (monitor.getItem() as GoodDragItem).orientedGood.good
      : undefined)
  const [goodType, setGoodType] = useState<GoodType>(GoodType.STANDARD)
  const play = usePlay()
  return (
    <>
      <div css={[darkBackground, draggingGood && hidden]} onClick={() => play(displayPlayerGoodsMove(player.color, false), {local: true})}/>
      {resourceGoods.map(good =>
        <GoodWithQuantity key={`${good}x${goods[good] ?? 0}`} good={good} quantity={goods[good]} draggable={player.color === playerId}
                          draggingGood={draggingGood}/>
      )}
      {goodType === GoodType.STANDARD && standardGoods.map(good =>
        <GoodWithQuantity key={`${good}x${goods[good] ?? 0}`} good={good} quantity={goods[good]}
                          draggable={player.color === playerId} draggingGood={draggingGood}
                          upgrade={player.color === playerId ? getUpgradeLevel(game, player, good) : 0}/>
      )}
      {goodType === GoodType.SPECIAL && specialTiles.filter(good => goods[good]).map((good, index) =>
        <DraggableGood key={good} good={good} css={specialTilePosition(index % 8)}/>
      )}
      <div css={buttonsBar}>
        <button css={[buttonReset, buttonCss, goodType === GoodType.STANDARD && selected]}
                onClick={() => setGoodType(GoodType.STANDARD)}>
          <Picture src={Images.goodsBox}/>
          <span>x{standardGoods.reduce((sum, good) => sum + (goods[good] ?? 0), 0)}</span>
        </button>
        <button css={[buttonReset, buttonCss, goodType === GoodType.SPECIAL && selected]}
                onClick={() => setGoodType(GoodType.SPECIAL)}>
          <Picture src={Images.specialTilesSupply} css={specialTilesButtonImg}/>
          <span>x{specialTiles.reduce((sum, good) => sum + (goods[good] ?? 0), 0)}</span>
        </button>
      </div>
    </>
  )
}

const darkBackground = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2em;

  &:before {
    content: '';
    position: absolute;
    top: -100em;
    bottom: -100em;
    left: -100em;
    right: -100em;
  }
`

const hidden = css`
  display: none;
`

const buttonsBar = css`
  position: absolute;
  top: 75em;
  left: 110em;
`

const buttonCss = css`
  position: relative;
  width: 15em;
  height: 12em;
  overflow: hidden;
  background: ${greyBackground};
  border: 0.1em solid black;
  padding: 1em;

  img {
    width: 100%;
    height: 100%;
  }

  > span {
    position: absolute;
    bottom: 0.25em;
    right: 0.25em;
    color: white;
    font-size: 4em;
    filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
  }

  &:hover {
    background: ${lighterBackground};
  }

  &:first-of-type {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }

  &:last-of-type {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
`

const selected = css`
  background: ${lightBackground};
  cursor: auto;
`

const specialTilesButtonImg = css`
  border-radius: 50%;
`

const specialTilePosition = (index: number) => css`
  position: absolute;
  top: ${Math.floor(index / 4) * squareSize * 6 + 12}em;
  left: ${Math.floor(index % 4) * squareSize * 6 + 12}em;
`

function getUpgradeLevel(game: GameView, player: PlayerLocal, good: Good): number {
  if (!player.goods[good]) return 0
  if (isLuxuryGood(good)) {
    if (player.effects.length > 0 && player.effects[0].occupation === Occupation.TravelingMerchant) return -1
    else return 0
  }
  if (player.effects.length > 0) {
    const effect = player.effects[0]
    switch (effect.occupation) {
      case Occupation.ForeignTrader:
      case Occupation.Shipowner:
        return 1
      case Occupation.Merchant:
        return 2
      case Occupation.OrientShipper:
        return 3
      case Occupation.Middleman:
        return playerHasGoods(player, Good.Silver) ? 1 : 0
      case Occupation.ShipsCook:
        return shipsCooksGoods.includes(good) ? 1 : 0
      case Occupation.FarmShopOwner:
        return farmProducts.includes(good) ? 1 : 0
      case Occupation.FieldFarmer:
        return isFarmProduct(good) && playerHasGoods(player, Good.Silver) ? 22 : 0
      case Occupation.TravelingMerchant:
        return isFarmProduct(good) || isCraftProduct(good) || good === Good.Sheep || good === Good.Cattle ? 1 : -1
      case Occupation.Wholesaler:
        if (!effect.traded.length) return 1
        switch (effect.traded[0]) {
          case Good.Sheep:
          case Good.PregnantSheep:
            return good === Good.Sheep || good === Good.PregnantSheep ? 1 : 0
          case Good.Cattle:
          case Good.PregnantCattle:
            return good === Good.Cattle || good === Good.PregnantCattle ? 1 : 0
          default:
            return effect.traded[0] === good ? 1 : 0
        }
      case Occupation.Clerk:
        return good > 20 && playerHasGoods(player, {[Good.Silver]: 2}) ? -9 : 0
      case Occupation.DorestadTraveller:
        if (!effect.traded.length) return 1
        return playerHasGoods(player, {[good]: effect.traded.reduce<number>((sum, e) => e === good ? sum + 1 : sum, 1)}) ? 1 : 0
      default:
        return 0
    }
  }
  const pendingAction = getPendingAction(game)
  if (player.color === game.currentPlayer && pendingAction) {
    switch (pendingAction.action) {
      case Action.TradeOverseas1:
      case Action.TradeOverseas2:
        if (isCraftProduct(good) && !pendingAction.goodsTraded!.includes(good)) {
          return 1
        }
        break
      default:
        const actionRules = getActionRules(game)!
        if (isMountainsAndTradeRules(actionRules) && pendingAction.goodsTraded
          && playerHasGoods(player, {[good]: pendingAction.goodsTraded.reduce<number>((sum, e) => e === good ? sum + 1 : sum, 1)})) {
          return actionRules.nextTrade
        }
    }
  }
  return 0
}
