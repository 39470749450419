/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import {hasStageForLargeShip} from '@gamepark/a-feast-for-odin/material/boats/LandingStages'

type Props = {
  player: PlayerLocal
  cost: number
}

export default function LongshipDiscountChoice({player, cost}: Props) {
  return <>
    <p>
      <Trans defaults="longship.discount.effect" values={{cost}} components={[
        <PayButton player={player} goods={{[Good.Silver]: cost}}
                   disabled={!playerHasGoods(player, {[Good.Silver]: cost}) || !hasStageForLargeShip(player)}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
