import {playerHasGoods} from '../../AFeastForOdin'
import Good, {farmProducts} from '../goods/Good'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {spendGoodsMove} from '../../moves/SpendGoods'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'

export default class FarmShopOwner extends UpgradeGoodOccupationRules {
  get upgradableGoods() {
    return farmProducts
  }

  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, Good.Silver) && farmProducts.some(good => playerHasGoods(this.player, good))
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.FarmShopOwner})
    return [spendGoodsMove(this.player.color, Good.Silver)]
  }
}
