import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'

export default class Slowpoke extends TradeOccupationRules {
  trades = [{spend: Good.Silver, receive: Good.Oil}, {spend: Good.Mead, receive: Good.Oil}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.Mountain) {
      this.player.effects.unshift({occupation: Occupation.Slowpoke})
    }
    return []
  }
}
