import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {getActionRules} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Drunkard extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.DeclareFailure && move.player === this.player.color) {
      const rules = getActionRules(this.game)
      if (rules?.player.color === this.player.color && rules.action === Action.Raiding) {
        return [receiveGoodsMove(this.player.color, Good.Mead)]
      }
    }
    return []
  }
}
