import Boat from './Boat'
import BoatType from './BoatType'

type Knarr = {
  type: BoatType.Knarr
}

export function isKnarr(boat: Boat | null): boat is Knarr {
  return boat?.type === BoatType.Knarr
}

export default Knarr