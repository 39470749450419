import {isLongship} from '../../material/boats/Longship'
import Good from '../../material/goods/Good'
import ProduceRules from '../market/ProduceRules'
import Action from '../Action'
import Occupation from '../../material/Occupation'
import Warmonger from '../../material/occupations/Warmonger'

export default class PlunderingRules extends ProduceRules {
  action = Action.Plundering
  goods = Good.SilverHoard

  canUseEffect() {
    if (this.player.occupations.includes(Occupation.Steersman)) {
      return this.player.landingStages.longBoats.filter(boat => boat !== null).length >= 2
    }
    return this.player.landingStages.longBoats.filter(isLongship).length >= 2
  }

  getVikingsCost(): number {
    if (this.player.occupations.includes(Occupation.Warmonger)) {
      return super.getVikingsCost() - new Warmonger(this.game, this.player).getDiscount()
    }
    return super.getVikingsCost()
  }
}
