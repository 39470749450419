import Good from '../goods/Good'
import {_1, __, PlacementSpace, XX} from '../goods/PlacementSpace'

const hi: PlacementSpace = {bonus: [Good.Hide]}

export const stoneHouse: PlacementSpace[][] = [
  [XX, __, _1, XX, XX],
  [_1, __, hi, _1, XX],
  [XX, _1, __, __, _1],
  [XX, XX, _1, __, _1]
]

export const stoneHouseExtraSpaces: PlacementSpace[] = [{requiredGood: Good.Wood, malus: -1}, {requiredGood: Good.Stone, malus: -1}]