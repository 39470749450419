/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {Picture, PictureAttributes} from '@gamepark/react-components'
import Images from '../images/Images'
import {squareSize} from '../styles'

type Props = {
  color: PlayerColor
} & Omit<PictureAttributes, 'color'>

export default function Viking({color, ...props}: Props) {
  return <Picture src={vikingsImage[color]} alt="A viking" css={style} {...props}/>
}

const vikingsImage: Record<PlayerColor, string> = {
  [PlayerColor.Blue]: Images.blueViking,
  [PlayerColor.Red]: Images.redViking,
  [PlayerColor.Black]: Images.blackViking,
  [PlayerColor.Yellow]: Images.yellowViking
}

const vikingRatio = 103 / 169

const style = css`
  position: absolute;
  width: ${squareSize}em;
  height: ${squareSize / vikingRatio}em;
  filter: drop-shadow(0 0 0.2em black) drop-shadow(0 0 0.2em black) drop-shadow(0 0 0.2em black);
`
