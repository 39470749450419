/** @jsxImportSource @emotion/react */
import {usePlay, usePlayerId} from '@gamepark/react-client'
import GameLocalView from '../GameLocalView'
import PlayerPanel from '../player/PlayerPanel'
import ActionBoardMenu from './ActionBoardMenu'
import {displayScreenMove, getScreen} from './DisplayScreen'
import ExplorationMenu from './ExplorationMenu'
import MountainsMenu from './MountainsMenu'
import RoundMenu from './RoundMenu'
import {getPlayerScreen} from './Screen'

type Props = {
  game: GameLocalView
}

export default function NavigationMenu({game}: Props) {
  const playerId = usePlayerId()
  const screen = getScreen(game, playerId)
  const play = usePlay()
  return <>
    <RoundMenu game={game}/>
    <ActionBoardMenu game={game}/>
    <ExplorationMenu game={game}/>
    <MountainsMenu game={game}/>
    {game.players.map((player, index) => {
      const playerScreen = getPlayerScreen(player.color)
      return <PlayerPanel key={player.color} player={player} index={index} selected={screen === playerScreen}
                          onClick={() => play(displayScreenMove(playerScreen), {local: true})}/>
    })}
  </>
}
