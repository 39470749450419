import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import PlayerColor from '../../PlayerColor'
import {getPendingAction, playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {chooseMountainStripMove} from '../../moves/ChooseMountainStrip'
import {spendGoodsMove} from '../../moves/SpendGoods'
import ExtractToPayActionRules from './ExtractToPayActionRules'
import Effect from '../../effects/Effect'

export default class ForestBlacksmith extends ExtractToPayActionRules {
  effect: Effect = {occupation: Occupation.ForestBlacksmith}

  isEligibleAction(action: Action): boolean {
    switch (action) {
      case Action.CraftChest:
        return playerHasGoods(this.player, Good.Ore) || this.stripsToExtractMissingGoods(Good.Ore, action).length > 0
      case Action.Smithy:
        return true
    }
    return false
  }

  onChooseEligibleAction(action: Action) {
    if (this.player.occupations.includes(Occupation.ApprenticeCraftsman)) {
      return // Combo handled in ApprenticeCraftsman rules
    }
    super.onChooseEligibleAction(action)
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    if (playerId !== this.player.color) return []
    if (playerHasGoods(this.player, Good.Ore)) {
      return super.getLegalMoves(playerId)
    } else {
      return this.stripsToExtractMissingGoods(Good.Ore, getPendingAction(this.game)!.action).map(strip => chooseMountainStripMove(this.player.color, strip.id))
    }
  }

  postActionPlayed(move: MoveRandomized | MoveView) {
    const consequences = super.postActionPlayed(move)
    if (move.type === MoveType.TakeGoodsFromMountainStrip && move.player === this.player.color) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.action === Action.CraftChest) {
        consequences.push(spendGoodsMove(this.player.color, Good.Ore))
      }
    }
    return consequences
  }
}
