import MoveType from './MoveType'

type StartNextPhase = {
  type: MoveType.StartNextPhase
}

export default StartNextPhase

export const startNextPhaseMove: StartNextPhase = {type: MoveType.StartNextPhase}

