import Game from '../Game'
import GameView from '../GameView'
import {mountainStrips} from '../material/MountainStrip'
import MoveType from './MoveType'

type AddMountainStrip = {
  type: MoveType.AddMountainStrip
}

export default AddMountainStrip

export type AddMountainStripView = AddMountainStrip & { strip: number }

export const addMountainStripMove: AddMountainStrip = {type: MoveType.AddMountainStrip}

export function addMountainStrip(game: Game) {
  const id = game.mountainStripsSupply.shift()!
  game.mountainStrips.push({id, goods: [...mountainStrips[id]].reverse()})
}

export function addMountainStripInView(game: GameView, move: AddMountainStripView) {
  game.mountainStripsSupply--
  game.mountainStrips.push({id: move.strip, goods: [...mountainStrips[move.strip]].reverse()})
}
