import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import {getActionRules} from '../../AFeastForOdin'

export default class OilSeller extends TradeOccupationRules {
  trades = [{spend: Good.Oil, receive: Good.Silverware}]

  eachTimeEffect(): Move[] {
    const actionRules = getActionRules(this.game)
    if (actionRules?.player.color === this.player.color
      && isMountainsAndTradeRules(actionRules) && actionRules.trades.length === 1 && actionRules.trades[0] === 1
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && !actionRules.pendingAction.afterEffects?.includes(Occupation.OilSeller)) {
      this.player.effects.push({occupation: Occupation.OilSeller})
      if (!actionRules.pendingAction.afterEffects) actionRules.pendingAction.afterEffects = []
      actionRules.pendingAction.afterEffects.push(Occupation.OilSeller)
    }
    return []
  }
}
