/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay} from '@gamepark/react-client'
import {useTranslation} from 'react-i18next'
import {buttonReset} from '../../styles'
import {displayPlayerCardsMove} from './DisplayPlayerCards'

type Props = {
  playerColor: PlayerColor
  toggled?: boolean
}

export default function DisplayCardsButton({playerColor, toggled}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  return (
    <button css={[buttonReset, style, toggled && toggledCss]}
            onClick={() => play(displayPlayerCardsMove(playerColor, !toggled), {local: true})}>
      <span>{t('Cards')}</span>
    </button>
  )
}

const style = css`
  position: absolute;
  bottom: 2em;
  left: 2em;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2em;
  width: 21em;
  height: 8em;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  > span {
    font-size: 3.5em;
    color: white;
  }
`

const toggledCss = css`
  background-color: rgba(0, 0, 0, 0.8) !important;
`
