import ActionRules from '../ActionRules'
import Move from '../../moves/Move'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../../material/goods/Good'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import TradeRules from '../mountainsAndTrade/TradeRules'
import equal from 'fast-deep-equal'
import OccupationsRules from '../../material/occupations/OccupationsRules'
import {isExtractToPayActionRules} from '../../material/occupations/ExtractToPayActionRules'

export default class CraftChestRules extends ActionRules {
  action = Action.CraftChest

  costOptions = [Good.Wood, Good.Ore]

  canUseEffect(): boolean {
    if (this.costOptions.some(good => playerHasGoods(this.player, good))) return true
    return this.player.occupations
      .map(occupation => new OccupationsRules[occupation](this.game, this.player))
      .filter(isExtractToPayActionRules)
      .some(rules => rules.isEligibleAction(this.action)
        && (rules.stripsToExtractMissingGoods(Good.Wood, this.action).length > 0 || rules.stripsToExtractMissingGoods(Good.Ore, this.action).length > 0))
  }

  delegate() {
    return new TradeRules(this.game, this.player, this.costOptions.map(good => ({spend: good, receive: [Good.Silver, Good.Chest]})))
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && equal(move.goods, [Good.Silver, Good.Chest])) {
      this.complete()
    }
    return super.play(move)
  }
}
