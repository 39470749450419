import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Occupation from '../Occupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import Game from '../../Game'
import GameView from '../../GameView'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import {passMove} from '../../moves/Pass'
import MoveView from '../../moves/MoveView'
import MoveRandomized from '../../moves/MoveRandomized'
import GoodsAreaType from '../goods/GoodsAreaType'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {getBanquet} from '../../phases/Feast'

export type FishCookEffect = {
  occupation: Occupation.FishCook
  fishes: number
}

export default class FishCook extends OccupationRules {

  constructor(game: Game | GameView, player: Player | PlayerView) {
    super(game, player)
  }

  get effect(): FishCookEffect {
    return this.player.effects[0] as FishCookEffect
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color && this.game.phase === Phase.Feast && !this.player.effects.length) {
      const fishes = getBanquet(this.player).placedGoods.reduce((sum, placedGood) => placedGood.good === Good.Stockfish ? sum + 1 : sum, 0)
      if (fishes > 0) {
        this.player.effects.unshift({occupation: Occupation.FishCook, fishes})
        return [receiveGoodsMove(this.player.color, {[Good.Stockfish]: fishes})]
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Building) {
      for (const placedGood of move.placedGoods) {
        if (placedGood.good === Good.Stockfish) {
          this.effect.fishes--
        }
      }
      if (this.effect.fishes <= 0) {
        this.player.effects.shift()
      }
    }
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      return [spendGoodsMove(this.player.color, {[Good.Stockfish]: this.effect.fishes})]
    }
    if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
