import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import HuntingGameSuccessOccupationRules from './HuntingGameSuccessOccupationRules'
import {getActionRules} from '../../AFeastForOdin'
import Action from '../../actions/Action'

export default class Farmhand extends HuntingGameSuccessOccupationRules {
  onHuntingGameSuccess(): Move[] {
    const rules = getActionRules(this.game)
    return rules?.action === Action.HuntingGameWithTwoVikings ? [receiveGoodsMove(this.player.color, [Good.Hide, Good.Silver])] : []
  }
}
