import Game from '../Game'
import GameView from '../GameView'
import Good, {Goods, isGood} from '../material/goods/Good'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'

type SpendGoods = {
  type: MoveType.SpendGoods
  player: PlayerColor
  goods: Goods
}

export default SpendGoods

export function spendGoodsMove(player: PlayerColor, goods: Goods): SpendGoods {
  return {type: MoveType.SpendGoods, player, goods}
}

export function spendGoods(game: Game | GameView, move: SpendGoods) {
  const player = game.players.find(player => player.color === move.player)!
  if (isGood(move.goods)) {
    removeGoodsToPlayer(player, move.goods)
  } else if (Array.isArray(move.goods)) {
    for (const good of move.goods) {
      removeGoodsToPlayer(player, good)
    }
  } else {
    for (const good in move.goods) {
      removeGoodsToPlayer(player, parseInt(good), move.goods[good])
    }
  }
}

export function removeGoodsToPlayer(player: Player | PlayerView, good: Good, quantity: number = 1) {
  const previousQuantity = player.goods[good]!
  if (previousQuantity === quantity) {
    delete player.goods[good]
  } else {
    player.goods[good] = previousQuantity - quantity
  }
}
