/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {Picture, PictureAttributes} from '@gamepark/react-components'
import Images from '../images/Images'

export default function ThingPenaltyTile(props: PictureAttributes) {
  return <Picture src={Images.thingPenalty} css={style} {...props}/>
}

const style = css`
  position: absolute;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black, 0 0 0.3em black;
`
