import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {getBanquet} from '../../phases/Feast'
import Good, {animals} from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Skinner extends OccupationRules {

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color && this.game.phase === Phase.Feast && !this.player.effects.length) {
      const countAnimals = getBanquet(this.player).placedGoods.reduce((sum, placedGood) => animals.includes(placedGood.good) ? sum + 1 : sum, 0)
      if (countAnimals > 0) {
        return [receiveGoodsMove(this.player.color, {[Good.SkinAndBones]: countAnimals})]
      }
    }
    return []
  }
}
