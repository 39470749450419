import ExploreRules from './ExploreRules'
import Action from '../Action'
import {hasLongBoat} from '../../material/boats/LandingStages'

export default class ExploreIIRules extends ExploreRules {
  action = Action.ExploreII

  canUseEffect() {
    return this.getAvailableTerritories().length > 0 && hasLongBoat(this.player)
  }
}