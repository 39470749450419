import OccupationRules from './OccupationRules'
import Occupation from '../Occupation'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import Weapon, {weapons} from '../Weapon'
import {isGameView} from '../../GameView'
import {drawWeaponMove, DrawWeaponView} from '../../moves/DrawWeapon'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveType from '../../moves/MoveType'

export default class EquipmentSupplier extends OccupationRules {
  immediateEffect() {
    if (weapons.some(weapon => (this.player.weapons[weapon] ?? 0) < 2)) {
      this.player.effects.unshift({occupation: Occupation.EquipmentSupplier})
    }
    return []
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const deck = isGameView(this.game) ? this.game.weaponsDeck : this.game.weaponsDeck.length
    if (!deck && !this.game.weaponsDiscard.length) {
      const missingWeapons: Weapon[] = []
      for (const weapon of weapons) {
        let count = this.player.weapons[weapon] ?? 0
        while (count < 2) {
          missingWeapons.push(weapon)
          count++
        }
      }
      return [receiveWeaponsMove(this.player.color, missingWeapons)]
    }
    return [drawWeaponMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.DrawWeapon:
        if (move.player === this.player.color) {
          const weaponDrawn = isGameView(this.game) ? (move as DrawWeaponView).weapon : this.game.weaponsDeck[0]
          for (const weapon of weapons) {
            const count = (this.player.weapons[weapon] ?? 0) + (weaponDrawn === weapon ? 1 : 0)
            if (count < 2) return []
          }
          this.player.effects.shift()
        }
        break
      case MoveType.ReceiveWeapons:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
    }
    return []
  }
}
