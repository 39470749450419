import MoveType from './MoveType'
import Good from '../material/goods/Good'
import Game from '../Game'
import GameView from '../GameView'

type RemoveGoodsFromMountainStrip = {
  type: MoveType.RemoveGoodsFromMountainStrip
  strip: number
  goods: Good[]
}

export default RemoveGoodsFromMountainStrip

export function removeGoodsFromMountainStripMove(strip: number, goods: Good[]): RemoveGoodsFromMountainStrip {
  return {type: MoveType.RemoveGoodsFromMountainStrip, strip, goods}
}

export function removeGoodsFromMountainStrip(game: Game | GameView, move: RemoveGoodsFromMountainStrip) {
  const strip = game.mountainStrips.find(strip => strip.id === move.strip)!
  strip.goods.pop()
  if (strip.goods.length === 0) {
    game.mountainStrips.splice(game.mountainStrips.indexOf(strip), 1)
  }
}
