import Game from '../Game'
import GameView from '../GameView'
import Weapon, {Weapons} from '../material/Weapon'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'

type SpendWeapons = {
  type: MoveType.SpendWeapons
  player: PlayerColor
  weapons: Weapons
}

export default SpendWeapons

export function spendWeaponsMove(player: PlayerColor, weapons: Weapons): SpendWeapons {
  return {type: MoveType.SpendWeapons, player, weapons}
}

export function spendWeapons(game: Game | GameView, move: SpendWeapons) {
  const player = game.players.find(player => player.color === move.player)!
  if (typeof move.weapons === 'number') {
    removeWeaponsToPlayer(player, move.weapons)
  } else if (Array.isArray(move.weapons)) {
    for (const weapon of move.weapons) {
      removeWeaponsToPlayer(player, weapon)
    }
  } else {
    for (const weapon in move.weapons) {
      removeWeaponsToPlayer(player, parseInt(weapon), move.weapons[weapon])
    }
  }
}

export function removeWeaponsToPlayer(player: Player | PlayerView, weapon: Weapon, quantity: number = 1) {
  const previousQuantity = player.weapons[weapon]!
  if (previousQuantity === quantity) {
    delete player.weapons[weapon]
  } else {
    player.weapons[weapon] = previousQuantity - quantity
  }
}
