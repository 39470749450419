import OccupationRules from './OccupationRules'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import Occupation from '../Occupation'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {hasStageForLargeShip} from '../boats/LandingStages'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import equal from 'fast-deep-equal'
import {takeBoatMove} from '../../moves/TakeBoat'
import BoatType from '../boats/BoatType'
import {armBoatMove} from '../../moves/ArmBoat'

export default class Sponsor extends OccupationRules {
  costOptions = [{[Good.Wood]: 4}, {[Good.Silver]: 3}]

  immediateEffect() {
    if (playerHasGoods(this.player, {[Good.Ore]: 3}) && hasStageForLargeShip(this.player)) {
      this.player.effects.unshift({occupation: Occupation.Sponsor})
    }
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = [passMove(this.player.color)]
    for (const goods of this.costOptions) {
      if (playerHasGoods(this.player, goods)) {
        moves.push(spendGoodsMove(this.player.color, goods))
      }
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.SpendGoods: {
        if (move.player === this.player.color && this.costOptions.some(option => (equal(move.goods, option)))) {
          const stage = this.player.landingStages.longBoats.findIndex(stage => stage === null)
          return [
            takeBoatMove(this.player.color, BoatType.Longship),
            armBoatMove(this.player.color, stage),
            armBoatMove(this.player.color, stage),
            armBoatMove(this.player.color, stage)
          ]
        }
        break
      }
      case MoveType.Pass:
      case MoveType.TakeBoat:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return []
  }
}
