/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Coordinates from '@gamepark/a-feast-for-odin/material/Coordinates'
import GoodsArea from '@gamepark/a-feast-for-odin/material/goods/GoodsArea'
import {isIncomePlacementArea} from '@gamepark/a-feast-for-odin/material/goods/IncomePlacementArea'
import OrientedGood, {getOrientedPolyomino} from '@gamepark/a-feast-for-odin/material/goods/OrientedGood'
import PlacedGood, {getCoveredCoordinates} from '@gamepark/a-feast-for-odin/material/goods/PlacedGood'
import PlacementArea from '@gamepark/a-feast-for-odin/material/goods/PlacementArea'
import {getPolyomino} from '@gamepark/a-feast-for-odin/material/goods/Polyomino'
import {placeGoodsMove} from '@gamepark/a-feast-for-odin/moves/PlaceGoods'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import useEfficientDragLayer from '@gamepark/react-components/dist/Draggable/useEfficientDragLayer'
import {HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {DropTargetMonitor, useDrop, XYCoord} from 'react-dnd'
import {GOOD, GoodDragItem} from '../../goods/DraggableGood'
import GoodItem from '../../goods/GoodItem'
import {dialogCloseIcon, dialogCss, squareSize, titleButtonCss} from '../../styles'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Dialog} from '@gamepark/react-components'
import {Trans, useTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {takeBackLocalPlacedGoodsMove} from '../../goods/TakeBackLocalPlacedGoods'
import PlacedGoodsLocal, {isPlacedGoodsLocal} from '@gamepark/a-feast-for-odin/moves/PlacedGoodsLocal'

type Props = {
  area: PlacementArea
  goodsArea: GoodsArea
  placedGoods: PlacedGoodsLocal[]
  square?: number
} & HTMLAttributes<HTMLDivElement>

export default function GoodsDropArea({area, goodsArea, placedGoods, square = squareSize, ...props}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const play = usePlay()
  const playerId = usePlayerId<PlayerColor>()!
  const {t} = useTranslation()

  const getAreaPosition = useCallback((sourceClientOffset: XYCoord, goodHeight: number) => {
    const dropArea = ref.current!.getBoundingClientRect()
    const x = Math.round((sourceClientOffset.x - dropArea.x) * area.width / dropArea.width)
    const y = area.height - goodHeight - Math.round((sourceClientOffset.y - dropArea.y) * area.height / dropArea.height)
    return {x, y}
  }, [area.width, area.height])

  const [{draggedGood, over}, dropRef] = useDrop({
    accept: GOOD,
    canDrop: (item: GoodDragItem, monitor) => {
      const sourceClientOffset = monitor.getSourceClientOffset()
      if (!sourceClientOffset) return false
      const position = getAreaPosition(sourceClientOffset, getOrientedPolyomino(item.orientedGood).length)
      return area.canPlaceGood({...item.orientedGood, ...position})
    },
    drop: (item: GoodDragItem, monitor) => {
      const position = getAreaPosition(monitor.getSourceClientOffset()!, getOrientedPolyomino(item.orientedGood).length)
      const placedGood = isIncomePlacementArea(area) ? {...item.orientedGood, ...position, local: true} : {...item.orientedGood, ...position}
      return placeGoodsMove(playerId, goodsArea, [placedGood])
    },
    collect: (monitor: DropTargetMonitor<GoodDragItem>) => {
      return ({
        draggedGood: monitor.getItemType() === GOOD ? monitor.getItem().orientedGood : undefined,
        over: monitor.isOver()
      })
    }
  })

  dropRef(ref)

  const invalidIncomeSpaces: Coordinates[] = useMemo(() => isIncomePlacementArea(area) ? area.getMissingIncomeCover() : [], [area, placedGoods])

  useEffect(() => {
    if (placedGoods.some(isPlacedGoodsLocal) && invalidIncomeSpaces.length === 0) {
      play(placeGoodsMove(playerId, goodsArea, placedGoods.filter(isPlacedGoodsLocal).map(placedGoodLocal => {
        const {local, ...placedGood} = placedGoodLocal
        return placedGood
      })))
    }
  }, [playerId, goodsArea, placedGoods, invalidIncomeSpaces])

  const [invalidIncomeInfoOpen, setInvalidIncomeInfoOpen] = useState(false)

  return (
    <div ref={ref} css={style(area.width * square, area.height * square)} {...props}>
      {placedGoods.map(placedGood =>
        <GoodItem key={`${placedGood.good}_${placedGood.x}_${placedGood.y}`} good={placedGood.good}
                  height={placedGood.good === Good.Wood ? square * 1.5 : undefined}
                  onClick={() => placedGood.local}
                  orientation={placedGood.orientation} css={positionGood(placedGood, square)}/>
      )}
      {draggedGood && <ValidDropAreaHighlight area={area} draggedGood={draggedGood} square={square}/>}
      {draggedGood && over && <DropShadow area={area} draggedGood={draggedGood} square={square} getAreaPosition={getAreaPosition}/>}
      {!draggedGood && invalidIncomeSpaces.map(({x, y}) =>
        <div key={`${x}_${y}`} css={[squareCss(square), position({x, y}, square), incomeErrorCss]}
             onClick={() => setInvalidIncomeInfoOpen(true)}/>
      )}
      <Dialog css={dialogCss} open={invalidIncomeInfoOpen} onBackdropClick={() => setInvalidIncomeInfoOpen(false)}>
        <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setInvalidIncomeInfoOpen(false)}/>
        <h2>{t('income.cover')}</h2>
        <p css={css`white-space: break-spaces;`}>
          <Trans defaults="income.cover.rules" components={[
            <button css={titleButtonCss} onClick={() => {
              play(takeBackLocalPlacedGoodsMove(playerId, goodsArea), {local: true})
              setInvalidIncomeInfoOpen(false)
            }}/>
          ]}/>
        </p>
        <p>
          <button css={titleButtonCss} onClick={() => setInvalidIncomeInfoOpen(false)}>{t('OK')}</button>
        </p>
      </Dialog>
    </div>
  )
}

type ValidDropAreaHighlightProps = {
  area: PlacementArea
  draggedGood: OrientedGood
  square: number
}

function ValidDropAreaHighlight({area, draggedGood, square}: ValidDropAreaHighlightProps) {
  return <>
    {area.getPotentialPlacementArea(draggedGood).map((column, x) =>
      column.map((valid, y) =>
        valid ? <div key={`${x}_${y}`} css={[squareCss(square), position({x, y}, square), highlight]}/> : null
      )
    )}
  </>
}

type DropShadowProps = {
  area: PlacementArea
  draggedGood: OrientedGood
  square: number
  getAreaPosition: (differenceFromInitialOffset: XYCoord, goodHeight: number) => Coordinates
}

function DropShadow({area, draggedGood, square, getAreaPosition}: DropShadowProps) {
  const goodHeight = useMemo(() => getOrientedPolyomino(draggedGood).length, [draggedGood])
  const sourceClientOffset = useEfficientDragLayer(monitor => monitor.getSourceClientOffset())
  if (!sourceClientOffset) return null
  const coordinates = getAreaPosition(sourceClientOffset, goodHeight)
  const placedGood = {...draggedGood, ...coordinates}
  if (!area.canPlaceGood(placedGood)) return null
  return <>{getCoveredCoordinates(placedGood).map(({x, y}) => <div key={`${x}_${y}`} css={[squareCss(square), position({x, y}, square), strongHighlight]}/>)}</>
}

const style = (width: number, height: number) => css`
  position: absolute;
  width: ${width}em;
  height: ${height}em;
`

const squareCss = (square: number) => css`
  position: absolute;
  width: ${square}em;
  height: ${square}em;
`

const highlight = css`
  background-color: rgba(0, 128, 0, 0.3);
`

const strongHighlight = css`
  background-color: rgba(255, 255, 255, 0.5);
`

function positionGood(placedGood: PlacedGood, square: number) {
  if (placedGood.orientation % 2 === 1) {
    const polyomino = getPolyomino(placedGood.good)
    return position({x: placedGood.x + (polyomino.length - polyomino[0].length) / 2, y: placedGood.y - (polyomino.length - polyomino[0].length) / 2}, square)
  } else {
    return position(placedGood, square)
  }
}

const position = ({x, y}: Coordinates, square: number) => css`
  position: absolute;
  left: ${x * square}em;
  bottom: ${y * square}em;
`

const incomeErrorCss = css`
  background-color: rgba(255, 0, 0, 0.5);
  cursor: help;
`
