import ExtractToPayActionRules from './ExtractToPayActionRules'
import Effect from '../../effects/Effect'
import Occupation from '../Occupation'
import Action from '../../actions/Action'
import Good from '../goods/Good'
import MountainStrip from '../MountainStrip'
import Carpenter from './Carpenter'

export default class ArmedFighter extends ExtractToPayActionRules {
  effect: Effect = {occupation: Occupation.ArmedFighter}

  isEligibleAction(action: Action): boolean {
    return action % 5 === 4
  }

  isValidStrip(strip: MountainStrip): boolean {
    return strip.goods[strip.goods.length - 1].some(good => good === Good.Stone || good === Good.Ore)
  }

  onChooseEligibleAction(action: Action) {
    if (this.player.occupations.includes(Occupation.Carpenter) && new Carpenter(this.game, this.player).isEligibleAction(action)) {
      return // Combo handled in Carpenter rules
    }
    super.onChooseEligibleAction(action)
  }
}
