import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {getPendingAction} from '../../AFeastForOdin'

export default class Hunter extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.dice) {
        pendingAction.dice.diceModifier -= 1
      }
    }
    return []
  }
}
