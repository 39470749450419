import OccupationRules from './OccupationRules'
import Good, {goodsToMap} from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'

export default class BosporusMerchant extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const spices = goodsToMap(move.goods)[Good.Spices]
      if (spices) {
        return [receiveGoodsMove(this.player.color, {[Good.Oil]: spices})]
      }
    }
    return []
  }
}
