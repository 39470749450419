enum Territory {
  Shetland = 1, BearIsland,
  FaroeIslands, BaffinIsland,
  Iceland, Labrador,
  Greenland, Newfoundland
}

export default Territory

export const territoryValue: Record<Territory, number> = {
  [Territory.Shetland]: 6,
  [Territory.BearIsland]: 16,
  [Territory.FaroeIslands]: 10,
  [Territory.BaffinIsland]: 10,
  [Territory.Iceland]: 16,
  [Territory.Labrador]: 36,
  [Territory.Greenland]: 12,
  [Territory.Newfoundland]: 38
}
