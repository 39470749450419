import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'
import VikingLocation from '../material/boards/VikingLocation'

type MoveViking = {
  type: MoveType.MoveViking
  player: PlayerColor
  viking: number
  location: VikingLocation
}

export default MoveViking

export function moveViking(player: PlayerColor, viking: number, location:VikingLocation): MoveViking {
  return {type: MoveType.MoveViking, player, viking, location}
}
