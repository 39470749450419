/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {css} from '@emotion/react'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function FurMerchantChoice({player}: Props) {
  return (
    <div>
      <p>
        <strong><Trans defaults="fur.merchant.effect" components={[<GoodItem good={Good.Fur} height={3} css={inlineGood}/>]}/></strong>
        <PayButton css={buttonCss} player={player} goods={Good.GameMeat}>
          <GoodItem good={Good.GameMeat} height={3}/>
        </PayButton>
        <PayButton css={buttonCss} player={player} goods={Good.Silk}>
          <GoodItem good={Good.Silk} height={1.5}/>
        </PayButton>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}

const buttonCss = css`
  margin-left: 1em;
  padding-top: 0.2em;
  min-width: 4em;
`
