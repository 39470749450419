import {claimTerritoryMove} from '../../moves/ClaimTerritory'
import ActionRules from '../ActionRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import TerritoriesExplorationAction from '../../material/territories/TerritoriesExplorationAction'
import Action from '../Action'

export default abstract class ExploreRules extends ActionRules {
  getAvailableTerritories() {
    return this.game.unexploredTerritories.filter(({territory}) => TerritoriesExplorationAction[territory] === this.action)
  }

  getPlayerMoves() {
    return this.getAvailableTerritories().map(({territory}) => claimTerritoryMove(this.player.color, territory))
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.ClaimTerritory) {
      this.complete()
    }
    return super.play(move)
  }
}

export function isExploration(action: Action): boolean {
  return action === Action.ExploreI || action === Action.ExploreII || action === Action.ExploreIII
}
