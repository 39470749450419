/** @jsxImportSource @emotion/react */
import {Trans, useTranslation} from 'react-i18next'
import GameLocalView from '../GameLocalView'
import {PassButton} from '../PassButton'
import Effect from '@gamepark/a-feast-for-odin/effects/Effect'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import {getBonusGoodsForBuildings} from '@gamepark/a-feast-for-odin/material/occupations/Builder'
import {getBonusGoodsForHome} from '@gamepark/a-feast-for-odin/material/occupations/Collector'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import GoodItem from '../goods/GoodItem'
import OccupationCardsDetails from '../occupations/OccupationCardsDetails'
import {PlayMoveButton} from '../PlayMoveButton'
import {occupationRulesDialog, setRulesDialog} from '../RulesDialog'
import PlayerLocal from '../PlayerLocal'
import FeastHeaderText from './FeastHeaderText'
import InspectorChoice from '../occupations/InspectorChoice'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen, {getPlayerScreen} from '../navigation/Screen'
import {displayPlayerGoodsMove} from '../player/goods/DisplayPlayerGoods'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import PendingActionHeaderText from './PendingActionHeaderText'
import {displayPlayerCardsMove} from '../player/cards/DisplayPlayerCards'
import {drawOccupationMove} from '@gamepark/a-feast-for-odin/moves/DrawOccupation'
import ForestBlacksmith from '@gamepark/a-feast-for-odin/material/occupations/ForestBlacksmith'
import ApprenticeCraftsman from '@gamepark/a-feast-for-odin/material/occupations/ApprenticeCraftsman'
import Carpenter from '@gamepark/a-feast-for-odin/material/occupations/Carpenter'
import ArmedFighter from '@gamepark/a-feast-for-odin/material/occupations/ArmedFighter'
import HarborGuard from '@gamepark/a-feast-for-odin/material/occupations/HarborGuard'
import BirkaSettler from '@gamepark/a-feast-for-odin/material/occupations/BirkaSettler'

type Props = {
  game: GameLocalView
  player: PlayerLocal
  effect: Effect
}

export default function OccupationEffectHeaderText({game, player, effect}: Props) {
  const {t} = useTranslation()
  switch (effect.occupation) {
    case Occupation.Chief:
      return <FeastHeaderText game={game}/>
    case Occupation.OreBoatman:
      return <Trans defaults="ore.boatman.effect" values={{ore: effect.ore}} components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.OrientShipper:
      return <Trans defaults="orient.shipper.effect" components={[<PassButton/>]}/>
    case Occupation.Builder:
      return <Trans defaults="builder.effect" values={{number: getBonusGoodsForBuildings(game, player).length}} components={[<PassButton/>]}/>
    case Occupation.Collector:
      return <Trans defaults="collector.effect" values={{number: getBonusGoodsForHome(game, player).length}} components={[<PassButton/>]}/>
    case Occupation.ShipsCook:
      return <Trans defaults="ships.cook.effect" components={[<PassButton/>]}/>
    case Occupation.ForeignTrader:
      return <Trans defaults="foreign.trader.effect" components={[<PassButton/>]}/>
    case Occupation.Steward:
      return <Trans defaults="steward.effect"
                    components={[<GoodItem good={Good.Wood} height={1}/>, <GoodItem good={Good.Stone} height={1}/>, <GoodItem good={Good.Silver} height={1}/>,
                      <PassButton/>]}/>
    case Occupation.Tutor:
      return <>{t('tutor.effect')}</>
    case Occupation.Inspector:
      return <InspectorChoice player={player} effect={effect} round={game.round}/>
    case Occupation.WharfOwner:
      return <Trans defaults="wharf.owner.effect" components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local/>,
        <GoodItem good={Good.Silver} height={1}/>,
        <PassButton/>
      ]}/>
    case Occupation.ShipBuilder:
      return <Trans defaults="ship.builder.effect" components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local/>,
        <GoodItem good={Good.Silver} height={1}/>,
        <PassButton/>
      ]}/>
    case Occupation.FieldFarmer:
      return <Trans defaults="field.farmer.effect" components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local disabled={!playerHasGoods(player, Good.Silver)}/>,
        <GoodItem good={Good.Silver} height={1}/>,
        <PassButton/>
      ]}/>
    case Occupation.Follower:
      if (effect.pendingAction) {
        return <PendingActionHeaderText game={game} pendingAction={effect.pendingAction}/>
      } else {
        return <Trans defaults="follower.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.ActionBoard)} local/>,
          <PassButton/>
        ]}/>
      }
    case Occupation.TravelingMerchant:
      return <Trans defaults="traveling.merchant.effect" values={{quantity: effect.upgradesLeft}} components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Wholesaler:
      if (effect.traded.length === 0) {
        return <Trans defaults="wholesaler.effect" components={[
          <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
          <PassButton/>
        ]}/>
      } else {
        return <Trans defaults="wholesaler.effect.ongoing" values={{left: 4 - effect.traded.length}} components={[
          <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
          <PassButton/>
        ]}/>
      }
    case Occupation.HarborGuard: {
      const cost = new HarborGuard(game, player).getEmigrationCost()
      return <Trans defaults="harbor.guard.effect" values={{cost}} components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local disabled={!playerHasGoods(player, {[Good.Silver]: cost})}/>,
        <PassButton/>
      ]}/>
    }
    case Occupation.Hornblower:
      if (effect.pendingAction) {
        return <PendingActionHeaderText game={game} pendingAction={effect.pendingAction} currentPlayer={player.color}/>
      } else {
        break
      }
    case Occupation.Clerk:
      return <Trans defaults="clerk.effect" values={{left: effect.upgradesLeft}} components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local disabled={!playerHasGoods(player, {[Good.Silver]: 2})}/>,
        <GoodItem good={Good.Silver} height={1}/>,
        <PassButton/>
      ]}/>
    case Occupation.SwordFighter:
      return <Trans defaults="sword.fighter.effect" components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Latecomer:
      if (effect.pendingAction) {
        return <PendingActionHeaderText game={game} pendingAction={effect.pendingAction} currentPlayer={player.color}/>
      } else {
        return <Trans defaults="latecomer.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.ActionBoard)} local disabled={!playerHasGoods(player, Good.Silver)}/>,
          <GoodItem good={Good.Silver} height={1}/>,
          <PassButton/>
        ]}/>
      }
    case Occupation.Wanderer:
      return <Trans defaults="wanderer.effect" components={[
        <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Preceptor:
      return <Trans defaults="preceptor.effect" components={[
        <PlayMoveButton move={displayPlayerCardsMove(player.color, true)} local/>,
        game.occupationsDeck > 0 ? <PlayMoveButton move={drawOccupationMove(player.color)} delayed/> : <PassButton/>
      ]}/>
    case Occupation.ForestBlacksmith:
      if (new ForestBlacksmith(game, player).canPayAction()) {
        return <Trans defaults="forest.blacksmith.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
          <PassButton/>
        ]}/>
      } else {
        return <Trans defaults="forest.blacksmith.required" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
        ]}/>
      }
    case Occupation.BirkaSettler: {
      const cost = new BirkaSettler(game, player).getEmigrationCost()
      return <Trans defaults="birka.settlers.effect" values={{cost}} components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local disabled={!playerHasGoods(player, {[Good.Silver]: cost})}/>,
        <PassButton/>
      ]}/>
    }
    case Occupation.ApprenticeCraftsman:
      if (new ApprenticeCraftsman(game, player).canPayAction()) {
        return <Trans defaults="apprentice.craftsman.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
          <PassButton/>
        ]}/>
      } else {
        return <Trans defaults="apprentice.craftsman.required" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
        ]}/>
      }
    case Occupation.Shipowner:
      return <Trans defaults="shipowner.effect" components={[<PassButton/>]}/>
    case Occupation.Middleman:
      return <Trans defaults="middleman.effect" components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local disabled={!playerHasGoods(player, Good.Silver)}/>,
        <GoodItem good={Good.Silver} height={1}/>,
        <PassButton/>
      ]}/>
    case Occupation.Carpenter:
      if (new Carpenter(game, player).canPayAction()) {
        if (player.effects[1]?.occupation === Occupation.ArmedFighter) {
          return <Trans defaults="carpenter.armed.fighter" components={[
            <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
            <PassButton/>
          ]}/>
        }
        return <Trans defaults="carpenter.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
          <PassButton/>
        ]}/>
      } else {
        if (player.effects[1]?.occupation === Occupation.ArmedFighter) {
          return <Trans defaults="carpenter.armed.fighter.required" components={[
            <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
            <PassButton/>
          ]}/>
        }
        return <Trans defaults="carpenter.required" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
        ]}/>
      }
    case Occupation.Digger:
      return <Trans defaults="digger.effect" components={[
        <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Berserker:
      return <Trans defaults="berserker.effect" components={[
        <PlayMoveButton move={displayScreenMove(getPlayerScreen(player.color))} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Forester:
      return <Trans defaults="forester.effect" components={[
        <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.Merchant:
      return <Trans defaults="merchant.effect" components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.FestiveHunter:
      return <PendingActionHeaderText game={game} pendingAction={effect.pendingAction} currentPlayer={player.color}/>
    case Occupation.ArmedFighter:
      if (new ArmedFighter(game, player).canPayAction()) {
        return <Trans defaults="armed.fighter.effect" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
          <PassButton/>
        ]}/>
      } else {
        return <Trans defaults="armed.fighter.required" components={[
          <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
        ]}/>
      }
    case Occupation.FishCook:
      return <Trans defaults="fish.cook.effect" values={{fishes: effect.fishes}} components={[
        <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
        <PassButton/>
      ]}/>
    case Occupation.DorestadTraveller:
      if (effect.traded.length === 0) {
        return <Trans defaults="dorestad.traveller.effect" components={[
          <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
          <PassButton/>
        ]}/>
      } else {
        return <Trans defaults="dorestad.traveller.effect.ongoing" components={[
          <PlayMoveButton move={displayPlayerGoodsMove(player.color, true)} local/>,
          <PassButton/>
        ]}/>
      }
  }
  return <Trans defaults="occupation.pending.effect" values={{occupation: OccupationCardsDetails[effect.occupation - 1].title(t)}}
                components={[<PlayMoveButton move={setRulesDialog(occupationRulesDialog(effect.occupation))} local/>]}/>
}
