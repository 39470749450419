import BoatType from '../../material/boats/BoatType'
import {hasStageForBoat} from '../../material/boats/LandingStages'
import ActionRules from '../ActionRules'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveType from '../../moves/MoveType'
import {buildBoatMove} from '../../moves/BuildBoat'

export default abstract class BuildBoatRules extends ActionRules {

  abstract get boat(): BoatType

  canUseEffect() {
    return hasStageForBoat(this.player, this.boat) && super.canUseEffect()
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.complete) {
      moves.push(buildBoatMove(this.player.color, this.boat))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.BuildBoat && move.player === this.player.color) {
      this.complete()
    }
    return super.play(move)
  }
}