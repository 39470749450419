/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {PassButton} from '../PassButton'
import PlayerLocal from '../PlayerLocal'
import GoodItem from '../goods/GoodItem'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function PrivateChefChoice({player}: Props) {
  return <>
    <p>
      <Trans defaults="private.chef.effect" components={[
        <PayButton player={player} goods={{[Good.Silver]: 4}}/>,
        <GoodItem css={inlineGood} good={Good.Silverware} height={1.5}/>,
        <GoodItem css={inlineGood} good={Good.GameMeat} height={3}/>,
        <GoodItem css={inlineGood} good={Good.Mead} height={1.5}/>
      ]}/>
    </p>
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
