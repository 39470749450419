import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Occupation from '../Occupation'
import {passMove} from '../../moves/Pass'
import Weapon from '../Weapon'
import {spendWeaponsMove} from '../../moves/SpendWeapons'
import MoveView from '../../moves/MoveView'
import LayingSnareSuccessOccupationRules from './LayingSnareSuccessOccupationRules'
import LocationType from '../LocationType'
import Action from '../../actions/Action'
import equal from 'fast-deep-equal'
import {returnVikingsMove} from '../../moves/ReturnVikings'

export default class SnareSpecialist extends LayingSnareSuccessOccupationRules {
  onLayingSnareSuccess(): Move[] {
    if (this.player.vikings.some(vikingLocation => vikingLocation.type === LocationType.Action && vikingLocation.action === Action.LayingSnare)) {
      this.player.effects.unshift({occupation: Occupation.SnareSpecialist})
    }
    return []
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = [passMove(this.player.color)]
    if ((this.player.weapons[Weapon.Snare] ?? 0) >= 2) {
      moves.push(spendWeaponsMove(this.player.color, {[Weapon.Snare]: 2}))
    }
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.SpendWeapons:
        if (move.player === this.player.color && equal(move.weapons, {[Weapon.Snare]: 2})) {
          this.player.effects.shift()
          return [returnVikingsMove(this.player.color, [Action.LayingSnare])]
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return []
  }
}
