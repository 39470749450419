/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import GoodItem from '../goods/GoodItem'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import {HornblowerEffect} from '@gamepark/a-feast-for-odin/material/occupations/Hornblower'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {PayButton} from '../PayButton'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
  effect: HornblowerEffect
}

export default function HornblowerChoice({player, effect}: Props) {
  if (effect.pendingAction) return null
  return <>
    {!effect.actionsTaken.includes(Action.HuntingGameWithOneViking) &&
      <p>
        <Trans defaults="hornturner.effect.hunting" components={[
          <PayButton player={player} goods={Good.Silver}/>,
          <GoodItem good={Good.Silver} height={1.5} css={inlineGood}/>
        ]}/>
      </p>
    }
    {!effect.actionsTaken.includes(Action.LayingSnare) &&
      <p>
        <Trans defaults="hornturner.effect.snare" components={[
          <PayButton player={player} goods={{[Good.Silver]: 2}}/>,
          <GoodItem good={Good.Silver} height={1.5} css={inlineGood}/>
        ]}/>
      </p>
    }
    <p>
      <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
    </p>
  </>
}
