import ExploreRules from './ExploreRules'
import Action from '../Action'
import {hasKnarr, hasLongship} from '../../material/boats/LandingStages'
import Occupation from '../../material/Occupation'

export default class ExploreIIIRules extends ExploreRules {
  action = Action.ExploreIII

  canUseEffect() {
    if (this.getAvailableTerritories().length > 0) return false
    return hasLongship(this.player)
      || this.player.occupations.includes(Occupation.Steersman) && hasKnarr(this.player)
  }
}