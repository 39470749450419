import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Good from '../goods/Good'
import {harvest} from '../../phases/HarvestPhaseRules'
import {playerHasGoods} from '../../AFeastForOdin'
import Occupation from '../Occupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import MoveView from '../../moves/MoveView'

export default class Sower extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (this.harvestStarts(move)) {
      const hasFlax = playerHasGoods(this.player, Good.Flax)
      const hasGrain = playerHasGoods(this.player, Good.Grain)
      if (hasFlax && hasGrain) {
        this.player.effects.unshift({occupation: Occupation.Sower})
      } else if (hasGrain) {
        return [receiveGoodsMove(this.player.color, Good.Grain)]
      } else if (hasFlax) {
        return [receiveGoodsMove(this.player.color, Good.Flax)]
      }
    }
    return []
  }

  harvestStarts(move: Move) {
    switch (move.type) {
      case MoveType.StartNextPhase:
        return this.game.phase === Phase.Harvest && harvest(this.game).length > 0
      case MoveType.PlayOccupationEffect:
        return move.player === this.player.color && move.occupation === Occupation.Plower
    }
    return false
  }

  getPlayerMoves(): Move[] {
    return [
      receiveGoodsMove(this.player.color, Good.Grain),
      receiveGoodsMove(this.player.color, Good.Flax)
    ]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && (move.goods === Good.Grain || move.goods === Good.Flax)) {
      this.player.effects.shift()
    }
    return []
  }
}
