import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {playerHasGoods} from '../../AFeastForOdin'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class FineBlacksmith extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.FineBlacksmith})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, [Good.Ore, Good.Silver])) {
      if (this.game.specialTilesSupply.includes(Good.Crucifix)) {
        moves.push(receiveGoodsMove(this.player.color, Good.Crucifix))
      }
      if (this.game.specialTilesSupply.includes(Good.Cloakpin)) {
        moves.push(receiveGoodsMove(this.player.color, Good.Cloakpin))
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
    } else if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      consequences.push(spendGoodsMove(this.player.color, [Good.Ore, Good.Silver]))
      this.player.effects.shift()
    }
    return consequences
  }
}
