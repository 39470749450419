import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {getPendingAction} from '../../AFeastForOdin'
import Occupation from '../Occupation'

export default class EtiquetteTeacher extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.PlayOccupation && move.player === this.player.color) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction && !pendingAction.afterEffects?.includes(Occupation.EtiquetteTeacher)) {
        if (!pendingAction.afterEffects) pendingAction.afterEffects = []
        pendingAction.afterEffects.push(Occupation.EtiquetteTeacher)
        return [receiveGoodsMove(this.player.color, Good.Peas)]
      }
    }
    return []
  }
}
