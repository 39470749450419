import Move from '../../moves/Move'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import TradeOccupationRules from './TradeOccupationRules'
import {getActionRules} from '../../AFeastForOdin'

export default class BaitLayer extends TradeOccupationRules {
  trades = [{spend: Good.Stockfish, receive: Good.GameMeat}]

  eachTimeEffect(): Move[] {
    const actionRules = getActionRules(this.game)
    if (actionRules?.player.color === this.player.color && actionRules?.action === Action.LayingSnare
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && !actionRules.pendingAction.afterEffects?.includes(Occupation.BaitLayer)) {
      this.player.effects.unshift({occupation: Occupation.BaitLayer})
      if (!actionRules.pendingAction.afterEffects) actionRules.pendingAction.afterEffects = []
      actionRules.pendingAction.afterEffects.push(Occupation.BaitLayer)
    }
    return []
  }
}
