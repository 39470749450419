import Move from '../../moves/Move'
import WhalingSuccessOccupationRules from './WhalingSuccessOccupationRules'
import {getActionRules, getPendingAction} from '../../AFeastForOdin'
import MoveType from '../../moves/MoveType'
import Weapon, {weaponsMap} from '../Weapon'
import Occupation from '../Occupation'
import {isWhalingRules} from '../../actions/hunting/WhalingRules'
import PendingAction from '../../effects/PendingAction'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'

export default class WhaleCatcher extends WhalingSuccessOccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.SpendWeapons && move.player === this.player.color && !this.player.effects.length) {
      const rules = getActionRules(this.game)
      if (isWhalingRules(rules) && rules.player.color === this.player.color) {
        const weapons = weaponsMap(move.weapons)
        const spears = weapons[Weapon.Spear] ?? 0
        for (let i = 0; i < spears; i++) {
          this.addWhaleCatcherAfterEffect(rules.pendingAction)
        }
      }
    }
    return super.eachTimeEffect(move)
  }

  // use "afterEffects" to count how many spears were spent
  addWhaleCatcherAfterEffect(pendingAction: PendingAction) {
    if (!pendingAction.afterEffects) pendingAction.afterEffects = []
    pendingAction.afterEffects.push(Occupation.WhaleCatcher)
  }


  onWhalingSuccess(): Move[] {
    const pendingAction = getPendingAction(this.game)
    if (pendingAction?.afterEffects && pendingAction.afterEffects.reduce((sum, effect) => effect === Occupation.WhaleCatcher ? sum + 1 : sum, 0) === 1) {
      return [receiveWeaponsMove(this.player.color, [Weapon.Spear])]
    }
    return []
  }
}
