import Move from '../../moves/Move'
import Occupation from '../Occupation'
import OccupationRules from './OccupationRules'
import {countLongBoats} from '../boats/LandingStages'
import {getIncome} from '../../phases/Income'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class SailPatcher extends OccupationRules {
  eachTimeEffect(): Move[] {
    const longBoats = countLongBoats(this.player)
    if (longBoats >= 3 && !this.player.exhaustedOccupations.includes(Occupation.SailPatcher)) {
      this.player.exhaustedOccupations.push(Occupation.SailPatcher)
      const income = getIncome(this.player)
      if (income <= 30) {
        return [receiveGoodsMove(this.player.color, {[Good.Wool]: income <= 4 ? 3 : income <= 9 ? 2 : 1})]
      }
    }
    return []
  }
}
