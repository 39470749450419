import BuildBuildingRules from './BuildBuildingRules'
import Building from '../../material/Building'
import Good from '../../material/goods/Good'
import Action from '../Action'
import Occupation from '../../material/Occupation'

export default class BuildStoneHouseRules extends BuildBuildingRules {
  action = Action.BuildStoneHouse

  get cost() {
    return this.player.occupations.includes(Occupation.MasterBricklayer) ? undefined : Good.Stone
  }

  building = Building.StoneHouse
}