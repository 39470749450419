import BuildBoatRules from './BuildBoatRules'
import Action from '../Action'
import BoatType from '../../material/boats/BoatType'
import Good from '../../material/goods/Good'
import Occupation from '../../material/Occupation'
import KnarrBuilder from '../../material/occupations/KnarrBuilder'

export class BuildWhalingBoatRules extends BuildBoatRules {
  action = Action.BuildWhalingBoat

  get cost() {
    return Good.Wood
  }

  boat = BoatType.WhalingBoat

  canUseEffect(): boolean {
    if (this.player.occupations.includes(Occupation.KnarrBuilder)) {
      return super.canUseEffect() || new KnarrBuilder(this.game, this.player).canUseEffect()
    } else {
      return super.canUseEffect()
    }
  }
}