import {playerHasGoods} from '../AFeastForOdin'
import Game from '../Game'
import GameView from '../GameView'
import {isBuildingPlacementArea} from '../material/goods/BuildingPlacementArea'
import Good from '../material/goods/Good'
import GoodsArea, {getPlacedGoodsArea, getPlacementArea, isSameGoodsArea} from '../material/goods/GoodsArea'
import {isIncomePlacementArea} from '../material/goods/IncomePlacementArea'
import PlacedGood from '../material/goods/PlacedGood'
import PlacedGoodsArea, {isBuildingPlacedGoodsArea} from '../material/goods/PlacedGoodsArea'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'
import {removeGoodsToPlayer} from './SpendGoods'
import Occupation from '../material/Occupation'
import GoodsAreaType from '../material/goods/GoodsAreaType'
import Phase from '../phases/Phase'

type PlaceGoods = {
  type: MoveType.PlaceGoods
  player: PlayerColor
  goodsArea: GoodsArea
  placedGoods: PlacedGood[]
  extraGood?: Good
}

export default PlaceGoods

export function placeGoodsMove(player: PlayerColor, goodsArea: GoodsArea, placedGoods: PlacedGood[], extraGood?: Good): PlaceGoods {
  if (extraGood) {
    return {type: MoveType.PlaceGoods, player, goodsArea, placedGoods, extraGood}
  } else {
    return {type: MoveType.PlaceGoods, player, goodsArea, placedGoods}
  }
}

export function isPlaceGoodsValid(game: Game | GameView, playerId: PlayerColor, move: PlaceGoods): boolean {
  if (move.player !== playerId) return false

  const player = game.players.find(player => player.color === playerId)
  if (!player) return false

  if (game.phase === Phase.Feast && player.passed && move.goodsArea.type === GoodsAreaType.Banquet) return false

  const placedGoodsArea = getPlacedGoodsArea(player, move.goodsArea)
  if (!placedGoodsArea) return false
  const area = getPlacementArea(player, placedGoodsArea)

  const goods = move.placedGoods.map(placedGood => placedGood.good)
  if (move.extraGood) {
    goods.push(move.extraGood)
  }
  if (!playerHasGoods(player, goods)) return false

  if (goods.includes(Good.Ore) && move.goodsArea.type !== GoodsAreaType.Home && move.goodsArea.type !== GoodsAreaType.Territory
    && player.effects.length > 0 && player.effects[0].occupation === Occupation.OreBoatman) {
    return false
  }

  if (!area.canPlaceGoods(move.placedGoods)) return false

  if (isIncomePlacementArea(area)) {
    area.placeGoods(move.placedGoods)
    if (!area.isIncomeCoverValid()) return false
  }

  if (move.extraGood) {
    return isBuildingPlacementArea(area) && area.canPlaceGood(move.extraGood)
  }

  return true
}

export function placeGoods(game: Game | GameView, move: PlaceGoods) {
  const player = game.players.find(player => player.color === move.player)!
  const area = player.placedGoodsAreas.find(area => isSameGoodsArea(area.goodsArea, move.goodsArea))!
  for (const placedGood of move.placedGoods) {
    placeGoodInArea(player, area, placedGood)
  }
  placeExtraGood(player, area, move)
}

export function placeExtraGood(player: Player | PlayerView, area: PlacedGoodsArea, move: PlaceGoods) {
  if (move.extraGood && isBuildingPlacedGoodsArea(area)) {
    area.extraGoods.push(move.extraGood)
    removeGoodsToPlayer(player, move.extraGood)
  }
}

export function placeGoodInArea(player: Player | PlayerView, area: PlacedGoodsArea, placedGood: PlacedGood) {
  area.placedGoods.push(placedGood)
  removeGoodsToPlayer(player, placedGood.good)
}