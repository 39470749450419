import Move from '../../moves/Move'
import Occupation from '../Occupation'
import MoveType from '../../moves/MoveType'
import GoodsAreaType from '../goods/GoodsAreaType'
import Building from '../Building'
import Good from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import {isPlacedGoodsLocal} from '../../moves/PlacedGoodsLocal'

export default class Nobleman extends TradeOccupationRules {
  trades = [{spend: {[Good.Silver]: 2}, receive: Good.Silk}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Building
      && (move.goodsArea.building === Building.StoneHouse || move.goodsArea.building === Building.LongHouse)
      && move.placedGoods.some(placedGood => placedGood.good === Good.Grain && !isPlacedGoodsLocal(placedGood))) {
      this.player.effects.unshift({occupation: Occupation.Nobleman})
    }
    return []
  }
}
