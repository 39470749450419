import Good, {isPregnantAnimal, upgradableGoods} from '../../material/goods/Good'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import Game from '../../Game'
import GameView from '../../GameView'
import PlayerColor from '../../PlayerColor'
import {Rules} from '@gamepark/rules-api'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Trade from './Trade'
import TradeRules from './TradeRules'
import {playerHasGoods} from '../../AFeastForOdin'

export default class UpgradeGoodRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  player: Player | PlayerView
  upgrade: number | ((good: Good) => number)
  upgradable: Good[]

  constructor(game: Game | GameView, player: Player | PlayerView, upgrade: number | ((good: Good) => number), upgradable = upgradableGoods) {
    super(game)
    this.player = player
    this.upgrade = upgrade
    this.upgradable = upgradable
  }

  delegate(): Rules<Game | GameView, Move | MoveView, PlayerColor> | undefined {
    return new TradeRules(this.game, this.player, this.tradeOptions)
  }

  get tradeOptions() {
    const tradeOptions: Trade[] = []
    for (const good of this.upgradable) {
      if (playerHasGoods(this.player, good)) {
        const result = upgradeGood(good, typeof this.upgrade === 'number' ? this.upgrade : this.upgrade(good))
        tradeOptions.push({spend: good, receive: result})
      }
    }
    return tradeOptions
  }
}

export function upgradeGood(good: Good, upgrade = 1): Good {
  if (isPregnantAnimal(good)) {
    return upgradeGood(good - 5, upgrade)
  }
  const result = good + upgrade
  if (result < Good.Peas || result % 10 > 4 || result > Good.SilverHoard) {
    switch (upgrade) {
      case 9:
      case 11:
        return good
      case 22:
        return upgradeGood(good, 11)
      case 33:
        return upgradeGood(good, 22)
      default:
        return upgradeGood(good, upgrade - 1)
    }
  }
  return result
}