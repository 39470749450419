/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good, {isSpecialTile} from '@gamepark/a-feast-for-odin/material/goods/Good'
import Polyomino, {getPolyomino} from '@gamepark/a-feast-for-odin/material/goods/Polyomino'
import Orientation from '@gamepark/a-feast-for-odin/material/Orientation'
import {Picture} from '@gamepark/react-components'
import {PictureAttributes} from '@gamepark/react-components/dist/Picture/Picture'
import {useMemo} from 'react'
import Images from '../images/Images'
import {squareSize} from '../styles'

type Props = {
  good: Good
  height?: number
  orientation?: Orientation
} & PictureAttributes

export default function GoodItem({good, height, orientation, ...props}: Props) {
  const polyomino = useMemo(() => getPolyomino(good), [good])
  return (
    <Picture src={goodImage[good]}
             css={[
               imgCss,
               orientation && rotation(orientation),
               good === Good.Wood ? woodSize(height) : good === Good.Stone ? stoneSize(height) : size(polyomino, height),
               shadow(good),
               good === Good.Silver && silverCss
             ]}
             {...props}/>
  )
}

const imgCss = css`
  display: block;
`

const rotation = (orientation: Orientation) => css`
  transform: rotate(${(orientation % 4) * 90}deg) rotateY(${orientation >= 4 ? 180 : 0}deg);
`

const size = (polyomino: Polyomino, height?: number) => css`
  width: ${height ? polyomino[0].length / polyomino.length * height : polyomino[0].length * squareSize}em;
  height: ${height ?? polyomino.length * squareSize}em;
`

const woodSize = (height = 10) => css`
  width: ${height * 114 / 176}em;
  height: ${height}em;
`

const stoneSize = (height = 10) => css`
  width: ${height * 111 / 157}em;
  height: ${height}em;
`

const silverCss = css`
  border-radius: 40%;
`

const shadow = (good: Good) => {
  if (good === Good.Wood || good === Good.Stone || good === Good.Ore || isSpecialTile(good)) {
    return css`
      filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
    `
  } else {
    return css`
      box-shadow: 0 0 0.1em black, 0 0 0.1em black;
    `
  }
}

const goodImage: Record<Good, string> = {
  [Good.Silver]: Images.silver,
  [Good.Wood]: Images.wood,
  [Good.Stone]: Images.stone,
  [Good.Ore]: Images.ore,
  [Good.WhalingBoat]: Images.whalingBoat,
  [Good.Knarr]: Images.knarr,
  [Good.Longship]: Images.longship,
  [Good.Shed]: Images.shed,
  [Good.StoneHouse]: Images.stoneHouse,
  [Good.LongHouse]: Images.longHouse,
  [Good.Peas]: Images.peas,
  [Good.Mead]: Images.mead,
  [Good.Oil]: Images.oil,
  [Good.RuneStone]: Images.runeStone,
  [Good.Flax]: Images.flax,
  [Good.Stockfish]: Images.stockFish,
  [Good.Hide]: Images.hide,
  [Good.Silverware]: Images.silverware,
  [Good.Beans]: Images.beans,
  [Good.Milk]: Images.milk,
  [Good.Wool]: Images.wool,
  [Good.Chest]: Images.chest,
  [Good.Grain]: Images.grain,
  [Good.SaltMeat]: Images.saltMeat,
  [Good.Linen]: Images.linen,
  [Good.Silk]: Images.silk,
  [Good.Cabbage]: Images.cabbage,
  [Good.GameMeat]: Images.gameMeat,
  [Good.SkinAndBones]: Images.skinAndBones,
  [Good.Spices]: Images.spices,
  [Good.Sheep]: Images.sheep,
  [Good.PregnantSheep]: Images.pregnantSheep,
  [Good.Fur]: Images.fur,
  [Good.Jewelry]: Images.jewelry,
  [Good.Fruits]: Images.fruits,
  [Good.WhaleMeat]: Images.whaleMeat,
  [Good.Robe]: Images.robe,
  [Good.TreasureChest]: Images.treasureChest,
  [Good.Cattle]: Images.cattle,
  [Good.PregnantCattle]: Images.pregnantCattle,
  [Good.Clothing]: Images.clothing,
  [Good.SilverHoard]: Images.silverHoard,
  [Good.GlassBeads]: Images.glassBeads,
  [Good.Helmet]: Images.helmet,
  [Good.Cloakpin]: Images.cloakpin,
  [Good.Belt]: Images.belt,
  [Good.Crucifix]: Images.crucifix,
  [Good.DrinkingHorn]: Images.drinkingHorn,
  [Good.AmberFigure]: Images.amberFigure,
  [Good.Horseshoe]: Images.horseshoe,
  [Good.GoldBrooch]: Images.goldBrooch,
  [Good.ForgeHammer]: Images.forgeHammer,
  [Good.Fibula]: Images.fibula,
  [Good.ThrowingAxe]: Images.throwingAxe,
  [Good.Chalice]: Images.chalice,
  [Good.RoundShield]: Images.roundShield,
  [Good.EnglishCrown]: Images.englishCrown
}
