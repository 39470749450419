/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next'
import PlayerLocal from '../PlayerLocal'
import {css} from '@emotion/react'
import {useCallback, useState} from 'react'
import {weapons} from '@gamepark/a-feast-for-odin/material/Weapon'
import WeaponCard from '../weapons/WeaponCard'
import {cardWidth, margin, playerBoardWidth, titleButtonCss} from '../styles'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'
import {usePlay} from '@gamepark/react-client'

type Props = {
  player: PlayerLocal
}

export default function ArmsDealerChoice({player}: Props) {
  const {t} = useTranslation()
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])
  const weaponCards = weapons.flatMap(weapon => Array(player.weapons[weapon] ?? 0).fill(weapon))
  const play = usePlay()

  const toggleSelection = useCallback((index: number) => {
    if (selectedIndexes.includes(index)) {
      setSelectedIndexes(() => selectedIndexes.filter(i => i !== index))
    } else if (selectedIndexes.length < 2) {
      setSelectedIndexes(() => selectedIndexes.concat(index))
    }
  }, [selectedIndexes, weaponCards])

  return (
    <div>
      <p>
        <strong>{t('arms.dealer.effect')}</strong>
      </p>
      <div css={flex}>
        {weaponCards.map((weapon, index) =>
          <WeaponCard key={index} weapon={weapon} css={[cardCss, cardPosition(index, weaponCards.length), selectedIndexes.includes(index) && selected]}
                      onClick={() => toggleSelection(index)}/>
        )}
      </div>
      <p>
        <button css={titleButtonCss} disabled={selectedIndexes.length < 2}
                onClick={() => play(spendWeaponsMove(player.color, selectedIndexes.map(i => weaponCards[i])))}>
          {t('arms.dealer.discard')}
        </button>
      </p>
    </div>
  )
}

const flex = css`
  display: flex;
`

const cardCss = css`
  font-size: 0.8em;
  margin: 1em;
  cursor: pointer;
`

const selected = css`
  box-shadow: 0 0 1em green, 0 0 1em green, 0 0 1em green;
`

const cardPosition = (index: number, totalCards: number) => css`
  left: ${playerBoardWidth - cardWidth - margin - index * Math.min(cardWidth + margin, (playerBoardWidth - margin * 2 - cardWidth) / (totalCards - 1))}em;
`
