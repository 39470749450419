import Move from '../../moves/Move'
import Occupation from '../Occupation'
import Woodcutter from './Woodcutter'
import {startEffectMove} from '../../moves/StartEffect'
import TradeRules from '../../actions/mountainsAndTrade/TradeRules'
import {passMove} from '../../moves/Pass'
import Good from '../goods/Good'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'

export default class Cooper extends Woodcutter {
  occupation = Occupation.Cooper

  triggerEffect(): Move[] {
    return [startEffectMove(this.player.color, {occupation: Occupation.Cooper})]
  }

  delegate() {
    return new TradeRules(this.game, this.player, [{spend: Good.Wood, receive: Good.Stockfish}])
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color && move.goods === Good.Stockfish) {
          this.player.effects.shift()
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return super.play(move)
  }
}
