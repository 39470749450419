import Game from '../Game'
import GameView from '../GameView'
import Territory from '../material/Territory'
import MoveType from './MoveType'

type TurnExplorationBoards = {
  type: MoveType.TurnExplorationBoards
}

export default TurnExplorationBoards

export const turnExplorationBoardsMove: TurnExplorationBoards = {type: MoveType.TurnExplorationBoards}

export function turnExplorationBoards(game: Game | GameView) {
  const territoriesToTurn = territoriesToTurnByLetter[game.shortGame ? game.round - 2 : game.round - 3]
  for (const unexploredTerritory of game.unexploredTerritories) {
    if (territoriesToTurn.includes(unexploredTerritory.territory)) {
      unexploredTerritory.territory++
      unexploredTerritory.silver = 0
    } else {
      unexploredTerritory.silver += 2
    }
  }
}

const territoriesToTurnByLetter = [
  [Territory.Shetland], // A
  [Territory.FaroeIslands], // B
  [Territory.Iceland], // C
  [Territory.Greenland] // D
] // TODO: expansions (Islay: D, ...)
