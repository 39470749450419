import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Action from '../../actions/Action'
import ActionsRules from '../../actions/ActionsRules'
import PendingAction from '../../effects/PendingAction'
import {playerHasGoods} from '../../AFeastForOdin'
import Good, {isGood} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {chooseActionMove} from '../../moves/ChooseAction'

export type HornblowerEffect = {
  occupation: Occupation.Hornblower
  actionsTaken: Action[]
  pendingAction?: PendingAction
}

export default class Hornblower extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Hornblower, actionsTaken: []})
    return []
  }

  delegate() {
    if (!this.effect.pendingAction) return
    return new ActionsRules[this.effect.pendingAction.action](this.game)
  }

  get effect(): HornblowerEffect {
    return this.player.effects[0] as HornblowerEffect
  }

  getPlayerMoves() {
    const moves: Move[] = []
    if (this.effect.pendingAction) return []
    if (!this.effect.actionsTaken.includes(Action.HuntingGameWithOneViking) && playerHasGoods(this.player, Good.Silver)) {
      moves.push(spendGoodsMove(this.player.color, Good.Silver))
    }
    if (!this.effect.actionsTaken.includes(Action.LayingSnare) && playerHasGoods(this.player, {[Good.Silver]: 2})) {
      moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: 2}))
    }
    moves.push(passMove(this.player.color))
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (this.effect.pendingAction) return super.play(move)
    switch (move.type) {
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          return [chooseActionMove(this.player.color, isGood(move.goods) ? Action.HuntingGameWithOneViking : Action.LayingSnare)]
        }
        break
      case MoveType.ChooseAction: {
        if (move.player === this.player.color) {
          this.effect.actionsTaken.push(move.action)
          this.effect.pendingAction = new ActionsRules[move.action](this.game).createPendingAction()
        }
        break
      }
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return []
  }

  eachTimeEffect() {
    const effect = this.player.effects[0]
    if (effect?.occupation === Occupation.Hornblower && effect.pendingAction?.complete) {
      delete effect.pendingAction
      if (effect.actionsTaken.length === 2) {
        this.player.effects.shift()
      }
    }
    return []
  }
}
