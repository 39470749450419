import OccupationRules from './OccupationRules'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'
import Weapon from '../Weapon'
import Action from '../../actions/Action'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {getPendingAction} from '../../AFeastForOdin'

export default class Archer extends OccupationRules {
  immediateEffect() {
    return [receiveWeaponsMove(this.player.color, [Weapon.Bow])]
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.HuntingGameWithOneViking || move.action === Action.HuntingGameWithTwoVikings)) {
      const pendingAction = getPendingAction(this.game)
      if (pendingAction?.dice) {
        pendingAction.dice.diceModifier -= 1
      }
    }
    return []
  }
}
