import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {playerHasGoods} from '../../AFeastForOdin'
import {spendGoodsMove} from '../../moves/SpendGoods'

export default class BoneCollector extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.BoneCollector})
    return []
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (playerHasGoods(this.player, Good.Silver)) {
      moves.push(spendGoodsMove(this.player.color, Good.Silver))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.Pass:
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
      case MoveType.SpendGoods:
        if (move.player === this.player.color) {
          consequences.push(receiveGoodsMove(this.player.color, Good.SkinAndBones))
        }
        break
    }
    return consequences
  }
}
