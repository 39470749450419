import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import GameLocalView from '../../GameLocalView'
import {getPlayerScreen} from '../../navigation/Screen'

export const DISPLAY_PLAYER_CARDS = 'DisplayPlayerCards'

export default interface DisplayPlayerCards {
  type: typeof DISPLAY_PLAYER_CARDS
  player: PlayerColor
  display: boolean
}

export const displayPlayerCardsMove = (player: PlayerColor, display: boolean): DisplayPlayerCards => ({
  type: DISPLAY_PLAYER_CARDS, player, display
})

export function displayPlayerCards(game: GameLocalView, move: DisplayPlayerCards) {
  const player = game.players.find(p => p.color === move.player)!
  if (move.display) {
    game.screen = getPlayerScreen(move.player)
    player.view = 'cards'
    delete game.rulesDialog
  } else {
    delete player.view
  }
}
