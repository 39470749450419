import BuildBoatRules from './BuildBoatRules'
import Action from '../Action'
import BoatType from '../../material/boats/BoatType'
import Good from '../../material/goods/Good'

export class BuildKnarrRules extends BuildBoatRules {
  action = Action.BuildKnarr

  get cost() {
    return {[Good.Wood]: 2}
  }

  boat = BoatType.Knarr
}