import {hasKnarr, hasLongship} from '../../material/boats/LandingStages'
import Good, {craftProducts, isCraftProduct, isSpecialTile, luxuryGoods, swordValues} from '../../material/goods/Good'
import Weapon from '../../material/Weapon'
import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Action from '../Action'
import DiceActionRules from '../DiceActionRules'
import PendingAction from '../../effects/PendingAction'
import Occupation from '../../material/Occupation'
import ActionRules from '../ActionRules'

const LOWEST_SWORD_VALUE = 6

export default class RaidingRules extends DiceActionRules {
  action = Action.Raiding
  dice = 8
  consumableGoods = [Good.Stone]

  get consumableWeapons(): Weapon[] {
    if (this.player.occupations.includes(Occupation.MeleeFighter)) {
      return [Weapon.Sword, Weapon.Spear]
    }
    return [Weapon.Sword]
  }

  canUseEffect() {
    return hasLongship(this.player)
      || this.player.occupations.includes(Occupation.Steersman) && hasKnarr(this.player)
  }

  createPendingAction(): PendingAction {
    return {action: this.action, dice: {side: 0, rollNumber: 0, maxRolls: 3, valueSpent: 0, diceModifier: 0}}
  }

  isBestRoll(roll: number): boolean {
    return roll === this.dice
  }

  getRequiredPayment() {
    if (this.player.occupations.includes(Occupation.Robber)) return LOWEST_SWORD_VALUE - 1 - this.getBattleResult()
    else return LOWEST_SWORD_VALUE - this.getBattleResult()
  }

  getMaxPayment(): number {
    return Infinity
  }

  getBattleResult() {
    const pendingDice = this.pendingDice
    return pendingDice.side + this.getDiceModifier() + pendingDice.valueSpent
  }

  potentialLoot(): Good[] {
    const loot = [...luxuryGoods, ...this.game.specialTilesSupply]
    if (this.player.occupations.includes(Occupation.Robber)) {
      loot.push(...craftProducts)
    }
    return loot
  }

  validLoot(battleResult = this.getBattleResult()): Good[] {
    return this.potentialLoot().filter(good => this.swordValue(good) <= battleResult)
  }

  swordValue(good: Good) {
    if (isCraftProduct(good)) {
      return swordValues[good + 1] - 1
    }
    let swordValue = swordValues[good] ?? Infinity
    if (this.player.occupations.includes(Occupation.LootHunter) && isSpecialTile(good)) {
      if (!this.game.specialTilesSupply.some(good => swordValues[good]! > swordValue)) {
        swordValue--
      }
    }
    return swordValue
  }

  getPlayerMoves(): Move[] {
    const moves = super.getPlayerMoves()
    for (const good of this.validLoot()) {
      moves.push(receiveGoodsMove(this.player.color, good))
    }
    return moves
  }

  getGoodValue(good: Good): number {
    if (good === Good.Stone && this.player.occupations.includes(Occupation.Catapulter)) return 2
    return super.getGoodValue(good)
  }
}

export function isRaidingRules(rules?: ActionRules): rules is RaidingRules {
  return rules?.action === Action.Raiding || rules?.action === Action.Pillaging1 || rules?.action === Action.Pillaging2
}