import {Rules} from '@gamepark/rules-api'
import GameView from '../GameView'
import Game from '../Game'
import Move from '../moves/Move'
import {receiveGoodsMove} from '../moves/ReceiveGoods'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import Good from '../material/goods/Good'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'

const {Peas, Flax, Beans, Grain, Cabbage, Fruits} = Good

export default class HarvestPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): any[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    const moves: Move[] = []
    const goods = harvest(this.game)
    if (goods.length) {
      for (const player of this.game.players) {
        moves.push(receiveGoodsMove(player.color, goods))
      }
    }
    moves.push(startNextPhaseMove)
    return moves
  }
}

export function harvestQuality(level: number) {
  switch (level) {
    case 1:
      return [Peas, Flax, Beans]
    case 2:
      return [Peas, Flax, Beans, Grain]
    case 3:
      return [Peas, Flax, Beans, Grain, Cabbage]
    case 4:
      return [Peas, Flax, Beans, Grain, Cabbage, Fruits]
  }
  return []
}

export function harvest(game: Game | GameView): Good[] {
  if (game.shortGame) {
    switch (game.round) {
      case 1:
        return harvestQuality(1)
      case 3:
        return harvestQuality(2)
      case 5:
        return harvestQuality(3)
    }
  } else {
    switch (game.round) {
      case 1:
        return harvestQuality(1)
      case 2:
        return harvestQuality(2)
      case 4:
        return harvestQuality(3)
      case 6:
        return harvestQuality(4)
    }
  }
  return []
}
