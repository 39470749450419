import ActionRules from '../ActionRules'
import Action from '../Action'
import LocationType from '../../material/LocationType'
import Move from '../../moves/Move'
import {chooseActionMove} from '../../moves/ChooseAction'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import ActionsRules from '../ActionsRules'

export default abstract class ImitationRules extends ActionRules {
  canUseEffect(): boolean {
    return this.game.players.some(player =>
        player.color !== this.player.color && player.vikings.some(location =>
          location.type === LocationType.Action && location.action % 5 === this.action % 5 && new ActionsRules[location.action](this.game).canUseEffect()
        )
    )
  }

  getPlayerMoves(): Move[] {
    const actions: Action[] = []
    for (const player of this.game.players) {
      if (player.color !== this.player.color) {
        for (const location of player.vikings) {
          if (location.type === LocationType.Action && location.action % 5 === this.action % 5 && !actions.includes(location.action)
            && new ActionsRules[location.action](this.game).canUseEffect()) {
            actions.push(location.action)
          }
        }
      }
    }
    return actions.map(action => chooseActionMove(this.player.color, action))
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      this.game.pendingAction = new ActionsRules[move.action](this.game).createPendingAction()
      this.game.pendingAction.imitation = this.action
    }
    return super.play(move)
  }
}

export function getImitationAction(imitation: number): Action {
  switch (imitation) {
    case 1:
      return Action.Imitation1
    case 2:
      return Action.Imitation2
    case 3:
      return Action.Imitation3
    default:
      return Action.Imitation4
  }
}
