import Move from '../../moves/Move'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'

export default class OrientShipper extends UpgradeGoodOccupationRules {
  get upgrade() {
    return 3
  }

  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.OrientShipper})
    return []
  }
}
