import {hasWhalingBoat} from '../../material/boats/LandingStages'
import Good from '../../material/goods/Good'
import Occupation from '../../material/Occupation'
import Weapon from '../../material/Weapon'
import Action from '../Action'
import HuntingRules from './HuntingRules'
import WhalingBoat from '../../material/boats/WhalingBoat'
import ActionRules from '../ActionRules'

export default class WhalingRules extends HuntingRules {
  action = Action.Whaling
  dice = 12
  successGoods = [Good.Oil, Good.SkinAndBones, Good.WhaleMeat]
  consumableWeapons = [Weapon.Spear]
  consumableGoods = [Good.Wood]
  vikingsFailBack = 2

  canUseEffect() {
    return hasWhalingBoat(this.player)
  }

  getDiceModifier(): number {
    return super.getDiceModifier() - this.getWhalingBoatsModifier()
  }

  getWhalingBoatsModifier(): number {
    return this.player.landingStages.whalingBoats.reduce((sum, boat) => sum + this.getWhalingBoatOre(boat), 0)
  }

  getWhalingBoatOre(boat: WhalingBoat | null): number {
    return boat?.extraOre ? 2 : boat ? 1 : 0
  }

  getWeaponValue(): number {
    const tridentHunter = this.player.occupations.includes(Occupation.TridentHunter)
    return tridentHunter ? 2 : 1
  }
}

export function isWhalingRules(rules?: ActionRules): rules is WhalingRules {
  return rules?.action === Action.Whaling || rules?.action === Action.WhalingWithOneBoat
}
