import Move from '../../moves/Move'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import HuntingGameSuccessOccupationRules from './HuntingGameSuccessOccupationRules'

export default class AntlersSeller extends HuntingGameSuccessOccupationRules {
  onHuntingGameSuccess(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Silver)]
  }
}
