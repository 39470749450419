import {hasLongBoat} from '../../material/boats/LandingStages'
import {emigrateBoatMove} from '../../moves/EmigrateBoat'
import Move from '../../moves/Move'
import {BanquetPlacementArea} from '../../phases/Feast'
import ActionRules from '../ActionRules'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Action from '../Action'
import Good from '../../material/goods/Good'

export default abstract class EmigrateRules extends ActionRules {
  get cost() {
    const emigrationCost = this.getEmigrationCost()
    if (!emigrationCost) return
    return {[Good.Silver]: emigrationCost}
  }

  getEmigrationCost(): number {
    return Math.max(0, this.game.round - this.getSilverDiscount(this.game.round))
  }

  canUseEffect() {
    return super.canUseEffect() && hasLongBoat(this.player) && new BanquetPlacementArea(this.player).canEmigrate()
  }

  getPlayerMoves(): Move[] {
    const player = this.game.players.find(p => p.color === this.player.color)!
    return Array.from(player.landingStages.longBoats.entries())
      .filter(entry => entry[1] !== null)
      .map(entry => emigrateBoatMove(player.color, entry[0]))
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.EmigrateBoat && move.player === this.player.color) {
      this.complete()
    }
    return super.play(move)
  }
}

export function isEmigration(action: Action) {
  return action === Action.Emigrate1 || action === Action.Emigrate2 || action === Action.ExchangeBoatAndEmigrate
}
