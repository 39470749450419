/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {isPlayerView} from '@gamepark/a-feast-for-odin/PlayerView'
import {usePlay} from '@gamepark/react-client'
import GameLocalView from '../../GameLocalView'
import PlayerLocal from '../../PlayerLocal'
import {displayPlayerCardsMove} from './DisplayPlayerCards'
import OccupationsDropArea from './OccupationsDropArea'
import PlayerHand from './PlayerHand'
import PlayerOccupations from './PlayerOccupations'
import PlayerWeapons from './PlayerWeapons'

type Props = {
  game: GameLocalView
  player: PlayerLocal
}

export default function PlayerCards({game, player}: Props) {
  const play = usePlay()
  return (
    <>
      <div css={darkBackground} onClick={() => play(displayPlayerCardsMove(player.color, false), {local: true})}/>
      <PlayerHand game={game} player={player}/>
      <PlayerWeapons player={player}/>
      <PlayerOccupations player={player}/>
      {!isPlayerView(player) && <OccupationsDropArea/>}
    </>
  )
}

const darkBackground = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2em;

  &:before {
    content: '';
    position: absolute;
    top: -100em;
    bottom: -100em;
    left: -100em;
    right: -100em;
  }
`
