import Orientation from '../Orientation'
import {baffinIsland} from '../territories/BaffinIsland'
import {bearIsland} from '../territories/BearIsland'
import {faroeIslands} from '../territories/FaroeIslands'
import {greenland} from '../territories/Greenland'
import {iceland} from '../territories/Iceland'
import {labrador} from '../territories/Labrador'
import {newfoundland} from '../territories/Newfoundland'
import {shetland} from '../territories/Shetland'
import Territory from '../Territory'
import Good, {isCraftProduct, isLuxuryGood, isSpecialTile} from './Good'
import GoodsAreaType from './GoodsAreaType'
import HomeGoodsArea, {homePlacementArea} from './HomeGoodsArea'
import IncomePlacementArea, {IncomeDiagonal} from './IncomePlacementArea'
import PlacedGoodsArea from './PlacedGoodsArea'
import {PlacementSpace} from './PlacementSpace'
import {orientMatrix} from './Polyomino'
import TerritoryGoodsArea from './TerritoryGoodsArea'

export default class TerritoryPlacementArea extends IncomePlacementArea {
  constructor(area: PlacedGoodsArea<HomeGoodsArea | TerritoryGoodsArea>) {
    super(orientMatrix(getTerritoryPlacementArea(area.goodsArea), Orientation.Right), getTerritoryIncomeDiagonals(area.goodsArea))
    this.placeGoods(area.placedGoods)
  }

  isValidGood(good: Good): boolean {
    return good === Good.Ore || good === Good.Silver || isLuxuryGood(good) || isCraftProduct(good) || isSpecialTile(good)
  }

  protected isAdjacencyForbidden(good: Good): boolean {
    return isCraftProduct(good)
  }
}

function getTerritoryPlacementArea(goodsArea: HomeGoodsArea | TerritoryGoodsArea): PlacementSpace[][] {
  if (goodsArea.type === GoodsAreaType.Home) {
    return homePlacementArea
  }
  switch (goodsArea.territory) {
    case Territory.Shetland:
      return shetland
    case Territory.FaroeIslands:
      return faroeIslands
    case Territory.Iceland:
      return iceland
    case Territory.Greenland:
      return greenland
    case Territory.BearIsland:
      return bearIsland
    case Territory.BaffinIsland:
      return baffinIsland
    case Territory.Labrador:
      return labrador
    case Territory.Newfoundland:
      return newfoundland
  }
}

function getTerritoryIncomeDiagonals(goodsArea: HomeGoodsArea | TerritoryGoodsArea): IncomeDiagonal[] {
  if (goodsArea.type === GoodsAreaType.Home) {
    return [{maxIncome: 18, x: 12, y: 12}]
  }
  switch (goodsArea.territory) {
    case Territory.Shetland:
      return [{maxIncome: 3, x: 3, y: 4}]
    case Territory.FaroeIslands:
      return [{maxIncome: 4, x: 8, y: 9}]
    case Territory.Iceland:
      return [{maxIncome: 8, x: 8, y: 8}]
    case Territory.Greenland:
      return [{maxIncome: 3, x: 6, y: 8}, {maxIncome: 5, x: 8, y: 6}]
    case Territory.BearIsland:
      return [{maxIncome: 5, x: 4, y: 7}]
    case Territory.BaffinIsland:
      return [{maxIncome: 6, x: 5, y: 5}]
    default:
      return []
  }
}
