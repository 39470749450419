import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import Occupation from '../Occupation'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'

export default class MasterMason extends OccupationRules {
  canUseAnyTimeEffect() {
    return playerHasGoods(this.player, Good.RuneStone)
  }

  anyTimeEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.MasterMason})
    return [spendGoodsMove(this.player.color, Good.RuneStone), receiveGoodsMove(this.player.color, Good.Silver)]
  }

  getPlayerMoves(): Move[] {
    return [receiveGoodsMove(this.player.color, Good.Milk), receiveGoodsMove(this.player.color, Good.Cabbage)]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && move.goods !== Good.Silver) {
      this.player.effects.shift()
    }
    return []
  }
}
