import Game from '../Game'
import GameView from '../GameView'
import MoveType from './MoveType'
import LocationType from '../material/LocationType'

type TakeNewViking = {
  type: MoveType.TakeNewViking
}

export default TakeNewViking

export const takeNewVikingMove: TakeNewViking = {type: MoveType.TakeNewViking}

export function takeNewViking(game: Game | GameView) {
  for (const player of game.players) {
    player.vikings.push({type: LocationType.ThingSquare, player: player.color})
  }
}
