import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import {getActionRules} from '../../AFeastForOdin'
import {isMountainsAndTradeRules} from '../../actions/mountainsAndTrade/MountainsAndTradeRules'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import {isGood} from '../goods/Good'
import {isWholesalerEffect} from './Wholesaler'
import ReceiveGoods from '../../moves/ReceiveGoods'

export default class Merchant extends UpgradeGoodOccupationRules {
  get upgrade() {
    return 2
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      const rules = getActionRules(this.game)
      if (rules?.player.color === this.player.color && isMountainsAndTradeRules(rules)) {
        if (rules.trades.length >= 3 && rules.trades.every(trade => trade === 1)) {
          this.player.effects.unshift({occupation: Occupation.Merchant})
        }
      }
    }
    if (move.type === MoveType.PlayOccupation && move.occupation === Occupation.Wholesaler && move.player === this.player.color) {
      this.player.effects.unshift({occupation: Occupation.Merchant})
    }
    return []
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && isGood(move.goods)) {
      const wholesalerEffect = this.player.effects.find(isWholesalerEffect)
      if (wholesalerEffect) {
        wholesalerEffect.traded.push(move.goods)
      }
    }
    return super.play(move)
  }

  onReceiveGoods(move: ReceiveGoods) {
    super.onReceiveGoods(move)
    const wholesalerEffect = this.player.effects.find(isWholesalerEffect)
    if (!wholesalerEffect) {
      const rules = getActionRules(this.game)
      if (isGood(move.goods) && rules?.player.color === this.player.color && isMountainsAndTradeRules(rules)) {
        rules.pendingAction.goodsTraded!.push(move.goods)
      }
    }
  }
}
