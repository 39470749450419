import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'
import Effect from '../effects/Effect'

type StartEffect = {
  type: MoveType.StartEffect
  player: PlayerColor
  effect: Effect
}

export default StartEffect

export function startEffectMove(player: PlayerColor, effect: Effect): StartEffect {
  return {type: MoveType.StartEffect, player, effect}
}
