import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import {playOccupationMove} from '../../moves/PlayOccupation'
import {isPlayerView} from '../../PlayerView'
import ActionRules from '../ActionRules'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import PendingAction from '../../effects/PendingAction'
import MoveRandomized from '../../moves/MoveRandomized'
import Action from '../Action'

export default abstract class PlayOccupationsRules extends ActionRules {
  abstract get quantity(): number

  canUseEffect() {
    return super.canUseEffect() && this.hasOccupationInHand()
  }

  createPendingAction(): PendingAction {
    return {action: this.action, occupationsPlayed: 0}
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    moves.push(...this.playOccupationMoves())
    if (this.pendingAction.occupationsPlayed! > 0) {
      moves.push(passMove(this.player.color))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView) {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.PlayOccupation:
        if (move.player === this.player.color) {
          this.pendingAction.occupationsPlayed!++
          if (this.pendingAction.occupationsPlayed === this.quantity) {
            this.complete()
          }
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
    }
    return consequences
  }

  protected hasOccupationInHand() {
    return isPlayerView(this.player) ? this.player.hand > 0 : this.player.hand.length > 0
  }

  protected playOccupationMoves() {
    if (isPlayerView(this.player)) return []
    return this.player.hand.map(occupation => playOccupationMove(this.player.color, occupation))
  }
}

export function isPlayOccupations(action: Action) {
  return action === Action.PlayOneOccupation || action === Action.PlayTwoOccupations || action === Action.PlayFourOccupations
}
