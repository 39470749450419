import Move from '../../moves/Move'
import Occupation from '../Occupation'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import {isWeeklyMarket} from './Messenger'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import Good, {isGood} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {getActionRules, playerHasGoods} from '../../AFeastForOdin'

export default class Middleman extends UpgradeGoodOccupationRules {
  eachTimeEffect(): Move[] {
    const actionRules = getActionRules(this.game)
    if (actionRules?.player.color === this.player.color && isWeeklyMarket(actionRules?.action)
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)
      && !actionRules.pendingAction.afterEffects?.includes(Occupation.Middleman)) {
      this.player.effects.push({occupation: Occupation.Middleman})
      if (!actionRules.pendingAction.afterEffects) actionRules.pendingAction.afterEffects = []
      actionRules.pendingAction.afterEffects.push(Occupation.Middleman)
    }
    return []
  }

  delegate() {
    if (!playerHasGoods(this.player, Good.Silver)) return
    return super.delegate()
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color && isGood(move.goods)) {
      consequences.push(spendGoodsMove(this.player.color, Good.Silver))
    }
    return consequences
  }
}
