/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import GameLocalView from '../GameLocalView'
import Images from '../images/Images'
import {canSelectMenuCss, headerHeight, margin, menuCss, menuHeight, selectedMenuCss} from '../styles'
import {displayScreenMove, getScreen} from './DisplayScreen'
import Screen from './Screen'

type Props = {
  game: GameLocalView
}

export default function ExplorationMenu({game}: Props) {
  const playerId = usePlayerId()
  const selected = getScreen(game, playerId) === Screen.BoardsSupply
  const play = usePlay()
  return (
    <button css={[menuCss, position, selected ? selectedMenuCss : canSelectMenuCss]}
            onClick={() => play(displayScreenMove(Screen.BoardsSupply), {local: true})}>
      <Picture src={Images.explorationIcon}/>
    </button>
  )
}

const position = css`
  top: ${headerHeight + margin * 2 + menuHeight}em;
  right: ${margin}em;
`
