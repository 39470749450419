import {chooseMountainStripMove} from '../../moves/ChooseMountainStrip'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import {takeGoodsFromMountainStripMove} from '../../moves/TakeGoodsFromMountainStrip'
import {Rules} from '@gamepark/rules-api'
import Game from '../../Game'
import GameView from '../../GameView'
import PlayerColor from '../../PlayerColor'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import MountainStrip from '../../material/MountainStrip'

export default class TakeMountainsResourcesRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  player: Player | PlayerView
  amount: number
  isValidStrip: (strip: MountainStrip) => boolean

  constructor(game: Game | GameView, player: Player | PlayerView, amount: number, isValidStrip = (_strip: MountainStrip) => true) {
    super(game)
    this.player = player
    this.amount = amount
    this.isValidStrip = isValidStrip
  }

  getActivePlayer(): PlayerColor | undefined {
    return this.player.color
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    if (playerId !== this.player.color) return []
    return this.game.mountainStrips.filter(this.isValidStrip).map(strip => chooseMountainStripMove(this.player.color, strip.id))
  }

  play(move: MoveRandomized | MoveView): Move[] {
    if (move.type === MoveType.ChooseMountainStrip && move.player === this.player.color) {
      const strip = this.game.mountainStrips.find(strip => strip.id === move.strip)!
      return [takeGoodsFromMountainStripMove(this.player.color, move.strip, strip.goods.slice(-this.amount))]
    }
    return []
  }
}
