/** @jsxImportSource @emotion/react */
import {usePlay} from '@gamepark/react-client'
import {useTranslation} from 'react-i18next'
import {redBackground, titleButtonCss} from '../../styles'
import PlayerLocal from '../../PlayerLocal'
import {css} from '@emotion/react'
import {useState} from 'react'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {hasStageForLargeShip} from '@gamepark/a-feast-for-odin/material/boats/LandingStages'
import Building from '@gamepark/a-feast-for-odin/material/Building'
import BuildingBoard from '../../buildings/BuildingBoard'
import BoatTile from '../../boats/BoatTile'
import GameLocalView from '../../GameLocalView'
import {BuildHouseAndBoatEffect} from '@gamepark/a-feast-for-odin/actions/building/BuildHouseAndBoatRules'
import {chooseEffectMove} from '@gamepark/a-feast-for-odin/moves/ChooseEffect'

type Props = {
  game: GameLocalView
  player: PlayerLocal
}

export default function BuildHouseAndBoatChoice({game, player}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const [choice, setChoice] = useState<BuildHouseAndBoatEffect>()
  const boat = hasStageForLargeShip(player)
  const stoneHouse = game.buildingSupply[Building.StoneHouse] > 0
  const longHouse = game.buildingSupply[Building.LongHouse] > 0

  return (
    <div css={css`text-align: center`}>
      <p>{t('buildHouseAndBoat.choice')}</p>
      <div css={buildingChoice}>
        <div css={[resultCss, choice === BuildHouseAndBoatEffect.STONE_HOUSE_LONG_BOAT && selectedCss]}
             onClick={() => boat && stoneHouse && setChoice(BuildHouseAndBoatEffect.STONE_HOUSE_LONG_BOAT)}>
          <div css={css`position: relative;`}>
            <BuildingBoard building={Building.StoneHouse} css={[!stoneHouse && unavailable]}/>
            {!stoneHouse && <span css={soldOut}>{t('Sold out')}</span>}
          </div>
          <BoatTile boatType={BoatType.Longship} css={[!boat && unavailable]}/>
        </div>
        <div css={[resultCss, choice === BuildHouseAndBoatEffect.LONG_HOUSE_KNARR && selectedCss]}
             onClick={() => boat && stoneHouse && setChoice(BuildHouseAndBoatEffect.LONG_HOUSE_KNARR)}>
          <div css={css`position: relative;`}>
            <BuildingBoard building={Building.LongHouse} css={[!longHouse && unavailable]}/>
            {!longHouse && <span css={soldOut}>{t('Sold out')}</span>}
          </div>
          <BoatTile boatType={BoatType.Knarr} css={[!boat && unavailable]}/>
        </div>
      </div>
      <p>
        <button disabled={!choice} css={titleButtonCss} onClick={() => choice && play(chooseEffectMove(player.color, choice))}>
          {t('Build')}
        </button>
      </p>
    </div>
  )
}

const buildingChoice = css`
  display: flex;
  justify-content: space-evenly;
`

const resultCss = css`
  border: 0.5em solid ${redBackground};
  border-radius: 1em;
  padding: 1em;
  display: flex;
  align-items: center;
  filter: brightness(50%);

  > *:not(:first-of-type) {
    margin-left: 1em;
  }
`

const selectedCss = css`
  border: 0.5em solid darkgreen;
  filter: none;
`

const unavailable = css`
  filter: grayscale();
  text-align: center;
`

const soldOut = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  font-size: 6em;
  font-weight: bold;
  color: darkred;
`