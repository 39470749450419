import Action from '../actions/Action'
import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type ChooseAction = {
  type: MoveType.ChooseAction
  player: PlayerColor
  action: Action
  choice?: number
}

export default ChooseAction

export function chooseActionMove(player: PlayerColor, action: Action): ChooseAction {
  return {type: MoveType.ChooseAction, player, action}
}
