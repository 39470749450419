/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import RaidingRules from '@gamepark/a-feast-for-odin/actions/sailing/RaidingRules'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import {declareFailureMove} from '@gamepark/a-feast-for-odin/moves/DeclareFailure'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import {spendGoodsMove} from '@gamepark/a-feast-for-odin/moves/SpendGoods'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'
import {throwDiceMove} from '@gamepark/a-feast-for-odin/moves/ThrowDice'
import {usePlay} from '@gamepark/react-client'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import {titleButtonCss} from '../../styles'
import Slider from '../../util/Slider'
import WeaponCard from '../../weapons/WeaponCard'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import Occupation from '@gamepark/a-feast-for-odin/material/Occupation'
import RaiderEffectInfo from './RaiderEffectInfo'

type Props = {
  raiding: RaidingRules
}

export default function RaidingChoice({raiding}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const pendingDice = raiding.pendingDice
  const [selectedGood, setSelectedGood] = useState<Good>()
  const playerStone = raiding.player.goods[Good.Stone] ?? 0
  const [stoneSpent, setStoneSpent] = useState(0)
  const playerSwords = raiding.player.weapons[Weapon.Sword] ?? 0
  const [swordsSpent, setSwordsSpent] = useState(0)
  const playerSpears = raiding.player.weapons[Weapon.Spear] ?? 0
  const [spearsSpent, setSpearsSpent] = useState(0)
  const battleResult = raiding.getBattleResult() + stoneSpent * raiding.getGoodValue(Good.Stone) + swordsSpent + spearsSpent
  useEffect(() => {
    if (selectedGood && selectedGood !== Good.Stone && raiding.swordValue(selectedGood) > battleResult) {
      setSelectedGood(undefined)
    }
  }, [selectedGood, battleResult])

  function loot() {
    if (stoneSpent > 0) {
      play(spendGoodsMove(raiding.player.color, {[Good.Stone]: stoneSpent}))
    }
    if (swordsSpent > 0) {
      play(spendWeaponsMove(raiding.player.color, {[Weapon.Sword]: swordsSpent}))
    }
    if (spearsSpent > 0) {
      play(spendWeaponsMove(raiding.player.color, {[Weapon.Spear]: spearsSpent}))
    }
    play(receiveGoodsMove(raiding.player.color, selectedGood!))
  }

  const diceModifier = useMemo(() => raiding.getDiceModifier(), [raiding])

  return (
    <div css={raidingCss}>
      <div css={diceResultCss}>
        <p>[{pendingDice.side}]</p>
        {diceModifier !== 0 && (
          raiding.action === Action.Pillaging1 || raiding.action === Action.Pillaging2 ?
            <p>{t('pillaging.modifier', {modifier: diceModifier > 0 ? '+' + diceModifier : diceModifier})}</p>
            : <p>{t('occupations.modifier', {modifier: diceModifier > 0 ? '+' + diceModifier : diceModifier})}</p>
        )}
        <p>
          <button css={titleButtonCss} onClick={() => play(throwDiceMove(raiding.dice), {delayed: true})}
                  disabled={pendingDice.rollNumber === pendingDice.maxRolls || raiding.isBestRoll(pendingDice.side)}>
            {t('reroll.button', {number: pendingDice.maxRolls - pendingDice.rollNumber})}
          </button>
        </p>
      </div>
      <div css={battleResultCss}>
        <p>{t('battle.result', {battleResult})}</p>
        <Slider css={sliderCss} value={swordsSpent} max={playerSwords} onValueChange={(value) => setSwordsSpent(value)}>
          <span css={sliderContent}>{swordsSpent}/{playerSwords}&nbsp;<WeaponCard weapon={Weapon.Sword} css={css`font-size: 0.05em;`}/></span>
        </Slider>
        {raiding.player.occupations.includes(Occupation.MeleeFighter) &&
          <Slider css={sliderCss} value={spearsSpent} max={playerSpears} onValueChange={(value) => setSpearsSpent(value)}>
            <span css={sliderContent}>{spearsSpent}/{playerSpears}&nbsp;<WeaponCard weapon={Weapon.Spear} css={css`font-size: 0.05em;`}/></span>
          </Slider>
        }
        <Slider css={sliderCss} value={stoneSpent} max={playerStone} onValueChange={(value) => setStoneSpent(value)}>
          <span css={sliderContent}>{stoneSpent}/{playerStone}&nbsp;<GoodItem good={Good.Stone} height={1}/></span>
        </Slider>
      </div>
      <div css={gridContainer}>
        {raiding.potentialLoot().map(good =>
          <GoodItem key={good} good={good} onClick={() => raiding.swordValue(good) <= battleResult && setSelectedGood(good)}
                    css={[good === selectedGood && selectedCss, raiding.swordValue(good) > battleResult && disabledLoot]}/>
        )}
        <div css={[failureLootCss, selectedGood === Good.Stone && selectedCss]} onClick={() => setSelectedGood(Good.Stone)}>
          <WeaponCard weapon={Weapon.Sword}/>
          <GoodItem good={Good.Stone}/>
        </div>
      </div>
      {raiding.player.occupations.includes(Occupation.Raider) &&
        <RaiderEffectInfo battleResult={battleResult} selectedGoodValue={selectedGood && raiding.swordValue(selectedGood)}/>
      }
      <p css={css`text-align: center;`}>
        {selectedGood ?
          selectedGood === Good.Stone ?
            <button css={titleButtonCss} onClick={() => play(declareFailureMove(raiding.player.color))}>{t('Declare failure')}</button>
            :
            <button css={titleButtonCss} onClick={loot}>{t('claim.loot')}</button>
          :
          <button css={titleButtonCss} disabled>{t('select.loot')}</button>
        }
      </p>
    </div>
  )
}

const raidingCss = css`
  display: flex;
  flex-direction: column;
`

const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1em;
  align-items: center;
  justify-items: center;
  margin: 1em;
  font-size: 0.8em;
`

const diceResultCss = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2em 0;

  > p {
    margin: 0;
  }
`

const battleResultCss = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2em 0;

  > p {
    margin: 0;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const sliderCss = css`
  width: fit-content;
  flex-shrink: 0;
  margin: 0 2em;

  > span {
    font-size: 3.5em;
    margin: 0 0.5em;
  }
`

const sliderContent = css`
  display: inline-flex;
  align-items: center;
`

const failureLootCss = css`
  display: flex;
  align-items: center;
  font-size: 0.8em;
`

const disabledLoot = css`
  filter: grayscale();
  cursor: auto;
`
