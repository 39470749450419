import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class MeatPreserver extends OccupationRules {
  immediateEffect() {
    const gameMeat = this.player.goods[Good.GameMeat] ?? 0
    const whaleMeat = this.player.goods[Good.WhaleMeat] ?? 0
    return gameMeat > 0 && whaleMeat > 0 ? [receiveGoodsMove(this.player.color, {[Good.SaltMeat]: Math.min(gameMeat, whaleMeat)})] : []
  }
}
