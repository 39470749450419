import Move from '../../moves/Move'
import Occupation from '../Occupation'
import NoHarvestOccupation from './NoHarvestOccupation'
import TakeMountainsResourcesRules from '../../actions/mountainsAndTrade/TakeMountainsResourcesRules'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {passMove} from '../../moves/Pass'

export default class Wanderer extends NoHarvestOccupation {
  noHarvestEffect(): Move[] {
    if (this.game.mountainStrips.length > 0) {
      this.player.effects.unshift({occupation: Occupation.Wanderer})
    }
    return []
  }

  getPlayerMoves() {
    return [passMove(this.player.color)]
  }

  delegate() {
    return new TakeMountainsResourcesRules(this.game, this.player, 2)
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.TakeGoodsFromMountainStrip:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
    }
    return consequences
  }
}
