/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import {Letterbox} from '@gamepark/react-components'
import GameLocalView from './GameLocalView'
import NavigationMenu from './navigation/NavigationMenu'
import ScreenDisplay from './ScreenDisplay'
import RulesDialog from './RulesDialog'
import EndOfGameDialog from './EndOfGameDialog'

type Props = {
  game: GameLocalView
}

export default function GameDisplay({game}: Props) {
  return (
    <Letterbox css={letterBoxStyle} width={185} height={100} top={0}>
      <NavigationMenu game={game}/>
      <ScreenDisplay game={game}/>
      <RulesDialog game={game}/>
      <EndOfGameDialog game={game}/>
    </Letterbox>
  )
}

const fadeIn = keyframes`
  from, 50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const letterBoxStyle = css`
  animation: ${fadeIn} 3s ease-in forwards;
`