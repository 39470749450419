import {Action, Competitive, RandomMove, Rules, SecretInformation, Undo} from '@gamepark/rules-api'
import shuffle from 'lodash.shuffle'
import {AFeastForOdinOptions, isGameOptions} from './AFeastForOdinOptions'
import Game from './Game'
import GameView, {isGameView} from './GameView'
import Building, {buildingValue} from './material/Building'
import Good, {animals, Goods, goodsArrayToGoodsMap, goodScore, goodsToArray, isGood} from './material/goods/Good'
import GoodsAreaType from './material/goods/GoodsAreaType'
import {specialTiles} from './material/goods/SpecialTile'
import {mountainStrips} from './material/MountainStrip'
import Occupation, {deckA, deckB, deckC, occupationsScore, startingDeckA, startingDeckB, startingDeckC} from './material/Occupation'
import Territory, {territoryValue} from './material/Territory'
import Weapon from './material/Weapon'
import {addMountainStrip, addMountainStripInView, AddMountainStripView} from './moves/AddMountainStrip'
import {armBoat, isArmBoatValid} from './moves/ArmBoat'
import {changeCurrentPlayer} from './moves/ChangeCurrentPlayer'
import {claimTerritory} from './moves/ClaimTerritory'
import {drawOccupation, drawOccupationInView} from './moves/DrawOccupation'
import {drawWeapon, drawWeaponInView, DrawWeaponView} from './moves/DrawWeapon'
import {emigrateBoat} from './moves/EmigrateBoat'
import {discardBoat} from './moves/DiscardBoat'
import Move from './moves/Move'
import MoveRandomized from './moves/MoveRandomized'
import MoveType from './moves/MoveType'
import MoveView from './moves/MoveView'
import {isPlaceGoodsValid, placeGoods} from './moves/PlaceGoods'
import {playOccupation} from './moves/PlayOccupation'
import {receiveGoods} from './moves/ReceiveGoods'
import {receiveWeaponInView, receiveWeapons, ReceiveWeaponsView} from './moves/ReceiveWeapons'
import {shuffleWeaponsDeck, shuffleWeaponsDeckInView, shuffleWeaponsDeckMove, ShuffleWeaponsDeckRandomized} from './moves/ShuffleWeaponsDeck'
import {spendGoods, spendGoodsMove} from './moves/SpendGoods'
import {spendWeapons} from './moves/SpendWeapons'
import {takeBoat, takeBoatMove} from './moves/TakeBoat'
import {takeBuilding} from './moves/TakeBuilding'
import {takeNewViking} from './moves/TakeNewViking'
import {turnExplorationBoards} from './moves/TurnExplorationBoards'
import Phase from './phases/Phase'
import PlayerColor from './PlayerColor'
import Player from './Player'
import PlayerView from './PlayerView'
import VikingPhaseRules from './phases/VikingPhaseRules'
import HarvestPhaseRules from './phases/HarvestPhaseRules'
import ExplorationPhaseRules from './phases/ExplorationPhaseRules'
import WeaponPhaseRules from './phases/WeaponPhaseRules'
import ActionsPhaseRules from './phases/ActionsPhaseRules'
import StartPlayerPhaseRules from './phases/StartPlayerPhaseRules'
import IncomePhaseRules from './phases/IncomePhaseRules'
import BreedingPhaseRules from './phases/BreedingPhaseRules'
import FeastPhaseRules from './phases/FeastPhaseRules'
import BonusPhaseRules from './phases/BonusPhaseRules'
import MountainPhaseRules from './phases/MountainPhaseRules'
import ReturnPhaseRules from './phases/ReturnPhaseRules'
import {takeGoodsFromMountainStrip} from './moves/TakeGoodsFromMountainStrip'
import OccupationsRules from './material/occupations/OccupationsRules'
import LocationType from './material/LocationType'
import BoatType, {boatsValue} from './material/boats/BoatType'
import {canBuyBoat} from './moves/BuyBoat'
import {disarmBoat} from './moves/DisarmBoat'
import PendingAction from './effects/PendingAction'
import Modifier from './material/occupations/Modifier'
import ActionsRules from './actions/ActionsRules'
import {moveViking} from './moves/MoveViking'
import {getBanquet} from './phases/Feast'
import Effect from './effects/Effect'
import {FollowerEffect} from './material/occupations/Follower'
import {HornblowerEffect} from './material/occupations/Hornblower'
import {LatecomerEffect} from './material/occupations/Latecomer'
import {FestiveHunterEffect} from './material/occupations/FestiveHunter'
import {removeGoodsFromMountainStrip} from './moves/RemoveGoodsFromMountainStrip'
import EndOfGameRules from './phases/EndOfGameRules'
import {isBuildingPlacedGoodsArea, isTerritoryPlacedGoodsArea} from './material/goods/PlacedGoodsArea'
import {getIncome} from './phases/Income'
import {getPlacementArea} from './material/goods/GoodsArea'
import {getHomePlacementArea} from './material/goods/HomeGoodsArea'
import {placeGoodsInView} from './moves/PlacedGoodsLocal'

export const numberOfVikings = 12

export default class AFeastForOdin extends Rules<Game | GameView, Move | MoveView, PlayerColor>
  implements RandomMove<Move, MoveRandomized>, SecretInformation<GameView, Move, MoveView, PlayerColor>, Undo<Game | GameView, Move | MoveView, PlayerColor>,
    Competitive<Game | GameView, Move | MoveView, PlayerColor> {

  constructor(game: Game | GameView)
  constructor(options: AFeastForOdinOptions)
  constructor(arg: Game | GameView | AFeastForOdinOptions) {
    if (isGameOptions(arg)) {
      const startingOccupations: Occupation[] = []
      const occupations: Occupation[] = []
      if (arg.deckB) {
        startingOccupations.push(...startingDeckB)
        occupations.push(...deckB)
      }
      if (arg.deckC) {
        startingOccupations.push(...startingDeckC)
        occupations.push(...deckC)
      }
      if (arg.deckA || occupations.length === 0) {
        startingOccupations.push(...startingDeckA)
        occupations.push(...deckA)
      }
      const startOcc = shuffle(startingOccupations)
      const bows = Array(12 - arg.players.length).fill(Weapon.Bow)
      const snares = Array(12 - arg.players.length).fill(Weapon.Snare)
      const spears = Array(12 - arg.players.length).fill(Weapon.Spear)
      const swords = Array(11).fill(Weapon.Sword)
      const game: Game = {
        players: arg.players.map(player => ({
          color: player.id,
          goods: {[Good.Mead]: 1},
          hand: [startOcc.pop()!],
          occupations: [],
          weapons: {[Weapon.Bow]: 1, [Weapon.Snare]: 1, [Weapon.Spear]: 1},
          vikings: [...Array(arg.shortGame ? 6 : 5)].map(() => ({type: LocationType.ThingSquare, player: player.id})),
          landingStages: {whalingBoats: [null, null, null], longBoats: [null, null, null, null]},
          emigration: [],
          placedGoodsAreas: [
            {goodsArea: {type: GoodsAreaType.Home}, placedGoods: []},
            {goodsArea: {type: GoodsAreaType.Banquet}, placedGoods: []}
          ],
          thingPenalties: 0,
          effects: [],
          exhaustedOccupations: []
        })),
        round: 1,
        phase: 1,
        currentPlayer: arg.players[0].id,
        buildingSupply: {[Building.Shed]: 3, [Building.StoneHouse]: 3, [Building.LongHouse]: 5},
        mountainStripsSupply: shuffle(Array.from(mountainStrips.keys())),
        mountainStrips: [],
        specialTilesSupply: specialTiles,
        weaponsDeck: shuffle([...bows, ...snares, ...spears, ...swords]),
        weaponsDiscard: [],
        unexploredTerritories: [
          {territory: Territory.Shetland, silver: 0},
          {territory: Territory.FaroeIslands, silver: 0},
          {territory: Territory.Iceland, silver: 0},
          {territory: Territory.Greenland, silver: 0}
        ],
        occupationsDeck: shuffle(occupations),
        shortGame: arg.shortGame
      }
      if (game.players.length === 4) {
        game.imitations = [Math.floor(Math.random() * 2) + 1, Math.floor(Math.random() * 2) + 3]
      }
      addMountainStrip(game)
      addMountainStrip(game)
      if (arg.players.length === 4) {
        addMountainStrip(game)
      }
      super(game)
    } else {
      super(arg)
    }
  }

  delegate(): Rules<Game | GameView, Move | MoveView, PlayerColor> | undefined {
    switch (this.game.phase) {
      case Phase.Viking:
        return new VikingPhaseRules(this.game)
      case Phase.Harvest:
        return new HarvestPhaseRules(this.game)
      case Phase.Exploration:
        return new ExplorationPhaseRules(this.game)
      case Phase.Weapon:
        return new WeaponPhaseRules(this.game)
      case Phase.Actions:
        return new ActionsPhaseRules(this.game)
      case Phase.StartPlayer:
        return new StartPlayerPhaseRules(this.game)
      case Phase.Income:
        return new IncomePhaseRules(this.game)
      case Phase.Breeding:
        return new BreedingPhaseRules(this.game)
      case Phase.Feast:
        return new FeastPhaseRules(this.game)
      case Phase.Bonus:
        return new BonusPhaseRules(this.game)
      case Phase.Mountain:
        return new MountainPhaseRules(this.game)
      case Phase.Return:
        return new ReturnPhaseRules(this.game)
      case Phase.EndOfGame:
        return new EndOfGameRules(this.game)
      default:
        return
    }
  }

  delegates(): Rules<Game | GameView, Move | MoveView, PlayerColor>[] {
    return super.delegates().concat(
      this.game.players.filter(player => player.effects.length > 0)
        .map(player => new OccupationsRules[player.effects[0].occupation](this.game, player))
    )
  }

  isLegalMove(playerId: PlayerColor, move: Move): boolean {
    const player = this.game.players.find(player => player.color === playerId)!
    switch (move.type) {
      case MoveType.PlaceGoods:
        return isPlaceGoodsValid(this.game, playerId, move)
      case MoveType.BuyBoat:
        return canBuyBoat(player, move.boat)
      case MoveType.ArmBoat:
        return isArmBoatValid(this.game, playerId, move)
      case MoveType.DisarmBoat:
        if (player.occupations.includes(Occupation.Modifier)) {
          return new Modifier(this.game, player).isLegalMove(playerId, move)
        } else {
          return super.isLegalMove(playerId, move)
        }
      case MoveType.PlayOccupationEffect:
        return !player.effects.length && player.occupations.includes(move.occupation)
          && new OccupationsRules[move.occupation](this.game, player).canUseAnyTimeEffect()
      default:
        return super.isLegalMove(playerId, move)
    }
  }

  randomize(move: Move): MoveRandomized {
    if (isGameView(this.game)) return move as MoveRandomized
    switch (move.type) {
      case MoveType.ThrowDice:
        return {...move, side: Math.floor(Math.random() * move.dice) + 1}
      case MoveType.ShuffleWeaponsDeck:
        return {...move, newDeck: shuffle(this.game.weaponsDeck.length > 0 ? this.game.weaponsDeck : this.game.weaponsDiscard)}
      default:
        return move
    }
  }

  isLastRound(): boolean {
    return this.game.shortGame ? this.game.round === 6 : this.game.round === 7
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.AddMountainStrip:
        if (isGameView(this.game)) {
          addMountainStripInView(this.game, move as AddMountainStripView)
        } else {
          addMountainStrip(this.game)
        }
        break
      case MoveType.TakeNewViking:
        takeNewViking(this.game)
        break
      case MoveType.ReceiveGoods:
        receiveGoods(this.game, move)
        break
      case MoveType.StartNextPhase:
        if (this.game.phase === Phase.Feast && this.isLastRound()) {
          this.game.phase = Phase.EndOfGame
        } else {
          this.game.phase++
        }
        break
      case MoveType.DrawWeapon:
        if (isGameView(this.game)) {
          drawWeaponInView(this.game, move as DrawWeaponView)
        } else {
          drawWeapon(this.game, move)
        }
        break
      case MoveType.ChangeCurrentPlayer:
        changeCurrentPlayer(this.game)
        break
      case MoveType.SpendGoods:
        spendGoods(this.game, move)
        break
      case MoveType.TakeBuilding:
        takeBuilding(this.game, move)
        break
      case MoveType.TakeBoat:
      case MoveType.BuildBoat:
        takeBoat(this.game, move)
        break
      case MoveType.SpendWeapons:
        spendWeapons(this.game, move)
        break
      case MoveType.ReceiveWeapons:
        if (isGameView(this.game)) {
          receiveWeaponInView(this.game, move as ReceiveWeaponsView)
        } else {
          consequences.push(...receiveWeapons(this.game, move))
        }
        break
      case MoveType.ShuffleWeaponsDeck:
        if (isGameView(this.game)) {
          shuffleWeaponsDeckInView(this.game)
        } else {
          shuffleWeaponsDeck(this.game, move as ShuffleWeaponsDeckRandomized)
        }
        break
      case MoveType.PlaceGoods:
        if (isGameView(this.game)) {
          placeGoodsInView(this.game, move)
        } else {
          placeGoods(this.game, move)
        }
        break
      case MoveType.TurnExplorationBoards:
        turnExplorationBoards(this.game)
        break
      case MoveType.ClaimTerritory:
        consequences.push(...claimTerritory(this.game, move))
        break
      case MoveType.DrawOccupation:
        if (isGameView(this.game)) {
          drawOccupationInView(this.game, move)
        } else {
          drawOccupation(this.game, move)
        }
        break
      case MoveType.PlayOccupation:
        consequences.push(...playOccupation(this.game, move))
        break
      case MoveType.EmigrateBoat:
        emigrateBoat(this.game, move)
        break
      case MoveType.ArmBoat:
        armBoat(this.game, move)
        break
      case MoveType.DisarmBoat:
        disarmBoat(this.game, move)
        break
      case MoveType.DiscardBoat:
        discardBoat(this.game, move)
        break
      case MoveType.StartNewRound:
        this.game.round++
        this.game.phase = Phase.Viking
        break
      case MoveType.TakeGoodsFromMountainStrip:
        takeGoodsFromMountainStrip(this.game, move)
        break
      case MoveType.MoveViking:
        this.game.players.find(player => player.color === move.player)!.vikings[move.viking] = move.location
        break
      case MoveType.BuyBoat:
        return [spendGoodsMove(move.player, {[Good.Silver]: boatsValue[move.boat]}), takeBoatMove(move.player, move.boat)]
      case MoveType.PlayOccupationEffect: {
        const player = this.game.players.find(player => player.color === move.player)!
        const rules = new OccupationsRules[move.occupation](this.game, player)
        if (rules.anyTimeEffect) {
          consequences.push(...rules.anyTimeEffect())
        }
        break
      }
      case MoveType.StartEffect: {
        const player = this.game.players.find(player => player.color === move.player)!
        player.effects.unshift(move.effect)
        break
      }
      case MoveType.EndEffect: {
        const player = this.game.players.find(player => player.color === move.player)!
        player.effects.shift()
        break
      }
      case MoveType.ReturnVikings: {
        const player = this.game.players.find(player => player.color === move.player)!
        const vikings: number[] = []
        for (const action of move.actions) {
          vikings.push(player.vikings.findIndex((location, viking) =>
            !vikings.includes(viking) && location.type === LocationType.Action && location.action === action
          ))
        }
        consequences.push(...vikings.map(viking => moveViking(player.color, viking, {type: LocationType.ThingSquare, player: player.color})))
        break
      }
      case MoveType.ClearBanquet: {
        const player = this.game.players.find(player => player.color === move.player)!
        getBanquet(player).placedGoods = []
        break
      }
      case MoveType.RemoveGoodsFromMountainStrip:
        removeGoodsFromMountainStrip(this.game, move)
        break
    }
    return consequences.concat(this.getOccupationsEachTimeEffects(move))
  }

  getOccupationsEachTimeEffects(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    const moves: (Move | MoveView)[] = []
    for (const player of this.game.players) {
      for (const occupation of player.occupations) {
        const rules = new OccupationsRules[occupation](this.game, player)
        if (rules.eachTimeEffect)
          moves.push(...rules.eachTimeEffect(move))
      }
    }
    return moves
  }

  getAutomaticMoves(): Move[] {
    const weaponsDeckSize = isGameView(this.game) ? this.game.weaponsDeck : this.game.weaponsDeck.length
    if (weaponsDeckSize === 0 && this.game.weaponsDiscard.length > 0) {
      return [shuffleWeaponsDeckMove]
    }
    return super.getAutomaticMoves()
  }

  getView(playerId?: PlayerColor): GameView {
    if (isGameView(this.game)) return this.game
    return {
      ...this.game,
      players: this.game.players.map(player => playerId === player.color ? player : {...player, hand: player.hand.length}),
      weaponsDeck: this.game.weaponsDeck.length,
      mountainStripsSupply: this.game.mountainStripsSupply.length,
      occupationsDeck: this.game.occupationsDeck.length
    }
  }

  getPlayerView(playerId: PlayerColor): GameView {
    return this.getView(playerId)
  }

  getMoveView(move: MoveRandomized, playerId?: PlayerColor): MoveView {
    if (isGameView(this.game)) throw new Error('Cannot get move view on client side')
    switch (move.type) {
      case MoveType.DrawWeapon:
        return {...move, weapon: this.game.weaponsDeck[0]}
      case MoveType.AddMountainStrip:
        return {...move, strip: this.game.mountainStripsSupply[0]}
      case MoveType.ReceiveWeapons:
        const weaponsDeck = this.game.weaponsDeck
        return {...move, fromDeck: move.weapons.filter(weapon => !this.game.weaponsDiscard.includes(weapon) && weaponsDeck.includes(weapon)).length}
      case MoveType.ShuffleWeaponsDeck:
        return {type: MoveType.ShuffleWeaponsDeck}
      case MoveType.DrawOccupation:
        return move.player === playerId ? {...move, occupation: this.game.occupationsDeck[0]} : move
      default:
        return move
    }
  }

  getPlayerMoveView(move: MoveRandomized, playerId: PlayerColor): MoveView {
    return this.getMoveView(move, playerId)
  }

  canUndo(action: Action<Move | MoveView>, consecutiveActions: Action<Move | MoveView>[]): boolean {
    switch (action.move.type) {
      case MoveType.ArmBoat:
      case MoveType.DisarmBoat:
      case MoveType.BuyBoat:
      case MoveType.PlaceGoods:
        return !consecutiveActions.some(consecutiveAction => consecutiveAction.playerId === action.playerId)
      default:
        if (!this.isPredictableMove(action.move)) return false
        for (const consequence of action.consequences) {
          if (!this.isPredictableMove(consequence)) return false
          if (consequence.type === MoveType.ChangeCurrentPlayer || consequence.type === MoveType.StartNextPhase) return false
        }
        if (consecutiveActions.length > 0) return false
    }
    return true
  }

  isPredictableMove(move: Move): move is Move & MoveView {
    return move.type !== MoveType.DrawWeapon
      && move.type !== MoveType.AddMountainStrip
      && move.type !== MoveType.ReceiveWeapons
      && move.type !== MoveType.DrawOccupation
      && move.type !== MoveType.ThrowDice
  }

  rankPlayers(playerA: PlayerColor, playerB: PlayerColor): number {
    return this.getScore(playerB) - this.getScore(playerA)
  }

  getScore(playerId: PlayerColor): number {
    const player = this.game.players.find(player => player.color === playerId)!
    return this.scorePositiveSubTotal(player) + this.scoreNegativeSubTotal(player)
  }

  scorePositiveSubTotal(player: Player | PlayerView) {
    return this.scoreShips(player) + this.scoreEmigration(player) + this.scoreExploration(player) + this.scoreBuildings(player) + this.scoreAnimals(player)
      + this.scoreOccupations(player) + this.scoreSilver(player) + this.scoreIncome(player) + this.scoreGoods(player)
  }

  scoreShips(player: Player | PlayerView) {
    return player.landingStages.whalingBoats.reduce((score, stage) => stage !== null ? score + 3 : score, 0)
      + player.landingStages.longBoats.reduce((score, stage) => !stage ? score : stage.type === BoatType.Knarr ? 5 : 8, 0)
  }

  scoreEmigration(player: Player | PlayerView) {
    return player.emigration.reduce((score, boatType) => boatType === BoatType.Knarr ? score + 18 : score + 21, 0)
  }

  scoreExploration(player: Player | PlayerView) {
    return player.placedGoodsAreas.filter(isTerritoryPlacedGoodsArea).reduce((score, area) => score + territoryValue[area.goodsArea.territory], 0)
  }

  scoreBuildings(player: Player | PlayerView) {
    return player.placedGoodsAreas.filter(isBuildingPlacedGoodsArea).reduce((score, area) => score + buildingValue[area.goodsArea.building], 0)
  }

  scoreAnimals(player: Player | PlayerView) {
    return animals.reduce(animal => (player.goods[animal] ?? 0) * (goodScore[animal] ?? 0), 0)
  }

  scoreOccupations(player: Player | PlayerView) {
    return player.occupations.reduce((score, occupation) => score + occupationsScore[occupation], 0)
  }

  scoreSilver(player: Player | PlayerView) {
    return player.goods[Good.Silver] ?? 0
  }

  scoreIncome(player: Player | PlayerView) {
    return getIncome(player)
  }

  scoreGoods(player: Player | PlayerView) {
    return player.placedGoodsAreas.reduce((score, area) =>
        score + area.placedGoods.reduce((score, {good}) => animals.includes(good) ? score : score + (goodScore[good] ?? 0), 0)
      , 0) + goodsToArray(player.goods).reduce((score, good) => animals.includes(good) ? score : score + (goodScore[good] ?? 0), 0)
  }

  scoreNegativeSubTotal(player: Player | PlayerView) {
    return this.thingPenalties(player) + player.placedGoodsAreas.reduce((score, area) => score + getPlacementArea(player, area).getMalus(), 0)
  }

  homeBoardMalus(player: Player | PlayerView) {
    return getHomePlacementArea(player).getMalus()
  }

  explorationBoardMalus(player: Player | PlayerView) {
    return player.placedGoodsAreas.filter(isTerritoryPlacedGoodsArea).reduce((score, area) => score + getPlacementArea(player, area).getMalus(), 0)
  }

  buildingsMalus(player: Player | PlayerView) {
    return player.placedGoodsAreas.filter(isBuildingPlacedGoodsArea).reduce((score, area) => score + getPlacementArea(player, area).getMalus(), 0)
  }

  thingPenalties(player: Player | PlayerView) {
    return player.thingPenalties * -3
  }
}

export function playerHasGoods(player: Player | PlayerView, goods: Goods) {
  if (isGood(goods)) {
    return !!player.goods[goods]
  } else if (Array.isArray(goods)) {
    goods = goodsArrayToGoodsMap(goods)
  }
  for (const good in goods) {
    if (!player.goods[good] || player.goods[good] < goods[good]) {
      return false
    }
  }
  return true
}

export function countSheep(player: Player | PlayerView) {
  return (player.goods[Good.Sheep] ?? 0) + (player.goods[Good.PregnantSheep] ?? 0)
}

export function countCattle(player: Player | PlayerView) {
  return (player.goods[Good.Cattle] ?? 0) + (player.goods[Good.PregnantCattle] ?? 0)
}

export function hasSilver(player: Player | PlayerView, quantity = 1) {
  return quantity === 0 || playerHasGoods(player, {[Good.Silver]: quantity})
}

export function isEffectWithPendingAction(effect?: Effect): effect is (FollowerEffect | HornblowerEffect | LatecomerEffect | FestiveHunterEffect) {
  switch (effect?.occupation) {
    case Occupation.Follower:
    case Occupation.Hornblower:
    case Occupation.Latecomer:
    case Occupation.FestiveHunter:
      return true
  }
  return false
}

export function getPendingAction(game: Game | GameView): PendingAction | undefined {
  for (const player of game.players) {
    const effect = player.effects[0]
    if (isEffectWithPendingAction(effect) && effect.pendingAction) {
      return effect.pendingAction
    }
  }
  return game.pendingAction
}

export function getActionRules(game: (Game | GameView)) {
  for (const player of game.players) {
    const effect = player.effects[0]
    if (isEffectWithPendingAction(effect) && effect.pendingAction) {
      return new ActionsRules[effect.pendingAction.action](game, player)
    }
  }
  if (game.pendingAction) {
    return new ActionsRules[game.pendingAction.action](game)
  }
  return
}