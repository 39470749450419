import OccupationRules from './OccupationRules'
import LocationType from '../LocationType'
import Action from '../../actions/Action'
import {returnVikingsMove} from '../../moves/ReturnVikings'

export default class Homecomer extends OccupationRules {
  immediateEffect() {
    const actions: Action[] = []
    for (let viking = 0; viking < this.player.vikings.length; viking++) {
      const location = this.player.vikings[viking]
      if (location.type === LocationType.Action && location.action % 10 === 4 && !actions.includes(location.action)) {
        actions.push(location.action)
      }
    }
    return [returnVikingsMove(this.player.color, actions)]
  }
}
