/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {banquet} from '@gamepark/a-feast-for-odin/material/goods/Banquet'
import {getHomeGoodsArea, getHomePlacementArea, homeGoodsArea} from '@gamepark/a-feast-for-odin/material/goods/HomeGoodsArea'
import {BanquetPlacementArea, getBanquet} from '@gamepark/a-feast-for-odin/phases/Feast'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {useMemo} from 'react'
import PlayerBoats from '../../boats/PlayerBoats'
import GameLocalView from '../../GameLocalView'
import Images from '../../images/Images'
import {
  banquetLeft,
  banquetSquareSize,
  banquetTop,
  headerHeight,
  homeAreaLeft,
  homeAreaTop,
  margin,
  playerBoardHeight,
  playerBoardWidth,
  squareSize
} from '../../styles'
import DisplayCardsButton from '../cards/DisplayCardsButton'
import PlayerCards from '../cards/PlayerCards'
import DisplayGoodsButton from '../goods/DisplayGoodsButton'
import PlayerGoods from '../goods/PlayerGoods'
import ThingPenaltyTile from '../ThingPenaltyTile'
import Viking from '../Viking'
import ExtraBoards from './ExtraBoards'
import GoodsDropArea from './GoodsDropArea'
import PlayerEmigrationTiles from './PlayerEmigrationTiles'
import Stables from './Stables'
import {numberOfVikings} from '@gamepark/a-feast-for-odin/AFeastForOdin'
import LocationType from '@gamepark/a-feast-for-odin/material/LocationType'

type Props = {
  game: GameLocalView
  playerColor: PlayerColor
}

export default function PlayerBoard({game, playerColor}: Props) {
  const player = useMemo(() => game.players.find(p => p.color === playerColor)!, [game, playerColor])
  const homeArea = useMemo(() => getHomePlacementArea(player), [player])
  const banquetArea = useMemo(() => new BanquetPlacementArea(player), [player])
  const playerId = usePlayerId<PlayerColor>()
  return (
    <>
      <div css={[style, game.shortGame ? shortGameBoard : longGameBoard]}>
        <GoodsDropArea area={banquetArea} goodsArea={banquet} placedGoods={getBanquet(player).placedGoods}
                       css={banquetTablePosition} square={banquetSquareSize}/>
        <PlayerEmigrationTiles emigration={player.emigration}/>
        <GoodsDropArea area={homeArea} goodsArea={homeGoodsArea} placedGoods={getHomeGoodsArea(player).placedGoods} css={homeGoodsAreaPosition}/>
        <PlayerBoats game={game} player={player}/>
        {[...Array(numberOfVikings - player.vikings.length)].map((_, index) =>
          <Viking key={index} color={player.color} css={vikingSupply(index)}/>
        )}
        {[...Array(player.vikings.filter(location => location.type === LocationType.ThingSquare).length)].map((_, index) =>
          <Viking key={index} color={player.color} css={vikingThingSquare(index)}/>
        )}
        {[...Array(player.thingPenalties)].map((_, index) =>
          <ThingPenaltyTile key={index} css={thingPenaltyPosition(index)}/>
        )}
        <ExtraBoards player={player}/>
        <Stables goods={player.goods} canDrag={player.color === playerId}/>
        {player.view === 'goods' && <PlayerGoods game={game} player={player}/>}
        {player.view === 'cards' && <PlayerCards game={game} player={player}/>}
        <DisplayGoodsButton playerColor={player.color} toggled={player.view === 'goods'}/>
        <DisplayCardsButton playerColor={player.color} toggled={player.view === 'cards'}/>
      </div>
    </>
  )
}

const style = css`
  position: absolute;
  top: ${headerHeight + margin}em;
  height: ${playerBoardHeight}em;
  left: ${margin}em;
  width: ${playerBoardWidth}em;
  background-image: url(${Images.playerBoard});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2em;
  box-shadow: 0 0 0.5em black, 0 0 0.5em black, 0 0 0.5em black;
`

const shortGameBoard = css`
  background-image: url(${Images.playerBoardShortGame});
`

const longGameBoard = css`
  background-image: url(${Images.playerBoard});
`

const banquetTablePosition = css`
  left: ${banquetLeft}em;
  top: ${banquetTop}em;
`

const homeGoodsAreaPosition = css`
  left: ${homeAreaLeft}em;
  top: ${homeAreaTop}em;
`

const thingPenaltyPosition = (index: number) => css`
  left: ${80 + index * 0.1}em;
  bottom: ${2 + index * 0.5}em;
`

const vikingSupply = (index: number) => css`
  top: 15.7em;
  right: ${4.1 + index * (squareSize + 0.1)}em;
`

const vikingThingSquare = (index: number) => css`
  top: ${31 + Math.floor(index / 4) * 12}em;
  left: ${(69 + index % 4 * 7)}em;
`
