/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {FailuresDialog, FullscreenDialog, Menu, useGame} from '@gamepark/react-client'
import {Header, ImagesLoader, LoadingScreen} from '@gamepark/react-components'
import {useEffect, useState} from 'react'
import {DndProvider} from 'react-dnd-multi-backend'
import HTML5ToTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch'
import {useTranslation} from 'react-i18next'
import GameDisplay from './GameDisplay'
import GameLocalView from './GameLocalView'
import HeaderText from './header/HeaderText'
import Images from './images/Images'

export default function App() {
  const game = useGame<GameLocalView>()
  const {t} = useTranslation()
  const [imagesLoading, setImagesLoading] = useState(true)
  const [isJustDisplayed, setJustDisplayed] = useState(true)
  useEffect(() => {
    setTimeout(() => setJustDisplayed(false), 2000)
  }, [])
  const loading = !game || imagesLoading || isJustDisplayed
  return (
    <DndProvider options={HTML5ToTouch}>
      {game && <GameDisplay game={game}/>}
      <LoadingScreen display={loading} author="Uwe Rosenberg" artist="Dennis Lohausen" publisher="Feuerland Spiele" developer="Game Park"/>
      <Header css={headerCss}>{loading ? t('Game loading...') : <HeaderText game={game}/>}</Header>
      <Menu/>
      <FailuresDialog/>
      <FullscreenDialog/>
      <ImagesLoader images={Object.values(Images)} onImagesLoad={() => setImagesLoading(false)}/>
    </DndProvider>
  )
}

const headerCss = css`
  h1 {
    margin: 0.1em 0;
  }
  
  img {
    display: inline;
    vertical-align: middle;
  }
`
