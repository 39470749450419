/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans, useTranslation} from 'react-i18next'
import GoodItem from '../../goods/GoodItem'
import PlayerLocal from '../../PlayerLocal'
import {css} from '@emotion/react'
import {PayButton} from '../../PayButton'
import {PassButton} from '../../PassButton'

type Props = {
  player: PlayerLocal
}

export default function CraftRobeAndJewelryChoice({player}: Props) {
  const {t} = useTranslation()

  return (
    <div>
      <p>
        <strong>{t('available.craft.choice')}</strong>
        <PayButton css={buttonCss} player={player} goods={Good.Wool}>
          <GoodItem good={Good.Wool} height={4} alt="Wool"/>
        </PayButton>
        <PayButton css={buttonCss} player={player} goods={Good.Silverware}>
          <GoodItem good={Good.Silverware} height={2} alt="Silverware"/>
        </PayButton>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}

const buttonCss = css`
  margin-left: 1em;
  padding-top: 0.2em;
  min-width: 6em;
`
