/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {forwardRef, HTMLAttributes, Ref} from 'react'
import Images from '../images/Images'
import {longBoatRatio, squareSize, whalingBoatRatio, whalingBoatWidth} from '../styles'

type Props = {
  boatType: BoatType
} & HTMLAttributes<HTMLDivElement>

function BoatTile({innerRef, boatType, ...props}: Props & { innerRef: Ref<HTMLDivElement> }) {
  return (
    <div ref={innerRef} css={[boatType === BoatType.WhalingBoat ? whalingBoatSize : longBoatSize, background(boatType)]} {...props}/>
  )
}

export default forwardRef<HTMLDivElement, Props>((props, ref) => <BoatTile innerRef={ref} {...props}/>)

const whalingBoatSize = css`
  width: ${whalingBoatWidth}em;
  height: ${whalingBoatWidth * whalingBoatRatio}em;
`

const longBoatSize = css`
  width: ${2 * squareSize}em;
  height: ${longBoatRatio * 2 * squareSize}em;
`

const boatImage: Record<BoatType, string> = {
  [BoatType.WhalingBoat]: Images.whalingBoat,
  [BoatType.Knarr]: Images.knarr,
  [BoatType.Longship]: Images.longship
}

const background = (type: BoatType) => css`
  background-image: url(${boatImage[type]});
  background-size: cover;
  border-radius: 1em;
  box-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
`
