import {css, keyframes} from '@emotion/react'

export const headerHeight = 7
export const margin = 1
export const menuWidth = 18.5
export const menuHeight = 8
export const playerPanelWidth = menuWidth * 2 + margin
export const playerBoardHeight = 100 - headerHeight - 2 * margin
export const playerBoardRatio = 1578 / 1000
export const playerBoardWidth = playerBoardHeight * playerBoardRatio
export const cardHeight = 25
export const cardRatio = 44 / 68
export const cardWidth = cardHeight * cardRatio

export const menuCss = css`
  position: absolute;
  width: ${menuWidth}em;
  height: ${menuHeight}em;
  border-radius: 2em;
  padding: 0;
  border: 0;

  picture, img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
  }
`

export const selectedMenuCss = css`
  box-shadow: 0 0 0.2em gold, 0 0 0.2em #e9e6d8, 0 0 0.2em gold;
`

export const canSelectMenuCss = css`
  cursor: pointer;
  box-shadow: 0.2em 0.2em 1em black;

  &:hover {
    border-color: rgb(255, 215, 0);
    box-shadow: 0 0 0.5em gold, 0 0 0.5em #e9e6d8, 0 0 0.5em gold;
  }
`

export const baseGameActionBoardImageWidth = 2600
export const baseGameActionBoardImageHeight = 5250
export const expansionActionBoardImageWidth = 3180
export const expansionActionBoardWidth = 185 - menuWidth * 2 - margin * 4
export const baseGameActionBoardWidth = expansionActionBoardWidth * baseGameActionBoardImageWidth / expansionActionBoardImageWidth
export const baseGameActionBoardHeight = baseGameActionBoardWidth * baseGameActionBoardImageHeight / baseGameActionBoardImageWidth
export const imitationBoard1ImageWidth = 1494
export const imitationBoard2ImageWidth = 1246
export const imitationBoardsImageHeight = 399
export const imitationBoard1Width = baseGameActionBoardWidth * imitationBoard1ImageWidth / (imitationBoard1ImageWidth + imitationBoard2ImageWidth)
export const imitationBoard2Width = baseGameActionBoardWidth * imitationBoard2ImageWidth / (imitationBoard1ImageWidth + imitationBoard2ImageWidth)
export const imitationBoardsHeight = imitationBoard1Width * imitationBoardsImageHeight / imitationBoard1ImageWidth

export const squareSize = 5.25

export const stripRatio = 2000 / 350
export const longerStripRatio = 2220 / 350
export const stripHeight = 14.33
export const stripWidth = stripHeight * stripRatio
export const longerStripWidth = stripHeight * longerStripRatio

export const banquetTop = 3.4
export const banquetLeft = 75.6
export const banquetSquareDelta = 0.07
export const banquetSquareSize = squareSize + banquetSquareDelta

export const homeAreaLeft = 2.4
export const homeAreaTop = 6.9

export const buttonReset = css`
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
`

export const whalingBoatWidth = 12.1
export const whalingBoatRatio = 400 / 310
export const whalingBoatTop = 44.5
export const whalingBoatLeft = (position: number) => 103 + 13.3 * position
export const longBoatRatio = 540 / 260
export const longBoatTop = 67.6
export const longBoatLeft = (position: number) => 95.2 + 11.5 * position


const opacityKeyframe = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

export const glowingHighlight = css`
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    animation: ${opacityKeyframe} 2s ease-in-out alternate infinite;
    box-shadow: 0 0 0.5em white, 0 0 0.5em white, 0 0 0.5em white;
    transform: none;
  }

  &:hover:after {
    animation: none;
    box-shadow: 0 0 0.5em white, 0 0 0.5em white, 0 0 0.5em white, 0 0 0.5em white, 0 0 0.5em white;
  }
`

export const materialText = (top: number, left: number, width?: number) => css`
  position: absolute;
  top: ${top}%;
  left: ${left}%;
  width: ${width ? `${width}%` : 'auto'};
`

export const slideKeyframes = keyframes`
  0%, 80% {
    transform: translate(-33%, 33%);
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translate(33%, -33%);
    opacity: 0;
  }
`

export const shineEffect = css`
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    animation: ${slideKeyframes} 3s infinite;
    z-index: 1;
    background: linear-gradient(to top right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
  }
`

export const lightBackground = '#F4D6A2'
export const lighterBackground = '#FFF1D4'
export const redBackground = '#ED8454'
export const lightRedBackground = '#F3AC80'
export const greyBackground = '#E9E3D8'

export const titleButtonCss = css`
  position: relative;
  background: ${lightRedBackground};
  background-size: cover;
  border-radius: 0.3em;
  border: 0.1em solid rgba(0, 0, 0, 0.3);
  font-weight: bold;

  &:not(:disabled) {
    cursor: pointer;

    &:active {
      transform: translateY(1px);
    }
  }

  &:disabled {
    filter: grayscale();
  }

  picture, img {
    display: inline;
  }
`

export const dialogCss = css`
  position: relative;
  padding: 2em;
  color: black;
  background-color: ${lightBackground};
  border-radius: 2em;
  text-align: center;

  h2 {
    margin: 0.2em 5em;
    font-size: 4em;
    text-align: center;
  }

  p {
    font-size: 3.5em;
  }
`

export const largeDialogCss = css`
  ${dialogCss};
  width: 180em;
`

export const dialogCloseIcon = css`
  position: absolute;
  right: 0.8em;
  top: 0.6em;
  font-size: 4em;
  cursor: pointer;
  z-index: 100;
`

export const boatOreSquareSize = squareSize * 0.9

export const whalingBoatOreTop = 9.5
export const whalingBoatOreLeft = 2

export const longshipOreTop = 5.8
export const longshipOreLeft = 4.1
export const longshipOreGap = 0.1

export const inlineGood = css`
  display: inline;
  vertical-align: sub;
  margin-top: 0.5em;
`

export const goodButtonCss = css`
  ${titleButtonCss};
  vertical-align: middle;
  height: 1.4em;
  ${inlineGood};
`