import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Good, {animalProducts, craftProducts, farmProducts, luxuryGoods} from '../goods/Good'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Move from '../../moves/Move'
import Occupation from '../Occupation'

export default class Storeman extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Storeman})
    return []
  }

  getPlayerMoves() {
    const goods = getStoremanEligibleGoods(this.player)
    if (goods.length > 0) {
      return goods.map(good => receiveGoodsMove(this.player.color, good))
    } else {
      return [passMove(this.player.color)]
    }
  }

  play(move: MoveRandomized | MoveView) {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.ReceiveGoods:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
    }
    return consequences
  }
}

export function getStoremanEligibleGoods(player: Player | PlayerView): Good[] {
  return [...farmProducts, ...animalProducts, ...craftProducts, ...luxuryGoods]
    .filter(good => player.goods[good] === 3)
}
