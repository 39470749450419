import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countWhalingBoats} from '../boats/LandingStages'
import Good from '../goods/Good'

export default class Fisherman extends OccupationRules {
  immediateEffect() {
    const whalingBoats = countWhalingBoats(this.player)
    return whalingBoats > 0 ? [receiveGoodsMove(this.player.color, {[Good.Stockfish]: whalingBoats})] : []
  }
}
