import {isEnumValue} from '@gamepark/rules-api'

enum Occupation {
  Peddler = 1, Patron, Furrier, Hunter, Chief, StoneCarver, Miller, CraftLeader, OreBoatman, Milker,
  TridentHunter, Helmsman, Archer, ShipOwner, Cottager, Captain, OrientShipper, Fighter, Host, Builder,
  Collector, Miner, Custodian, WhalingEquipper, MeatTrader, MeatInspector, Fisherman, Locksmith, MeatPreserver, SilkMerchant,
  Storeman, WeaponsSupplier, SheepShearer, Shepherd, CattleBreeder, ShipsCook, ForeignTrader, Peacemaker, Modifier, MasterJoiner,
  Slowpoke, Steward, FruitPicker, MasterTailor, Pirate, ShipArchitect, Tanner, Farmer, Plower, RuneEngraver,
  MasterMason, Tutor, FarmShopOwner, Tradesman, Treasurer, ArmsDealer, BeanFarmer, LinseedOilPresser, LinenWeaver, Inspector,
  WharfOwner, FineBlacksmith, Hornturner, ShipBuilder, Dragonslayer, FieldFarmer, PrivateChef, WeaponSupplier, EquipmentSupplier, Courier,
  Innkeeper, Earl, Metalsmith, Cowherd, BoneCollector, Homecomer, Follower, TravelingMerchant, Breeder, ShepherdBoy,
  Quartermaster, LongshipBuilder, Wholesaler, HarborGuard, Hornblower, HideBuyer, Preacher, Clerk, Catapulter, SwordFighter,
  Latecomer, SympatheticSoul, HelperInTimeOfNeed, SpiceMerchant, Wanderer, MeatMerchant, PeaCounter, Nobleman, Preceptor, Undertaker,
  Judge, FurMerchant, ForestBlacksmith, DisheartenedWarrior, KnarrBuilder, Warmonger, Adventurer, Ironsmith, MasterCount, BirkaSettler,
  Artist, Barkeep, ApprenticeCraftsman, SilkStitcher, Princess, MasterBricklayer, Shipowner, Messenger, Milkman, Middleman,
  Carpenter, MountainGuard, OilSeller, Laborer, Scribe, Trapper, Angler, Digger, MeatCurer, SnareSpecialist,
  BaitLayer, Berserker, LanceBearer, Drunkard, LoneWolf, Raider, MeleeFighter, Robber, LootHunter, BlubberCook,
  IvorySculptor, WhaleCatcher, AntlersSeller, Deerstalker, Forester, Sledger, Steersman, SpiceTrader, Priest, MeatBuyer,
  ThingSpokesman, Merchant, ProficientHunter, Woodcutter, Cooper, StoneCrusher, Skinner, Barbarian, FestiveHunter, SoberMan,
  WhalingAssistant, FlaxFarmer, Farmhand, ArmedFighter, VillageLeader, WeaponsWarden, Taster, EarlOfLade, Sower, RefugeeHelper,
  FlaxBaker, FishCook, Punchcutter, BosporusTraveller, CodliverOilPresser, EtiquetteTeacher, Maid, BoatBuilder, CutterOperator, BeachRaider,
  SailPatcher, HerbGardener, GrainDeliveryman, Sponsor, DorestadTraveller, PeaFlourBaker, Chronicler, Mineworker, Seafarer, BosporusMerchant
}

export default Occupation

export const occupations = Object.values(Occupation).filter(isEnumValue)

export const startingDeckA = [
  Occupation.CraftLeader, Occupation.Shepherd, Occupation.Slowpoke, Occupation.Tanner, Occupation.Tutor, Occupation.Catapulter, Occupation.ApprenticeCraftsman,
  Occupation.MeleeFighter, Occupation.Forester, Occupation.ProficientHunter, Occupation.Woodcutter, Occupation.SoberMan, Occupation.Farmhand,
  Occupation.RefugeeHelper, Occupation.BosporusTraveller
]

export const deckA = [
  Occupation.Peddler, Occupation.Furrier, Occupation.Chief, Occupation.Miller, Occupation.OreBoatman, Occupation.Milker, Occupation.Helmsman,
  Occupation.OrientShipper, Occupation.Builder, Occupation.Miner, Occupation.Custodian, Occupation.WhalingEquipper, Occupation.Fisherman,
  Occupation.WeaponsSupplier, Occupation.SheepShearer, Occupation.CattleBreeder, Occupation.ShipsCook, Occupation.ForeignTrader, Occupation.Peacemaker,
  Occupation.FruitPicker, Occupation.Farmer, Occupation.RuneEngraver, Occupation.Tradesman, Occupation.ArmsDealer, Occupation.LinseedOilPresser,
  Occupation.LinenWeaver, Occupation.ShipBuilder, Occupation.Dragonslayer, Occupation.FieldFarmer, Occupation.WeaponSupplier, Occupation.Cowherd,
  Occupation.Homecomer, Occupation.Follower, Occupation.Breeder, Occupation.Wholesaler, Occupation.HideBuyer, Occupation.Preacher, Occupation.SpiceMerchant,
  Occupation.Wanderer, Occupation.MeatMerchant, Occupation.Nobleman, Occupation.Preceptor, Occupation.Shipowner, Occupation.MountainGuard, Occupation.Trapper,
  Occupation.Digger, Occupation.Drunkard, Occupation.LoneWolf, Occupation.BlubberCook, Occupation.Steersman, Occupation.Priest, Occupation.Merchant,
  Occupation.Barbarian, Occupation.ArmedFighter, Occupation.VillageLeader, Occupation.FlaxBaker, Occupation.PeaFlourBaker
]

export const startingDeckB = [
  Occupation.Collector, Occupation.Steward, Occupation.FarmShopOwner, Occupation.BeanFarmer, Occupation.FineBlacksmith, Occupation.Undertaker,
  Occupation.DisheartenedWarrior, Occupation.Barkeep, Occupation.Princess, Occupation.Milkman, Occupation.Middleman, Occupation.Angler, Occupation.MeatCurer,
  Occupation.Berserker, Occupation.Cooper
]

export const deckB = [
  Occupation.StoneCarver, Occupation.Archer, Occupation.MeatTrader, Occupation.Locksmith, Occupation.MeatPreserver, Occupation.SilkMerchant,
  Occupation.MasterJoiner, Occupation.MasterTailor, Occupation.ShipArchitect, Occupation.MasterMason, Occupation.Courier, Occupation.Earl,
  Occupation.TravelingMerchant, Occupation.ShepherdBoy, Occupation.LongshipBuilder, Occupation.HarborGuard, Occupation.PeaCounter, Occupation.Judge,
  Occupation.FurMerchant, Occupation.KnarrBuilder, Occupation.Warmonger, Occupation.Adventurer, Occupation.MasterCount, Occupation.Carpenter,
  Occupation.Laborer, Occupation.LanceBearer, Occupation.WhaleCatcher, Occupation.AntlersSeller, Occupation.Deerstalker, Occupation.StoneCrusher,
  Occupation.FestiveHunter, Occupation.WhalingAssistant, Occupation.WeaponsWarden, Occupation.Taster, Occupation.Punchcutter, Occupation.CodliverOilPresser,
  Occupation.EtiquetteTeacher, Occupation.Maid, Occupation.BoatBuilder, Occupation.CutterOperator, Occupation.DorestadTraveller, Occupation.Chronicler,
  Occupation.Seafarer, Occupation.BosporusMerchant
]

export const startingDeckC = [
  Occupation.Hunter, Occupation.TridentHunter, Occupation.MeatInspector, Occupation.EquipmentSupplier, Occupation.Latecomer, Occupation.Ironsmith,
  Occupation.MasterBricklayer, Occupation.Messenger, Occupation.Scribe, Occupation.BaitLayer, Occupation.Raider, Occupation.Robber, Occupation.IvorySculptor,
  Occupation.MeatBuyer, Occupation.ThingSpokesman
]

export const deckC = [
  Occupation.Patron, Occupation.ShipOwner, Occupation.Cottager, Occupation.Captain, Occupation.Fighter, Occupation.Host, Occupation.Storeman,
  Occupation.Modifier, Occupation.Pirate, Occupation.Plower, Occupation.Treasurer, Occupation.Inspector, Occupation.WharfOwner, Occupation.Hornturner,
  Occupation.PrivateChef, Occupation.Innkeeper, Occupation.Metalsmith, Occupation.BoneCollector, Occupation.Quartermaster, Occupation.Hornblower,
  Occupation.Clerk, Occupation.SwordFighter, Occupation.SympatheticSoul, Occupation.HelperInTimeOfNeed, Occupation.ForestBlacksmith, Occupation.BirkaSettler,
  Occupation.Artist, Occupation.SilkStitcher, Occupation.OilSeller, Occupation.SnareSpecialist, Occupation.LootHunter, Occupation.Sledger,
  Occupation.SpiceTrader, Occupation.Skinner, Occupation.FlaxFarmer, Occupation.EarlOfLade, Occupation.Sower, Occupation.FishCook, Occupation.BeachRaider,
  Occupation.SailPatcher, Occupation.HerbGardener, Occupation.GrainDeliveryman, Occupation.Sponsor, Occupation.Mineworker
]

export function isStartingOccupation(occupation: Occupation) {
  return startingDeckA.includes(occupation) || startingDeckB.includes(occupation) || startingDeckC.includes(occupation)
}

export const occupationsScore: Record<Occupation, number> = {
  [Occupation.Peddler]: 0,
  [Occupation.Patron]: 1,
  [Occupation.Furrier]: 1,
  [Occupation.Hunter]: 0,
  [Occupation.Chief]: 7,
  [Occupation.StoneCarver]: 1,
  [Occupation.Miller]: 3,
  [Occupation.CraftLeader]: 1,
  [Occupation.OreBoatman]: 0,
  [Occupation.Milker]: 1,
  [Occupation.TridentHunter]: 1,
  [Occupation.Helmsman]: 0,
  [Occupation.Archer]: 1,
  [Occupation.ShipOwner]: 2,
  [Occupation.Cottager]: 1,
  [Occupation.Captain]: 2,
  [Occupation.OrientShipper]: 0,
  [Occupation.Fighter]: 0,
  [Occupation.Host]: 0,
  [Occupation.Builder]: 0,
  [Occupation.Collector]: 1,
  [Occupation.Miner]: 1,
  [Occupation.Custodian]: 1,
  [Occupation.WhalingEquipper]: 2,
  [Occupation.MeatTrader]: 1,
  [Occupation.MeatInspector]: 0,
  [Occupation.Fisherman]: 1,
  [Occupation.Locksmith]: 1,
  [Occupation.MeatPreserver]: 3,
  [Occupation.SilkMerchant]: 3,
  [Occupation.Storeman]: 2,
  [Occupation.WeaponsSupplier]: 2,
  [Occupation.SheepShearer]: 2,
  [Occupation.Shepherd]: 0,
  [Occupation.CattleBreeder]: 0,
  [Occupation.ShipsCook]: 2,
  [Occupation.ForeignTrader]: 1,
  [Occupation.Peacemaker]: 2,
  [Occupation.Modifier]: 2,
  [Occupation.MasterJoiner]: 3,
  [Occupation.Slowpoke]: 1,
  [Occupation.Steward]: 2,
  [Occupation.FruitPicker]: 0,
  [Occupation.MasterTailor]: 1,
  [Occupation.Pirate]: -1,
  [Occupation.ShipArchitect]: 1,
  [Occupation.Tanner]: 2,
  [Occupation.Farmer]: 3,
  [Occupation.Plower]: 3,
  [Occupation.RuneEngraver]: 2,
  [Occupation.MasterMason]: 1,
  [Occupation.Tutor]: 0,
  [Occupation.FarmShopOwner]: 1,
  [Occupation.Tradesman]: 3,
  [Occupation.Treasurer]: 2,
  [Occupation.ArmsDealer]: 1,
  [Occupation.BeanFarmer]: 1,
  [Occupation.LinseedOilPresser]: 3,
  [Occupation.LinenWeaver]: 0,
  [Occupation.Inspector]: 2,
  [Occupation.WharfOwner]: 3,
  [Occupation.FineBlacksmith]: 3,
  [Occupation.Hornturner]: 2,
  [Occupation.ShipBuilder]: 1,
  [Occupation.Dragonslayer]: 0,
  [Occupation.FieldFarmer]: 0,
  [Occupation.PrivateChef]: 1,
  [Occupation.WeaponSupplier]: 2,
  [Occupation.EquipmentSupplier]: 1,
  [Occupation.Courier]: 0,
  [Occupation.Innkeeper]: -1,
  [Occupation.Earl]: 2,
  [Occupation.Metalsmith]: 2,
  [Occupation.Cowherd]: 1,
  [Occupation.BoneCollector]: 0,
  [Occupation.Homecomer]: 1,
  [Occupation.Follower]: 0,
  [Occupation.TravelingMerchant]: 0,
  [Occupation.Breeder]: 1,
  [Occupation.ShepherdBoy]: 0,
  [Occupation.Quartermaster]: 2,
  [Occupation.LongshipBuilder]: 1,
  [Occupation.Wholesaler]: 1,
  [Occupation.HarborGuard]: 0,
  [Occupation.Hornblower]: 0,
  [Occupation.HideBuyer]: 3,
  [Occupation.Preacher]: 0,
  [Occupation.Clerk]: 2,
  [Occupation.Catapulter]: 1,
  [Occupation.SwordFighter]: 2,
  [Occupation.Latecomer]: 0,
  [Occupation.SympatheticSoul]: 1,
  [Occupation.HelperInTimeOfNeed]: 3,
  [Occupation.SpiceMerchant]: 2,
  [Occupation.Wanderer]: 1,
  [Occupation.MeatMerchant]: 1,
  [Occupation.PeaCounter]: 1,
  [Occupation.Nobleman]: 2,
  [Occupation.Preceptor]: 2,
  [Occupation.Undertaker]: -1,
  [Occupation.Judge]: 1,
  [Occupation.FurMerchant]: 2,
  [Occupation.ForestBlacksmith]: 3,
  [Occupation.DisheartenedWarrior]: 0,
  [Occupation.KnarrBuilder]: 3,
  [Occupation.Warmonger]: -1,
  [Occupation.Adventurer]: 1,
  [Occupation.Ironsmith]: 2,
  [Occupation.MasterCount]: 2,
  [Occupation.BirkaSettler]: 1,
  [Occupation.Artist]: 1,
  [Occupation.Barkeep]: 0,
  [Occupation.ApprenticeCraftsman]: 0,
  [Occupation.SilkStitcher]: 3,
  [Occupation.Princess]: 1,
  [Occupation.MasterBricklayer]: 2,
  [Occupation.Shipowner]: 3,
  [Occupation.Messenger]: 0,
  [Occupation.Milkman]: 1,
  [Occupation.Middleman]: 2,
  [Occupation.Carpenter]: 0,
  [Occupation.MountainGuard]: 0,
  [Occupation.OilSeller]: 1,
  [Occupation.Laborer]: 1,
  [Occupation.Scribe]: 0,
  [Occupation.Trapper]: 2,
  [Occupation.Angler]: 1,
  [Occupation.Digger]: 2,
  [Occupation.MeatCurer]: 1,
  [Occupation.SnareSpecialist]: 1,
  [Occupation.BaitLayer]: 1,
  [Occupation.Berserker]: 2,
  [Occupation.LanceBearer]: 1,
  [Occupation.Drunkard]: 0,
  [Occupation.LoneWolf]: 2,
  [Occupation.Raider]: 2,
  [Occupation.MeleeFighter]: 3,
  [Occupation.Robber]: 1,
  [Occupation.LootHunter]: 1,
  [Occupation.BlubberCook]: 2,
  [Occupation.IvorySculptor]: 2,
  [Occupation.WhaleCatcher]: 3,
  [Occupation.AntlersSeller]: 2,
  [Occupation.Deerstalker]: 0,
  [Occupation.Forester]: 2,
  [Occupation.Sledger]: 0,
  [Occupation.Steersman]: 1,
  [Occupation.SpiceTrader]: 1,
  [Occupation.Priest]: 1,
  [Occupation.MeatBuyer]: 0,
  [Occupation.ThingSpokesman]: 3,
  [Occupation.Merchant]: 1,
  [Occupation.ProficientHunter]: 2,
  [Occupation.Woodcutter]: 2,
  [Occupation.Cooper]: 2,
  [Occupation.StoneCrusher]: 2,
  [Occupation.Skinner]: 0,
  [Occupation.Barbarian]: 1,
  [Occupation.FestiveHunter]: 1,
  [Occupation.SoberMan]: 2,
  [Occupation.WhalingAssistant]: 0,
  [Occupation.FlaxFarmer]: 1,
  [Occupation.Farmhand]: 1,
  [Occupation.ArmedFighter]: 3,
  [Occupation.VillageLeader]: 3,
  [Occupation.WeaponsWarden]: 1,
  [Occupation.Taster]: 3,
  [Occupation.EarlOfLade]: 2,
  [Occupation.Sower]: 1,
  [Occupation.RefugeeHelper]: 0,
  [Occupation.FlaxBaker]: 2,
  [Occupation.FishCook]: 0,
  [Occupation.Punchcutter]: 1,
  [Occupation.BosporusTraveller]: 1,
  [Occupation.CodliverOilPresser]: 2,
  [Occupation.EtiquetteTeacher]: 3,
  [Occupation.Maid]: 0,
  [Occupation.BoatBuilder]: 0,
  [Occupation.CutterOperator]: 2,
  [Occupation.BeachRaider]: -2,
  [Occupation.SailPatcher]: 1,
  [Occupation.HerbGardener]: 1,
  [Occupation.GrainDeliveryman]: 2,
  [Occupation.Sponsor]: 0,
  [Occupation.DorestadTraveller]: 2,
  [Occupation.PeaFlourBaker]: 3,
  [Occupation.Chronicler]: 2,
  [Occupation.Mineworker]: 0,
  [Occupation.Seafarer]: 1,
  [Occupation.BosporusMerchant]: 0
}
