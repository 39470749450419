import BoatType, {boatsValue} from '../material/boats/BoatType'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'
import {hasSilver} from '../AFeastForOdin'
import {canTakeBoat} from './TakeBoat'

type BuyBoat = {
  type: MoveType.BuyBoat
  player: PlayerColor
  boat: BoatType
}

export default BuyBoat

export function buyBoatMove(player: PlayerColor, boat: BoatType): BuyBoat {
  return {type: MoveType.BuyBoat, player, boat}
}

export function canBuyBoat(player: Player | PlayerView, boat: BoatType) {
  return hasSilver(player, boatsValue[boat]) && canTakeBoat(player, boat)
}