import Good from '../goods/Good'
import {_1, __, i0, i1, i2, i3, PlacementSpace, XX} from '../goods/PlacementSpace'

const pe: PlacementSpace = {bonus: [Good.Peas]}
const fl: PlacementSpace = {bonus: [Good.Flax]}
const fu: PlacementSpace = {bonus: [Good.Fur]}
const oi: PlacementSpace = {bonus: [Good.Oil]}
const mi: PlacementSpace = {bonus: [Good.Milk]}
const sh: PlacementSpace = {bonus: [Good.Sheep]}

export const faroeIslands: PlacementSpace[][] = [
  [XX, XX, XX, XX, XX, _1, sh, i3, _1],
  [XX, XX, _1, _1, XX, XX, i2, __, _1],
  [XX, _1, oi, __, __, i2, XX, __, mi],
  [XX, __, __, __, i2, _1, _1, _1, _1],
  [XX, XX, XX, i1, __, __, _1, XX, __],
  [__, __, i1, XX, __, fl, _1, _1, XX],
  [pe, i1, __, __, XX, __, __, _1, XX],
  [i0, __, __, __, XX, __, fu, _1, XX],
  [XX, XX, XX, XX, XX, __, __, _1, XX]
]