import Good from '../goods/Good'
import {_1, __, i0, i1, i2, i4, PlacementSpace, XX} from '../goods/PlacementSpace'

const sb: PlacementSpace = {bonus: [Good.SkinAndBones]}
const wm: PlacementSpace = {bonus: [Good.WhaleMeat]}
const or: PlacementSpace = {bonus: [Good.Ore]}
const oi: PlacementSpace = {bonus: [Good.Oil]}

export const baffinIsland: PlacementSpace[][] = [
  [_1, __, _1, _1, or, XX, XX, XX, XX],
  [_1, _1, __, _1, _1, _1, __, __, __],
  [XX, __, _1, __, _1, _1, __, wm, __],
  [XX, _1, XX, _1, __, XX, XX, XX, __],
  [XX, __, __, __, i4, _1, _1, XX, XX],
  [__, __, __, i2, _1, _1, _1, oi, _1],
  [XX, __, i2, __, _1, _1, XX, XX, XX],
  [__, i1, __, __, _1, __, __, XX, XX],
  [i0, __, __, XX, _1, __, sb, __, _1]
]