import Move from '../../moves/Move'
import OccupationRules from './OccupationRules'
import MoveType from '../../moves/MoveType'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Scribe extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if ((move.type === MoveType.ClaimTerritory || move.type === MoveType.EmigrateBoat) && move.player === this.player.color) {
      return [receiveGoodsMove(this.player.color, Good.RuneStone)]
    }
    return []
  }
}
