import Game from '../../Game'
import GameView from '../../GameView'
import Coordinates from '../Coordinates'
import Good, {isSpecialTile, isStandardGoodTile} from './Good'
import OrientedGood, {getOrientedPolyomino} from './OrientedGood'
import PlacedGood, {getAdjacentCoordinates, getCoveredCoordinates} from './PlacedGood'
import {PlacementSpace} from './PlacementSpace'

export default abstract class PlacementArea {
  protected area: PlacementSpace[][]

  protected constructor(area: PlacementSpace[][]) {
    this.area = JSON.parse(JSON.stringify(area))
  }

  public placeGoods(placedGoods: PlacedGood[]) {
    for (const placedGood of placedGoods) {
      for (const {x, y} of getCoveredCoordinates(placedGood)) {
        const space = this.area[x][y]
        if (!space) throw new Error('You cannot place a good on a blocked space')
        space.good = placedGood.good
      }
    }
  }

  get width() {
    return this.area.length
  }

  get height() {
    return this.area[0].length
  }

  abstract isValidGood(good: Good): boolean

  protected abstract isAdjacencyForbidden(good: Good): boolean

  canPlaceGood(placedGood: PlacedGood) {
    if (!this.isValidGood(placedGood.good)) return false
    const coveredCoordinates = getCoveredCoordinates(placedGood)
    if (coveredCoordinates.some(space => !this.canCoverSpace(space))) return false
    return !this.isAdjacencyForbidden(placedGood.good) || !this.hasSameGoodTypeAdjacent(placedGood)
  }

  canPlaceGoods(placedGoods: PlacedGood[]) {
    return placedGoods.every(placedGood => this.canPlaceGood(placedGood))
  }

  protected hasSameGoodTypeAdjacent(placedGood: PlacedGood) {
    const adjacentCoordinates = getAdjacentCoordinates(placedGood)
    return adjacentCoordinates.some(coordinates => this.isCoveredWithSameGoodType(coordinates, placedGood.good))
  }

  protected isCoveredWithSameGoodType(coordinates: Coordinates, good: Good) {
    const space = this.getSpace(coordinates)
    if (!space?.good) return false
    if (!isStandardGoodTile(space.good)) return false
    return good % 5 === space.good % 5
  }

  protected getSpace({x, y}: Coordinates): PlacementSpace {
    return (this.area[x] && this.area[x][y]) ?? null
  }

  protected canCoverSpace(coordinates: Coordinates) {
    const space = this.getSpace(coordinates)
    return space && !space.good
  }

  getPotentialPlacementArea(orientedGood: OrientedGood): boolean[][] {
    const area = this.area.map(column => column.map(() => false))
    for (let x = 0; x < area.length; x++) {
      for (let y = 0; y < area[x].length; y++) {
        if (area[x][y]) continue
        const placedGood = this.searchPotentialPlacementToCoverSpace(orientedGood, {x, y})
        if (placedGood) {
          getCoveredCoordinates(placedGood).forEach(({x, y}) => area[x][y] = true)
        }
      }
    }
    return area
  }

  protected searchPotentialPlacementToCoverSpace(orientedGood: OrientedGood, {x, y}: Coordinates): PlacedGood | undefined {
    const polyomino = getOrientedPolyomino(orientedGood)
    for (let deltaX = 1 - polyomino[0].length; deltaX < polyomino[0].length; deltaX++) {
      for (let deltaY = 1 - polyomino.length; deltaY < polyomino.length; deltaY++) {
        const placedGood = {...orientedGood, x: x - deltaX, y: y - deltaY}
        if (this.canPlaceGood(placedGood) && getCoveredCoordinates(placedGood).some(coordinates => coordinates.x === x && coordinates.y === y)) {
          return placedGood
        }
      }
    }
    return
  }

  getBonusGoods(game: Game | GameView) {
    const goods: Good[] = []
    for (let x = 0; x < this.area.length; x++) {
      const column = this.area[x]
      for (let y = 0; y < column.length; y++) {
        const space = column[y]
        if (space?.bonus && !space.good && this.surroundingSpacesAreCovered({x, y})) {
          for (const good of space.bonus) {
            if (!isSpecialTile(good) || game.specialTilesSupply.includes(good)) {
              goods.push(good)
            }
          }
        }
      }
    }
    return goods
  }

  getMalus() {
    return this.area.reduce((malus, column) => malus + column.reduce((malus, space) => !space?.malus || space?.good ? malus : malus + space.malus, 0), 0)
  }

  protected surroundingSpacesAreCovered(coordinates: Coordinates) {
    for (let x = coordinates.x - 1; x <= coordinates.x + 1; x++) {
      for (let y = coordinates.y - 1; y <= coordinates.y + 1; y++) {
        if (!this.countAsCovered({x, y})) {
          return false
        }
      }
    }
    return true
  }

  protected countAsCovered(coordinates: Coordinates): boolean {
    const space = this.getSpace(coordinates)
    return !space || !!space.good || !!space.bonus
  }
}
