import Good from '../goods/Good'
import {_1, __, PlacementSpace, XX} from '../goods/PlacementSpace'

const li: PlacementSpace = {bonus: [Good.Linen]}
const ch: PlacementSpace = {bonus: [Good.Chest]}
const sf: PlacementSpace = {bonus: [Good.Stockfish]}
const gm: PlacementSpace = {bonus: [Good.GameMeat]}

export const labrador: PlacementSpace[][] = [
  [XX, XX, XX, _1, _1, XX, XX, XX, XX],
  [XX, XX, XX, _1, _1, _1, XX, XX, XX],
  [XX, XX, XX, _1, __, _1, XX, XX, XX],
  [XX, XX, XX, gm, _1, _1, __, _1, XX],
  [XX, XX, _1, _1, _1, _1, _1, _1, _1],
  [_1, _1, _1, __, _1, _1, _1, _1, _1],
  [__, _1, __, _1, ch, _1, XX, _1, XX],
  [_1, li, _1, __, _1, __, _1, _1, _1],
  [XX, _1, XX, _1, __, _1, sf, _1, _1]
]