/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {PassButton} from '../PassButton'
import {PlayMoveButton} from '../PlayMoveButton'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen from '../navigation/Screen'
import {InspectorEffect} from '@gamepark/a-feast-for-odin/material/occupations/Inspector'
import PlayerLocal from '../PlayerLocal'

type Props = {
  player: PlayerLocal
  effect: InspectorEffect
  round: number
}

export default function InspectorChoice({player, effect, round}: Props) {
  if (effect.costPaid === undefined) {
    return <Trans defaults="inspector.effect.pay" values={{cost: round}}
                  components={[<PayButton player={player} goods={{[Good.Silver]: round}}/>, <PassButton/>]}/>
      } else {
        return <Trans defaults="inspector.effect" values={{vikings: effect.costPaid ? 2 : 1}}
                      components={[<PlayMoveButton move={displayScreenMove(Screen.ActionBoard)} local/>, <PassButton/>]}/>
  }
}
