import Game from '../Game'
import GameView from '../GameView'
import Weapon from '../material/Weapon'
import MoveType from './MoveType'

type ShuffleWeaponsDeck = {
  type: MoveType.ShuffleWeaponsDeck
}

export default ShuffleWeaponsDeck

export type ShuffleWeaponsDeckRandomized = ShuffleWeaponsDeck & {
  newDeck: Weapon[]
}

export const shuffleWeaponsDeckMove: ShuffleWeaponsDeck = {type: MoveType.ShuffleWeaponsDeck}

export function shuffleWeaponsDeck(game: Game, move: ShuffleWeaponsDeckRandomized) {
  if (game.weaponsDeck.length === 0) {
    game.weaponsDiscard = []
  }
  game.weaponsDeck = move.newDeck
}

export function shuffleWeaponsDeckInView(game: GameView) {
  if (game.weaponsDeck === 0) {
    game.weaponsDeck = game.weaponsDiscard.length
    game.weaponsDiscard = []
  }
}