import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Occupation from '../Occupation'
import {passMove} from '../../moves/Pass'
import MoveView from '../../moves/MoveView'
import Good from '../goods/Good'
import GoodsAreaType from '../goods/GoodsAreaType'
import Building from '../Building'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'

export default class Steward extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.StartPlayer) {
      this.player.effects.unshift({occupation: Occupation.Steward})
    }
    return []
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.effects.shift()
    }
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color
      && move.goodsArea.type === GoodsAreaType.Building && move.goodsArea.building === Building.Shed
      && (move.extraGood === Good.Wood || move.extraGood === Good.Stone)) {
      this.player.effects.shift()
      return [receiveGoodsMove(this.player.color, Good.Silver)]
    }
    return []
  }
}
