/** @jsxImportSource @emotion/react */
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {Trans} from 'react-i18next'
import PlayerLocal from '../PlayerLocal'
import {PayButton} from '../PayButton'
import {PassButton} from '../PassButton'
import GoodItem from '../goods/GoodItem'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
  canTakeBelt: boolean
}

export default function ChroniclerChoice({player, canTakeBelt}: Props) {
  return (
    <div>
      <p>
        <Trans defaults="chronicler.effect" components={[
          <PayButton player={player} goods={{[Good.Flax]: 2}} disabled={!canTakeBelt}/>,
          <GoodItem css={inlineGood} good={Good.Belt} height={1.5}/>,
          <PayButton player={player} goods={{[Good.Flax]: 3}}/>,
          <GoodItem css={inlineGood} good={Good.TreasureChest} height={4.5}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
