/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {titleButtonCss} from '../styles'
import GoodItem from './GoodItem'

type Props = {
  goods: Good[]
}

export default function ReceiveGoodDialogContent({goods}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const playerId = usePlayerId<PlayerColor>()!
  const [selectedGood, setSelectedGood] = useState<Good>()
  return (
    <div css={css`text-align: center;`}>
      <p>{t('choose.good')}</p>
      <div css={flexContainer}>
        {goods.map(good => <GoodItem key={good} good={good} onClick={() => setSelectedGood(good)} css={good === selectedGood && selectedCss}/>)}
      </div>
      <p>
        <button css={titleButtonCss} disabled={!selectedGood} onClick={() => selectedGood && play(receiveGoodsMove(playerId, selectedGood))}>
          {t('Choose this good')}
        </button>
      </p>
    </div>
  )
}

const flexContainer = css`
  display: flex;
  max-width: 180em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin: 2em;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`
