import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import Player from '../Player'
import PlayerView from '../PlayerView'
import {Rules} from '@gamepark/rules-api'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'

export default abstract class EffectRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  player: Player | PlayerView

  constructor(game: Game | GameView, player: Player | PlayerView) {
    super(game)
    this.player = player
  }

  getActivePlayer(): PlayerColor | undefined {
    return this.player.color
  }

  getLegalMoves(playerId: PlayerColor): (Move | MoveView)[] {
    const legalMoves = super.getLegalMoves(playerId)
    if (playerId === this.player.color) {
      legalMoves.push(...this.getPlayerMoves())
    }
    return legalMoves
  }

  getPlayerMoves(): Move[] {
    return []
  }
}
