/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {weapons} from '@gamepark/a-feast-for-odin/material/Weapon'
import PlayerLocal from '../../PlayerLocal'
import {cardWidth, margin, playerBoardWidth} from '../../styles'
import WeaponCard from '../../weapons/WeaponCard'

type Props = {
  player: PlayerLocal
}

export default function PlayerWeapons({player}: Props) {
  const weaponCards = weapons.flatMap(weapon => Array(player.weapons[weapon] ?? 0).fill(weapon))
  return <>{weaponCards.map((weapon, index) => <WeaponCard key={index} weapon={weapon} css={[style, position(index, weaponCards.length)]}/>)}</>
}

const style = css`
  position: absolute;
  top: ${margin}em;
`

const position = (index: number, totalCards: number) => css`
  left: ${playerBoardWidth - cardWidth - margin - index * Math.min(cardWidth + margin, (playerBoardWidth - margin * 2 - cardWidth) / (totalCards - 1))}em;
`
