import MoveType from './MoveType'
import PlayerColor from '../PlayerColor'

type ChooseEffect = {
  type: MoveType.ChooseEffect
  player: PlayerColor
  id: number
}

export default ChooseEffect

export function chooseEffectMove(player: PlayerColor, id: number): ChooseEffect {
  return {type: MoveType.ChooseEffect, player, id}
}
