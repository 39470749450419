import TradeOccupationRules from './TradeOccupationRules'
import Good, {goodsToMap} from '../goods/Good'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Occupation from '../Occupation'

export default class BosporusTraveller extends TradeOccupationRules {
  trades = [{spend: {[Good.Silver]: 3}, receive: Good.Silk}, {spend: {[Good.Silver]: 4}, receive: Good.Spices}]

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const cattle = goodsToMap(move.goods)[Good.Cattle]
      if (cattle) {
        this.player.effects.unshift({occupation: Occupation.BosporusTraveller})
      }
    }
    return []
  }
}
