import actionBoard from './boards/action-board.jpg'
import buildHouseAndBoat from './actions/build-house-and-boat.png'
import buildKnarr from './actions/build-knarr.png'
import buildLongHouse from './actions/build-long-house.png'
import buildLongship from './actions/build-longship.png'
import buildShed from './actions/build-shed.png'
import buildStoneHouse from './actions/build-stone-house.png'
import buildWhalingBoat from './actions/build-whaling-boat.png'
import buyCattleAndMilk from './actions/buy-cattle-and-milk.png'
import buyFish from './actions/buy-fish.png'
import buyMeat from './actions/buy-meat.png'
import buySheepAndCattle from './actions/buy-sheep-and-cattle.png'
import buySheepOrCattle from './actions/buy-sheep-or-cattle.png'
import buySheep from './actions/buy-sheep.png'
import craftChest from './actions/craft-chest.png'
import craftChestsAndRuneStones from './actions/craft-chests-and-rune-stones.png'
import craftClothing from './actions/craft-clothing.png'
import craftLinen from './actions/craft-linen.png'
import craftRobeAndJewelry from './actions/craft-robe-and-jewelry.png'
import craftRuneStone from './actions/craft-rune-stone.png'
import drawOccupation from './actions/draw-occupation.png'
import emigrate1 from './actions/emigrate-1.png'
import emigrate2 from './actions/emigrate-2.png'
import exploreI from './actions/explore-1.png'
import exploreII from './actions/explore-2.png'
import exploreIII from './actions/explore-3.png'
import fishing from './actions/fishing.png'
import getBeans from './actions/get-beans.png'
import getFlaxAndFish from './actions/get-flax-and-fish.png'
import getFruitsOilMeat from './actions/get-fruits-oil-meat.png'
import getMead from './actions/get-mead.png'
import getSpiceMilkWool from './actions/get-spice-milk-wool.png'
import huntingGame1 from './actions/hunting-game-1.png'
import huntingGame2 from './actions/hunting-game-2.png'
import layingSnare from './actions/laying-snare.png'
import milking from './actions/milking.png'
import pillaging1 from './actions/pillaging-1.png'
import pillaging2 from './actions/pillaging-2.png'
import play2Occupations from './actions/play-2-occupations.png'
import play4Occupations from './actions/play-4-occupations.png'
import playOccupation from './actions/play-occupation.png'
import plundering from './actions/plundering.png'
import raiding from './actions/raiding.png'
import shearing from './actions/shearing.png'
import smithy from './actions/smithy.png'
import specialSale from './actions/special-sale.png'
import take1ResourceAndTrade from './actions/take-1-resource-and-trade.png'
import take2Resources from './actions/take-2-resources.png'
import take3ResourcesAndTrade from './actions/take-3-resource-and-trade.png'
import take4ResourcesAndTrade2 from './actions/take-4-resources-and-trade-2.png'
import take5Resources from './actions/take-5-resources.png'
import take8ResourcesOrTrade3 from './actions/take-8-resources-or-trade-3.png'
import takeWoodAndOre from './actions/take-wood-and-ore.png'
import trade2 from './actions/trade-2.png'
import trade3AndWeapons from './actions/trade-3-and-weapons.png'
import trade3 from './actions/trade-3.png'
import trade4 from './actions/trade-4.png'
import tradeOversee1 from './actions/trade-oversee-1.png'
import tradeOversee2 from './actions/trade-oversee-2.png'
import exchangeBoatAndEmigrate from './actions/exchange-boat-and-emigrate.png'
import whalingMinor from './actions/whaling-minor.png'
import whaling from './actions/whaling.png'
import boatsSupply from './boards/boats-supply.jpg'
//import specialTilesForgeableSupply from './boards-supply/special-tiles-forgeable-supply.jpg'
//import specialTilesSupplyBack from './boards-supply/special-tiles-supply-back.jpg'
import specialTilesSupply from './boards/special-tiles-supply.jpg'
import knarr from './boats/knarr.jpg'
import longship from './boats/longship.jpg'
import whalingBoat from './boats/whaling-boat.jpg'
import longHouse from './buildings/long-house.jpg'
import shed from './buildings/shed.jpg'
import stoneHouse from './buildings/stone-house.jpg'
import knarrEmigration from './emigration/knarr-emigration.jpg'
import longshipEmigration from './emigration/longship-emigration.jpg'
import whalingBoatEmigration from './emigration/whaling-boat-emigration.jpg'
import baffinIsland from './exploration/baffin-island.jpg'
import bearIsland from './exploration/bear-island.jpg'
import faroeIslands from './exploration/faroe-islands.jpg'
import greenland from './exploration/greenland.jpg'
import iceland from './exploration/iceland.jpg'
import labrador from './exploration/labrador.jpg'
import newfoundland from './exploration/newfoundland.jpg'
import shetland from './exploration/shetland.jpg'
import amberFigure from './goods/amber-figure.png'
import beans from './goods/beans.jpg'
import belt from './goods/belt.jpg'
import cabbage from './goods/cabbage.jpg'
import cattle from './goods/cattle.jpg'
import chalice from './goods/chalice.png'
import chest from './goods/chest.jpg'
import cloakpin from './goods/cloakpin.png'
import clothing from './goods/clothing.jpg'
import crucifix from './goods/crucifix.png'
import drinkingHorn from './goods/drinking-horn.png'
import englishCrown from './goods/english-crown.png'
import fibula from './goods/fibula.png'
import flax from './goods/flax.jpg'
import forgeHammer from './goods/forge-hammer.png'
import fruits from './goods/fruits.jpg'
import fur from './goods/fur.jpg'
import gameMeat from './goods/game-meat.jpg'
import glassBeads from './goods/glass-beads.png'
import goldBrooch from './goods/gold-brooch.png'
import grain from './goods/grain.jpg'
import helmet from './goods/helmet.png'
import hide from './goods/hide.jpg'
import horseshoe from './goods/horseshoe.png'
import jewelry from './goods/jewelry.jpg'
import linen from './goods/linen.jpg'
import mead from './goods/mead.jpg'
import milk from './goods/milk.jpg'
import oil from './goods/oil.jpg'
import ore from './goods/ore.png'
import peas from './goods/peas.jpg'
import pregnantCattle from './goods/pregnant-cattle.jpg'
import pregnantSheep from './goods/pregnant-sheep.jpg'
import robe from './goods/robe.jpg'
import roundShield from './goods/round-shield.png'
import runeStone from './goods/rune-stone.jpg'
import saltMeat from './goods/salt-meat.jpg'
import sheep from './goods/sheep.jpg'
import silk from './goods/silk.jpg'
import silverHoard from './goods/silver-hoard.jpg'
import silver from './goods/silver.jpg'
import silverware from './goods/silverware.jpg'
import skinAndBones from './goods/skin-and-bones.jpg'
import spices from './goods/spices.jpg'
import stockFish from './goods/stockFish.jpg'
import stone from './goods/stone.png'
import throwingAxe from './goods/throwing-axe.png'
import treasureChest from './goods/treasure-chest.jpg'
import whaleMeat from './goods/whale-meat.jpg'
import wood from './goods/wood.png'
import wool from './goods/wool.jpg'
import mountain0 from './mountains/mountain-0.jpg'
import mountain1 from './mountains/mountain-1.jpg'
import mountain2 from './mountains/mountain-2.jpg'
import mountain3 from './mountains/mountain-3.jpg'
import mountain4 from './mountains/mountain-4.jpg'
import mountain5 from './mountains/mountain-5.jpg'
import mountain6 from './mountains/mountain-6.jpg'
import mountain7 from './mountains/mountain-7.jpg'
//import mountain8 from './mountains/mountain-8.jpg'
//import mountain8Back from './mountains/mountain-8-back.jpg'
import mountainBack from './mountains/mountain-back.jpg'
import actionBoardIcon from './menu/action-board-icon.jpg'
import close from './menu/close.png'
import explorationIcon from './menu/exploration-icon.jpg'
import islandIcon from './menu/island-icon.png'
import longHouseIcon from './menu/long-house-icon.png'
import mountainsIcon from './menu/mountains-icon.jpg'
import shedIcon from './menu/shed-icon.png'
import stoneHouseIcon from './menu/stone-house-icon.png'
import Undertaker from './occupations/100b-Undertaker.jpg'
import Judge from './occupations/101B-Judge.jpg'
import FurMerchant from './occupations/102B-Fur_Merchant.jpg'
import ForestBlacksmith from './occupations/103C-Forest_Blacksmith.jpg'
import DisheartenedWarrior from './occupations/104b-Disheartened_Warrior.jpg'
import KnarrBuilder from './occupations/105B-Knarr_Builder.jpg'
import Warmonger from './occupations/106B-Warmonger.jpg'
import Adventurer from './occupations/107B-Adventurer.jpg'
import Ironsmith from './occupations/108c-Ironsmith.jpg'
import MasterCount from './occupations/109B-Master_Count.jpg'
import Milker from './occupations/10A-Milker.jpg'
import BirkaSettler from './occupations/110C-Birka_Settler.jpg'
import Artist from './occupations/111C-Artist.jpg'
import Barkeep from './occupations/112b-Barkeep.jpg'
import ApprenticeCraftsman from './occupations/113a-Apprentice_Craftsman.jpg'
import SilkStitcher from './occupations/114C-Silk_Stitcher.jpg'
import Princess from './occupations/115b-Princess.jpg'
import MasterBricklayer from './occupations/116c-Master_Bricklayer.jpg'
import Shipowner from './occupations/117A-Shipowner.jpg'
import Messenger from './occupations/118c-Messenger.jpg'
import Milkman from './occupations/119b-Milkman.jpg'
import TridentHunter from './occupations/11c-Trident_Hunter.jpg'
import Middleman from './occupations/120b-Middleman.jpg'
import Carpenter from './occupations/121B-Carpenter.jpg'
import MountainGuard from './occupations/122A-Mountain_Guard.jpg'
import OilSeller from './occupations/123C-Oil_Seller.jpg'
import Laborer from './occupations/124B-Laborer.jpg'
import Scribe from './occupations/125c-Scribe.jpg'
import Trapper from './occupations/126A-Trapper.jpg'
import Angler from './occupations/127b-Angler.jpg'
import Digger from './occupations/128A-Digger.jpg'
import MeatCurer from './occupations/129b-Meat_Curer.jpg'
import Helmsman from './occupations/12A-Helmsman.jpg'
import SnareSpecialist from './occupations/130C-Snare_Specialist.jpg'
import BaitLayer from './occupations/131c-Bait_Layer.jpg'
import Berserker from './occupations/132b-Berserker.jpg'
import LanceBearer from './occupations/133B-Lance_Bearer.jpg'
import Drunkard from './occupations/134A-Drunkard.jpg'
import LoneWolf from './occupations/135A-Lone_Wolf.jpg'
import Raider from './occupations/136c-Raider.jpg'
import MeleeFighter from './occupations/137a-Melee_Fighter.jpg'
import Robber from './occupations/138c-Robber.jpg'
import LootHunter from './occupations/139C-Loot_Hunter.jpg'
import Archer from './occupations/13B-Archer.jpg'
import BlubberCook from './occupations/140A-Blubber_Cook.jpg'
import IvorySculptor from './occupations/141c-Ivory_Sculptor.jpg'
import WhaleCatcher from './occupations/142B-Whale_Catcher.jpg'
import AntlersSeller from './occupations/143B-Antlers_Seller.jpg'
import Deerstalker from './occupations/144B-Deerstalker.jpg'
import Forester from './occupations/145a-Forester.jpg'
import Sledger from './occupations/146C-Sledger.jpg'
import Steersman from './occupations/147A-Steersman.jpg'
import SpiceTrader from './occupations/148C-Spice_Trader.jpg'
import Priest from './occupations/149A-Priest.jpg'
import ShipOwner from './occupations/14C-Ship_Owner.jpg'
import MeatBuyer from './occupations/150c-Meat_Buyer.jpg'
import ThingSpokesman from './occupations/151c-Thing_Spokesman.jpg'
import Merchant from './occupations/152A-Merchant.jpg'
import ProficientHunter from './occupations/153a-Proficient_Hunter.jpg'
import Woodcutter from './occupations/154a-Woodcutter.jpg'
import Cooper from './occupations/155b-Cooper.jpg'
import StoneCrusher from './occupations/156B-Stone_Crusher.jpg'
import Skinner from './occupations/157C-Skinner.jpg'
import Barbarian from './occupations/158A-Barbarian.jpg'
import FestiveHunter from './occupations/159B-Festive_Hunter.jpg'
import Cottager from './occupations/15C-Cottager.jpg'
import SoberMan from './occupations/160a-Sober_Man.jpg'
import WhalingAssistant from './occupations/161B-Whaling_Assistant.jpg'
import FlaxFarmer from './occupations/162C-Flax_Farmer.jpg'
import Farmhand from './occupations/163a-Farmhand.jpg'
import ArmedFighter from './occupations/164A-Armed_Fighter.jpg'
import VillageLeader from './occupations/165A-Village_Leader.jpg'
import WeaponsWarden from './occupations/166B-Weapons_Warden.jpg'
import Taster from './occupations/167B-Taster.jpg'
import EarlOfLade from './occupations/168C-Earl_of_Lade.jpg'
import Sower from './occupations/169C-Sower.jpg'
import Captain from './occupations/16C-Captain.jpg'
import RefugeeHelper from './occupations/170a-Refugee_Helper.jpg'
import FlaxBaker from './occupations/171A-Flax_Baker.jpg'
import FishCook from './occupations/172C-Fish_Cook.jpg'
import Punchcutter from './occupations/173B-Punchcutter.jpg'
import BosporusTraveller from './occupations/174a-Bosporus_Traveller.jpg'
import CodliverOilPresser from './occupations/175B-Codliver_Oil_Presser.jpg'
import EtiquetteTeacher from './occupations/176B-Etiquette_Teacher.jpg'
import Maid from './occupations/177B-Maid.jpg'
import BoatBuilder from './occupations/178B-Boat_Builder.jpg'
import CutterOperator from './occupations/179B-Cutter_Operator.jpg'
import OrientShipper from './occupations/17A-Orient_Shipper.jpg'
import BeachRaider from './occupations/180C-Beach_Raider.jpg'
import SailPatcher from './occupations/181C-Sail_Patcher.jpg'
import HerbGardener from './occupations/182C-Herb_Gardener.jpg'
import GrainDeliveryman from './occupations/183C-Grain_Deliveryman.jpg'
import Sponsor from './occupations/184C-Sponsor.jpg'
import DorestadTraveller from './occupations/185B-Dorestad_Traveller.jpg'
import PeaFlourBaker from './occupations/186A-Pea_Flour_Baker.jpg'
import Chronicler from './occupations/187B-Chronicler.jpg'
import Mineworker from './occupations/188C-Mineworker.jpg'
import Seafarer from './occupations/189B-Seafarer.jpg'
import Fighter from './occupations/18C-Fighter.jpg'
import BosporusMerchant from './occupations/190B-Bosporus_Merchant.jpg'
import Host from './occupations/19C-Host.jpg'
import Peddler from './occupations/1A-Peddler.jpg'
import Builder from './occupations/20A-Builder.jpg'
import Collector from './occupations/21b-Collector.jpg'
import Miner from './occupations/22A-Miner.jpg'
import Custodian from './occupations/23A-Custodian.jpg'
import WhalingEquipper from './occupations/24A-Whaling_Equipper.jpg'
import MeatTrader from './occupations/25B-Meat_Trader.jpg'
import MeatInspector from './occupations/26c-Meat_Inspector.jpg'
import Fisherman from './occupations/27A-Fisherman.jpg'
import Locksmith from './occupations/28B-Locksmith.jpg'
import MeatPreserver from './occupations/29B-Meat_Preserver.jpg'
import Patron from './occupations/2C-Patron.jpg'
import SilkMerchant from './occupations/30B-Silk_Merchant.jpg'
import Storeman from './occupations/31C-Storeman.jpg'
import WeaponsSupplier from './occupations/32A-Weapons_Supplier.jpg'
import SheepShearer from './occupations/33A-Sheep_Shearer.jpg'
import Shepherd from './occupations/34a-Shepherd.jpg'
import CattleBreeder from './occupations/35A-Cattle_Breeder.jpg'
import ShipsCook from './occupations/36A-Ship’s_Cook.jpg'
import ForeignTrader from './occupations/37A-Foreign_Trader.jpg'
import Peacemaker from './occupations/38A-Peacemaker.jpg'
import Modifier from './occupations/39C-Modifier.jpg'
import Furrier from './occupations/3A-Furrier.jpg'
import MasterJoiner from './occupations/40B-Master_Joiner.jpg'
import Slowpoke from './occupations/41a-Slowpoke.jpg'
import Steward from './occupations/42b-Steward.jpg'
import FruitPicker from './occupations/43A-Fruit_Picker.jpg'
import MasterTailor from './occupations/44B-Master_Tailor.jpg'
import Pirate from './occupations/45C-Pirate.jpg'
import ShipArchitect from './occupations/46B-Ship_Architect.jpg'
import Tanner from './occupations/47a-Tanner.jpg'
import Farmer from './occupations/48A-Farmer.jpg'
import Plower from './occupations/49C-Plower.jpg'
import Hunter from './occupations/4c-Hunter.jpg'
import RuneEngraver from './occupations/50A-Rune_Engraver.jpg'
import MasterMason from './occupations/51B-Master_Mason.jpg'
import Tutor from './occupations/52a-Tutor.jpg'
import FarmShopOwner from './occupations/53b-Farm_Shop_Owner.jpg'
import Tradesman from './occupations/54A-Tradesman.jpg'
import Treasurer from './occupations/55C-Treasurer.jpg'
import ArmsDealer from './occupations/56A-Arms_Dealer.jpg'
import BeanFarmer from './occupations/57b-Bean_Farmer.jpg'
import LinseedOilPresser from './occupations/58A-Linseed_Oil_Presser.jpg'
import LinenWeaver from './occupations/59A-Linen_Weaver.jpg'
import Chief from './occupations/5A-Chief.jpg'
import Inspector from './occupations/60C-Inspector.jpg'
import WharfOwner from './occupations/61C-Wharf_Owner.jpg'
import FineBlacksmith from './occupations/62b-Fine_Blacksmith.jpg'
import Hornturner from './occupations/63C-Hornturner.jpg'
import ShipBuilder from './occupations/64A-Ship_Builder.jpg'
import Dragonslayer from './occupations/65A-Dragonslayer.jpg'
import FieldFarmer from './occupations/66A-Field_Farmer.jpg'
import PrivateChef from './occupations/67C-Private_Chef.jpg'
import WeaponSupplier from './occupations/68A-Weapon_Supplier.jpg'
import EquipmentSupplier from './occupations/69c-Equipment_Supplier.jpg'
import StoneCarver from './occupations/6B-Stone_Carver.jpg'
import Courier from './occupations/70B-Courier.jpg'
import Innkeeper from './occupations/71C-Innkeeper.jpg'
import Earl from './occupations/72B-Earl.jpg'
import Metalsmith from './occupations/73C-Metalsmith.jpg'
import Cowherd from './occupations/74A-Cowherd.jpg'
import BoneCollector from './occupations/75C-Bone_Collector.jpg'
import Homecomer from './occupations/76A-Homecomer.jpg'
import Follower from './occupations/77A-Follower.jpg'
import TravelingMerchant from './occupations/78B-Traveling_Merchant.jpg'
import Breeder from './occupations/79A-Breeder.jpg'
import Miller from './occupations/7A-Miller.jpg'
import ShepherdBoy from './occupations/80B-Shepherd_Boy.jpg'
import Quartermaster from './occupations/81C-Quartermaster.jpg'
import LongshipBuilder from './occupations/82B-Longship_Builder.jpg'
import Wholesaler from './occupations/83A-Wholesaler.jpg'
import HarborGuard from './occupations/84B-Harbor_Guard.jpg'
import Hornblower from './occupations/85C-Hornblower.jpg'
import HideBuyer from './occupations/86A-Hide_Buyer.jpg'
import Preacher from './occupations/87A-Preacher.jpg'
import Clerk from './occupations/88C-Clerk.jpg'
import Catapulter from './occupations/89a-Catapulter.jpg'
import CraftLeader from './occupations/8a-Craft_Leader.jpg'
import SwordFighter from './occupations/90C-Sword_Fighter.jpg'
import Latecomer from './occupations/91c-Latecomer.jpg'
import SympatheticSoul from './occupations/92C-Sympathetic_Soul.jpg'
import HelperInTimeOfNeed from './occupations/93C-Helper_in_Time_of_Need.jpg'
import SpiceMerchant from './occupations/94A-Spice_Merchant.jpg'
import Wanderer from './occupations/95A-Wanderer.jpg'
import MeatMerchant from './occupations/96A-Meat_Merchant.jpg'
import PeaCounter from './occupations/97B-Pea_Counter.jpg'
import Nobleman from './occupations/98A-Nobleman.jpg'
import Preceptor from './occupations/99A-Preceptor.jpg'
import OreBoatman from './occupations/9A-Ore_Boatman.jpg'
import occupationBack from './occupations/occupation-back.jpg'
import startingOccupationBack from './occupations/starting-occupation-back.jpg'
import blackViking from './markers/black-viking.png'
import blueViking from './markers/blue-viking.png'
import playerBoard from './boards/player-board.jpg'
import playerBoardShortGame from './boards/player-board-short-game.jpg'
import redViking from './markers/red-viking.png'
import thingPenalty from './tokens/thing-penalty.jpg'
import yellowViking from './markers/yellow-viking.png'
import roundOverview from './boards/round-overview.jpg'
import roundTrackingCube from './markers/round-tracking-cube.png'
import bow from './weapons/bow.jpg'
import snare from './weapons/snare.jpg'
import spear from './weapons/spear.jpg'
import sword from './weapons/sword.jpg'
import weaponBack from './weapons/weapon-back.jpg'
import goodsBox from './goods/goods-box.png'
import upgradeArrow from './goods/upgrade_arrow.png'
import imitationBoard1 from './boards/imitation-board-1.jpg'
import imitationBoard2 from './boards/imitation-board-2.jpg'
import imitationBoard3 from './boards/imitation-board-3.jpg'
import imitationBoard4 from './boards/imitation-board-4.jpg'
import imitation1 from './actions/imitation-1.png'
import imitation2 from './actions/imitation-2.png'
import imitation3 from './actions/imitation-3.png'
import imitation4 from './actions/imitation-4.png'

const Images = {
  actionBoard, playerBoard, playerBoardShortGame, actionBoardIcon, explorationIcon, mountainsIcon, roundOverview, roundTrackingCube, thingPenalty,
  close, islandIcon, shedIcon, stoneHouseIcon, longHouseIcon, goodsBox, upgradeArrow,
  mountainBack, mountain0, mountain1, mountain2, mountain3, mountain4, mountain5, mountain6, mountain7,
  shetland, faroeIslands, iceland, greenland, bearIsland, baffinIsland, labrador, newfoundland,
  shed, stoneHouse, longHouse,
  silver, wood, stone, ore, peas, mead, oil, runeStone, flax, stockFish, hide, silverware, beans, milk, wool, chest, grain, saltMeat, linen, silk,
  cabbage, gameMeat, skinAndBones, spices, sheep, pregnantSheep, fur, jewelry, fruits, whaleMeat, robe, treasureChest,
  cattle, pregnantCattle, clothing, silverHoard,
  glassBeads, helmet, cloakpin, belt, crucifix, drinkingHorn, amberFigure, horseshoe, goldBrooch, forgeHammer, fibula, throwingAxe, chalice, roundShield,
  englishCrown, specialTilesSupply, boatsSupply,
  blueViking, redViking, blackViking, yellowViking,
  Peddler, Patron, Furrier, Hunter, Chief, StoneCarver, Miller, CraftLeader, OreBoatman, Milker, TridentHunter, Helmsman, Archer, ShipOwner, Cottager, Captain,
  OrientShipper, Fighter, Host, Builder, Collector, Miner, Custodian, WhalingEquipper, MeatTrader, MeatInspector, Fisherman, Locksmith, MeatPreserver,
  SilkMerchant, Storeman, WeaponsSupplier, SheepShearer, Shepherd, CattleBreeder, ShipsCook, ForeignTrader, Peacemaker, Modifier, MasterJoiner, Slowpoke,
  Steward, FruitPicker, MasterTailor, Pirate, ShipArchitect, Tanner, Farmer, Plower, RuneEngraver, MasterMason, Tutor, FarmShopOwner, Tradesman, Treasurer,
  ArmsDealer, BeanFarmer, LinseedOilPresser, LinenWeaver, Inspector, WharfOwner, FineBlacksmith, Hornturner, ShipBuilder, Dragonslayer, FieldFarmer,
  PrivateChef, WeaponSupplier, EquipmentSupplier, Courier, Innkeeper, Earl, Metalsmith, Cowherd, BoneCollector, Homecomer, Follower, TravelingMerchant,
  Breeder, ShepherdBoy, Quartermaster, LongshipBuilder, Wholesaler, HarborGuard, Hornblower, HideBuyer, Preacher, Clerk, Catapulter, SwordFighter, Latecomer,
  SympatheticSoul, HelperInTimeOfNeed, SpiceMerchant, Wanderer, MeatMerchant, PeaCounter, Nobleman, Preceptor, Undertaker, Judge, FurMerchant, ForestBlacksmith,
  DisheartenedWarrior, KnarrBuilder, Warmonger, Adventurer, Ironsmith, MasterCount, BirkaSettler, Artist, Barkeep, ApprenticeCraftsman, SilkStitcher, Princess,
  MasterBricklayer, Shipowner, Messenger, Milkman, Middleman, Carpenter, MountainGuard, OilSeller, Laborer, Scribe, Trapper, Angler, Digger, MeatCurer,
  SnareSpecialist, BaitLayer, Berserker, LanceBearer, Drunkard, LoneWolf, Raider, MeleeFighter, Robber, LootHunter, BlubberCook, IvorySculptor, WhaleCatcher,
  AntlersSeller, Deerstalker, Forester, Sledger, Steersman, SpiceTrader, Priest, MeatBuyer, ThingSpokesman, Merchant, ProficientHunter, Woodcutter, Cooper,
  StoneCrusher, Skinner, Barbarian, FestiveHunter, SoberMan, WhalingAssistant, FlaxFarmer, Farmhand, ArmedFighter, VillageLeader, WeaponsWarden, Taster,
  EarlOfLade, Sower, RefugeeHelper, FlaxBaker, FishCook, Punchcutter, BosporusTraveller, CodliverOilPresser, EtiquetteTeacher, Maid, BoatBuilder,
  CutterOperator, BeachRaider, SailPatcher, HerbGardener, GrainDeliveryman, Sponsor, DorestadTraveller, PeaFlourBaker, Chronicler, Mineworker, Seafarer,
  BosporusMerchant,
  bow, snare, spear, sword, weaponBack, occupationBack, startingOccupationBack,
  whalingBoat, knarr, longship, whalingBoatEmigration, knarrEmigration, longshipEmigration,
  buildShed, buildStoneHouse, buildLongHouse, buildWhalingBoat, buildKnarr, buildLongship, buildHouseAndBoat,
  huntingGame1, huntingGame2, fishing, layingSnare, whaling, whalingMinor,
  buyFish, buySheep, buyMeat, buyCattleAndMilk, buySheepOrCattle, buySheepAndCattle,
  getBeans, getFlaxAndFish, getFruitsOilMeat, milking, getMead, shearing, getSpiceMilkWool,
  craftLinen, craftClothing, smithy, craftRuneStone, craftChest, craftChestsAndRuneStones, craftRobeAndJewelry,
  take2Resources, takeWoodAndOre, take5Resources, take1ResourceAndTrade, take3ResourcesAndTrade, trade3AndWeapons, take4ResourcesAndTrade2,
  trade2, trade3, trade4, tradeOversee1, tradeOversee2, specialSale, take8ResourcesOrTrade3,
  raiding, pillaging1, pillaging2, exploreI, exploreII, exploreIII, plundering,
  drawOccupation, emigrate1, emigrate2, playOccupation, play2Occupations, play4Occupations, exchangeBoatAndEmigrate,
  imitationBoard1, imitationBoard2, imitationBoard3, imitationBoard4, imitation1, imitation2, imitation3, imitation4
}

export default Images