/** @jsxImportSource @emotion/react */
import GameLocalView from '../GameLocalView'
import {Trans, useTranslation} from 'react-i18next'
import {css} from '@emotion/react'

import Occupation, {occupationsScore} from '@gamepark/a-feast-for-odin/material/Occupation'
import OccupationCard from './OccupationCard'
import {TFunction} from 'i18next'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {useEffect, useMemo, useRef} from 'react'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'
import {titleButtonCss} from '../styles'
import {playOccupationMove} from '@gamepark/a-feast-for-odin/moves/PlayOccupation'
import ReceiveGoodDialogContent from '../goods/ReceiveGoodDialogContent'
import {getStoremanEligibleGoods} from '@gamepark/a-feast-for-odin/material/occupations/Storeman'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import SlowpokeChoice from './SlowpokeChoice'
import OccupationsRules from '@gamepark/a-feast-for-odin/material/occupations/OccupationsRules'
import {playOccupationEffectMove} from '@gamepark/a-feast-for-odin/moves/PlayOccupationEffect'
import ShipArchitectChoice from './ShipArchitectChoice'
import ArmsDealerChoice from './ArmsDealerChoice'
import InspectorChoice from './InspectorChoice'
import {InspectorEffect} from '@gamepark/a-feast-for-odin/material/occupations/Inspector'
import FineBlacksmithChoice from './FineBlacksmithChoice'
import HornturnerChoice from './HornturnerChoice'
import {HornturnerEffect} from '@gamepark/a-feast-for-odin/material/occupations/Hornturner'
import {PlayMoveButton} from '../PlayMoveButton'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import EarlChoice from './EarlChoice'
import MetalsmithChoice from './MetalsmithChoice'
import PrivateChefChoice from './PrivateChefChoice'
import CowherdChoice from './CowherdChoice'
import LongshipDiscountChoice from './LongshipDiscountChoice'
import {getQuartermasterCost} from '@gamepark/a-feast-for-odin/material/occupations/Quartermaster'
import {getLongshipBuilderCost} from '@gamepark/a-feast-for-odin/material/occupations/LongshipBuilder'
import {HornblowerEffect} from '@gamepark/a-feast-for-odin/material/occupations/Hornblower'
import HornblowerChoice from './HornblowerChoice'
import HideBuyerChoice from './HideBuyerChoice'
import PreacherChoice from './PreacherChoice'
import PayToGetChoice from './PayToGetChoice'
import SpiceMerchantChoice from './SpiceMerchantChoice'
import MeatMerchantChoice from './MeatMerchantChoice'
import FurMerchantChoice from './FurMerchantChoice'
import KnarrBuilderChoice from './KnarrBuilderChoice'
import IronsmithChoice from './IronsmithChoice'
import OilSellerChoice from './OilSellerChoice'
import LaborerChoice from './LaborerChoice'
import MeatCurerChoice from './MeatCurerChoice'
import SnareSpecialistChoice from './SnareSpecialistChoice'
import BaitLayerChoice from './BaitLayerChoice'
import Raider from '@gamepark/a-feast-for-odin/material/occupations/Raider'
import MeatBuyerChoice from './MeatBuyerChoice'
import {MeatBuyerEffect} from '@gamepark/a-feast-for-odin/material/occupations/MeatBuyer'
import CooperChoice from './CooperChoice'
import FlaxBakerChoice from './FlaxBakerChoice'
import BosporusTravellerChoice from './BosporusTravellerChoice'
import Maid from '@gamepark/a-feast-for-odin/material/occupations/Maid'
import MaidChoice from './MaidChoice'
import HerbGardenerChoice from './HerbGardenerChoice'
import SponsorChoice from './SponsorChoice'
import ChroniclerChoice from './ChroniclerChoice'
import Chronicler from '@gamepark/a-feast-for-odin/material/occupations/Chronicler'

type Props = {
  game: GameLocalView
  occupation: Occupation
  close: () => void
}

export default function OccupationRulesDialogContent({game, occupation, close}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(player => player.color === playerId)
  const canPlay = useMemo(() =>
    playerId !== undefined && new AFeastForOdin(game).getLegalMoves(playerId).some(move =>
      move.type === MoveType.PlayOccupation && move.occupation === occupation
    ), [game, playerId])
  const hasAnyTimeEffect = useMemo(() =>
    !!(player?.occupations.includes(occupation) && new OccupationsRules[occupation](game, player).anyTimeEffect), [game, playerId])
  const canPlayAnyTimeEffect = useMemo(() =>
      player?.effects.length === 0 && new OccupationsRules[occupation](game, player).canUseAnyTimeEffect()
    , [game])
  const play = usePlay()

  const isPendingEffect = player && player.effects.length > 0 && player.effects[0].occupation === occupation
  const wasPendingEffect = useRef(false)
  useEffect(() => {
    if (isPendingEffect) wasPendingEffect.current = true
    else if (wasPendingEffect.current) close()
  }, [isPendingEffect])

  return <>
    <h2>{t('Occupation rules')}</h2>
    <div css={flexContainer}>
      <OccupationCard occupation={occupation} css={occupationCardCss}/>
      <div css={rightColumn}>
        <p css={rulesCss}>{occupationsRulesTexts[occupation](t)}</p>
        <p><em>{t('occupation.score', {score: occupationsScore[occupation]})}</em></p>
        {canPlay && playerId &&
          <p>
            <button css={titleButtonCss} onClick={() => play(playOccupationMove(playerId, occupation))}>{t('Play this occupation')}</button>
          </p>
        }
        {isPendingEffect && <OccupationChoice game={game} player={player} occupation={occupation}/>}
        {!isPendingEffect && hasAnyTimeEffect &&
          <p>
            <button css={titleButtonCss} onClick={() => play(playOccupationEffectMove(playerId!, occupation))} disabled={!canPlayAnyTimeEffect}>
              {t('play.effect')}
            </button>
          </p>
        }
        {player?.exhaustedOccupations.includes(occupation) && <p>{t('exhausted.occupation')}</p>}
      </div>
    </div>
  </>
}

function OccupationChoice({game, player, occupation}: { game: GameLocalView, player: PlayerLocal, occupation: Occupation }) {
  switch (occupation) {
    case Occupation.Storeman:
      const goods = getStoremanEligibleGoods(player)
      if (goods.length > 0) {
        return <ReceiveGoodDialogContent goods={getStoremanEligibleGoods(player)}/>
      } else {
        return <p><Trans defaults="no.eligible.good" components={[<PassButton/>]}/></p>
      }
    case Occupation.Locksmith:
      return <PayToGetChoice player={player} cost={1} good={Good.Oil}/>
    case Occupation.Peacemaker:
      return <ReceiveGoodDialogContent goods={[Good.Wood, Good.Stone, Good.Ore]}/>
    case Occupation.Slowpoke:
      return <SlowpokeChoice player={player}/>
    case Occupation.ShipArchitect:
      return <ShipArchitectChoice player={player}/>
    case Occupation.MasterMason:
      return <ReceiveGoodDialogContent goods={[Good.Milk, Good.Cabbage]}/>
    case Occupation.Tradesman:
      return <ReceiveGoodDialogContent goods={[Good.Silk, Good.Chest]}/>
    case Occupation.ArmsDealer:
      return <ArmsDealerChoice player={player}/>
    case Occupation.Inspector:
      return <p><InspectorChoice player={player} effect={player.effects[0] as InspectorEffect} round={game.round}/></p>
    case Occupation.FineBlacksmith:
      return <FineBlacksmithChoice player={player} specialTilesSupply={game.specialTilesSupply}/>
    case Occupation.Hornturner:
      return <HornturnerChoice player={player} effect={player.effects[0] as HornturnerEffect}
                               hornAvailable={game.specialTilesSupply.includes(Good.DrinkingHorn)}/>
    case Occupation.Dragonslayer:
      return <p>
        <Trans defaults="dragonslayer.effect" components={[
          <PlayMoveButton move={spendWeaponsMove(player.color, {[Weapon.Snare]: 2, [Weapon.Spear]: 2})}/>, <PassButton/>
        ]}/>
      </p>
    case Occupation.PrivateChef:
      return <PrivateChefChoice player={player}/>
    case Occupation.Earl:
      return <EarlChoice player={player} round={game.round}/>
    case Occupation.Metalsmith:
      return <MetalsmithChoice player={player}/>
    case Occupation.Cowherd:
      return <CowherdChoice player={player}/>
    case Occupation.BoneCollector:
      return <PayToGetChoice player={player} cost={1} good={Good.SkinAndBones}/>
    case Occupation.ShepherdBoy:
      return <PayToGetChoice player={player} cost={2} good={Good.Sheep}/>
    case Occupation.Quartermaster:
      return <LongshipDiscountChoice player={player} cost={getQuartermasterCost(player)}/>
    case Occupation.LongshipBuilder:
      return <LongshipDiscountChoice player={player} cost={getLongshipBuilderCost(player)}/>
    case Occupation.Hornblower:
      return <HornblowerChoice player={player} effect={player.effects[0] as HornblowerEffect}/>
    case Occupation.HideBuyer:
      return <HideBuyerChoice player={player}/>
    case Occupation.Preacher:
      return <PreacherChoice player={player} crucifixAvailable={game.specialTilesSupply.includes(Good.Crucifix)}/>
    case Occupation.SpiceMerchant:
      return <SpiceMerchantChoice player={player}/>
    case Occupation.MeatMerchant:
      return <MeatMerchantChoice player={player}/>
    case Occupation.Nobleman:
      return <PayToGetChoice player={player} cost={2} good={Good.Silk}/>
    case Occupation.FurMerchant:
      return <FurMerchantChoice player={player}/>
    case Occupation.DisheartenedWarrior:
      return <ReceiveGoodDialogContent goods={[Good.Stockfish, Good.Oil]}/>
    case Occupation.KnarrBuilder:
      return <KnarrBuilderChoice player={player}/>
    case Occupation.Ironsmith:
      return <IronsmithChoice player={player}/>
    case Occupation.SilkStitcher:
      return <PayToGetChoice player={player} cost={3} good={Good.Silk}/>
    case Occupation.OilSeller:
      return <OilSellerChoice player={player}/>
    case Occupation.Laborer:
      return <LaborerChoice player={player}/>
    case Occupation.MeatCurer:
      return <MeatCurerChoice player={player}/>
    case Occupation.SnareSpecialist:
      return <SnareSpecialistChoice player={player}/>
    case Occupation.BaitLayer:
      return <BaitLayerChoice player={player}/>
    case Occupation.LanceBearer:
      return <ReceiveGoodDialogContent goods={[Good.Peas, Good.Silver]}/>
    case Occupation.Raider:
      return <ReceiveGoodDialogContent goods={new Raider(game, player).getEligibleGoods()}/>
    case Occupation.Deerstalker:
      return <PayToGetChoice player={player} cost={2} good={Good.SkinAndBones}/>
    case Occupation.SpiceTrader:
      return <PayToGetChoice player={player} cost={3} good={Good.Spices}/>
    case Occupation.MeatBuyer:
      return <MeatBuyerChoice player={player} good={(player.effects[0] as MeatBuyerEffect).good}/>
    case Occupation.Cooper:
      return <CooperChoice player={player}/>
    case Occupation.Sower:
      return <ReceiveGoodDialogContent goods={[Good.Flax, Good.Grain]}/>
    case Occupation.FlaxBaker:
      return <FlaxBakerChoice player={player}/>
    case Occupation.BosporusTraveller:
      return <BosporusTravellerChoice player={player}/>
    case Occupation.CodliverOilPresser:
      return <PayToGetChoice player={player} cost={1} good={Good.Oil}/>
    case Occupation.Maid:
      return <MaidChoice player={player} good={new Maid(game, player).effect.good}/>
    case Occupation.HerbGardener:
      return <HerbGardenerChoice player={player}/>
    case Occupation.Sponsor:
      return <SponsorChoice player={player}/>
    case Occupation.Chronicler:
      return <ChroniclerChoice player={player} canTakeBelt={new Chronicler(game, player).canTakeBelt()}/>
    default:
      return null

  }
}

const flexContainer = css`
  display: flex;
  margin: 5em;
  align-items: start;
`

const rightColumn = css`
  flex-grow: 1;
  margin-left: 5em;
  text-align: left;
`

const rulesCss = css`
  white-space: break-spaces;
  text-align: start;
  margin: 0;
`

const occupationsRulesTexts: Record<Occupation, (t: TFunction) => string> = {
  [Occupation.Peddler]: t => t('occ.rules.1'),
  [Occupation.Patron]: t => t('occ.rules.2'),
  [Occupation.Furrier]: t => t('occ.rules.3'),
  [Occupation.Hunter]: t => t('occ.rules.4'),
  [Occupation.Chief]: t => t('occ.rules.5'),
  [Occupation.StoneCarver]: t => t('occ.rules.6'),
  [Occupation.Miller]: t => t('occ.rules.7'),
  [Occupation.CraftLeader]: t => t('occ.rules.8'),
  [Occupation.OreBoatman]: t => t('occ.rules.9'),
  [Occupation.Milker]: t => t('occ.rules.10'),
  [Occupation.TridentHunter]: t => t('occ.rules.11'),
  [Occupation.Helmsman]: t => t('occ.rules.12'),
  [Occupation.Archer]: t => t('occ.rules.13'),
  [Occupation.ShipOwner]: t => t('occ.rules.14'),
  [Occupation.Cottager]: t => t('occ.rules.15'),
  [Occupation.Captain]: t => t('occ.rules.16'),
  [Occupation.OrientShipper]: t => t('occ.rules.17'),
  [Occupation.Fighter]: t => t('occ.rules.18'),
  [Occupation.Host]: t => t('occ.rules.19'),
  [Occupation.Builder]: t => t('occ.rules.20'),
  [Occupation.Collector]: t => t('occ.rules.21'),
  [Occupation.Miner]: t => t('occ.rules.22'),
  [Occupation.Custodian]: t => t('occ.rules.23'),
  [Occupation.WhalingEquipper]: t => t('occ.rules.24'),
  [Occupation.MeatTrader]: t => t('occ.rules.25'),
  [Occupation.MeatInspector]: t => t('occ.rules.26'),
  [Occupation.Fisherman]: t => t('occ.rules.27'),
  [Occupation.Locksmith]: t => t('occ.rules.28'),
  [Occupation.MeatPreserver]: t => t('occ.rules.29'),
  [Occupation.SilkMerchant]: t => t('occ.rules.30'),
  [Occupation.Storeman]: t => t('occ.rules.31'),
  [Occupation.WeaponsSupplier]: t => t('occ.rules.32'),
  [Occupation.SheepShearer]: t => t('occ.rules.33'),
  [Occupation.Shepherd]: t => t('occ.rules.34'),
  [Occupation.CattleBreeder]: t => t('occ.rules.35'),
  [Occupation.ShipsCook]: t => t('occ.rules.36'),
  [Occupation.ForeignTrader]: t => t('occ.rules.37'),
  [Occupation.Peacemaker]: t => t('occ.rules.38'),
  [Occupation.Modifier]: t => t('occ.rules.39'),
  [Occupation.MasterJoiner]: t => t('occ.rules.40'),
  [Occupation.Slowpoke]: t => t('occ.rules.41'),
  [Occupation.Steward]: t => t('occ.rules.42'),
  [Occupation.FruitPicker]: t => t('occ.rules.43'),
  [Occupation.MasterTailor]: t => t('occ.rules.44'),
  [Occupation.Pirate]: t => t('occ.rules.45'),
  [Occupation.ShipArchitect]: t => t('occ.rules.46'),
  [Occupation.Tanner]: t => t('occ.rules.47'),
  [Occupation.Farmer]: t => t('occ.rules.48'),
  [Occupation.Plower]: t => t('occ.rules.49'),
  [Occupation.RuneEngraver]: t => t('occ.rules.50'),
  [Occupation.MasterMason]: t => t('occ.rules.51'),
  [Occupation.Tutor]: t => t('occ.rules.52'),
  [Occupation.FarmShopOwner]: t => t('occ.rules.53'),
  [Occupation.Tradesman]: t => t('occ.rules.54'),
  [Occupation.Treasurer]: t => t('occ.rules.55'),
  [Occupation.ArmsDealer]: t => t('occ.rules.56'),
  [Occupation.BeanFarmer]: t => t('occ.rules.57'),
  [Occupation.LinseedOilPresser]: t => t('occ.rules.58'),
  [Occupation.LinenWeaver]: t => t('occ.rules.59'),
  [Occupation.Inspector]: t => t('occ.rules.60'),
  [Occupation.WharfOwner]: t => t('occ.rules.61'),
  [Occupation.FineBlacksmith]: t => t('occ.rules.62'),
  [Occupation.Hornturner]: t => t('occ.rules.63'),
  [Occupation.ShipBuilder]: t => t('occ.rules.64'),
  [Occupation.Dragonslayer]: t => t('occ.rules.65'),
  [Occupation.FieldFarmer]: t => t('occ.rules.66'),
  [Occupation.PrivateChef]: t => t('occ.rules.67'),
  [Occupation.WeaponSupplier]: t => t('occ.rules.68'),
  [Occupation.EquipmentSupplier]: t => t('occ.rules.69'),
  [Occupation.Courier]: t => t('occ.rules.70'),
  [Occupation.Innkeeper]: t => t('occ.rules.71'),
  [Occupation.Earl]: t => t('occ.rules.72'),
  [Occupation.Metalsmith]: t => t('occ.rules.73'),
  [Occupation.Cowherd]: t => t('occ.rules.74'),
  [Occupation.BoneCollector]: t => t('occ.rules.75'),
  [Occupation.Homecomer]: t => t('occ.rules.76'),
  [Occupation.Follower]: t => t('occ.rules.77'),
  [Occupation.TravelingMerchant]: t => t('occ.rules.78'),
  [Occupation.Breeder]: t => t('occ.rules.79'),
  [Occupation.ShepherdBoy]: t => t('occ.rules.80'),
  [Occupation.Quartermaster]: t => t('occ.rules.81'),
  [Occupation.LongshipBuilder]: t => t('occ.rules.82'),
  [Occupation.Wholesaler]: t => t('occ.rules.83'),
  [Occupation.HarborGuard]: t => t('occ.rules.84'),
  [Occupation.Hornblower]: t => t('occ.rules.85'),
  [Occupation.HideBuyer]: t => t('occ.rules.86'),
  [Occupation.Preacher]: t => t('occ.rules.87'),
  [Occupation.Clerk]: t => t('occ.rules.88'),
  [Occupation.Catapulter]: t => t('occ.rules.89'),
  [Occupation.SwordFighter]: t => t('occ.rules.90'),
  [Occupation.Latecomer]: t => t('occ.rules.91'),
  [Occupation.SympatheticSoul]: t => t('occ.rules.92'),
  [Occupation.HelperInTimeOfNeed]: t => t('occ.rules.93'),
  [Occupation.SpiceMerchant]: t => t('occ.rules.94'),
  [Occupation.Wanderer]: t => t('occ.rules.95'),
  [Occupation.MeatMerchant]: t => t('occ.rules.96'),
  [Occupation.PeaCounter]: t => t('occ.rules.97'),
  [Occupation.Nobleman]: t => t('occ.rules.98'),
  [Occupation.Preceptor]: t => t('occ.rules.99'),
  [Occupation.Undertaker]: t => t('occ.rules.100'),
  [Occupation.Judge]: t => t('occ.rules.101'),
  [Occupation.FurMerchant]: t => t('occ.rules.102'),
  [Occupation.ForestBlacksmith]: t => t('occ.rules.103'),
  [Occupation.DisheartenedWarrior]: t => t('occ.rules.104'),
  [Occupation.KnarrBuilder]: t => t('occ.rules.105'),
  [Occupation.Warmonger]: t => t('occ.rules.106'),
  [Occupation.Adventurer]: t => t('occ.rules.107'),
  [Occupation.Ironsmith]: t => t('occ.rules.108'),
  [Occupation.MasterCount]: t => t('occ.rules.109'),
  [Occupation.BirkaSettler]: t => t('occ.rules.110'),
  [Occupation.Artist]: t => t('occ.rules.111'),
  [Occupation.Barkeep]: t => t('occ.rules.112'),
  [Occupation.ApprenticeCraftsman]: t => t('occ.rules.113'),
  [Occupation.SilkStitcher]: t => t('occ.rules.114'),
  [Occupation.Princess]: t => t('occ.rules.115'),
  [Occupation.MasterBricklayer]: t => t('occ.rules.116'),
  [Occupation.Shipowner]: t => t('occ.rules.117'),
  [Occupation.Messenger]: t => t('occ.rules.118'),
  [Occupation.Milkman]: t => t('occ.rules.119'),
  [Occupation.Middleman]: t => t('occ.rules.120'),
  [Occupation.Carpenter]: t => t('occ.rules.121'),
  [Occupation.MountainGuard]: t => t('occ.rules.122'),
  [Occupation.OilSeller]: t => t('occ.rules.123'),
  [Occupation.Laborer]: t => t('occ.rules.124'),
  [Occupation.Scribe]: t => t('occ.rules.125'),
  [Occupation.Trapper]: t => t('occ.rules.126'),
  [Occupation.Angler]: t => t('occ.rules.127'),
  [Occupation.Digger]: t => t('occ.rules.128'),
  [Occupation.MeatCurer]: t => t('occ.rules.129'),
  [Occupation.SnareSpecialist]: t => t('occ.rules.130'),
  [Occupation.BaitLayer]: t => t('occ.rules.131'),
  [Occupation.Berserker]: t => t('occ.rules.132'),
  [Occupation.LanceBearer]: t => t('occ.rules.133'),
  [Occupation.Drunkard]: t => t('occ.rules.134'),
  [Occupation.LoneWolf]: t => t('occ.rules.135'),
  [Occupation.Raider]: t => t('occ.rules.136'),
  [Occupation.MeleeFighter]: t => t('occ.rules.137'),
  [Occupation.Robber]: t => t('occ.rules.138'),
  [Occupation.LootHunter]: t => t('occ.rules.139'),
  [Occupation.BlubberCook]: t => t('occ.rules.140'),
  [Occupation.IvorySculptor]: t => t('occ.rules.141'),
  [Occupation.WhaleCatcher]: t => t('occ.rules.142'),
  [Occupation.AntlersSeller]: t => t('occ.rules.143'),
  [Occupation.Deerstalker]: t => t('occ.rules.144'),
  [Occupation.Forester]: t => t('occ.rules.145'),
  [Occupation.Sledger]: t => t('occ.rules.146'),
  [Occupation.Steersman]: t => t('occ.rules.147'),
  [Occupation.SpiceTrader]: t => t('occ.rules.148'),
  [Occupation.Priest]: t => t('occ.rules.149'),
  [Occupation.MeatBuyer]: t => t('occ.rules.150'),
  [Occupation.ThingSpokesman]: t => t('occ.rules.151'),
  [Occupation.Merchant]: t => t('occ.rules.152'),
  [Occupation.ProficientHunter]: t => t('occ.rules.153'),
  [Occupation.Woodcutter]: t => t('occ.rules.154'),
  [Occupation.Cooper]: t => t('occ.rules.155'),
  [Occupation.StoneCrusher]: t => t('occ.rules.156'),
  [Occupation.Skinner]: t => t('occ.rules.157'),
  [Occupation.Barbarian]: t => t('occ.rules.158'),
  [Occupation.FestiveHunter]: t => t('occ.rules.159'),
  [Occupation.SoberMan]: t => t('occ.rules.160'),
  [Occupation.WhalingAssistant]: t => t('occ.rules.161'),
  [Occupation.FlaxFarmer]: t => t('occ.rules.162'),
  [Occupation.Farmhand]: t => t('occ.rules.163'),
  [Occupation.ArmedFighter]: t => t('occ.rules.164'),
  [Occupation.VillageLeader]: t => t('occ.rules.165'),
  [Occupation.WeaponsWarden]: t => t('occ.rules.166'),
  [Occupation.Taster]: t => t('occ.rules.167'),
  [Occupation.EarlOfLade]: t => t('occ.rules.168'),
  [Occupation.Sower]: t => t('occ.rules.169'),
  [Occupation.RefugeeHelper]: t => t('occ.rules.170'),
  [Occupation.FlaxBaker]: t => t('occ.rules.171'),
  [Occupation.FishCook]: t => t('occ.rules.172'),
  [Occupation.Punchcutter]: t => t('occ.rules.173'),
  [Occupation.BosporusTraveller]: t => t('occ.rules.174'),
  [Occupation.CodliverOilPresser]: t => t('occ.rules.175'),
  [Occupation.EtiquetteTeacher]: t => t('occ.rules.176'),
  [Occupation.Maid]: t => t('occ.rules.177'),
  [Occupation.BoatBuilder]: t => t('occ.rules.178'),
  [Occupation.CutterOperator]: t => t('occ.rules.179'),
  [Occupation.BeachRaider]: t => t('occ.rules.180'),
  [Occupation.SailPatcher]: t => t('occ.rules.181'),
  [Occupation.HerbGardener]: t => t('occ.rules.182'),
  [Occupation.GrainDeliveryman]: t => t('occ.rules.183'),
  [Occupation.Sponsor]: t => t('occ.rules.184'),
  [Occupation.DorestadTraveller]: t => t('occ.rules.185'),
  [Occupation.PeaFlourBaker]: t => t('occ.rules.186'),
  [Occupation.Chronicler]: t => t('occ.rules.187'),
  [Occupation.Mineworker]: t => t('occ.rules.188'),
  [Occupation.Seafarer]: t => t('occ.rules.189'),
  [Occupation.BosporusMerchant]: t => t('occ.rules.190')
}

const occupationCardCss = css`
  position: relative;
  font-size: 3em;
  flex-grow: 0;
  flex-shrink: 0;
`
