import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import GoodsAreaType from '../goods/GoodsAreaType'
import Good from '../goods/Good'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {isPlacedGoodsLocal} from '../../moves/PlacedGoodsLocal'

export default class Undertaker extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color && move.goodsArea.type === GoodsAreaType.Territory
      && move.placedGoods.some(placedGood => placedGood.good === Good.RuneStone && !isPlacedGoodsLocal(placedGood))) {
      return [receiveGoodsMove(this.player.color, Good.Silver)]
    }
    return []
  }
}
