import Territory from '../Territory'
import Action from '../../actions/Action'

const TerritoriesExplorationAction: Record<Territory, Action> = {
  [Territory.Shetland]: Action.ExploreI,
  [Territory.FaroeIslands]: Action.ExploreI,
  [Territory.Greenland]: Action.ExploreII,
  [Territory.Iceland]: Action.ExploreII,
  [Territory.BearIsland]: Action.ExploreII,
  [Territory.BaffinIsland]: Action.ExploreIII,
  [Territory.Labrador]: Action.ExploreIII,
  [Territory.Newfoundland]: Action.ExploreIII,
}

export default TerritoriesExplorationAction