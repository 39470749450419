import PlayerColor from '../PlayerColor'
import MoveType from './MoveType'

type ClearBanquet = {
  type: MoveType.ClearBanquet
  player: PlayerColor
}

export default ClearBanquet

export function clearBanquetMove(player: PlayerColor): ClearBanquet {
  return {type: MoveType.ClearBanquet, player}
}
