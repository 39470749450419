/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PayButton} from '../PayButton'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import PlayerLocal from '../PlayerLocal'
import GoodItem from '../goods/GoodItem'
import {PlayMoveButton} from '../PlayMoveButton'
import {receiveGoodsMove} from '@gamepark/a-feast-for-odin/moves/ReceiveGoods'
import {inlineGood} from '../styles'

type Props = {
  player: PlayerLocal
}

export default function MetalsmithChoice({player}: Props) {
  return <p>
    <Trans defaults="metalsmith.effect" components={[
      <PlayMoveButton move={receiveGoodsMove(player.color, Good.Silverware)}/>,
      <PayButton player={player} goods={{[Good.Silver]: 2}}/>,
      <GoodItem css={inlineGood} good={Good.Crucifix} height={4}/>,
      <PayButton player={player} goods={{[Good.Silver]: 5}}/>,
      <GoodItem css={inlineGood} good={Good.Chalice} height={4}/>
    ]}/>
  </p>
}
