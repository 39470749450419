/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import BoatType from '@gamepark/a-feast-for-odin/material/boats/BoatType'
import {useMemo} from 'react'
import EmigrationTile from '../../emigration/EmigrationTile'
import {banquetLeft, banquetSquareSize, banquetTop, longBoatRatio, squareSize} from '../../styles'

type Props = {
  emigration: BoatType[]
}

export default function PlayerEmigrationTiles({emigration}: Props) {
  const placedTiles: PlacedEmigrationTiles[] = useMemo(
    () => {
      const result: PlacedEmigrationTiles[] = []
      let x = 0
      for (const boatType of emigration) {
        result.push({boatType, x})
        x += boatType === BoatType.WhalingBoat ? 1 : 2
      }
      return result
    }, [emigration]
  )
  return (
    <>
      {placedTiles.map((tile, index) => <EmigrationTile key={index} boatType={tile.boatType} css={position(tile.x)}/>)}
    </>
  )
}

type PlacedEmigrationTiles = {
  boatType: BoatType
  x: number
}

const position = (x: number) => css`
  left: ${banquetLeft + x * banquetSquareSize}em;
  top: ${banquetTop - longBoatRatio * 2 * squareSize + 4 * banquetSquareSize}em;
`
