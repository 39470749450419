import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import {countKnarrs} from '../boats/LandingStages'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import Good, {upgradableGoods} from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {playerHasGoods} from '../../AFeastForOdin'
import {startEffectMove} from '../../moves/StartEffect'

export type ClerkEffect = {
  occupation: Occupation.Clerk
  upgradesLeft: number
}

export default class Clerk extends UpgradeGoodOccupationRules {
  get upgradableGoods() {
    return upgradableGoods.filter(good => good > 20)
  }

  get upgrade() {
    return -9
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.TradeOverseas1 || move.action === Action.TradeOverseas2)) {
      return [startEffectMove(this.player.color, {occupation: Occupation.Clerk, upgradesLeft: countKnarrs(this.player)})]
    }
    return []
  }

  get effect(): ClerkEffect {
    return this.player.effects[0] as ClerkEffect
  }

  delegate() {
    if (!playerHasGoods(this.player, {[Good.Silver]: 2})) return
    return super.delegate()
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences: Move[] = []
    if (move.type === MoveType.SpendGoods && move.player === this.player.color && move.goods !== Good.Silver) {
      consequences.push(spendGoodsMove(this.player.color, Good.Silver))
    }
    return consequences.concat(super.play(move))
  }

  onReceiveGoods() {
    this.effect.upgradesLeft--
    if (!this.effect.upgradesLeft) {
      this.player.effects.shift()
    }
  }
}
