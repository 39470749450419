import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Good, {goodsToMap} from '../goods/Good'
import TradeOccupationRules from './TradeOccupationRules'
import Trade from '../../actions/mountainsAndTrade/Trade'
import Occupation from '../Occupation'

export type MeatBuyerEffect = {
  occupation: Occupation.MeatBuyer
  good: Good.Stockfish | Good.SaltMeat
}

export default class MeatBuyer extends TradeOccupationRules {
  get trades(): Trade[] {
    return [{spend: (this.player.effects[0] as MeatBuyerEffect)?.good, receive: Good.GameMeat}]
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      const goods = goodsToMap(move.goods)
      const stockFishes = goods[Good.Stockfish] ?? 0
      const saltMeat = goods[Good.SaltMeat] ?? 0
      if (stockFishes >= 2) {
        this.player.effects.unshift({occupation: Occupation.MeatBuyer, good: Good.Stockfish})
      }
      if (saltMeat >= 2) {
        this.player.effects.unshift({occupation: Occupation.MeatBuyer, good: Good.SaltMeat})
      }
    }
    return []
  }
}
