import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {countKnarrs, countWhalingBoats} from '../boats/LandingStages'
import Good from '../goods/Good'

export default class WhalingEquipper extends OccupationRules {
  immediateEffect() {
    const whalingBoats = countWhalingBoats(this.player)
    const knarrs = countKnarrs(this.player)
    return whalingBoats > 0 || knarrs > 0 ? [receiveGoodsMove(this.player.color, {[Good.Oil]: knarrs, [Good.Wood]: whalingBoats})] : []
  }
}
