import ProduceRules from '../market/ProduceRules'
import Action from '../Action'
import Good from '../../material/goods/Good'

export default class TakeWoodAndOreRules extends ProduceRules {
  action = Action.TakeWoodAndOre

  get goods() {
    return {[Good.Wood]: this.game.players.length, [Good.Ore]: 1}
  }
}