import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import Good from '../goods/Good'
import Occupation from '../Occupation'
import TradeOccupationRules from './TradeOccupationRules'
import Trade from '../../actions/mountainsAndTrade/Trade'
import {isBuildingPlacedGoodsArea} from '../goods/PlacedGoodsArea'
import Building from '../Building'
import BuildingPlacementArea from '../goods/BuildingPlacementArea'

export type MaidEffect = {
  occupation: Occupation.Maid
  good: Good
}

export default class Maid extends TradeOccupationRules {

  get effect(): MaidEffect {
    return this.player.effects[0] as MaidEffect
  }

  get trades(): Trade[] {
    const spend = this.effect?.good
    return spend ? [{spend, receive: Good.Silverware}] : []
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.Pass && move.player === this.player.color && this.game.phase === Phase.Bonus) {
      for (const placedGoodsArea of this.player.placedGoodsAreas.filter(isBuildingPlacedGoodsArea)) {
        if (placedGoodsArea.goodsArea.building === Building.StoneHouse || placedGoodsArea.goodsArea.building === Building.LongHouse) {
          const bonusGoods = new BuildingPlacementArea(placedGoodsArea).getBonusGoods(this.game)
          if (bonusGoods.length === 1) {
            this.player.effects.unshift({occupation: Occupation.Maid, good: bonusGoods[0]})
          }
        }
      }
    }
    return []
  }
}
