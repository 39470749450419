import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {countLongships} from '../boats/LandingStages'
import Occupation from '../Occupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import Game from '../../Game'
import GameView from '../../GameView'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import {passMove} from '../../moves/Pass'
import MoveView from '../../moves/MoveView'
import MoveRandomized from '../../moves/MoveRandomized'
import GoodsAreaType from '../goods/GoodsAreaType'
import {spendGoodsMove} from '../../moves/SpendGoods'

export type OreBoatmanEffect = {
  occupation: Occupation.OreBoatman
  ore: number
}

export default class OreBoatman extends OccupationRules {

  constructor(game: Game | GameView, player: Player | PlayerView) {
    super(game, player)
  }

  get effect(): OreBoatmanEffect {
    return this.player.effects[0] as OreBoatmanEffect
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.StartNextPhase && this.game.phase === Phase.StartPlayer) {
      const longships = countLongships(this.player)
      if (longships > 0) {
        this.player.effects.unshift({occupation: Occupation.OreBoatman, ore: longships})
        return [receiveGoodsMove(this.player.color, {[Good.Ore]: longships})]
      }
    }
    return []
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.PlaceGoods && move.player === this.player.color
      && (move.goodsArea.type === GoodsAreaType.Home || move.goodsArea.type === GoodsAreaType.Territory)) {
      for (const placedGood of move.placedGoods) {
        if (placedGood.good === Good.Ore) {
          this.effect.ore--
        }
      }
      if (this.effect.ore <= 0) {
        this.player.effects.shift()
      }
    }
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      return [spendGoodsMove(this.player.color, {[Good.Ore]: this.effect.ore})]
    }
    if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      this.player.effects.shift()
    }
    return []
  }
}
