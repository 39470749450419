import Good from './goods/Good'

type MountainStrip = {
  id: number
  goods: Good[][]
}

export default MountainStrip

export const mountainStrips = [
  [[Good.Wood], [Good.Wood], [Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Wood], [Good.Wood], [Good.Stone], [Good.Ore], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Stone], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Ore], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Wood], [Good.Stone], [Good.Ore], [Good.Ore], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Stone], [Good.Ore], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Ore], [Good.Ore], [Good.Silver, Good.Silver]],
  [[Good.Wood], [Good.Wood], [Good.Stone], [Good.Ore], [Good.Ore], [Good.Silver, Good.Silver], [Good.Silver, Good.Silver]]
  // Norwegian expansion: [[Good.Wood], [Good.Wood], [Good.Stone], [Good.Stone], [Good.Ore], [Good.Silver, Good.Silver], [Good.Silver, Good.Silver], [Good.Ore, Good.Ore]]
]