/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import {isStartingOccupation} from '@gamepark/a-feast-for-odin/material/Occupation'
import {isPlayerView} from '@gamepark/a-feast-for-odin/PlayerView'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Hand} from '@gamepark/react-components'
import {ItemProps} from '@gamepark/react-components/dist/Hand/Hand'
import {useCallback, useMemo} from 'react'
import GameLocalView from '../../GameLocalView'
import OccupationCard, {OCCUPATION} from '../../occupations/OccupationCard'
import PlayerLocal from '../../PlayerLocal'
import {cardHeight, cardWidth} from '../../styles'
import {occupationRulesDialog, setRulesDialog} from '../../RulesDialog'
import AFeastForOdin from '@gamepark/a-feast-for-odin/AFeastForOdin'
import MoveType from '@gamepark/a-feast-for-odin/moves/MoveType'

type Props = {
  game: GameLocalView
  player: PlayerLocal
}

export default function PlayerHand({game, player}: Props) {
  const playerId = usePlayerId()
  const canPlay = useMemo(() =>
      player.color === playerId && new AFeastForOdin(game).getLegalMoves(player.color).some(move => move.type === MoveType.PlayOccupation),
    [game, player])
  const play = usePlay()

  const getItemProps = useCallback<(index: number) => ItemProps<any, any>>((index: number) => {
    if (isPlayerView(player)) return {}
    const occupation = player.hand[index]
    return {
      css: [cursorPointer, canPlay && scaleUpAnimation],
      hoverStyle,
      onClick: () => play(setRulesDialog(occupationRulesDialog(occupation)), {local: true}),
      drag: {
        type: OCCUPATION,
        item: {occupation},
        canDrag: canPlay,
        drop: play
      }
    }
  }, [player, canPlay, play])

  return (
    <Hand css={style} getItemProps={getItemProps} maxAngle={22} gapMaxAngle={3.4} sizeRatio={cardWidth / cardHeight}>
      {isPlayerView(player) ?
        [...Array(player.hand)].map((_, index) =>
          <OccupationCard key={index} startingOccupation={index === 0 && !player.occupations.some(isStartingOccupation)}/>
        )
        :
        player.hand.map(occupation => <OccupationCard key={occupation} occupation={occupation}/>)
      }
    </Hand>
  )
}

const style = css`
  position: absolute;
  bottom: 2em;
  left: 76em;
  width: ${cardWidth}em;
  height: ${cardHeight}em;
`

const cursorPointer = css`
  cursor: pointer;
`

const hoverStyle = css`
  transform: translateY(-25%) scale(1.5);
`

const scaleUpKeyframes = keyframes`
  from, 60% {
    transform: none;
  }
  to {
    transform: scale(1.05);
  }
`

const scaleUpAnimation = css`
  animation: ${scaleUpKeyframes} 2s ease-in-out alternate infinite;
`
