import Good from '../goods/Good'
import {_1, __, PlacementSpace, XX} from '../goods/PlacementSpace'

const sh: PlacementSpace = {bonus: [Good.StoneHouse]}
const cp: PlacementSpace = {bonus: [Good.Cloakpin]}
const sb: PlacementSpace = {bonus: [Good.SkinAndBones]}

export const newfoundland: PlacementSpace[][] = [
  [XX, XX, _1, _1, _1, XX, XX, XX, XX],
  [XX, XX, _1, _1, XX, XX, XX, XX, XX],
  [XX, _1, sb, _1, XX, _1, _1, XX, XX],
  [XX, __, _1, __, _1, _1, _1, XX, XX],
  [__, _1, __, _1, __, _1, _1, _1, XX],
  [_1, cp, _1, __, _1, __, _1, _1, _1],
  [__, _1, __, _1, _1, _1, sh, _1, XX],
  [_1, _1, _1, __, _1, _1, _1, _1, _1],
  [XX, XX, XX, XX, XX, _1, XX, _1, _1],
]