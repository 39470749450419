/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {getPlayerScreen} from '../../navigation/Screen'
import {displayScreenMove} from '../../navigation/DisplayScreen'
import {PlayMoveButton} from '../../PlayMoveButton'
import {PassButton} from '../../PassButton'
import ExchangeBoatAndEmigrateRules from '@gamepark/a-feast-for-odin/actions/emigration/ExchangeBoatAndEmigrateRules'
import GoodItem from '../../goods/GoodItem'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {css} from '@emotion/react'

type Props = {
  rules: ExchangeBoatAndEmigrateRules
}

export default function ExchangeBoatAndEmigrateChoice({rules}: Props) {
  if (!rules.pendingAction.boatUpgraded && !rules.pendingAction.emigrated) {
    return <Trans defaults="trade.boat.or.emigrate" values={{cost: rules.getEmigrationCost()}}
                  components={[
                    <PlayMoveButton move={displayScreenMove(getPlayerScreen(rules.player.color))} local/>,
                    <PlayMoveButton move={displayScreenMove(getPlayerScreen(rules.player.color))} local/>,
                    <GoodItem css={css`display: inline;`} good={Good.Silver} height={1}/>
                  ]}/>
  } else if (rules.pendingAction.boatUpgraded) {
    if (rules.canEmigrate()) {
      return <Trans defaults="emigrate.or.pass" values={{cost: rules.getEmigrationCost()}}
                    components={[
                      <PlayMoveButton move={displayScreenMove(getPlayerScreen(rules.player.color))} local/>,
                      <GoodItem css={css`display: inline;`} good={Good.Silver} height={1}/>,
                      <PassButton/>
                    ]}/>
    } else {
      return <Trans defaults="emigrate.impossible" components={[<PassButton/>]}/>
    }
  } else {
    return <Trans defaults="trade.boat.or.pass"
                  components={[<PlayMoveButton move={displayScreenMove(getPlayerScreen(rules.player.color))} local/>, <PassButton/>]}/>
  }
}