import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView, {isGameView} from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'
import {addMountainStripMove} from '../moves/AddMountainStrip'
import {removeGoodsFromMountainStripMove} from '../moves/RemoveGoodsFromMountainStrip'
import MoveType from '../moves/MoveType'
import {isActionLocation} from '../material/boards/ActionLocation'

export default class MountainPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    const moves: Move[] = []
    const supply = isGameView(this.game) ? this.game.mountainStripsSupply : this.game.mountainStripsSupply.length
    if (supply > 0) {
      moves.push(addMountainStripMove)
    }
    for (const mountainStrip of this.game.mountainStrips) {
      moves.push(removeGoodsFromMountainStripMove(mountainStrip.id, mountainStrip.goods[mountainStrip.goods.length - 1]))
    }
    moves.push(startNextPhaseMove)
    return moves
  }

  play(move: Move | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.StartNextPhase && this.game.players.length === 1) {
      this.game.blockerVikings = this.game.players[0].vikings.filter(isActionLocation).map(location => location.action)
    }
    return []
  }
}
