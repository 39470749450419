import Move from '../../moves/Move'
import Occupation from '../Occupation'
import OccupationRules from './OccupationRules'
import BoatType from '../boats/BoatType'
import MoveType from '../../moves/MoveType'
import {countKnarrs} from '../boats/LandingStages'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class CutterOperator extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if ((move.type === MoveType.TakeBoat || move.type === MoveType.BuildBoat) && move.player === this.player.color && move.boat === BoatType.Knarr
      && !this.player.exhaustedOccupations.includes(Occupation.CutterOperator)) {
      this.player.exhaustedOccupations.push(Occupation.CutterOperator)
      const knarrs = countKnarrs(this.player)
      return [receiveGoodsMove(this.player.color, {[Good.Stockfish]: knarrs, [Good.Silver]: knarrs})]
    }
    return []
  }
}
