import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Priest extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.TradeOverseas1 || move.action === Action.TradeOverseas2)) {
      return [receiveGoodsMove(this.player.color, Good.Oil)]
    }
    return []
  }
}
