import {playerHasGoods} from '../../AFeastForOdin'
import Game from '../../Game'
import GameView from '../../GameView'
import Good from '../../material/goods/Good'
import Move from '../../moves/Move'
import {passMove} from '../../moves/Pass'
import {playOccupationMove} from '../../moves/PlayOccupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import {spendGoodsMove} from '../../moves/SpendGoods'
import {isPlayerView} from '../../PlayerView'
import Action from '../Action'
import ActionRules from '../ActionRules'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import PendingAction from '../../effects/PendingAction'
import MoveRandomized from '../../moves/MoveRandomized'

export default class PlayOneOccupationRules extends ActionRules {
  action = Action.PlayOneOccupation

  constructor(game: Game | GameView) {
    super(game)
  }

  createPendingAction(): PendingAction {
    return {action: this.action, occupationsPlayed: 0}
  }

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.goodsReceived) {
      moves.push(receiveGoodsMove(this.player.color, Good.Silver))
    }
    return moves
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (!this.pendingAction.costPaid) {
      if (this.hasOccupationInHand()) {
        if (playerHasGoods(this.player, Good.Stone)) {
          moves.push(spendGoodsMove(this.player.color, Good.Stone))
        }
        if (playerHasGoods(this.player, Good.Ore)) {
          moves.push(spendGoodsMove(this.player.color, Good.Ore))
        }
      }
      moves.push(passMove(this.player.color))
    } else {
      moves.push(...this.playOccupationMoves())
    }
    return moves
  }

  play(move: MoveRandomized | MoveView) {
    const consequences = super.play(move)
    switch (move.type) {
      case MoveType.PlayOccupation:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.complete()
        }
        break
    }
    return consequences
  }

  protected hasOccupationInHand() {
    return isPlayerView(this.player) ? this.player.hand > 0 : this.player.hand.length > 0
  }

  protected playOccupationMoves() {
    if (isPlayerView(this.player)) return []
    return this.player.hand.map(occupation => playOccupationMove(this.player.color, occupation))
  }
}
