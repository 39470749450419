import {isEnumValue} from '@gamepark/rules-api'

enum Weapon {
  Bow = 1, Snare, Spear, Sword
}

export const weapons = Object.values(Weapon).filter(isEnumValue)

export type Weapons = Weapon | Weapon[] | Partial<Record<Weapon, number>>

export function weaponsMap(weapons: Weapons) {
  if (typeof weapons === 'number') {
    return {[weapons]: 1}
  } else if (Array.isArray(weapons)) {
    return weaponsArrayToWeaponsMap(weapons)
  } else {
    return weapons
  }
}

export function weaponsArrayToWeaponsMap(weapons: Weapon[]) {
  return weapons.reduce<Partial<Record<Weapon, number>>>((map, weapon) => {
    if (!map[weapon]) map[weapon] = 0
    map[weapon]!++
    return map
  }, {})
}

export default Weapon