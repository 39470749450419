import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import {BanquetPlacementArea, getBanquet} from '../../phases/Feast'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'

export default class Chief extends OccupationRules {
  immediateEffect(): Move[] {
    this.player.effects.unshift({occupation: Occupation.Chief})
    return []
  }

  getPlayerMoves() {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      this.player.thingPenalties += new BanquetPlacementArea(this.player).getThingPenalties()
      getBanquet(this.player).placedGoods = []
      this.player.effects.shift()
    }
    return consequences
  }
}
