import Good from '../../material/goods/Good'
import {drawOccupationMove} from '../../moves/DrawOccupation'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import ActionRules from '../ActionRules'
import Action from '../Action'
import MoveView from '../../moves/MoveView'
import Move from '../../moves/Move'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveType from '../../moves/MoveType'
import Player from '../../Player'
import PlayerView, {isPlayerView} from '../../PlayerView'
import Occupation from '../../material/Occupation'
import Game from '../../Game'
import GameView, {isGameView} from '../../GameView'
import {startEffectMove} from '../../moves/StartEffect'

export default class DrawOccupationRules extends ActionRules {
  action = Action.DrawOccupation

  getAutomaticMoves(): (Move | MoveView)[] {
    const moves = super.getAutomaticMoves()
    if (!this.pendingAction.complete) {
      moves.push(receiveGoodsMove(this.player.color, Good.Silver))
      moves.push(...prepareToDrawOccupation(this.game, this.player))
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    if (move.type === MoveType.ReceiveGoods && move.player === this.player.color) {
      this.complete()
    }
    return super.play(move)
  }
}

export function prepareToDrawOccupation(game: Game | GameView, player: Player | PlayerView): Move[] {
  if (player.occupations.includes(Occupation.Preceptor) && (isPlayerView(player) ? player.hand : player.hand.length) > 0) {
    return [startEffectMove(player.color, {occupation: Occupation.Preceptor})]
  } else if (countOccupationDeck(game) > 0) {
    return [drawOccupationMove(player.color)]
  } else {
    return []
  }
}

export function countOccupationDeck(game: Game | GameView) {
  return isGameView(game) ? game.occupationsDeck : game.occupationsDeck.length
}
