/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next'

type Props = {
  battleResult: number
  selectedGoodValue?: number
}

export default function RaiderEffectInfo({battleResult, selectedGoodValue}: Props) {
  const {t} = useTranslation()
  if (!selectedGoodValue || battleResult - selectedGoodValue < 6) {
    return <p><strong>[{t('Raider')}]</strong> {t('raider.1')}</p>
  } else {
    return <p>{t('raider.effect', {value: battleResult - selectedGoodValue})}</p>
  }
}
