import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {receiveWeaponsMove} from '../../moves/ReceiveWeapons'
import Weapon from '../Weapon'

export default class Furrier extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color && actionsProvidingExactly1Silver.includes(move.action)) {
      return [receiveWeaponsMove(this.player.color, [Weapon.Snare])]
    }
    return []
  }
}

const actionsProvidingExactly1Silver = [
  Action.GetBeans, Action.GetFlaxAndFish, Action.GetFruitsOilMeat, Action.GetSpiceMilkWool,
  Action.CraftRuneStone, Action.CraftChest, Action.DrawOccupation, Action.PlayOneOccupation
]