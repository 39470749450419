import Move from '../../moves/Move'
import Good from '../goods/Good'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import Occupation from '../Occupation'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'

export default class ShipsCook extends UpgradeGoodOccupationRules {
  get upgradableGoods() {
    return shipsCooksGoods
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color
      && (move.action === Action.TradeOverseas1 || move.action === Action.TradeOverseas2)) {
      this.player.effects.unshift({occupation: Occupation.ShipsCook})
    }
    return []
  }
}

export const shipsCooksGoods = [Good.SaltMeat, Good.GameMeat, Good.WhaleMeat]
