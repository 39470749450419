/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import PlayerLocal from '../PlayerLocal'
import {PassButton} from '../PassButton'
import {PlayMoveButton} from '../PlayMoveButton'
import Weapon from '@gamepark/a-feast-for-odin/material/Weapon'
import {spendWeaponsMove} from '@gamepark/a-feast-for-odin/moves/SpendWeapons'

type Props = {
  player: PlayerLocal
}

export default function SnareSpecialistChoice({player}: Props) {
  const snares = player.weapons[Weapon.Snare] ?? 0
  return (
    <div>
      <p>
        <Trans defaults="snare.specialist.effect" components={[
          <PlayMoveButton move={spendWeaponsMove(player.color, {[Weapon.Snare]: 2})} disabled={snares < 2}/>
        ]}/>
      </p>
      <p>
        <Trans defaults="effect.renounce" components={[<PassButton/>]}/>
      </p>
    </div>
  )
}
