import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Phase from '../../phases/Phase'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class ThingSpokesman extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ReturnVikings && move.player === this.player.color && this.game.phase === Phase.Actions && move.actions.length <= 2) {
      return [receiveGoodsMove(this.player.color, Good.Silver)]
    }
    return []
  }
}
