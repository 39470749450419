/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import Boat from '@gamepark/a-feast-for-odin/material/boats/Boat'
import {isLongship} from '@gamepark/a-feast-for-odin/material/boats/Longship'
import {isWhalingBoat} from '@gamepark/a-feast-for-odin/material/boats/WhalingBoat'
import Good from '@gamepark/a-feast-for-odin/material/goods/Good'
import {forwardRef, HTMLAttributes, Ref} from 'react'
import GoodItem from '../goods/GoodItem'
import {boatOreSquareSize, longshipOreGap, longshipOreLeft, longshipOreTop, whalingBoatOreLeft, whalingBoatOreTop} from '../styles'
import BoatTile from './BoatTile'

type Props = {
  boat: Boat
} & HTMLAttributes<HTMLDivElement>

function PlayerBoat({innerRef, boat, ...props}: Props & { innerRef: Ref<HTMLDivElement> }) {
  return (
    <BoatTile ref={innerRef} boatType={boat.type} {...props}>
      {isWhalingBoat(boat) && boat.extraOre && <GoodItem good={Good.Ore} css={whalingBoatOrePosition}/>}
      {isLongship(boat) && boat.ore > 0 && [...Array(boat.ore)].map((_, index) =>
        <GoodItem key={index} good={Good.Ore} css={longshipOrePosition(index)}/>
      )}
    </BoatTile>
  )
}

export default forwardRef<HTMLDivElement, Props>((props, ref) => <PlayerBoat innerRef={ref} {...props}/>)

const whalingBoatOrePosition = css`
  position: absolute;
  top: ${whalingBoatOreTop}em;
  left: ${whalingBoatOreLeft}em;
`

const longshipOrePosition = (index: number) => css`
  position: absolute;
  top: ${longshipOreTop + index * (boatOreSquareSize + longshipOreGap)}em;
  left: ${longshipOreLeft}em;
`