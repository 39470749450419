import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Action, {isCrafting} from '../../actions/Action'
import Occupation from '../Occupation'
import {getPendingAction} from '../../AFeastForOdin'
import Good, {Goods, goodsToMap} from '../goods/Good'
import ForestBlacksmith from './ForestBlacksmith'
import MountainStrip from '../MountainStrip'
import ExtractToPayActionRules from './ExtractToPayActionRules'
import Effect from '../../effects/Effect'

// TODO norwegian actions
export default class ApprenticeCraftsman extends ExtractToPayActionRules {
  effect: Effect = {occupation: Occupation.ApprenticeCraftsman}

  isEligibleAction(action: Action): boolean {
    return isCrafting(action)
  }

  hasForestBlacksmith() {
    return this.player.occupations.includes(Occupation.ForestBlacksmith)
  }

  stripsToExtractMissingGoods(cost: Goods, action: Action): MountainStrip[] {
    const costMap = goodsToMap(cost)
    // Awful combo: need to check all the resources available by extracting two time 1 resource...
    if (costMap[Good.Ore] && this.hasForestBlacksmith()) {
      return this.game.mountainStrips.filter(strip =>
        this.game.mountainStrips.some(strip2 => {
          const goods = this.getExtractedGoods(strip, strip2)
          return this.isEnoughToPay(goods, costMap)
        })
      )
    } else {
      return super.stripsToExtractMissingGoods(cost, action)
    }
  }

  postActionPlayed(move: MoveRandomized | MoveView) {
    const consequences = super.postActionPlayed(move)
    switch (move.type) {
      case MoveType.TakeGoodsFromMountainStrip:
      case MoveType.Pass:
        if (move.player === this.player.color) {
          if (this.hasForestBlacksmith() && new ForestBlacksmith(this.game, this.player).isEligibleAction(getPendingAction(this.game)!.action)) {
            this.player.effects.unshift({occupation: Occupation.ForestBlacksmith})
          }
        }
        break
    }
    return consequences
  }
}
