/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PlayMoveButton} from '../../PlayMoveButton'
import PendingAction from '@gamepark/a-feast-for-odin/effects/PendingAction'
import Action from '@gamepark/a-feast-for-odin/actions/Action'
import {displayPlayerCardsMove} from '../../player/cards/DisplayPlayerCards'
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {PassButton} from '../../PassButton'

type Props = {
  pendingAction: PendingAction
  playerId: PlayerColor
}

export default function PlayOccupationsChoice({pendingAction, playerId}: Props) {
  const max = pendingAction.action === Action.PlayOneOccupation ? 1 : pendingAction.action === Action.PlayTwoOccupations ? 2 : 4
  const played = pendingAction.occupationsPlayed ?? 0
  if (!played) {
    return <Trans defaults="play.occupations" values={{occupations: max - played}}
                  components={[<PlayMoveButton move={displayPlayerCardsMove(playerId, true)} local/>]}/>
  } else {
    return <Trans defaults="play.occupations.or.pass" values={{occupations: max - played}}
                  components={[<PlayMoveButton move={displayPlayerCardsMove(playerId, true)} local/>, <PassButton/>]}/>
  }
}
