/** @jsxImportSource @emotion/react */
import {ButtonHTMLAttributes} from 'react'
import {PlayMoveButton} from './PlayMoveButton'
import PlayerLocal from './PlayerLocal'
import {Goods} from '@gamepark/a-feast-for-odin/material/goods/Good'
import {spendGoodsMove} from '@gamepark/a-feast-for-odin/moves/SpendGoods'
import {playerHasGoods} from '@gamepark/a-feast-for-odin/AFeastForOdin'

type Props = {
  player: PlayerLocal
  goods: Goods
} & ButtonHTMLAttributes<HTMLButtonElement>

export function PayButton({player, goods, ...props}: Props) {
  return <PlayMoveButton move={spendGoodsMove(player.color, goods)} disabled={!playerHasGoods(player, goods)} {...props}/>
}