import {Rules} from '@gamepark/rules-api'
import Game from '../Game'
import GameView from '../GameView'
import Move from '../moves/Move'
import MoveView from '../moves/MoveView'
import PlayerColor from '../PlayerColor'
import {startNextPhaseMove} from '../moves/StartNextPhase'

export default class StartPlayerPhaseRules extends Rules<Game | GameView, Move | MoveView, PlayerColor> {
  getAutomaticMoves(): (Move | MoveView)[] {
    if (this.game.players.some(player => player.effects.length > 0)) return []
    return [startNextPhaseMove] // Last active player simply remains current player for next turn
  }
}