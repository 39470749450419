import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'
import Move from '../../moves/Move'
import {drawWeaponMove} from '../../moves/DrawWeapon'
import MoveType from '../../moves/MoveType'
import BoatType from '../boats/BoatType'
import {hasLongship} from '../boats/LandingStages'
import Action from '../../actions/Action'
import {getActionRules, getPendingAction} from '../../AFeastForOdin'
import {hasBonusForPlacingFourVikings} from '../../actions/BonusForPlacingFourVikingsRules'
import {isRaidingRules} from '../../actions/sailing/RaidingRules'

export default class Helmsman extends OccupationRules {
  immediateEffect() {
    return [receiveGoodsMove(this.player.color, Good.Chest)]
  }

  eachTimeEffect(move: Move): Move[] {
    return this.usedALongship(move) ? [drawWeaponMove(this.player.color)] : []
  }

  private usedALongship(move: Move) {
    const pendingAction = getPendingAction(this.game)
    if (this.game.currentPlayer !== this.player.color || !pendingAction) return false
    switch (move.type) {
      case MoveType.EmigrateBoat:
        return move.player === this.player.color && this.player.landingStages.longBoats[move.landingStage]!.type === BoatType.Longship
      case MoveType.ClaimTerritory:
        return hasLongship(this.player)
    }
    const actionRules = getActionRules(this.game)
    if (move.type !== MoveType.DrawWeapon && actionRules?.player.color === this.player.color
      && (isRaidingRules(actionRules) || actionRules?.action === Action.Plundering)
      && actionRules.pendingAction.complete && !hasBonusForPlacingFourVikings(actionRules.pendingAction)) {
      return hasLongship(this.player)
    }
    return false
  }
}
