import OccupationRules from './OccupationRules'
import {passMove} from '../../moves/Pass'
import Move from '../../moves/Move'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import MoveRandomized from '../../moves/MoveRandomized'
import Occupation from '../Occupation'
import {playerHasGoods} from '../../AFeastForOdin'
import Good from '../goods/Good'
import {spendGoodsMove} from '../../moves/SpendGoods'
import Action from '../../actions/Action'
import LocationType from '../LocationType'
import {chooseActionMove} from '../../moves/ChooseAction'
import {returnVikingsMove} from '../../moves/ReturnVikings'

export type InspectorEffect = {
  occupation: Occupation.Inspector
  costPaid?: boolean
}

export default class Inspector extends OccupationRules {
  immediateEffect(): Move[] {
    if (this.hasTwoVikingOnSameAction()) {
      this.player.effects.unshift({occupation: Occupation.Inspector})
    } else {
      this.player.effects.unshift({occupation: Occupation.Inspector, costPaid: false})
    }
    return []
  }

  hasTwoVikingOnSameAction() {
    const actions: Action[] = []
    for (const vikingLocation of this.player.vikings) {
      if (vikingLocation.type === LocationType.Action) {
        if (actions.includes(vikingLocation.action)) return true
        else actions.push(vikingLocation.action)
      }
    }
    return false
  }

  get effect(): InspectorEffect {
    return this.player.effects[0] as InspectorEffect
  }

  getPlayerMoves() {
    const moves: Move[] = [passMove(this.player.color)]
    if (this.effect.costPaid === undefined) {
      if (playerHasGoods(this.player, {[Good.Silver]: this.game.round})) {
        moves.push(spendGoodsMove(this.player.color, {[Good.Silver]: this.game.round}))
      }
    } else {
      const actions: Action[] = []
      for (const vikingLocation of this.player.vikings) {
        if (vikingLocation.type === LocationType.Action) {
          const action = vikingLocation.action
          if (!moves.some(move => move.type === MoveType.ChooseAction && move.action === action) && (!this.effect.costPaid || actions.includes(action))) {
            moves.push(chooseActionMove(this.player.color, action))
          } else {
            actions.push(action)
          }
        }
      }
    }
    return moves
  }

  play(move: MoveRandomized | MoveView): Move[] {
    const consequences = super.play(move)
    if (move.type === MoveType.Pass && move.player === this.player.color) {
      if (this.effect.costPaid === undefined) {
        this.effect.costPaid = false
      } else {
        this.player.effects.shift()
      }
    } else if (move.type === MoveType.SpendGoods && move.player === this.player.color) {
      this.effect.costPaid = true
    } else if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      consequences.push(returnVikingsMove(this.player.color, Array(this.effect.costPaid ? 2 : 1).fill(move.action)))
      this.player.effects.shift()
    }
    return consequences
  }
}
