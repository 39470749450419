import Game from '../Game'
import GameView from '../GameView'
import Action from './Action'
import ActionRules from './ActionRules'
import BuildShedRules from './building/BuildShedRules'
import BuildStoneHouseRules from './building/BuildStoneHouseRules'
import BuildLongHouseRules from './building/BuildLongHouseRules'
import {BuildWhalingBoatRules} from './building/BuildWhalingBoatRules'
import {BuildKnarrRules} from './building/BuildKnarrRules'
import {BuildLongshipRules} from './building/BuildLongshipRules'
import BuildHouseAndBoatRules from './building/BuildHouseAndBoatRules'
import HuntingGameWithOneVikingRules from './hunting/HuntingGameWithOneVikingRules'
import HuntingGameWithTwoVikingsRules from './hunting/HuntingGameWithTwoVikingsRules'
import FishingRules from './hunting/FishingRules'
import LayingSnareRules from './hunting/LayingSnareRules'
import WhalingRules from './hunting/WhalingRules'
import WhalingWithOneBoatRules from './hunting/WhalingWithOneBoatRules'
import BuyFishRules from './market/BuyFishRules'
import BuySheepRules from './market/BuySheepRules'
import BuyMeatRules from './market/BuyMeatRules'
import BuyCattleAndMilkRules from './market/BuyCattleAndMilkRules'
import BuySheepOrCattleRules from './market/BuySheepOrCattleRules'
import BuySheepAndCattleRules from './market/BuySheepAndCattleRules'
import GetBeansRules from './market/GetBeansRules'
import GetFlaxAndFishRules from './market/GetFlaxAndFishRules'
import GetFruitsOilMeatRules from './market/GetFruitsOilMeatRules'
import MilkingRules from './market/MilkingRules'
import GetMeadRules from './market/GetMeadRules'
import ShearingRules from './market/ShearingRules'
import GetSpiceMilkWoolRules from './market/GetSpiceMilkWoolRules'
import CraftLinenRules from './crafting/CraftLinenRules'
import CraftClothingRules from './crafting/CraftClothingRules'
import SmithyRules from './crafting/SmithyRules'
import CraftRuneStoneRules from './crafting/CraftRuneStoneRules'
import CraftChestRules from './crafting/CraftChestRules'
import CraftChestsAndRuneStonesRules from './crafting/CraftChestsAndRuneStonesRules'
import CraftRobeAndJewelryRules from './crafting/CraftRobeAndJewelryRules'
import Take2ResourcesRules from './mountainsAndTrade/Take2ResourcesRules'
import TakeWoodAndOreRules from './mountainsAndTrade/TakeWoodAndOreRules'
import Take5ResourcesRules from './mountainsAndTrade/Take5ResourcesRules'
import Take1ResourceAndTradeRules from './mountainsAndTrade/Take1ResourceAndTradeRules'
import Take3ResourcesAndTradeRules from './mountainsAndTrade/Take3ResourcesAndTradeRules'
import Trade3AndWeaponsRules from './mountainsAndTrade/Trade3AndWeaponsRules'
import Take4ResourcesAndTrade2Rules from './mountainsAndTrade/Take4ResourcesAndTrade2Rules'
import Trade2Rules from './mountainsAndTrade/Trade2Rules'
import Trade3Rules from './mountainsAndTrade/Trade3Rules'
import Trade4Rules from './mountainsAndTrade/Trade4Rules'
import TradeOverseas1Rules from './sailing/TradeOverseas1Rules'
import TradeOverseas2Rules from './sailing/TradeOverseas2Rules'
import SpecialSaleRules from './sailing/SpecialSaleRules'
import Take8ResourcesOrTrade3Rules from './mountainsAndTrade/Take8ResourcesOrTrade3Rules'
import RaidingRules from './sailing/RaidingRules'
import Pillaging1Rules from './sailing/Pillaging1Rules'
import Pillaging2Rules from './sailing/Pillaging2Rules'
import ExploreIIRules from './sailing/ExploreIIRules'
import ExploreIRules from './sailing/ExploreIRules'
import ExploreIIIRules from './sailing/ExploreIIIRules'
import PlunderingRules from './sailing/PlunderingRules'
import DrawOccupationRules from './occupation/DrawOccupationRules'
import Emigrate1Rules from './emigration/Emigrate1Rules'
import Emigrate2Rules from './emigration/Emigrate2Rules'
import ExchangeBoatAndEmigrateRules from './emigration/ExchangeBoatAndEmigrateRules'
import PlayOneOccupationRules from './occupation/PlayOneOccupationRules'
import PlayTwoOccupationsRules from './occupation/PlayTwoOccupationsRules'
import PlayFourOccupationsRules from './occupation/PlayFourOccupationsRules'
import Imitation1Rules from './imitation/Imitation1Rules'
import Imitation2Rules from './imitation/Imitation2Rules'
import Imitation3Rules from './imitation/Imitation3Rules'
import Imitation4Rules from './imitation/Imitation4Rules'
import Player from '../Player'
import PlayerView from '../PlayerView'

const ActionsRules: Record<Action, new(game: Game | GameView, player?: Player | PlayerView) => ActionRules> = {
  [Action.BuildShed]: BuildShedRules,
  [Action.BuildStoneHouse]: BuildStoneHouseRules,
  [Action.BuildLongHouse]: BuildLongHouseRules,
  [Action.BuildWhalingBoat]: BuildWhalingBoatRules,
  [Action.BuildKnarr]: BuildKnarrRules,
  [Action.BuildLongship]: BuildLongshipRules,
  [Action.BuildHouseAndBoat]: BuildHouseAndBoatRules,
  [Action.HuntingGameWithOneViking]: HuntingGameWithOneVikingRules,
  [Action.HuntingGameWithTwoVikings]: HuntingGameWithTwoVikingsRules,
  [Action.Fishing]: FishingRules,
  [Action.LayingSnare]: LayingSnareRules,
  [Action.Whaling]: WhalingRules,
  [Action.WhalingWithOneBoat]: WhalingWithOneBoatRules,
  [Action.BuyFish]: BuyFishRules,
  [Action.BuySheep]: BuySheepRules,
  [Action.BuyMeat]: BuyMeatRules,
  [Action.BuyCattleAndMilk]: BuyCattleAndMilkRules,
  [Action.BuySheepOrCattle]: BuySheepOrCattleRules,
  [Action.BuySheepAndCattle]: BuySheepAndCattleRules,
  [Action.GetBeans]: GetBeansRules,
  [Action.GetFlaxAndFish]: GetFlaxAndFishRules,
  [Action.GetFruitsOilMeat]: GetFruitsOilMeatRules,
  [Action.Milking]: MilkingRules,
  [Action.GetMead]: GetMeadRules,
  [Action.Shearing]: ShearingRules,
  [Action.GetSpiceMilkWool]: GetSpiceMilkWoolRules,
  [Action.CraftLinen]: CraftLinenRules,
  [Action.CraftClothing]: CraftClothingRules,
  [Action.Smithy]: SmithyRules,
  [Action.CraftRuneStone]: CraftRuneStoneRules,
  [Action.CraftChest]: CraftChestRules,
  [Action.CraftChestsAndRuneStones]: CraftChestsAndRuneStonesRules,
  [Action.CraftRobeAndJewelry]: CraftRobeAndJewelryRules,
  [Action.Take2Resources]: Take2ResourcesRules,
  [Action.TakeWoodAndOre]: TakeWoodAndOreRules,
  [Action.Take5Resources]: Take5ResourcesRules,
  [Action.Take1ResourceAndTrade]: Take1ResourceAndTradeRules,
  [Action.Take3ResourcesAndTrade]: Take3ResourcesAndTradeRules,
  [Action.Trade3AndWeapons]: Trade3AndWeaponsRules,
  [Action.Take4ResourcesAndTrade2]: Take4ResourcesAndTrade2Rules,
  [Action.Trade2]: Trade2Rules,
  [Action.Trade3]: Trade3Rules,
  [Action.Trade4]: Trade4Rules,
  [Action.TradeOverseas1]: TradeOverseas1Rules,
  [Action.TradeOverseas2]: TradeOverseas2Rules,
  [Action.SpecialSale]: SpecialSaleRules,
  [Action.Take8ResourcesOrTrade3]: Take8ResourcesOrTrade3Rules,
  [Action.Raiding]: RaidingRules,
  [Action.Pillaging1]: Pillaging1Rules,
  [Action.Pillaging2]: Pillaging2Rules,
  [Action.ExploreI]: ExploreIRules,
  [Action.ExploreII]: ExploreIIRules,
  [Action.ExploreIII]: ExploreIIIRules,
  [Action.Plundering]: PlunderingRules,
  [Action.DrawOccupation]: DrawOccupationRules,
  [Action.Emigrate1]: Emigrate1Rules,
  [Action.Emigrate2]: Emigrate2Rules,
  [Action.PlayOneOccupation]: PlayOneOccupationRules,
  [Action.PlayTwoOccupations]: PlayTwoOccupationsRules,
  [Action.PlayFourOccupations]: PlayFourOccupationsRules,
  [Action.ExchangeBoatAndEmigrate]: ExchangeBoatAndEmigrateRules,
  [Action.Imitation1]: Imitation1Rules,
  [Action.Imitation2]: Imitation2Rules,
  [Action.Imitation3]: Imitation3Rules,
  [Action.Imitation4]: Imitation4Rules

}

export default ActionsRules
