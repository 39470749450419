import Move from '../../moves/Move'
import Good from '../goods/Good'
import HuntingGameSuccessOccupationRules from './HuntingGameSuccessOccupationRules'
import TradeRules from '../../actions/mountainsAndTrade/TradeRules'
import {passMove} from '../../moves/Pass'
import MoveRandomized from '../../moves/MoveRandomized'
import MoveView from '../../moves/MoveView'
import MoveType from '../../moves/MoveType'
import {startEffectMove} from '../../moves/StartEffect'
import Occupation from '../Occupation'

export default class Deerstalker extends HuntingGameSuccessOccupationRules {
  onHuntingGameSuccess(): Move[] {
    return [startEffectMove(this.player.color, {occupation: Occupation.Deerstalker})]
  }

  delegate() {
    return new TradeRules(this.game, this.player, [{spend: {[Good.Silver]: 2}, receive: Good.SkinAndBones}])
  }

  getPlayerMoves(): Move[] {
    return [passMove(this.player.color)]
  }

  play(move: MoveRandomized | MoveView): (Move | MoveView)[] {
    switch (move.type) {
      case MoveType.ReceiveGoods:
        if (move.player === this.player.color && move.goods === Good.SkinAndBones) {
          this.player.effects.shift()
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.player.effects.shift()
        }
        break
    }
    return super.play(move)
  }
}
