import OccupationRules from './OccupationRules'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Building from '../Building'
import Good from '../goods/Good'
import {isBuildingPlacedGoodsArea} from '../goods/PlacedGoodsArea'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'

export default class Cottager extends OccupationRules {
  immediateEffect() {
    const houses = countStoneAndLongHouses(this.player)
    return houses > 0 ? [receiveGoodsMove(this.player.color, {[Good.Mead]: houses * 2})] : []
  }

  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.TakeBuilding && move.player === this.player.color
      && (move.building === Building.StoneHouse || move.building === Building.LongHouse)) {
      return [receiveGoodsMove(this.player.color, Good.Hide)]
    }
    return []
  }
}

export function countStoneAndLongHouses(player: Player | PlayerView) {
  return player.placedGoodsAreas.reduce((sum, area) =>
    isBuildingPlacedGoodsArea(area) && (area.goodsArea.building === Building.StoneHouse || area.goodsArea.building === Building.LongHouse)
      ? sum + 1 : sum, 0)
}
