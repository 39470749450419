import EffectRules from '../effects/EffectRules'
import Move from '../moves/Move'
import {isPlayerView} from '../PlayerView'
import {playOccupationMove} from '../moves/PlayOccupation'
import {passMove} from '../moves/Pass'
import MoveView from '../moves/MoveView'
import MoveType from '../moves/MoveType'
import Game from '../Game'
import GameView from '../GameView'
import MoveRandomized from '../moves/MoveRandomized'
import PendingAction from '../effects/PendingAction'

export default class BonusForPlacingFourVikingsRules extends EffectRules {

  constructor(game: Game | GameView) {
    super(game, game.players.find(p => p.color === game.currentPlayer)!)
  }

  getPlayerMoves(): Move[] {
    const moves: Move[] = []
    if (!isPlayerView(this.player)) {
      for (const occupation of this.player.hand) {
        moves.push(playOccupationMove(this.player.color, occupation))
      }
    }
    moves.push(passMove(this.player.color))
    return moves
  }

  play(move: MoveRandomized | MoveView) {
    switch (move.type) {
      case MoveType.PlayOccupation:
        if (move.player === this.player.color) {
          this.game.pendingAction!.playOccupationBonus = {used: true}
        }
        break
      case MoveType.Pass:
        if (move.player === this.player.color) {
          this.game.pendingAction!.playOccupationBonus = {used: !!this.game.pendingAction?.complete}
        }
        break
    }
    return []
  }
}

export function hasBonusForPlacingFourVikings(pendingAction: PendingAction) {
  if (pendingAction.action % 10 !== 4) return false
  if (pendingAction.complete) return !pendingAction.playOccupationBonus?.used
  return !pendingAction.playOccupationBonus
}