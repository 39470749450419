import {isEnumValue} from '@gamepark/rules-api'

enum Action {
  BuildShed = 1, BuildStoneHouse, BuildLongHouse,
  BuildWhalingBoat = 11, BuildKnarr, BuildLongship, BuildHouseAndBoat,
  HuntingGameWithOneViking = 21, HuntingGameWithTwoVikings,
  Fishing = 31, LayingSnare, Whaling, WhalingWithOneBoat,
  BuyFish = 41, BuySheep,
  BuyMeat = 51, BuyCattleAndMilk, BuySheepOrCattle, BuySheepAndCattle,
  GetBeans = 61, GetFlaxAndFish, GetFruitsOilMeat,
  Milking = 71, GetMead, Shearing, GetSpiceMilkWool,
  CraftLinen = 81, CraftClothing, Smithy,
  CraftRuneStone = 91, CraftChest, CraftChestsAndRuneStones, CraftRobeAndJewelry,
  Take2Resources = 101, TakeWoodAndOre, Take5Resources,
  Take1ResourceAndTrade = 111, Take3ResourcesAndTrade, Trade3AndWeapons, Take4ResourcesAndTrade2,
  Trade2 = 121, Trade3, Trade4,
  TradeOverseas1 = 131, TradeOverseas2, SpecialSale, Take8ResourcesOrTrade3,
  Raiding = 141, Pillaging1, Pillaging2,
  ExploreI = 151, ExploreII, ExploreIII, Plundering,
  DrawOccupation = 161, Emigrate1, Emigrate2,
  PlayOneOccupation = 171, PlayTwoOccupations, PlayFourOccupations, ExchangeBoatAndEmigrate,
  Imitation1 = 181, Imitation2, Imitation3, Imitation4
}

export default Action

export const actions = Object.values(Action).filter(isEnumValue)

export const baseGameActions = actions.filter(action => action > 0 && action < 180)

export function isCrafting(action: Action) {
  return action >= 81 && action <= 94
}