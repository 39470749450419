import Game from '../Game'
import GameView from '../GameView'
import Weapon from '../material/Weapon'
import PlayerColor from '../PlayerColor'
import {addWeapon} from './DrawWeapon'
import MoveType from './MoveType'
import {shuffleWeaponsDeckMove} from './ShuffleWeaponsDeck'
import Move from './Move'

type ReceiveWeapons = {
  type: MoveType.ReceiveWeapons
  player: PlayerColor
  weapons: Weapon[]
}

export default ReceiveWeapons

export type ReceiveWeaponsView = ReceiveWeapons & { fromDeck: number }

export function receiveWeaponsMove(player: PlayerColor, weapons: Weapon[]): ReceiveWeapons {
  return {type: MoveType.ReceiveWeapons, player, weapons}
}

export function receiveWeapons(game: Game, move: ReceiveWeapons): Move[] {
  const player = game.players.find(player => player.color === move.player)!
  let weaponTakenFromDeck = false
  for (const weapon of move.weapons) {
    addWeapon(player, weapon)
    if (game.weaponsDiscard.includes(weapon)) {
      game.weaponsDiscard.splice(game.weaponsDiscard.indexOf(weapon), 1)
    } else if (game.weaponsDeck.includes(weapon)) {
      game.weaponsDeck.splice(game.weaponsDeck.indexOf(weapon), 1)
      weaponTakenFromDeck = true
    }
  }
  return weaponTakenFromDeck && game.weaponsDeck.length > 1 ? [shuffleWeaponsDeckMove] : []
}

export function receiveWeaponInView(game: GameView, move: ReceiveWeaponsView) {
  const player = game.players.find(player => player.color === move.player)!
  for (const weapon of move.weapons) {
    addWeapon(player, weapon)
    if (game.weaponsDiscard.includes(weapon)) {
      game.weaponsDiscard.splice(game.weaponsDiscard.indexOf(weapon), 1)
    }
  }
  game.weaponsDeck -= move.fromDeck
}
