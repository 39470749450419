import OccupationRules from './OccupationRules'
import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import Action from '../../actions/Action'
import {receiveGoodsMove} from '../../moves/ReceiveGoods'
import Good from '../goods/Good'

export default class Artist extends OccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.ChooseAction && move.player === this.player.color) {
      switch (move.action) {
        case Action.CraftRuneStone:
        case Action.CraftChest:
        case Action.Smithy:
          return [receiveGoodsMove(this.player.color, Good.Silver)]
        case Action.CraftChestsAndRuneStones:
          return [receiveGoodsMove(this.player.color, {[Good.Silver]: 2})]
        // TODO Norwegian actions
      }
    }
    return []
  }
}
