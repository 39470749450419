import Move from '../../moves/Move'
import MoveType from '../../moves/MoveType'
import BoatType from '../boats/BoatType'
import UpgradeGoodOccupationRules from './UpgradeGoodOccupationRules'
import Occupation from '../Occupation'

export default class Shipowner extends UpgradeGoodOccupationRules {
  eachTimeEffect(move: Move): Move[] {
    if (move.type === MoveType.BuildBoat && move.player === this.player.color && move.boat !== BoatType.WhalingBoat) {
      this.player.effects.unshift({occupation: Occupation.Shipowner})
    }
    return []
  }
}
