import Game from '../Game'
import GameView from '../GameView'
import Occupation from '../material/Occupation'
import PlayerColor from '../PlayerColor'
import Player from '../Player'
import PlayerView from '../PlayerView'
import MoveType from './MoveType'

type DrawOccupation = {
  type: MoveType.DrawOccupation
  player: PlayerColor
}

export default DrawOccupation

export type DrawOccupationView = DrawOccupation & {
  occupation: Occupation
}

export function isDrawOccupationView(move: DrawOccupation | DrawOccupationView): move is DrawOccupationView {
  return (move as DrawOccupationView).occupation !== undefined
}

export function drawOccupationMove(player: PlayerColor): DrawOccupation {
  return {type: MoveType.DrawOccupation, player}
}

export function drawOccupation(game: Game, move: DrawOccupation) {
  const player = game.players.find(player => player.color === move.player)!
  player.hand.push(game.occupationsDeck.shift()!)
}

export function drawOccupationInView(game: GameView, move: DrawOccupation | DrawOccupationView) {
  if (isDrawOccupationView(move)) {
    const player = game.players.find(player => player.color === move.player) as Player
    game.occupationsDeck--
    player.hand.push(move.occupation)
  } else {
    const player = game.players.find(player => player.color === move.player) as PlayerView
    game.occupationsDeck--
    player.hand++
  }
}
