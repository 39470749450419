import {BanquetPlacementArea, getBanquet} from '../../phases/Feast'
import Player from '../../Player'
import PlayerView from '../../PlayerView'
import Banquet from './Banquet'
import BuildingGoodsArea from './BuildingGoodsArea'
import BuildingPlacementArea from './BuildingPlacementArea'
import GoodsAreaType from './GoodsAreaType'
import HomeGoodsArea, {getHomeGoodsArea} from './HomeGoodsArea'
import PlacedGoodsArea, {isBuildingPlacedGoodsArea} from './PlacedGoodsArea'
import TerritoryGoodsArea from './TerritoryGoodsArea'
import TerritoryPlacementArea from './TerritoryPlacementArea'
import Occupation from '../Occupation'
import PlacementArea from './PlacementArea'

type GoodsArea = HomeGoodsArea | Banquet | BuildingGoodsArea | TerritoryGoodsArea

export default GoodsArea

export function isSameGoodsArea(goodArea1: GoodsArea, goodArea2: GoodsArea) {
  switch (goodArea1.type) {
    case GoodsAreaType.Building:
      return goodArea2.type === GoodsAreaType.Building && goodArea1.building === goodArea2.building && goodArea1.id === goodArea2.id
    case GoodsAreaType.Territory:
      return goodArea2.type === GoodsAreaType.Territory && goodArea1.territory === goodArea2.territory
    default:
      return goodArea2.type === goodArea1.type
  }
}

export function getPlacementArea(player: Player | PlayerView, placedGoodsArea: PlacedGoodsArea): PlacementArea {
  switch (placedGoodsArea.goodsArea.type) {
    case GoodsAreaType.Banquet:
      return new BanquetPlacementArea(player, placedGoodsArea)
    case GoodsAreaType.Building:
      return new BuildingPlacementArea(placedGoodsArea as PlacedGoodsArea<BuildingGoodsArea>, player.occupations.includes(Occupation.MasterJoiner))
    default:
      return new TerritoryPlacementArea(placedGoodsArea)
  }
}

export function getPlacedGoodsArea(player: Player | PlayerView, goodsArea: GoodsArea) {
  switch (goodsArea.type) {
    case GoodsAreaType.Banquet:
      return getBanquet(player)
    case GoodsAreaType.Home:
      return getHomeGoodsArea(player)
    case GoodsAreaType.Territory:
      return player.placedGoodsAreas.find(area => area.goodsArea.type === GoodsAreaType.Territory && area.goodsArea.territory === goodsArea.territory)
    case GoodsAreaType.Building:
      return player.placedGoodsAreas.filter(isBuildingPlacedGoodsArea)
        .find(({goodsArea: {id, building}}) => building === goodsArea.building && id === goodsArea.id)
  }
}
