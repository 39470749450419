/** @jsxImportSource @emotion/react */
import PlayerColor from '@gamepark/a-feast-for-odin/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import ActionBoard from './actions/board/ActionBoard'
import BoardsSupply from './boards-supply/BoardsSupply'
import GameLocalView from './GameLocalView'
import MountainsScreen from './mountains/MountainsScreen'
import {getScreen} from './navigation/DisplayScreen'
import Screen from './navigation/Screen'
import PlayerBoard from './player/board/PlayerBoard'
import RoundOverview from './round-overview/RoundOverview'

type Props = {
  game: GameLocalView
}

export default function ScreenDisplay({game}: Props) {
  const playerId = usePlayerId()
  const screen = getScreen(game, playerId)
  switch (screen) {
    case Screen.RoundOverview:
      return <RoundOverview phase={game.phase} shortGame={game.shortGame}/>
    case Screen.ActionBoard:
      return <ActionBoard game={game}/>
    case Screen.Mountains:
      return <MountainsScreen game={game}/>
    case Screen.BoardsSupply:
      return <BoardsSupply game={game}/>
    case Screen.BluePlayer:
      return <PlayerBoard game={game} playerColor={PlayerColor.Blue}/>
    case Screen.RedPlayer:
      return <PlayerBoard game={game} playerColor={PlayerColor.Red}/>
    case Screen.BlackPlayer:
      return <PlayerBoard game={game} playerColor={PlayerColor.Black}/>
    case Screen.YellowPlayer:
      return <PlayerBoard game={game} playerColor={PlayerColor.Yellow}/>
  }
  return null
}
