import Coordinates from '../Coordinates'
import PlacementArea from './PlacementArea'
import {PlacementSpace} from './PlacementSpace'

export default abstract class IncomePlacementArea extends PlacementArea {
  protected incomeDiagonals: IncomeDiagonal[]

  protected constructor(area: PlacementSpace[][], incomeDiagonals: IncomeDiagonal[]) {
    super(area)
    this.incomeDiagonals = incomeDiagonals
  }

  isIncomeCoverValid() {
    return this.incomeDiagonals.every(incomeDiagonal => this.isIncomeDiagonalCoverValid(incomeDiagonal))
  }

  private isIncomeDiagonalCoverValid(incomeDiagonal: IncomeDiagonal) {
    let {x, y} = incomeDiagonal
    while (--x >= 0 && --y >= 0) {
      const space = this.getSpace({x, y})
      if (typeof space?.income === 'number' && space.good) {
        return this.isIncomeSquareCovered({x, y})
      }
    }
    return true
  }

  private isIncomeSquareCovered(coordinates: Coordinates) {
    for (let x = 0; x <= coordinates.x; x++) {
      for (let y = 0; y <= coordinates.y; y++) {
        if (!this.countAsCovered({x, y})) {
          return false
        }
      }
    }
    return true
  }

  getMissingIncomeCover(): Coordinates[] {
    return this.incomeDiagonals.flatMap(incomeDiagonal => this.getMissingIncomeDiagonalCover(incomeDiagonal))
  }

  private getMissingIncomeDiagonalCover(incomeDiagonal: IncomeDiagonal): Coordinates[] {
    let {x, y} = incomeDiagonal
    while (--x >= 0 && --y >= 0) {
      const space = this.getSpace({x, y})
      if (typeof space?.income === 'number' && space.good) {
        return this.getUncoveredCoordinatesBelow({x, y})
      }
    }
    return []
  }

  private getUncoveredCoordinatesBelow(coordinates: Coordinates) {
    const result: Coordinates[] = []
    for (let x = 0; x <= coordinates.x; x++) {
      for (let y = 0; y <= coordinates.y; y++) {
        if (!this.countAsCovered({x, y})) {
          result.push({x, y})
        }
      }
    }
    return result
  }

  getIncome(): number {
    return this.incomeDiagonals.reduce((income, diagonal) => income + this.getDiagonalIncome(diagonal), 0)
  }

  private getDiagonalIncome(diagonal: IncomeDiagonal): number {
    for (let i = Math.min(diagonal.x, diagonal.y); i > 0; i--) {
      const space = this.getSpace({x: diagonal.x - i, y: diagonal.y - i})
      if (typeof space?.income === 'number' && !space.good) {
        return space.income
      }
    }
    return diagonal.maxIncome
  }
}

export type IncomeDiagonal = { maxIncome: number } & Coordinates

export function isIncomePlacementArea(placementArea: PlacementArea): placementArea is IncomePlacementArea {
  return typeof (placementArea as IncomePlacementArea).getIncome === 'function'
}