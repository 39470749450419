/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus'
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {HTMLAttributes, PropsWithChildren} from 'react'
import {lightRedBackground, redBackground, titleButtonCss} from '../styles'

type SliderProps = {
  min?: number
  max?: number
  step?: number
  value?: number
  onValueChange: (value: number) => void
} & HTMLAttributes<HTMLDivElement>

export default function Slider({children, min = 0, max = 0, step, value = min, onValueChange, ...props}: PropsWithChildren<SliderProps>) {
  return (
    <div {...props}>
      <button css={[titleButtonCss, css`font-size: 4em;`]} disabled={value <= min} onClick={() => onValueChange(value - 1)}>
        <FontAwesomeIcon icon={faMinus}/>
      </button>
      {children}
      <button css={[titleButtonCss, css`font-size: 4em;`]} disabled={value >= max} onClick={() => onValueChange(value + 1)}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
      <input type="range" min={min} max={max} step={step} value={value} onChange={event => onValueChange(parseInt(event.target.value))} disabled={min >= max}
             css={inputCss}/>
    </div>
  )
}

const thumbSize = '2em'
const trackSize = '0.8em'
const thumbBg = redBackground
const trackBg = lightRedBackground
const progressBg = lightRedBackground
const webkitProgressPercent = '0%'

const inputCss = css`
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: ${thumbSize};
  padding: 0;
  margin: 1em 0 0;
  background: none;

  &:focus {
    outline: none;
  }
  
  &:disabled {
    filter: grayscale();
  }

  /* Thumb */

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${thumbSize};
    height: ${thumbSize};
    background-color: ${thumbBg};
    border-radius: calc(${thumbSize} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((${thumbSize} - ${trackSize}) / 2) * -1);
    &:not([disabled]) {
      cursor: pointer;
    }
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: ${thumbSize};
    height: ${thumbSize};
    background-color: ${thumbBg};
    border-radius: calc(${thumbSize} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((${thumbSize} - ${trackSize}) / 2) * -1);
    &:not([disabled]) {
      cursor: pointer;
    }
  }

  &::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: ${thumbSize};
    height: ${thumbSize};
    background-color: ${thumbBg};
    border-radius: calc(${thumbSize} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((${thumbSize} - ${trackSize}) / 2) * -1);
    &:not([disabled]) {
      cursor: pointer;
    }
  }

  /* Track */

  &::-webkit-slider-runnable-track {
    height: ${trackSize};
    background-image: linear-gradient(90deg, ${progressBg} ${webkitProgressPercent}, ${trackBg} ${webkitProgressPercent});
    border-radius: calc(${trackSize} / 2);
  }

  &::-moz-range-track {
    height: ${trackSize};
    background-color: ${trackBg};
    border-radius: calc(${trackSize} / 2);
  }

  &::-ms-track {
    height: ${trackSize};
    background-color: ${trackBg};
    border-radius: calc(${trackSize} / 2);
  }

  /* Progress */

  &::-moz-range-progress {
    height: ${trackSize};
    background-color: ${progressBg};
    border-radius: calc(${trackSize} / 2) 0 0 calc(${trackSize} / 2);
  }

  &::-ms-fill-lower {
    height: ${trackSize};
    background-color: ${progressBg};
    border-radius: calc(${trackSize} / 2) 0 0 calc(${trackSize} / 2);
  }


`
