/** @jsxImportSource @emotion/react */
import {Trans} from 'react-i18next'
import {PlayMoveButton} from '../PlayMoveButton'
import {displayPlayerGoodsMove} from '../player/goods/DisplayPlayerGoods'
import {displayScreenMove} from '../navigation/DisplayScreen'
import Screen from '../navigation/Screen'
import {PassButton} from '../PassButton'
import MountainsAndTradeRules from '@gamepark/a-feast-for-odin/actions/mountainsAndTrade/MountainsAndTradeRules'

type Props = {
  rules: MountainsAndTradeRules
}

export default function MountainsAndTradeChoice({rules}: Props) {
  const pendingAction = rules.pendingAction
  const playerId = rules.player.color
  if (pendingAction.mountainsExtracted && pendingAction.goodsTraded) {
    if (rules.canPass()) {
      return <Trans defaults="action.can.trade.or.extract"
                    components={[
                      <PlayMoveButton move={displayPlayerGoodsMove(playerId, true)} local/>,
                      <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
                      <PassButton/>
                    ]}/>
    } else {
      return <Trans defaults="action.must.trade.or.extract"
                    components={[
                      <PlayMoveButton move={displayPlayerGoodsMove(playerId, true)} local/>,
                      <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
                    ]}/>
    }
  } else if (pendingAction.mountainsExtracted) {
    if (rules.canPass()) {
      return <Trans defaults="action.can.extract"
                    components={[
                      <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>,
                      <PassButton/>
                    ]}/>
    } else {
      return <Trans defaults="action.must.extract"
                    components={[
                      <PlayMoveButton move={displayScreenMove(Screen.Mountains)} local/>
                    ]}/>
    }
  } else if (pendingAction.goodsTraded) {
    if (rules.canPass()) {
      return <Trans defaults="action.can.trade"
                    components={[
                      <PlayMoveButton move={displayPlayerGoodsMove(playerId, true)} local/>,
                      <PassButton/>
                    ]}/>
    } else {
      return <Trans defaults="action.must.trade"
                    components={[
                      <PlayMoveButton move={displayPlayerGoodsMove(playerId, true)} local/>
                    ]}/>
    }
  }
  return null
}